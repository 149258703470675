import "./ListProductStock.scss";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ItenOrdenReasiganation} from "../../molecules/ItenOrdenReasiganation/ItenOrdenReasiganation";
import { ReportStockCompany } from "../../../page/private/reports/ReportStockCompany";

export const ListProductStock = ({ items, handleChange , numberOfPages, currentPage, idc}) => {
    const groupedItems = items.reduce((acc, item) => {
        if (!acc[item.ido]) {
            acc[item.ido] = {
                ido: item.ido,
                date_create: item.date_create,
                date_delivery: item.date_delivery,
                date_delete: item.date_delete,
                business_id: item.business_id,
                idc: item.idc,
                kit: item.kit,
                orden_id: item.orden_id,
                ovelocity: item.ovelocity,
                products: []
            };
        }
    
        acc[item.ido].products.push({
            counter: item.counter,
            img: item.img,
            name: item.name,
            sku: item.sku
        });
    
        return acc;
    }, {});
    const resultArray = Object.values(groupedItems);
    return (
        <div className="list-product-reasignate">
            <div className="contect-list-product-reasignate">
                <div className="encabezado-list-reasignate">
                    <h2 className="title">Bodega de stock</h2>
                    <ReportStockCompany idc={idc}/>
                </div>
                {resultArray && (
                    <div className="list-reasignate">
                        {resultArray.map(items => (
                            <ItenOrdenReasiganation key={items.ido} {...items} items={items}/>
                        ))}
                    </div>
                )}
            </div>
            <div className="pagination-numb-reasignate">
                <Stack spacing={2}>
                    <Pagination count={numberOfPages} page={currentPage} onChange={handleChange} size="large" />
                </Stack>
            </div>
        </div>
    );
}