import { Link } from "react-router-dom";
import { ButtonHome2 } from "../../atoms/ButtonHome/ButtonHome";
import { BiCloudDownload , BiChevronLeft} from "react-icons/bi";
import "./DownloadGuia.scss"

export const DownloadGuia = () => {
    const pdfURL = "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/pdf%2FGuia%20Trabajo%20Remoto%20para%20Dummies_SHOFI_2023.pdf?alt=media&token=ab4aa17f-9026-45b3-833f-b0255cd687fa"; // Asegúrate de reemplazar esto con la URL real de tu PDF
    return (  
        <div className="main-register">
            <ButtonHome2 />
            <div className="container-register">
                <Link to="/" className="brand" >
                    <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2Fblanco%20SHOFI.png?alt=media&token=10552f6d-e2f8-432f-b592-95588b3d9a4a" />
                </Link>
                <h1 className="title">Perfecto</h1>
                <h3 className="subtitle2">Descarga tu guia de trabajo remoto <br></br>te seguiremos haciendo llegar actualizaciones</h3>
                <a 
                    href={pdfURL} 
                    download 
                    className="button-download"
                    target="_blank" 
                    rel="noopener noreferrer" 
                    >
                    <BiCloudDownload />
                    Descargar PDF
                </a>
                <Link 
                    className="button-volver"
                    to="/">
                        <BiChevronLeft />
                    Volver
                </Link>
            </div>
        </div>
    );
}