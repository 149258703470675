import "./BlogsAdmin.scss"
import { useState } from "react";
// Importar db y storage desde tu archivo de configuración de Firebase
import { db, storage } from '../../../../firebase/config';
import { collection, addDoc} from "firebase/firestore"; 
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// Alertas para componente
import { toast } from "react-toastify";
// Importar iconos
import { BiChevronLeft, BiPaperclip, BiX } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import { LuSave } from "react-icons/lu";
import { IoVideocamOutline } from "react-icons/io5";
import { IoMdImages } from "react-icons/io";
import { InfoLoading } from "../../../../Loanding";

export const CreatorBlogs = () => {
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(false);
    const [files, setFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const [videoFile, setVideoFile] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const [mainImagePreviewUrl, setMainImagePreviewUrl] = useState(null);
    const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
    const [isVideoUploading, setIsVideoUploading] = useState(false);

    const [formData, setFormData] = useState({
        title: "",
        meta: "",
        contect: "",
        videoUrlYoutube: "",
    });

    const handleVideoFileChange = (e) => {
        const file = e.target.files[0];
        setVideoFile(file);
        if (file) {
            setVideoPreviewUrl(URL.createObjectURL(file));
        } else {
            setVideoPreviewUrl(null);
        }
    };

    const handleRemoveVideo = () => {
        setVideoFile(null);
        setVideoPreviewUrl(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        if (files.length + e.target.files.length > 4) {
            alert("Solo puedes subir un máximo de 4 imágenes");
            return;
        }
        const selectedFiles = Array.from(e.target.files);
        setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
        const newPreviewUrls = selectedFiles.map(file => URL.createObjectURL(file));
        setPreviewUrls(prevUrls => [...prevUrls, ...newPreviewUrls]);
    };

    const handleRemoveFile = (index) => {
        const newFiles = files.filter((_, fileIndex) => fileIndex !== index);
        const newPreviewUrls = previewUrls.filter((_, urlIndex) => urlIndex !== index);
        setFiles(newFiles);
        setPreviewUrls(newPreviewUrls);
    }; 

    const handleMainImageChange = (e) => {
        const file = e.target.files[0];
        setMainImage(file);
        if (file) {
            setMainImagePreviewUrl(URL.createObjectURL(file));
        } else {
            setMainImagePreviewUrl(null);
        }
    };
    
    const handleRemoveMainImage = () => {
        setMainImage(null);
        setMainImagePreviewUrl(null);
    };    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsVideoUploading(true); // Activar el indicador de carga del video
        setLoading(true); 
        try {
            const randomId = Math.random().toString(36).substring(2, 15);
    
            let mainImageUrl = "";
            if (mainImage) {
                const mainImageFileName = `main-image-${formData.title}-${randomId}-${new Date().toISOString()}`;
                const mainImageRef = ref(storage, `blogs/main_images/${mainImageFileName}`);
                await uploadBytes(mainImageRef, mainImage);
                mainImageUrl = await getDownloadURL(mainImageRef);
            }
    
            let imageUrls = [];
            if (files.length > 0) {
                imageUrls = await Promise.all(files.map(async (file) => {
                    const fileName = `image-${formData.title}-${randomId}-${new Date().toISOString()}`;
                    const fileRef = ref(storage, `blogs/images/${fileName}`);
                    await uploadBytes(fileRef, file);
                    return await getDownloadURL(fileRef);
                }));
            }
    
            let videoDownloadUrl = "";
            if (videoFile) {
                const videoFileName = `video-${formData.title}-${randomId}-${new Date().toISOString()}`;
                const videoRef = ref(storage, `blogs/videos/${videoFileName}`);
                await uploadBytes(videoRef, videoFile);
                videoDownloadUrl = await getDownloadURL(videoRef);
            }
    
            const blogsCollectionRef = collection(db, "blogs");
            const docRef = await addDoc(blogsCollectionRef, {
                title: formData.title,
                contect: formData.contect,
                meta: formData.meta,
                videoUrlYoutube: formData.videoUrlYoutube,
                timestamp: new Date(),
                mainImage: mainImageUrl,
                images: imageUrls,
                videoUrl: videoDownloadUrl
            });
            toast.success(`Blog guardado con éxito: ${docRef.id}`);
            navigate('/blogs/lista');
        } catch (error) {
            console.error("Error al guardar el blog y adjuntos: ", error);
            toast.error("Error al guardar el blog y adjuntos: ", error);
        } finally {
            setIsVideoUploading(false);
            setLoading(false); 
        }
    };
    
    if (loading) return <InfoLoading/>;
    return (
        <div className="windons-admin-two">
            <div className="windons-title-section">
                <div className="title-info">
                    <h2 className="title">Nuevo Blog</h2>
                </div>
                <div></div>
                <Link className='button-new' to="/blogs/lista"><BiChevronLeft /> Volver</Link>
            </div>
            <form className="form-blogs" onSubmit={handleSubmit}>
                <div className="creator-form-blogs">
                    <section className="form-blogs-section">
                        <div className='input-date'>
                            <label>Título:</label>
                            <input name="title" value={formData.title} onChange={handleChange} required />
                        </div>
                        <div className='input-date'>
                            <label>Texto Meta:</label>
                            <input name="meta" value={formData.meta} onChange={handleChange} required />
                        </div>
                        <div className='input-date'>
                            <label>Video URL:</label>
                            <input name="videoUrlYoutube" value={formData.videoUrlYoutube} onChange={handleChange}/>
                        </div>
                        <div className='input-message'>
                            <label>Contenido</label>
                            <textarea name="contect" value={formData.contect} onChange={handleChange} required />
                        </div>
                    </section>
                    <section className="form-blogs-section1">
                        <article className="button-load-section">
                            <h2 className="subtitle2">Cargan imagen principal</h2>
                            <div className='input-load'>
                                <label htmlFor="main-image-upload" className="custom-file-upload">
                                    <IoMdImages />Imagen
                                </label>
                                <input id="main-image-upload" type="file" accept="image/*" onChange={handleMainImageChange} required/>
                            </div>
                        </article>
                        {mainImagePreviewUrl && (
                            <div className="container-input-img">
                                <div className="input-img">
                                    <img src={mainImagePreviewUrl} alt="Previsualización de la imagen principal" style={{ width: '100px', height: 'auto' }} />
                                    <button className="delete-img" onClick={handleRemoveMainImage}>
                                        <BiX />
                                    </button>
                                </div>
                            </div>
                        )}
                        <article className="button-load-section">
                            <div className='input-load'>
                                <label htmlFor="file-upload" className="custom-file-upload">
                                    <BiPaperclip />Imágenes
                                </label>
                                <input id="file-upload" type="file" accept=".jpg, .png" onChange={handleFileChange} multiple />
                            </div>
                            <div className='input-load'>
                                <label htmlFor="video-upload" className="custom-file-upload">
                                    <IoVideocamOutline />
                                    {videoFile ? "Reemplazar Video" : "Cargar Video"}
                                </label>
                                <input id="video-upload" type="file" accept="video/*" onChange={handleVideoFileChange} />
                            </div>
                        </article>
                        {previewUrls&&
                            <div className="container-input-img">
                                {previewUrls.map((url, index) => (
                                    <div key={index} className="input-img">
                                        <img src={url} alt={`Previsualización ${index}`} style={{ width: 'auto', height: '100px' }} />
                                        <button className="delete-img" onClick={() => handleRemoveFile(index)}>
                                            <BiX/>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        }
                        {videoPreviewUrl && (
                            <div className="container-input-img">
                                <div className="input-img">
                                    <video src={videoPreviewUrl} controls style={{ width: '20vw', height: 'auto', borderRadius: '20px'}}/>
                                    <button className="delete-img" onClick={handleRemoveVideo}>
                                        <BiX />
                                    </button>
                                    
                                </div>
                            </div>
                        )}
                    </section>
                    <div className="cotainer-button-mens">
                        <button className='button-new' type="submit" disabled={isVideoUploading}><LuSave />Guardar</button>
                    </div>
                </div>
            </form>
        </div>
    );
}