import "./PcMenu.scss";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiUser } from "react-icons/fi";

export const PcMenu = ({setIsLogin}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); // El array vacío asegura que el efecto se ejecute solo una vez, cuando el componente se monta
    const toggleLogin = () => {
        setIsLogin(prevIsLogin=> !prevIsLogin);
    }; 
    return ( 
        <nav className='desktop-menu'>
            <Link className='button-navbar' to="/" >Inicio</Link>
            <Link className='button-navbar' to="/nosotros">Nosotros</Link>
            <Link className='button-navbar' to="/productos">Productos</Link>
            <button onClick={toggleLogin} className='button-color'>Login <FiUser/></button>
        </nav>
    );
}
