import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { LuSave } from "react-icons/lu";
import { toast } from 'react-toastify';
import { HiOutlinePhotograph } from "react-icons/hi";
import colombia from '../../../../firebase/colombia';
import { InfoLoading } from '../../../../Loanding';
import { db } from '../../../../firebase/config';
import { ListOrden } from '../../../UI/organisms/ListOrden/ListOrden';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FiPaperclip } from 'react-icons/fi';

export const Perfil = ({id}) => {
    const [ nombre, setNombre ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ telefono, setTelefono ] = useState('');
    const [ img, setImg ] = useState('');
    const [ cc, setCc ] = useState('');
    const [ apellido, setApellido] = useState('');
    const [ pais, setPais ] = useState('');
    const [ ciudad, setCiudad ] = useState('');
    const [ departamento, setDepartamento ] = useState ('');
    const [ direccion_1, setDireccion_1 ] = useState('');
    const [ direccion_2, setDireccion_2 ] = useState('');
    const [ sexo, setSexo ] = useState('');
    const [ edad, setEdad ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ selectedDepartment, setSelectedDepartment ] = useState('');
    const [ cities, setCities ] = useState([]);
    const [ error, setError ] = useState(null);
    const [ answer, setAnswer ] = useState (false);
    const [ ider , setIder ] = useState ('');
    const [ rol , setRol ] = useState ('');

    useEffect(() => {
        async function obtenerDatosDeUsuario(id) {
            try {
                const db = getFirestore(); 
                const userDocRef = doc(db, 'users', id);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    return userDoc.data();
                } else {
                    toast.error(`No user found with id: ${id}`);
                    return null;
                }
            } catch (error) {
                toast.error('Error fetching user data:', error);
                throw error;
            }
        }
        async function loadUserData() {
            try {
                const userData = await obtenerDatosDeUsuario(id);
                if (userData) {
                    setAnswer(userData.answer|| '');
                    setIder(userData.ider||'');
                    setNombre(userData.nombre || '');
                    setEmail(userData.email || '');
                    setTelefono(userData.telefono || '');
                    setApellido(userData.apellido || '');
                    setPais(userData.pais || '');
                    setDepartamento(userData.departamento || '')
                    setCiudad(userData.ciudad || '');
                    setDireccion_1(userData.direccion_1 || '');
                    setDireccion_2(userData.direccion_2 || '');
                    setEdad(userData.edad || '');
                    setSexo(userData.sexo || '');
                    setImg(userData.img || '');
                    setCc(userData.cc || '');
                    setRol(userData.rol || '');
                }
            } catch (error) {
                setError(true)
                toast.error("Error cargando los datos del usuario:", error);
            }
        }
        loadUserData();
    }, [id]);
    
    useEffect(() => {
        if (departamento) {
          setSelectedDepartment(departamento); // Asegura que selectedDepartment está sincronizado con departamento
          if (colombia[departamento]) setCities(colombia[departamento]); // Actualiza la lista de ciudades cuando se monta el componente y ya existe un departamento seleccionado.
        }
    }, [departamento]);
    
    useEffect(() => {
        if (selectedDepartment) {
          setCities(colombia[selectedDepartment] || []); // Actualiza la lista de ciudades cuando selectedDepartment cambia.
        }
    }, [selectedDepartment]);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'departamento') {
            setDepartamento(value);
            setSelectedDepartment(value);
        }
    };
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const defaultImage = 'https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/users%2Fuser.jpg?alt=media&token=f37c3246-4c72-4270-a332-9bbac12290db&_gl=1*1rnd6m4*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5NTkwODQ1NS4yNjAuMS4xNjk1OTA4NDk1LjIwLjAuMA..';
    const handleImageChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
        setImg(reader.result);
        setImagePreviewUrl(reader.result);
        }
        if (file) reader.readAsDataURL(file);
    }
    
    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(telefono)) {
            toast.error('Por favor, introduce un número de teléfono válido.');
            return;
        }
        const ccRegex = /^[0-9]+$/;
        if (!ccRegex.test(cc)) {
            toast.error('Por favor, introduce un número de documento válido.');
            return;
        }
        setLoading(true);
        // Concatenar dirección para geocodificación
        const fullAddress = `${direccion_1}, ${ciudad}, ${departamento}, ${pais}`;
        let latTemp, lngTemp; // Variables temporales para lat y lng
        try {
            const encodedAddress = encodeURIComponent(fullAddress);
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=AIzaSyDQnvwWu0NOA5v4NEMB0NqlQ_RSIk6dxlg`);
            const data = response.data;
            if (data.results && data.results.length > 0) {
                const { lat, lng } = data.results[0].geometry.location;
                latTemp = lat;
                lngTemp = lng;
            } else {
                toast.error("No se pudo obtener las coordenadas de la dirección proporcionada.");
                return;
            }
        } catch (error) {
            toast.error("Error al obtener las coordenadas: " + error.message);
            return;
        }
        // Define el documento a actualizar
        const userDocRef = doc(db, 'users', id);
        // Prepara los datos a actualizar
        const updatedData = {
            nombre,
            telefono,
            img,
            apellido,
            pais,
            departamento,
            ciudad,
            direccion_1,
            direccion_2,
            sexo,
            edad,
            latitud: latTemp,
            longitud: lngTemp,
            cc,
        };
        try {
            // Actualizar el documento del usuario
            await updateDoc(userDocRef, updatedData);
            toast.success('Usuario editado con éxito');
        } catch (error) {
            console.error("Error al actualizar el usuario: ", error);
            toast.error('Error al editar el usuario: ' + error.message);
        } finally {
            setLoading(false); // Desactivar el indicador de carga
        }
    };
    if (loading) return <InfoLoading/>;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="equipo-admin">
            <div className="encabezado-list">
                <h2 className="title">Editar información</h2>
            </div>
            <div className='scroll-equip-admin'>
                <form onSubmit={handleSubmit} className="form-contact-e">
                    <div className='column-img'>
                        <div className='section-img'>
                            <div className='input-img'>
                                <img className='user' alt='user' src={imagePreviewUrl || img || defaultImage} />
                            </div>
                            <div className='input-upload'>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <HiOutlinePhotograph />  {imagePreviewUrl || img ? 'Cambiar' : 'Subir Imagen'}
                            </label>
                            <input id="file-upload" type="file" onChange={handleImageChange} style={{ display: 'none' }} />
                            </div>
                        </div>
                    </div>
                    <div className='column-form'>
                        <div className='input-date'>
                            <label>Nombre:</label>
                            <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                        </div>
                        <div className='input-date'>
                            <label>Apellido:</label>
                            <input type="text" value={apellido} onChange={(e) => setApellido(e.target.value)} />
                        </div>
                        <div className='input-date'>
                            <label>Email:</label>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} disabled/>
                        </div>
                        <div className='input-date'>
                            <label>Teléfono:</label>
                            <input type="tel" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                        </div>
                        <div className='input-date'>
                            <label>Cedula CC / PEP:</label>
                            <input type="tel" name="cc" value={cc} onChange={(e) => setCc(e.target.value)} required />
                        </div>
                        <div className='input-date'>
                            <label>Sexo:</label>
                            <select value={sexo} onChange={(e) => setSexo(e.target.value)}>
                                <option value="" disabled>Seleccione</option>
                                <option value="hombre">Hombre</option>
                                <option value="mujer">Mujer</option>
                                <option value="otro">Otro</option>
                            </select>
                        </div>
                        <div className='input-date'>
                            <label>Edad:</label>
                            <input type="number" value={edad} onChange={(e) => setEdad(e.target.value)} min="18" max="99" />
                        </div>
                        <div className='input-date'>
                            <label>País:</label>
                            <input type="text" value={pais} onChange={(e) => setPais(e.target.value)} />
                        </div>
                        <div className='input-date'>
                            <label>Departamento:</label>
                            <select type="text" value={departamento} onChange={handleChange} name="departamento">
                                <option value="" disabled>Seleccione</option>
                                {Object.keys(colombia).map((department) => (
                                <option key={department} value={department}>{department}</option>
                                ))}
                            </select>
                        </div>
                        <div className='input-date'>
                            <label>Ciudad:</label>
                            <select type="text" name='ciudad' value={ciudad} onChange={(e) => setCiudad(e.target.value)}>
                                <option value="" disabled>Seleccione</option>
                                {cities.map((city) => (
                                <option key={city} value={city}>{city}</option>
                                ))}
                            </select>
                        </div>
                        <div className='input-date'>
                            <label>Dirección:</label>
                            <input type="text" value={direccion_1} onChange={(e) => setDireccion_1(e.target.value)} />
                        </div>
                        <div className='input-date'>
                            <label>Dirección  detallada:</label>
                            <input type="text" value={direccion_2} onChange={(e) => setDireccion_2(e.target.value)} />
                        </div>
                        <button className='button-new' type="submit" disabled={loading}>
                            <LuSave /> Guardar cambios
                        </button>
                        { (rol === "AA-001" || rol === "SA-001") &&
                            <div>
                                {answer&&
                                    <Link to={`/encuestas/diagnostic/${ider}`} className='button-question'>
                                        <FiPaperclip />Diagnóstico
                                    </Link>
                                }
                            </div>
                        }
                    </div>
                </form>
                <div className='container-set-users'>
                    <ListOrden idu={id} />
                </div>
            </div>
        </div>
    );
};
