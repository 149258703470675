import "./Quizopen.scss";
import restquestions from './RestBase';
import { BiChevronLeft } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { InfoLoading } from "../../../../Loanding";
import { doc, getDoc, getFirestore} from "firebase/firestore";
import { FiMonitor } from "react-icons/fi";
import { PiOfficeChair , PiHeadsetBold  } from "react-icons/pi";
import { MdOutlineDesk } from "react-icons/md";
import { TbLamp2 } from "react-icons/tb";
import { BsOutlet } from "react-icons/bs";
import { MdOutlineElectricalServices } from "react-icons/md";
import { PiCardholderBold } from "react-icons/pi";
import { PiCloudBold } from "react-icons/pi";
import { BsMouse3 } from "react-icons/bs";
import { LuKeyboard } from "react-icons/lu";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { HiOutlineEmojiSad } from "react-icons/hi";
import { DateUserProve } from "../../../UI/organisms/DateUserProve/DateUserProve";
import Stack from '@mui/material/Stack';
import { PieChart } from '@mui/x-charts/PieChart';

export const DetailQuizOpen = () => {
    const { id } = useParams ();
    const [answer, setAnswer] = useState ([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [results, setResults] = useState([]);
    
    // Ejemplo de uso dentro del componente:
    useEffect(() => {
        const fetchAnswers = async () => {
            setLoading(true);
            try {
                const db = getFirestore();
                const docRef = doc(db, 'quizanswer_open', id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setAnswer(docSnap.data());
                    compareAnswers(docSnap.data().respuestas);  // Asumiendo que las respuestas están en este campo
                } else {
                    throw new Error('Documento no encontrado');
                }
            } catch (error) {
                toast.error(`Error fetching data: ${error.message}`);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchAnswers();
        }
    }, [id]);

    const compareAnswers = (givenAnswers) => {
        const tempResults = restquestions.map(question => {
            // Extrayendo la respuesta principal dada
            const givenMainAnswer = givenAnswers[question.id]?.respuesta?.opcione || "";
            let mainResult = {
                id: question.id,
                result: Array.isArray(question.mainQuestion) ? question.mainQuestion.includes(givenMainAnswer) : givenMainAnswer === question.mainQuestion
            };
    
            // Verificar las subpreguntas, si existen
            const subquestionResults = question.subquestions ? Object.keys(question.subquestions).map(subQId => {
                const givenSubAnswer = givenAnswers[question.id]?.subquestions[subQId]?.respuesta?.opcione || "";
                const correctSubAnswer = question.subquestions[subQId];
                return {
                    id: subQId,
                    result: givenSubAnswer === correctSubAnswer
                };
            }) : [];
    
            return [mainResult, ...subquestionResults];
        }).flat(); // Aplanar el arreglo de resultados para no tener sub-arreglos
        setResults(tempResults);
    };    

    const getResultById = (results, id) => {
        const result = results.find(result => result.id === id);
        return result ? result.result : null; // Devuelve el resultado si se encuentra, de lo contrario devuelve null
    };

    // Preparar los datos para la gráfica circular
    const porcentajeRedondeado = Math.round(answer.resultado); // Redondea el porcentaje a un número entero
    const porcentajeRestanteRedondeado = 100 - porcentajeRedondeado; 
    const datosResult = [
        { label: 'Positivo', value: porcentajeRedondeado },
        { label: 'Negativo', value: porcentajeRestanteRedondeado }
    ];

    //Consulta Escritorio ID 'Q00000000001'
    const Res1 = getResultById(results, 'Q00000000001');
    const Res1_1 = getResultById(results, 'Q00000000001-1');
    const Res1_2 = getResultById(results, 'Q00000000001-2');
    const Res1_3 = getResultById(results, 'Q00000000001-3');
    const finalResult1 = (Res1 === true) && (Res1_1 === true) && 
    (Res1_2 === true) && (Res1_3 === true);
    //Consulta Sillas ID 'Q00000000002'
    const Res2 = getResultById(results, 'Q00000000002');
    const Res2_1 = getResultById(results, 'Q00000000002-1');
    const Res2_2 = getResultById(results, 'Q00000000002-2');
    const Res2_3 = getResultById(results, 'Q00000000002-3');
    const Res2_4 = getResultById(results, 'Q00000000002-4');
    const Res2_5 = getResultById(results, 'Q00000000002-5');
    const Res2_6 = getResultById(results, 'Q00000000002-6');
    const finalResult2 = (Res2 === true) && 
    (Res2_1 === true) && (Res2_2 === true) && 
    (Res2_3 === true) && (Res2_4 === true) &&
    (Res2_4 === true) && (Res2_5 === true) &&
    (Res2_6 === true)
    //Consulta Bases pies ID 'Q00000000003' u 'Q00000000004'
    const Res3 = getResultById(results, 'Q00000000003');
    const Res4 = getResultById(results, 'Q00000000004');
    const finalResult3_4 = (Res3 === true) && 
    (Res4 === true) 
    //Consulta iluminación ID 'Q00000000005'
    const Res5 = getResultById(results, 'Q00000000005');
    const Res5_1 = getResultById(results, 'Q00000000005-1');
    const finalResult5 = (Res5 === true) &&  (Res5_1 === true)
    //Consulta monitor ID 'Q00000000006'
    const finalResult6 = getResultById(results, 'Q00000000006');
    //Consulta mouse ID 'Q00000000007'
    const finalResult7 = getResultById(results, 'Q00000000007');
    //Consulta teclado ID 'Q00000000008'
    const finalResult8 = getResultById(results, 'Q00000000008');
    //Consulta audifonos ID 'Q00000000009'
    const finalResult9 = getResultById(results, 'Q00000000009');
    //Consulta regulador de eneriga ID 'Q00000000010'
    const finalResult10 = getResultById(results, 'Q00000000010');
    //Consulta cableado ID 'Q00000000011'
    const finalResult11 = getResultById(results, 'Q00000000011');
    //Consulta base monitor ID 'Q00000000012'
    const finalResult12 = getResultById(results, 'Q00000000012');
    //Consulta teletrabajo ID 'Q00000000013'
    const finalResult13 = getResultById(results, 'Q00000000013');

    console.log(answer.files)

    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="windons-quiz-result">
            <div className="windons-title-result">
                <div className="title-info">
                    <h1 className="title-aswer">{answer.title}</h1>
                </div>
                <Link className='button-new'  to={"/open/invitations"}><BiChevronLeft />Volver</Link>
            </div>
            <section className="wimdons-quiz-contador-result-answer">
                <DateUserProve idu = {answer.idu}/>
                <div className="cabezera-quiz-contador-cabe">
                    <div className="card-quiz-contador">
                        <Stack direction="row">
                            <PieChart
                                colors={['#1E67E2', '#85D0D5']} // Colores de tu elección
                                series={[{
                                    paddingAngle: 1,
                                    innerRadius: 60,
                                    outerRadius: 80,
                                    cornerRadius: 19,
                                    data: datosResult,
                                }]}
                                margin={{ right: 5 }}
                                width={200}
                                height={200}
                                legend={{ hidden: true }}
                            />
                        </Stack>
                        <div>
                            <h1>Puntuación:</h1>
                            <h2>{porcentajeRedondeado}%</h2>
                            <p>Esta es la calificación con respecto al <br></br>cumplimiento de la situación ergonómica de tu colaborador.</p>
                        </div>
                    </div>
                    {answer.files&&
                        <div className="galery-photo-result">
                            {answer.files.map((url, index) => (
                                <div key={index} className="input-img">
                                    <img src={url} alt={`Previsualización ${index}`}/>
                                </div>
                        ))}
                        </div>
                    }
                </div>
                <div className="section-icon-result-quiz">
                    <div className={(!finalResult1)? "result-pending": "result-positive"}>
                        <MdOutlineDesk />
                        <h2>Escritorio</h2>
                        {(!finalResult1)?<p>Necesita un escritorio o necesita cambiar el actual por no tener las condiciones idóneas</p>
                        :<p>Está dotado con lo adecuado</p>}
                    </div>
                    <div className={(!finalResult2)? "result-pending": "result-positive"}>
                        <PiOfficeChair />
                        <h2>Silla</h2>
                        {(!finalResult2)? <p>Necesita una silla o necesita cambiar la actual por no tener las condiciones idóneas</p>
                        :<p>Está dotado con lo adecuado</p>}
                    </div>
                    <div className={(!finalResult3_4)? "result-pending": "result-positive"}>
                        <PiCloudBold />
                        <h2>Descansa pies</h2>
                        {(!finalResult3_4)? <p>Necesita descansapiés para cumplir con una postura ergonómica</p>
                        :<p>Su espacio es adecuado</p>}
                    </div> 
                    <div className={(!finalResult5)? "result-pending": "result-positive"}>
                        <TbLamp2 />
                        <h2>Lámparas</h2>
                        {(!finalResult5)?<p>Necesita una lámpara por no tener la luz suficiente permanentemente u ocasionalmente</p>
                        :<p>Está dotado con lo adecuado</p>}
                    </div>
                    <div className={(!finalResult6)? "result-pending": "result-positive"}>
                        <FiMonitor />
                        <h2>Monitor</h2>
                        {(!finalResult6 )? <p>Necesita un monitor, pues no tiene uno de apoyo</p>
                        :<p>Está dotado con lo adecuado</p>}
                    </div>
                    <div className={(!finalResult7)? "result-pending": "result-positive"}>
                        <BsMouse3 />
                        <h2>Mouse</h2>
                        {(!finalResult7 )? <p>Necesita un mouse adecuado</p>
                        :<p>Está dotado con uno</p>}
                    </div>
                    <div className={(!finalResult8)? "result-pending": "result-positive"}>
                        <LuKeyboard />
                        <h2>Teclado</h2>
                        {(!finalResult8)? <p>Necesita un teclado adecuado</p>
                        :<p>Está dotado con uno</p>}
                    </div>
                    <div className={(!finalResult9)? "result-pending": "result-positive"}>
                        <PiHeadsetBold />
                        <h2>Auriculares</h2>
                        {(!finalResult9)? <p>Necesita auriculares para evitar contaminación sonora</p>
                        :<p>Está dotado con uno o no les necesita</p>}
                    </div>
                    <div className={(!finalResult10)? "result-pending": "result-positive"}>
                        <BsOutlet />
                        <h2>Reguladores de electricidad</h2>
                        {(!finalResult10)? <p>Necesita un regulador de energía para cuidar sus equipos</p>
                        :<p>Su flujo eléctrico es adecuado</p>}
                    </div>
                    <div className={(!finalResult11)? "result-pending": "result-positive"}>
                        <MdOutlineElectricalServices />
                        <h2>Cableado eléctrico</h2>
                        {(!finalResult11 )? <p>Necesita arreglar la disposición de sus conexiones</p>
                        :<p>Tiene una zona segura y ordenada</p>}
                    </div>
                    <div className={(!finalResult12)? "result-pending": "result-positive"}>
                        <PiCardholderBold />
                        <h2>Bases</h2>
                        {(!finalResult12 )? <p>Necesita bases para que su espacio de trabajo sea ergonómico</p>
                        :<p>Cumple con las necesidades ergonómicas</p>}
                    </div>
                    <div className={(!finalResult13)? "result-pending": "result-positive"}>
                        {(!finalResult13 )? <HiOutlineEmojiSad />:<HiOutlineEmojiHappy />}
                        <h2>Satisfacción con el trabajo en casa</h2>
                        {(!finalResult13 )? <p>Algunos de los factores actuales, le causan insatisfacción o estrés.</p>
                        :<p>Está cómodo con la situación actual, se encuentra motivado</p>}
                    </div>                                    
                </div>
            </section>
        </div>
    );
};