import "./Marca.scss"
import { useEffect, useState } from "react";
import { db, storage  } from "../../../../firebase/config";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { InfoLoading } from "../../../../Loanding";
import { Link } from "react-router-dom";
import { BiChevronLeft, BiRefresh } from "react-icons/bi";
import { HiOutlinePhotograph } from "react-icons/hi";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { LuSave } from "react-icons/lu";
import { FaRegFilePdf } from "react-icons/fa";

export const Marca = ({idc}) => {
    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editableInfo, setEditableInfo] = useState({});
    const [img, setImg] = useState('');
    const navigate = useNavigate();
    const [rutPdf, setRutPdf] = useState(null);
    const [camaraComercioPdf, setCamaraComercioPdf] = useState(null);
    const [cedulaPdf, setCedulaPdf] = useState(null);

     // Modifica esta función para manejar archivos PDF
    const uploadFileToFirebaseStorage = async (file, filePath) => {
        if (!file) {
            throw new Error("No file provided");
        }
        const storageRef = ref(storage, `company/${idc}/${filePath}`);
        const snapshot = await uploadBytes(storageRef, file);
        return await getDownloadURL(snapshot.ref);
    };

    // Estados para previsualizar los PDFs
    const [rutPreviewUrl, setRutPreviewUrl] = useState('');
    const [camaraComercioPreviewUrl, setCamaraComercioPreviewUrl] = useState('');
    const [cedulaPreviewUrl, setCedulaPreviewUrl] = useState('');

     // Función para manejar el cambio en los inputs de archivos PDF
    const handlePdfChange = (e, setPreviewUrl, setFileState) => {
        let file = e.target.files[0];
        if (file) {
            setPreviewUrl(URL.createObjectURL(file)); // Previsualizar URL del archivo
            setFileState(file);
        }
    }

    useEffect(() => {
        let isMounted = true;
        const fetchUser = async () => {
            try {
                if(idc) {
                    const userDocRef = doc(db, 'company', idc);
                    const userDoc = await getDoc(userDocRef);
                    
                    if(userDoc.exists() && isMounted) {
                        let userData = userDoc.data();
                        setInfo(userData);
                        setEditableInfo(userData);
                        setImagePreviewUrl(userData.imageUrl || defaultImage); // Asegúrate de que 'imageUrl' sea el campo correcto
                        setRutPreviewUrl(userData.rutUrl || ''); // Asume que 'rutUrl' es el nombre del campo en la base de datos
                        setCamaraComercioPreviewUrl(userData.camaraComercioUrl || ''); // Asume que 'camaraComercioUrl' es el nombre del campo
                        setCedulaPreviewUrl(userData.cedulaUrl || ''); // Asume que 'cedulaUrl' es el nombre del campo
                    }
                }
            } catch (error) {
                if (isMounted) setError(error);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchUser();
        return () => { isMounted = false; };
    }, [idc]);
    
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const defaultImage = 'https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/users%2Fuser.jpg?alt=media&token=f37c3246-4c72-4270-a332-9bbac12290db&_gl=1*1rnd6m4*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5NTkwODQ1NS4yNjAuMS4xNjk1OTA4NDk1LjIwLjAuMA..';
    
    const handleImageChange = (e) => {
        let file = e.target.files[0];
        if (file) {
            let reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setImg(file); 
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditableInfo(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        try {
            let newImageUrl = img ? await uploadFileToFirebaseStorage(img, 'image.jpg') : imagePreviewUrl;
            let newRutUrl = rutPdf ? await uploadFileToFirebaseStorage(rutPdf, 'rut.pdf') : rutPreviewUrl;
            let newCamaraComercioUrl = camaraComercioPdf ? await uploadFileToFirebaseStorage(camaraComercioPdf, 'camara_comercio.pdf') : camaraComercioPreviewUrl;
            let newCedulaUrl = cedulaPdf ? await uploadFileToFirebaseStorage(cedulaPdf, 'cedula_representante_legal.pdf') : cedulaPreviewUrl;

            const userDocRef = doc(db, 'company', idc);
            await updateDoc(userDocRef, {
                ...editableInfo,
                imageUrl: newImageUrl,
                rutUrl: newRutUrl,
                camaraComercioUrl: newCamaraComercioUrl,
                cedulaUrl: newCedulaUrl
            });
            toast.success("Cambios guardados");
            navigate('/');
        } catch (error) {
            toast.error('Error de guardado:', error);
        }
    };
    
    if (loading) return <InfoLoading />;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (  
        <div className="equipo-admin">
            <div className="encabezado-list">
                <Link className='button-new' to="/"><BiChevronLeft /> Volver</Link>
                <h2 className="title">Datos de compañía</h2>
            </div>
            <form onSubmit={handleSubmit} className="form-contact">
                <div className='column-brand'>
                    <div className='section-img'>
                        <div className='input-brand'>
                            <img 
                                className='brand' 
                                alt='user' 
                                src={imagePreviewUrl ||'https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/users%2Fuser.jpg?alt=media&token=f37c3246-4c72-4270-a332-9bbac12290db&_gl=1*1rnd6m4*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5NTkwODQ1NS4yNjAuMS4xNjk1OTA4NDk1LjIwLjAuMA..'}
                            />
                        </div>
                        <div className='input-upload'>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <HiOutlinePhotograph />   {imagePreviewUrl ? 'Cambiar' : 'Subir Imagen'}
                            </label>
                            <input id="file-upload" type="file" onChange={handleImageChange} required style={{display: 'none'}} />
                        </div>
                    </div>
                </div>
                <div className='column-company'>
                    <div className='input-date'>
                        <label>Nombre de la compañía:</label>
                        <input type="text"  name="namecompany" value={editableInfo.namecompany || ''} onChange={handleChange}  />
                    </div>
                    <div className='input-date'>
                        <label>RUT o NIT:</label>
                        <input type="text"  name="rut" value={editableInfo.rut || ''} onChange={handleChange}  />
                    </div>
                    <div className='input-date'>
                        <label>Dirección 1</label>
                        <input type="text"  name="direccion1" value={editableInfo.direccion1 || ''} onChange={handleChange}  />
                    </div>
                    <div className='input-date'>
                        <label>Dirección 2</label>
                        <input type="text"  name="direccion2" value={editableInfo.direccion2 || ''} onChange={handleChange}  />
                    </div>
                    <div className='input-date'>
                        <label>Email:</label>
                        <input type="email" name="emailfacturacion" value={editableInfo.emailfacturacion || ''} onChange={handleChange} />
                    </div>
                    <div className='input-date'>
                        <label>Teléfono:</label>
                        <input type="tel" name="telefono" value={editableInfo.telefono || ''} onChange={handleChange} />
                    </div>
                    <div className="input-dat">
                        <div className='input-upload'>
                            <label htmlFor="rut-upload" className="custom-file-upload">
                                {rutPreviewUrl ? <><BiRefresh />Cambiar RUT</> : <><FaRegFilePdf />Subir RUT</> }
                            </label>
                            <input id="rut-upload" type="file" onChange={(e) => handlePdfChange(e, setRutPreviewUrl, setRutPdf)} accept=".pdf" style={{display: 'none'}} />
                        </div>
                        <div className='input-upload'>
                            <label htmlFor="camara-comercio-upload" className="custom-file-upload">
                                {camaraComercioPreviewUrl ? <><BiRefresh />Cambiar cámara y comercio</> : <><FaRegFilePdf /> Subir cámara y comercio</>}
                            </label>
                            <input id="camara-comercio-upload" type="file" onChange={(e) => handlePdfChange(e, setCamaraComercioPreviewUrl, setCamaraComercioPdf)} accept=".pdf" style={{display: 'none'}} />
                        </div>
                        <div className='input-upload'>
                            <label htmlFor="cedula-upload" className="custom-file-upload">
                                {cedulaPreviewUrl ? <><BiRefresh />Cambiar CC. representate</> : <><FaRegFilePdf /> Subir CC. representate</>}
                            </label>
                            <input id="cedula-upload" type="file" onChange={(e) => handlePdfChange(e, setCedulaPreviewUrl, setCedulaPdf)} accept=".pdf" style={{display: 'none'}} />
                        </div>
                    </div>
                    <div className="input-date">
                        <button className='button-new' type="button" onClick={handleSubmit}>
                            <LuSave />
                            Guardar cambios
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
