const status =[
    {
        id: "S000",
        name: "Verificación",
        status: [0],
    },
    {
        id: "S001",
        name: "Sin Asignar",
        status: [99],
    },
    {
        id: "S003",
        name: "Preparación",
        status: [1, 2, 15, 13, 14, 4],
    },
    {
        id: "S002",
        name: "En camino",
        status: [12, 11, 5],
    },
    {
        id: "S004",
        name: "Entregado",
        status: [6],
    },
    {
        id: "S005",
        name: "Cancelado",
        status: [7, 8],
    },
    {
        id: "S999",
        name:"Todos...",
        status: [1, 2, 15, 13, 14, 4, 20, 5, 12, 11, 0, 6, 7, 8 , 99],
    },
];

export default status;