// useFetchImages.js
import { useState, useEffect } from 'react';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../../../firebase/config';

function useFetchImages() {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;

        const fetchImages = async () => {
            try {
                const imagesCollectionRef = collection(db, 'images');

                // Crea una consulta que ordena por 'timestamp' de manera descendente
                const q = query(imagesCollectionRef, orderBy('timestamp', 'desc'));

                const querySnapshot = await getDocs(q);
                // Transforma los documentos en un array de objetos
                const fetchedImages = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                if (isMounted) {
                    setImages(fetchedImages);
                }
            } catch (fetchError) {
                if (isMounted) {
                    setError(fetchError);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchImages();
        return () => { isMounted = false; }; // Esto es para manejar cancelaciones en caso de que el componente se desmonte antes de que termine la operación.
    }, []);

    return { images, loading, error };
}

export default useFetchImages;
