/* eslint-disable react-hooks/exhaustive-deps */
import "./TraySupport.scss";
import { db } from "../../../../firebase/config";
import { SectionSupport } from "../../../UI/organisms/SectionSupport/SectionSupport";
import { BiChat, BiCommentCheck, BiCommentDetail, BiCommentDots, BiCommentX } from "react-icons/bi";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { InfoLoading } from "../../../../Loanding";
import { ListTickeUser } from "./ListTickeUser";
import { ListTickeAdmin } from "./ListTickeAdmin";
import { ListTickeSuperAdmin } from "./ListTickeSuperAdmin";

export const TraySupport = ({ id, idc, compañia, email , rol }) => {
    const [tiket, setTiket] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [error, setError] = useState(null);

    const fetchTickets = async () => {
        try {
            if (!idc) throw new Error("idc is undefined");
            const kitsCollectionRef = collection(db, 'tickets');
            let q;
                if (rol === "SA-001") {
                    q = query(kitsCollectionRef, where('scalability', '==', true));
                } else {
                    q = query(kitsCollectionRef, where('idc', '==', idc));
                }
            let querySnapshot;
            if (rol === "US-001" || rol === "US-002") {
                const u = query(q, where('idu', '==', id));
                querySnapshot = await getDocs(u);
            } else {
                querySnapshot = await getDocs(q);
            }
            const fetchedTickets = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setTiket(fetchedTickets);
            setLoading(false);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!idc) throw new Error("idc is undefined");
        fetchTickets();
    }, [idc]);

    const getStatusComponent = (status) => {
        if ( status === "send" ) {
            return <div className="status  send"><BiCommentDots /></div>;
        } else if ( status === "answer" ) {
            return <div className="status  answer"><BiCommentDetail /></div>;
        } else if ( status === "close" ) {
            return <div className="status  close"><BiCommentCheck /></div>;
        } else {
            return <div className="status  error"><BiCommentX /></div>;
        }
    }

    const restSend = (send) => {
        if ( send === id ) {
            return <BiChat className="search-rest"/>
        } else {
            return <BiChat className="search"/>
        }
    }

    const ticketItems = tiket.filter(user => (
        user.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.event.toLowerCase().includes(searchTerm.toLowerCase())
    ));

    const asignation = ticketItems.filter(tik => tik.assigned_id === id);
    const noAsignation  = ticketItems.filter(tik => tik.assigned_id === "" );
    const teamAsignation = ticketItems.filter(tik => tik.assigned_id && tik.assigned_id !== id);

    const asignationSuper = ticketItems.filter(tik => tik.super_admin_id === id);
    const noAsignationSuper  = ticketItems.filter(tik => tik.super_admin_id === "" );
    const teamAsignationSuper = ticketItems.filter(tik => tik.super_admin_id && tik.super_admin_id !== id);

    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="windons-admin-send">
            <div className="windons-title-section">
                <div className="title-info">
                    <h2 className="title">Soporte: {compañia}</h2>
                </div>
            </div>
            <div className="windons-section-info">
                {tiket.length===0
                    ?<div className="empty-form">
                        <img className="img-shofi-loanding" alt="Quiz Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031141348qHNT%20(1).png?alt=media&token=564826ec-6b61-4f17-b779-886b8e5d4cd7&_gl=1*1utmy1v*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5ODc2MTY4NS4zNDYuMS4xNjk4NzYxNzQ4LjYwLjAuMA.." />
                        <h2 className="title-shofi-loanding">Si tienes una novedad<br></br>Escríbenos</h2>
                    </div>
                    :<section className="section-info">
                        <input
                            className="input-search"
                            type="text"
                            placeholder="Buscar ticket..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="list-info">
                            {(rol==="SA-001")&&
                                <ListTickeSuperAdmin
                                    noAsignation =  {noAsignationSuper}
                                    teamAsignation = {teamAsignationSuper}
                                    restSend = {restSend}
                                    getStatusComponent = {getStatusComponent}
                                    asignation = {asignationSuper}
                                />
                            }
                            {(rol === "AA-001" || rol === "AA-002" || rol === "AA-003")&&
                                <ListTickeAdmin
                                    noAsignation =  {noAsignation}
                                    teamAsignation = {teamAsignation}
                                    restSend = {restSend}
                                    getStatusComponent = {getStatusComponent}
                                    asignation = {asignation}
                                />
                            }
                            {(rol==="US-001" || rol==="US-002")&&
                                <ListTickeUser 
                                    ticketItems = {ticketItems}
                                    restSend = {restSend}
                                    getStatusComponent = {getStatusComponent}
                                />
                            }
                        </div>
                    </section>
                }
                <section className="section-info1">
                    <SectionSupport
                        id = {id}
                        idc = {idc}
                        compañia = {compañia}
                        email = {email}
                        rol = {rol}
                        setLoading = {setLoading}
                    />
                </section>
            </div>
        </div>
    );
}
