/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import useFetchImages from './components/UseFetchImages';
import RegisterForm from './components/RegisterForm';
import {Section_Gallery} from '../../UI/organisms/Section_Gallery/Section_Gallery';
import "./gallery.scss"
import { Link } from 'react-router-dom';

export const Gallery = () => {
    const { images } = useFetchImages();
    const [selectedImages, setSelectedImages] = useState([]);
    
    // Verifica en localStorage al iniciar el componente
    const [isRegistered, setIsRegistered] = useState(localStorage.getItem('isRegistered') === 'true');
    
    useEffect(() => {
        if (isRegistered) {
            localStorage.setItem('isRegistered', 'true');
        }
    }, [isRegistered]);

    if (!isRegistered) {
        return <RegisterForm onRegisterSuccess={() => setIsRegistered(true)} />;
    }
    return (
        <div className="main-gallery">
            {images && 
                <div>
                    <Section_Gallery 
                        images={images} 
                    />
                </div>
            }
            <div className='barra-gallery'>
                <h2 className='subtitle3'>Visitanos en nuestra web</h2>
                <Link className='button-email' to="/">Home</Link>
            </div>
        </div>
    );
}
