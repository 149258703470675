import "./SearchBar.scss";
import { FiAlignJustify,  FiX } from "react-icons/fi";
// import React, { useState } from 'react';
export const SearchBar  = ({ onSearch, toggleMenuA, isOpena  }) => {
    // const [term, setTerm] = useState('');
    // const onSubmit = (event) => {
    //     event.preventDefault();
    //     onSearch(term);
    // };
    return (
        // <div onSubmit={onSubmit} className="sention-search">
        //     <button className="button-menu" onClick={toggleMenuA}>
        //         {isOpena ? <FiX /> : <FiAlignJustify />}
        //     </button>
        //     <form>
        //         <input 
        //             className="input-search"
        //             type="text"
        //             value={term}
        //             onChange={(e) => setTerm(e.target.value)}
        //             placeholder="Buscar..."
        //         />
        //         <button className="button-search" type="submit">
        //             <FiSearch />
        //         </button>
        //     </form>
        // </div>
        <div className="sention-search">
            <button className="button-menu" onClick={toggleMenuA}>
                {isOpena ? <FiX /> : <FiAlignJustify />}
            </button>
        </div>
    );
}