import "./ListProductBase.scss";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ItenProductBase } from "../../molecules/ItenProductBase/ItenProductBase";
import { BiSortDown , BiSortUp } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs} from "firebase/firestore"; 
import { InfoLoading } from "../../../../Loanding";
import { db } from "../../../../firebase/config";

export const ListProductBase = ({ info, setSortType , setSortDirection , setFilterCategory , filterCategory , handleChange , sortDirection, numberOfPages, displayItems, currentPage}) => {
    const [ category, setCategory ] = useState([]);
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false)
    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                setLoading(true);
                const q = collection (db, 'category');
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }))
                    .filter(category => category.status === true); // Filtra solo las categorías con status true

                if (isMounted) setCategory(fetchedInfo);
                setLoading(false);
            } catch (err) {
                if (isMounted) setError(err);
                setLoading(false);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, []);


    if (loading) return <InfoLoading />;
    if (!info || !displayItems) {return <InfoLoading />;}
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="list-prodcut-bass">
            <div className="contect-list-prodcut-bass">
                <div className="encabezado-list">
                    <h2 className="title">Productos Base</h2>
                    <Link className='button-new' to="/productos/creador"> 
                    <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                        Nuevo Producto
                    </Link>
                </div>
                {info && (
                    <div>
                        <div className="title-prodcut-bass">
                            <p>Image</p>
                            <div className="filter">
                                <button onClick={() => setSortType('nombre')}>Nombre</button>
                                <button onClick={() => setSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'))}>
                                    {sortDirection === 'asc' ? <BiSortDown />  : <BiSortUp />}
                                </button>
                            </div>
                            <div className="filter">
                                <button onClick={() => setSortType('SKU')}>SKU</button>
                                <button onClick={() => setSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'))}>
                                    {sortDirection === 'asc' ? <BiSortUp /> : <BiSortDown />}
                                </button>
                            </div>
                            <p>
                                Descripción
                            </p>
                            <div className="filter">
                                <label>Categoría:</label>
                                <select onChange={(e) => setFilterCategory(e.target.value)}>
                                    <option value="">{filterCategory.length===0? <p>Seleccione Categoria</p>:<p>{filterCategory}</p>}</option>
                                    {category.filter(cat => cat.name !== filterCategory).map((cat) => (
                                        <option  key={cat.id} value={cat.name}>{cat.name}</option>
                                    ))}
                                    <option value="">Todos...</option>
                                </select>
                            </div>
                        </div>
                        <div className="list">
                            {displayItems.map(info => (
                                <ItenProductBase key={info.id} {...info} />
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className="pagination-numb">
                <Stack spacing={2}>
                    <Pagination count={numberOfPages} page={currentPage} onChange={handleChange} size="large" />
                </Stack>
            </div>
        </div>
    );
}