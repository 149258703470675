import { useEffect, useState } from "react";
// Importar db y storage desde tu archivo de configuración de Firebase
import { db, storage } from '../../../../../firebase/config';
import { collection, addDoc, getDocs} from "firebase/firestore"; 
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// Alertas para componente
import { toast } from "react-toastify";
// Importar iconos
import { BiChevronLeft, BiPaperclip, BiX } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import { LuSave } from "react-icons/lu";
import { IoMdImages } from "react-icons/io";
import { InfoLoading } from "../../../../../Loanding";

export const CreateProduct = () => {
    const navigate = useNavigate();
    const [ info, setInfo] = useState([]);
    const [ error, setError ] = useState(null);
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const [mainImage, setMainImage] = useState(null);
    const [mainImagePreviewUrl, setMainImagePreviewUrl] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        SKU: "",
        brand: "",
        categoryName: "", 
        categoryId: "",
        description: "",
        price:"",
    });

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'category');
                const querySnapshot = await getDocs(q);
                    
                const fetchedInfo = querySnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }))
                    .filter(category => category.status === true); // Filtra solo las categorías con status true
                    
                if (isMounted) setInfo(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        // Manejo especial para el selector de categorías
        if (name === 'selectcategoria') {
        const selectedCategory = info.find(category => category.id === value);
            if (selectedCategory) {
                setFormData(prev => ({
                    ...prev, 
                    categoryName: selectedCategory.name,
                    categoryId: selectedCategory.id
                }));
            }
        } else if (name === 'price') {
            const numberValue = parseFloat(value);
            if (!isNaN(numberValue)) {
                setFormData(prev => ({ ...prev, [name]: numberValue }));
            }
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleFileChange = (e) => {
        if (files.length + e.target.files.length > 4) {
            alert("Solo puedes subir un máximo de 4 imágenes");
            return;
        }
        const selectedFiles = Array.from(e.target.files);
        setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
        const newPreviewUrls = selectedFiles.map(file => URL.createObjectURL(file));
        setPreviewUrls(prevUrls => [...prevUrls, ...newPreviewUrls]);
    };

    const handleRemoveFile = (index) => {
        const newFiles = files.filter((_, fileIndex) => fileIndex !== index);
        const newPreviewUrls = previewUrls.filter((_, urlIndex) => urlIndex !== index);
        setFiles(newFiles);
        setPreviewUrls(newPreviewUrls);
    }; 

    const handleMainImageChange = (e) => {
        const file = e.target.files[0];
        setMainImage(file);
        if (file) {
            setMainImagePreviewUrl(URL.createObjectURL(file));
        } else {
            setMainImagePreviewUrl(null);
        }
    };
    
    const handleRemoveMainImage = () => {
        setMainImage(null);
        setMainImagePreviewUrl(null);
    };    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); 
        try {
            const randomId = Math.random().toString(36).substring(2, 15);
    
            let mainImageUrl = "";
            if (mainImage) {
                const mainImageFileName = `main-image-${formData.title}-${randomId}-${new Date().toISOString()}`;
                const mainImageRef = ref(storage, `product/main_images/${mainImageFileName}`);
                await uploadBytes(mainImageRef, mainImage);
                mainImageUrl = await getDownloadURL(mainImageRef);
            }
    
            let imageUrls = [];
            if (files.length > 0) {
                imageUrls = await Promise.all(files.map(async (file) => {
                    const fileName = `image-${formData.title}-${randomId}-${new Date().toISOString()}`;
                    const fileRef = ref(storage, `product/images/${fileName}`);
                    await uploadBytes(fileRef, file);
                    return await getDownloadURL(fileRef);
                }));
            }
            
            const blogsCollectionRef = collection(db, "produc");
            const docRef = await addDoc(blogsCollectionRef, {
                name: formData.name,
                SKU: formData.SKU,
                description: formData.description,
                brand: formData.brand,
                category: formData.categoryName,
                categoryId: formData.categoryId,
                price: formData.price,
                status:true,
                timestamp: new Date(),
                img: mainImageUrl,
                images: imageUrls,
            });
            toast.success(`Producto guardado con éxito: ${docRef.id}`);
            navigate('/productos');
        } catch (error) {
            toast.error("Error al guardar el prodcuto y adjuntos: ", error);
        } finally {
            setLoading(false); 
        }
    };
    
    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="foil-product">
            <div className="encabezado-list">
                <Link className='button-new' to="/productos"><BiChevronLeft /> Volver</Link>
                <h2 className="title">Nuevo producto</h2>
            </div>
            <form className="form-product" onSubmit={handleSubmit}>
                <div className="creator-form-product">
                    <section className="form-blogs-section">
                        <div className='input-date'>
                            <label>Name:</label>
                            <input name="name" value={formData.name} onChange={handleChange} required />
                        </div>
                        <div className='input-date'>
                            <label>SKU:</label>
                            <input name="SKU" value={formData.SKU} onChange={handleChange} required />
                        </div>
                        <div className='input-date'>
                            <label>Marca:</label>
                            <input name="brand" value={formData.brand} onChange={handleChange}/>
                        </div>
                        <div className='input-date'>
                            <label>Precio:</label>
                            <input name="price" type="number" value={formData.price} onChange={handleChange}  required/>
                        </div>
                        <div className='input-date'>
                            <label>Categoría:</label>
                            <select name="selectcategoria" value={formData.selectcategoria} onChange={handleChange} required>
                                <option value="" disabled hidden>Seleccione Categoria</option>
                                {info.map((category) => (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                ))}
                            </select>
                        </div>     
                        <div className='input-message'>
                            <label>Descriocion</label>
                            <textarea name="description" value={formData.description} onChange={handleChange} required />
                        </div>
                    </section>
                    <section className="form-blogs-section1">
                        <article className="button-load-section">
                            <h2 className="subtitle2">Cargan imagen principal</h2>
                            <div className='input-load'>
                                <label htmlFor="main-image-upload" className="custom-file-upload">
                                    <IoMdImages />Imagen
                                </label>
                                <input id="main-image-upload" type="file" accept="image/*" onChange={handleMainImageChange} required/>
                            </div>
                        </article>
                        {mainImagePreviewUrl && (
                            <div className="container-input-img">
                                <div className="input-img">
                                    <img src={mainImagePreviewUrl} alt="Previsualización de la imagen principal" style={{ width: '100px', height: 'auto' }} />
                                    <button className="delete-img" onClick={handleRemoveMainImage}>
                                        <BiX />
                                    </button>
                                </div>
                            </div>
                        )}
                        <article className="button-load-section">
                            <div className='input-load'>
                                <label htmlFor="file-upload" className="custom-file-upload">
                                    <BiPaperclip />Imágenes
                                </label>
                                <input id="file-upload" type="file" accept=".jpg, .png" onChange={handleFileChange} multiple />
                            </div>
                        </article>
                        {previewUrls&&
                            <div className="container-input-img">
                                {previewUrls.map((url, index) => (
                                    <div key={index} className="input-img">
                                        <img src={url} alt={`Previsualización ${index}`} style={{ width: 'auto', height: '100px' }} />
                                        <button className="delete-img" onClick={() => handleRemoveFile(index)}>
                                            <BiX/>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        }
                    </section>
                    <div className="cotainer-button-mens">
                        <button className='button-new' type="submit" ><LuSave />Guardar</button>
                    </div>
                </div>
            </form>
        </div>
    );    
};