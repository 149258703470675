import { useEffect, useState } from "react";
import { InfoLoading } from "../../../../Loanding";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { toast } from "react-toastify";

export const DateUserProve = ({idu}) => {
    const [user, setUser] = useState ([]);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(null);


    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);
            try {
                const db = getFirestore();
                const docRef = doc(db, 'users_open', idu);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setUser(docSnap.data());
                } else {
                    throw new Error('Documento no encontrado');
                }
            } catch (error) {
                toast.error(`Error fetching data: ${error.message}`);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        if (idu) {
            fetchUser();
        }
    }, [idu]);

    if (loading) return <InfoLoading/>;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="date-user-prove">
            <div className='date-input'>
                <label>Nombre:</label>
                <p>{user.name}</p>
            </div>
            <div className='date-input'>
                <label>Apellido:</label>
                <p>{user.lastname}</p>
            </div>
            <div className='date-input'>
                <label>CC:</label>
                <p>{user.cc}</p>
            </div>
            <div className='date-input'>
                <label>Email:</label>
                <p>{user.email}</p>
            </div>
            <div className='date-input'>
                <label>Teléfono:</label>
                <p>{user.phone}</p>
            </div>
            <div className='date-input'>
                <label>Altura (cm):</label>
                <p>{user.height}</p>
            </div>
            <div className='date-input'>
                <label>Peso (kg):</label>
                <p>{user.weight} </p>
            </div>
            <div className='date-input'>
                <label>Sexo:</label>
                <p>{user.sex}</p>
            </div>
            <div className='date-input'>
                <label>Edad:</label>
                <p>{user.year}</p>  
            </div>
        </div>
    );
};