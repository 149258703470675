import { collection, getDocs} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../../../firebase/config";
import { Link } from "react-router-dom";
import { BiPencil} from "react-icons/bi";
import { InfoLoading } from "../../../../Loanding";

export const ListBlogs = () => {
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'blogs');
                const querySnapshot = await getDocs(q);
                    
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                    
                if (isMounted) setInfo(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, []);   

    // ...

    const formatDate = (timestamp) => {
        if (!timestamp) return '';
        // Convertir timestamp a objeto Date
        const date = new Date(timestamp.seconds * 1000); // Ajustar según el tipo de timestamp que recibes
        // Opciones de formato
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
        // Formatear fecha
        return new Intl.DateTimeFormat('es', options).format(date);
    };

    if (loading) return <InfoLoading/>;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (
        <>
        {info.length!==0 
            ?<div className="windons-admin-two">
                <div className="windons-title-section">
                    <div className="title-info">
                        <h2 className="title">Lista de blogs</h2>
                    </div>
                    <Link className='button-new'  to="/blogs/creador">
                        <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                        Crear Blogs
                    </Link>
                </div>
                <div className="windons-section-group">
                    <section className="section-info-group">
                        <h2>Listado Blogs</h2>
                        <div className="list-info-group">
                        {info.map(group => (
                            <Link key={group.id} className="item-kits-group" to={`/blogs/creador/${group.id}`} >
                                <div><BiPencil className="search"/></div>
                                <div>
                                    <p className="name">{group.title}</p>
                                    <p>{formatDate(group.timestamp)}</p> {/* Aquí se muestra la fecha formateada */}
                                </div>
                            </Link>
                        ))}
                        </div>
                    </section>
                </div>
            </div>
            :<div className="windons">
                <div className="encabezado-list">
                    <h2 className="title">Lista de Blogs</h2>
                </div>
                <div className="windons-quiz-init">
                    <div className="section-quiz-init">
                        <div className="quiz-init-img">
                            <img alt="Shofi Encuestas" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fgroud%2FPJ_To_do_Checklist-PhotoRoom.png-PhotoRoom.png?alt=media&token=af91c841-59f3-475c-ab05-7ad9334ca226&_gl=1*hcsaao*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAzODQ5NC4zNjYuMS4xNjk5MDQwNDI3LjEuMC4w"/>
                            <h2 className="title-shofi-loanding">Crear Nuestros Blogs</h2>
                        </div>
                        <div className="quiz-init-contect">
                            <h3 className="subtitle2">Crea grupos o departamentos<br/>de trabajo asi facilita tu trabajo.</h3>
                            <Link className='button-asing' to="/blogs/creador">
                                <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fgroud%2Fia_book_009-PhotoRoom.png-PhotoRoom.png?alt=media&token=932e3fef-b7eb-429c-b725-4aac9dd3ae78&_gl=1*1fmcut6*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAzODQ5NC4zNjYuMS4xNjk5MDQwNjMzLjYwLjAuMA.." />
                                Crea tu primer Blog
                            </Link>
                            <h3 className="subtitle2">Una vez tengas comiences a cargas nuestros blog mejorara nuestro SEO</h3>
                        </div>
                    </div>
                </div>
            </div> 
            }
        </>
    );
}

