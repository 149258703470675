import "./Kits.scss";
import { useEffect, useState } from "react";
import { BiMessageSquareDetail } from "react-icons/bi";
import { query, collection, where, getDocs} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { InfoLoading } from "../../../../Loanding";
import { SectionSupportEquip } from "../../../UI/organisms/SectionSupport/SectionSupportEquip";

export const ListProduct = ({compañia, idc, rol, email, id}) => {
    const [ info, setInfo ] = useState([]);
    const [ filteredInfo, setFilteredInfo ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);
    const [ open, setOpen ] = useState(false);
    const [ idProduct, setIdProduct ] = useState(null);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ filter, setFilter ] = useState("003");

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                if (!idc) throw new Error("Company undefined");
                const infoCollectionRef = collection(db, 'products-assignment');
                
                let q
                if (filter === "002") {
                    q = query(infoCollectionRef)
                } else if (filter === "001"){
                    q = query(infoCollectionRef, where('idc', '==', idc),where('idu', '==', id));
                } else {
                    q = query(infoCollectionRef, where('idc', '==', idc));
                }

                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                if (isMounted) {
                    setInfo(fetchedInfo);
                }
            } catch (err) {
                if (isMounted) {
                    setError(err);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, [idc, filter, id, rol]);

    useEffect(() => {
        const lowercasedFilter = searchTerm.toLowerCase();
        const filteredData = info.filter(item => 
            item.name.toLowerCase().includes(lowercasedFilter) ||
            item.sku.toLowerCase().includes(lowercasedFilter) ||
            item.velocity.toLowerCase().includes(lowercasedFilter) ||
            item.serial.toLowerCase().includes(lowercasedFilter));
        setFilteredInfo(filteredData);
    }, [searchTerm, info]);

    const handleDetail = (idProduct) => {
        setIdProduct(idProduct);
        setOpen(!open);
    };

    if (loading) return <InfoLoading />;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (
        <>
            {open && (
                <div className="wimdons-emerge-detail">
                    <section className="emerge-detail2">
                        <SectionSupportEquip
                            idProduct = {idProduct}
                            setIdProduct = {setIdProduct}
                            setOpen= {setOpen}
                            open={open}
                            idc={idc}
                            compañia={compañia}
                            id={id}
                            rol={rol}
                            email={email}
                        />
                    </section>
                </div>
            )}
            <div className="windons-admin-two">
                <div className="windons-title-section">
                    <input
                        className="input-search"
                        type="text"
                        placeholder="Buscar equipo..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div></div>
                    {(rol === "AA-001" || rol === "SA-001" || rol === "AA-003") &&
                        <div className='input-date-filter'>
                            <label>Filtro:</label>
                            <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                                <option value="" disabled>Seleccionar</option>
                                <option value="003">Compañia</option>
                                <option value="001">Mis Equipos</option>
                                { rol==="SA-001" && <option value="002">Todos</option> }
                            </select>
                        </div>
                    }
                </div>
                <div className="windons-section-group">
                    <section className="section-info-group">
                        <h2 className="title2">Listado de productos</h2>
                        {filteredInfo.length!==0
                            ?<div className="list-info-group">
                                {filteredInfo.map( product => (
                                    <div key={product.id} className="orden-item">
                                        <div  key={product.id} className="orden-item-info">
                                            <img src={product.img} alt={product.name} />
                                            <div className="contect-orden-item-info">
                                                <div>
                                                    <h4>{product.name}</h4>
                                                    <p>SKU: {product.sku}</p>
                                                    {product.serial&&<p>Serial: {product.serial}</p>}
                                                    {product.ovelocity&&<p>Orden de entrega: {product.ovelocity}</p>}
                                                </div>
                                                <div className="orden-item-info-date">
                                                   {/* {product.date_create&&<p className="date-sale">Entregado: {product.date_create}</p>}
                                                    {product.date_installment&&<p className="date-upade">Renovación:{product.date_installment}</p>} */} 
                                                </div>
                                            </div>
                                            <div className="logisty-orden-item-info">
                                                <div></div>
                                                <button className="button-delete" onClick={() => handleDetail(product.id)} >
                                                    <BiMessageSquareDetail />
                                                    Soporte
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :<div className="windons-quiz-init">
                                <div className="section-quiz-init2">
                                    <div className="quiz-init-img">
                                        <img alt="Shofi Encuestas" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fgroud%2FPJ_To_do_Checklist-PhotoRoom.png-PhotoRoom.png?alt=media&token=af91c841-59f3-475c-ab05-7ad9334ca226&_gl=1*hcsaao*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAzODQ5NC4zNjYuMS4xNjk5MDQwNDI3LjEuMC4w"/>
                                        <h2 className="title-shofi-loanding">No tienes equipos asignados aún.<br></br>Permanece pendiente de próximas actividades.</h2>
                                    </div>
                                </div>
                            </div>
                        }
                    </section>
                </div>
            </div>
        </>
    );
}
