import { useEffect, useState } from "react";
import "./Guia.scss"
import { RegisterDownload } from "../../UI/molecules/Register_Download/RegisterDownload";
import { DownloadGuia } from "../../UI/molecules/Download_Guia/DownloadGuia";

export const Guia = () => {
    const [isRegisteredD, setIsRegisteredD] = useState(localStorage.getItem('isRegisteredD') === 'true');

    useEffect(() => {
        if (isRegisteredD) {
            localStorage.setItem('isRegisteredD', 'true');
        }
    }, [isRegisteredD]);

    if (!isRegisteredD) {
        return <RegisterDownload onRegisterSuccess={() => setIsRegisteredD(true)} />;
    }


    return (
        <DownloadGuia />
    );
}
