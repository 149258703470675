import React from 'react';
import { db } from "../../../../firebase/config";
import Papa from 'papaparse';
import { collection, getDocs } from "firebase/firestore";

export const DownloadData = () => {

  const fetchOrders = async () => {
    try {
      const ordersSnapshot = await getDocs(collection(db, 'form-cita'));
      const ordersData = ordersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      const formattedData = ordersData.map(order => ({
        name: order.name || '',
        company: order.company || '',
        email: order.email || '',
        load: order.load || '',
        numbemploye: order.numbemploye || '',
        phone: order.phone || '',
        date: order.date ? order.date.toDate().toISOString() : '',
        id: order.id,
      }));

      const csv = Papa.unparse(formattedData);
      downloadCSV(csv);
    } catch (error) {
      console.error("Error fetching register: ", error);
    }
  };

  const downloadCSV = (csv) => {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', 'registerSHOFI.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button className='button-new' onClick={fetchOrders}>Download CSV</button>
  );
};

export default DownloadData;
