import {  collection, doc, getDoc, getDocs, query,  where } from "firebase/firestore";
import { Link, useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { useEffect, useState } from "react";
import { BiChevronLeft, BiX, BiMessageSquareDetail } from "react-icons/bi";
import { toast } from "react-toastify";
import { InfoLoading } from "../../../../Loanding";
import { SectionSupportOrden } from "../../../UI/organisms/SectionSupport/SectionSupportOrden";
import { ShippingInfomation } from "../../../UI/organisms/ShippingInfomation/ShippingInfomation";

export const Asiganaciones = ({idc, rol, email, compañia, business_id }) => {
    const { id } = useParams();
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [products, setProducts] = useState([]);
    const [idProduct, setIdProduct] = useState(null)
    const [idOrdenDetail, setIdOrdenDetail] = useState(null)
    const [open, setOpen] = useState(false);
    const [orden, setOrden] = useState(null);
    const [openSupport, setOpenSupport] = useState(false);
    const [detail, setDetail] = useState([]);
    const [opendetail, setOpenDetail] = useState(false);
    
    const handleDetailClose = () => {
        setOpen(!open);
        setIdProduct(null);
        setOpenDetail(false);
    }; 

    const handleNoDetail = (idProduct) => {
        setOpen(!open);
        setOpenDetail(!opendetail);
        setIdProduct(idProduct);
    }; 

    useEffect(() => {
        let isMounted = true;
        const fetchKits = async () => {
            try {
                const q = query(collection(db, 'orden'), where('idk', '==', id));
                const querySnapshot = await getDocs(q);
                if (isMounted && !querySnapshot.empty) {
                    const doc = querySnapshot.docs[0];
                    setInfo({ id: doc.id, ...doc.data() });
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        if (id) {
            fetchKits();
        }
        return () => { isMounted = false; };
    }, [id]);

    useEffect(() => {
        let isMounted = true;
        const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection (db, "produc"));
                if (isMounted) {
                    setProducts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
                }
            } catch (error) {
                toast.error("Error fetching products: ", error);
            }
        };
        fetchProducts();
        return () => { isMounted = false; };
    }, []);

    useEffect(() => {
        const fetchDetail = async () => {
            if (!idProduct) return;
            try {
                const docRef = doc(db, "produc", idProduct);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setDetail(docSnap.data());
                } else {
                    toast.error("No se encontró información del producto");
                }
            } catch (err) {
                toast.error(err);
            } finally {
                toast.error(false);
            }
        };
        fetchDetail();
    }, [idProduct]);

    const handleDetail = (id) => {
        setIdOrdenDetail(id);
        setOpenSupport(!openSupport);
    };


    if (loading) return <InfoLoading />;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (
        <>
            {openSupport && (
                <div className="wimdons-emerge-detail">
                    <section className="emerge-detail2">
                        <SectionSupportOrden 
                            idOrdenDetail = {idOrdenDetail}
                            setIdOrdenDetail ={setIdOrdenDetail} 
                            setOpenSupport= {setOpenSupport}
                            openSupport={openSupport}
                            idc={idc} 
                            compañia={compañia} 
                            rol={rol} 
                            email={email}
                            business_id={business_id}
                        />
                    </section>
                </div>
            )}
            {open && (
                <div className="wimdons-emerge-detail">
                    <section className="emerge-detail">
                        <div className="emerge-detail-img">
                            <img alt={detail.name}  src={detail.img}/>
                        </div>
                        <div className="emerge-detail-contect">
                            <h2 className="subtitle3">{detail.name}</h2>
                            <div className="text-emerge">
                                <h4>Categoría: {detail.category}</h4>
                                <p><span>Marca:</span> {detail.brand}</p>
                                <p><span>Descripción:</span> {detail.description}</p>
                            </div>
                            <div className="emerge-detail-button">
                                <button className="button-delete" onClick={handleDetailClose}>
                                    <BiX /> 
                                    Cerrar 
                                </button>
                            </div>
                        </div>
                    </section>
                </div>
            )}
            <div className="windons-two">
                <div className="windons-title-section">
                    <div className="title-info">
                        <h2 className="title2">{info.idkName}</h2>
                    </div>
                    <div></div>
                    <Link className='button-new' to="/kits/lista"><BiChevronLeft /> Volver</Link>
                </div>
                <div className="windons-section-info">
                    <section className="section-info-two  seleccione-group">
                        <h2>Productos en el kit:({info.products.length})</h2>
                        <div className="list-info">
                            {info.products && info.products.map((id, index) => {
                                const product = products.find(product => product.id === id); // Suponiendo que 'products' es tu arreglo de productos
                                return (
                                    <div key={index} className="item-produc-selec" >
                                        <div className="img-item-product">
                                            <div className="avatar-img" onClick={() =>   handleNoDetail(product.id)} > 
                                                <img alt={product ? product.name : ''}  src={product ? product.img : ''} />
                                            </div>
                                        </div>
                                        <div>
                                            <p className="name">{product ? product.name : ''}</p>
                                            <p>Categoría: {product? product.category: ''}</p>
                                            <p>SKU: {product? product.SKU : ''}</p>
                                            <p>Marca: {product ? product.brand : ''}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </section>
                    <section className="section-info-two   seleccione-employe">
                        <ShippingInfomation
                            order_velocity = {info.order_velocity}
                            setOrden = {setOrden}
                            orden = {orden}
                        />
                    </section>
                </div>
                <div className="container-button">
                    <button className='button-delete' onClick={() => handleDetail(id)} ><BiMessageSquareDetail />Contactar Soporte</button>
                </div>
            </div>
        </>
    );
}
