import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { BiHide, BiShow, BiX } from 'react-icons/bi';
import { useLoginContext } from '../../../../context/LoginContext';

export const Password = ({ setIsPassword }) => {
    const [ newPassword, setNewPassword] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const { changePassword } = useLoginContext();
    const passwordValidationRegex = /^(?=.*[A-Za-z])(?=.*\W)[A-Za-z\d\W]{6,}$/;
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!passwordValidationRegex.test(newPassword)) {
            toast.error("La contraseña debe tener al menos 6 caracteres, incluyendo una letra y un carácter especial.");
            return;
        }
        if (newPassword !== confirmPassword) {
            toast.error("Las contraseñas no coinciden.");
            return;
        }
    
        await changePassword (newPassword);
        // if (result.success) {
        //     // Si la contraseña se actualizó correctamente
        //     toast.success(result.message);
        // } else {
        //     // Si hubo un error al actualizar la contraseña
        //     toast.error(result.message);
        // }
        setIsPassword(false);
    }; 

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const togglePassword = () => {
        setIsPassword(false);
    };

    return (
        <div className="container-login">
            <div className='section-log'>
                <div className="container-register">
                    <button onClick={togglePassword} className='button-x'>
                        <BiX />
                    </button>
                    <div className="brand" >
                        <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4" />
                    </div>
                    <p className="subtitle2">Crea tu nueva contraseña</p>
                    <form className="form-contact" onSubmit={handleSubmit}>
                        <div className='input-clave'>
                            <label className="label-t">Ingresa tu clave</label>
                            <input 
                                className='input-clave'
                                type={showPassword ? "text" : "password"}
                                placeholder="Nueva contraseña"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                            <button className="button-password" type="button-password" onClick={toggleShowPassword}>
                                {showPassword ? <BiHide /> : <BiShow />}
                            </button>
                        </div>
                        <div className='input-clave'>
                            <label className="label-t">Confirma tu clave</label>
                            <input 
                                className='input-clave'
                                type={showPassword ? "text" : "password"}
                                placeholder="Confirma contraseña"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            <button className="button-password" type="button" onClick={toggleShowPassword}>
                                {showPassword ? <BiHide /> : <BiShow />}
                            </button>
                        </div>
                        <button className='button-form' type="submit">Crear contraseña</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
