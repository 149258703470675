import "./Group.scss";
import { Timestamp, addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../../../firebase/config";
import { Link, useNavigate } from "react-router-dom";
import { BiChevronLeft, BiPlus, BiSave, BiX } from "react-icons/bi";
import { InfoLoading, Loading } from "../../../../Loanding";

export const CreadorGroup = ({rol, idc}) => {
    const [group, setGroup] = useState('');
    const [selectedEquipment, setSelectedEquipment] = useState([]); // Lista de productos seleccionados para el kit
    const [equipment, setEquipment] = useState([]); // Lista de todos los productos disponibles
    const [searchTerm, setSearchTerm] = useState('');
    const [saving, setSaving] = useState(false);
    const [ info, setInfo] = useState([]);
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ compania, setCompania ] = useState('');
    const [ idcSelect, setIdcSelect ] = useState('');
    const [ searchTermG, setSearchTermG ] = useState('');

    const navigate = useNavigate();
    useEffect(() => {
        let isMounted = true;
        const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "users"));
                if (isMounted) {
                    let filteredUsers;
                    if (rol === "SA-001") {
                        filteredUsers = querySnapshot.docs
                        .map(doc => ({ id: doc.id, ...doc.data() }))
                        .filter(user => user.idc === idcSelect);
                    } else {
                        filteredUsers = querySnapshot.docs
                        .map(doc => ({ id: doc.id, ...doc.data() }))
                        .filter(user => user.idc === idc);
                    }
                    // Filtra los usuarios que no están asignados a ningún grupo
                    const filterUserR = filteredUsers
                        .filter(userR => userR.idg === ''); 
                    setEquipment(filterUserR);
                }
            } catch (error) {
                toast.error("Error fetching products: ", error);
            }
        };
        fetchProducts();
        return () => { isMounted = false; };
    }, [idc , idcSelect, rol]); // Dependencia de idc

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'company');
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) setInfo(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, []);

    const handleAdd = (equipmId) => {
        const equipm = equipment.find(equipm => equipm.id === equipmId);
        if (equipm) {
            setSelectedEquipment(prevSelected => [...prevSelected, equipm.id]); // Añadir el producto seleccionado a la lista del kit
        }
    };

    const handleDelete = (equipmId) => {
        setSelectedEquipment(prevSelected => prevSelected.filter(id => id !== equipmId)); // Remover el producto seleccionado de la lista del kit
    };

    const handleSelectAll = () => {
        setSelectedEquipment(equipment.map(equip => equip.id)); // Selecciona todos los equipos
    };
    
    const handleDeleteAll = () => {
        setSelectedEquipment([]); // Borra todos los equipos seleccionados
    };

    const handleChange = (e) => {
        const companyId = e.target.value;
        const company = info.find(c => c.id === companyId);
        setIdcSelect(companyId); // Actualiza el estado de idc con el id de la nueva compañía seleccionada
        setCompania(company ? company.namecompany : ''); // Actualiza el estado de compania
        setSelectedEquipment([]); // Reinicia la lista de equipos seleccionados
    };
    
    const filteredEquipment = equipment.filter(user => (
        user.nombre.toLowerCase().includes(searchTerm.toLowerCase()) || 
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) || 
        user.apellido.toLowerCase().includes(searchTerm.toLowerCase())) &&
        !selectedEquipment.includes(user.id)
    );

    const filterSelectedUsers = (selectedUsers, searchTerm) => {
        return selectedUsers
            .map(userId => equipment.find(user => user.id === userId))
            .filter(user => user && ( // Asegurarse de que el usuario existe antes de intentar acceder a sus propiedades
                user.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.apellido.toLowerCase().includes(searchTerm.toLowerCase())
            ));
    };
    

    let registerIdc = rol === 'AA-001' ?idc:idcSelect;
    const handleSave = async () => {
        if (!group === 0) {
            toast.error("El nombre del kit y los productos son obligatorios");
            return;
        }
        if (registerIdc.length===0){
            toast.error("Debes selecionar una de los Seller disponibles");
            return;
        }
        if (selectedEquipment.length === 0) {
            toast.error("Debes seleccionar usuarios del equipo");
            return;
        }
        const currentDate = Timestamp.now();
        setSaving(true);
        try {
            
            // Añadir el grupo y obtener la referencia al grupo recién creado
            const groupRef = await addDoc(collection(db, 'equipment_group'), {
                name_group: group,
                group: selectedEquipment,
                idc: registerIdc,
                nameCompany:compania,
                creation: currentDate,
            });
            
            // Obtener el ID del grupo recién creado
            const groupId = groupRef.id;
            
            // Aquí actualizamos cada usuario seleccionado con el ID del grupo
            for (const equipmId of selectedEquipment) {
                const userRef = doc(db, "users", equipmId); // Referencia al documento del usuario
                await updateDoc(userRef, { groupName: group, idg: groupId }); // Actualiza el campo 'groupName' con el nombre del grupo y 'idg' con el ID del grupo
            }
            setSaving(false);
            toast.success('Grupo creado con éxito');
            navigate('/usuarios/grupos'); 
        } catch (error) {
            setSaving(false);
            toast.error("Error creando el grupo: ", error);
        }
    };


    // Función para obtener la primera letra en mayúscula
    const getFirstLetterCapitalized = (name) => {
        if (!name) return ''; // Retorna una cadena vacía si el nombre no está definido
        return name.charAt(0).toUpperCase(); // Toma el primer carácter y lo convierte en mayúscula
    };

    if (saving) {return <Loading />;}
    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="windons-two">
            <div className="windons-title-section">
                <div className="title-info-input">
                    <p>Nombre del grupo</p>
                    <input  type="text"  value={group}  onChange={(e) => setGroup(e.target.value)}  />
                </div>
                <div className="windons-title-info">
                    {rol === "SA-001" && (
                        <div className='input-date'>
                            <label>Empresa:</label>
                            <select name="selectcompañia" value={idcSelect} onChange={handleChange} required>
                                <option value="">Seleccionar seller</option>
                                {info.map((empresa) => (
                                    <option key={empresa.id} value={empresa.id}>{empresa.namecompany}</option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
                <Link className='button-two' to="/usuarios/grupos"><BiChevronLeft /> Volver</Link>
            </div>
            <div className="windons-section-info">
                <section className="section-info-two  seleccione-group">
                    <div className="section-title">
                        <div>
                            <label>Seleccionados</label>
                            <input 
                            type="text" 
                            placeholder="Buscar usuarios..." 
                            value={searchTermG}
                            onChange={(e) => setSearchTermG(e.target.value)}
                            />
                        </div>
                        <button onClick={handleDeleteAll} className="button-two"><BiX /> Borrar todo</button>
                    </div>
                    <div className="list-info">
                        {filterSelectedUsers(selectedEquipment, searchTermG).map((user, index) => (
                            <div key={index} className="item-produc-selec">
                                <div className="img-item-product">
                                    {user.img.length !== 0 ? (
                                        <div className="avatar-img">
                                            <img alt="avatar" src={user.img}/>
                                        </div>
                                    ) : (
                                        <h2 className="avatar-letter">{getFirstLetterCapitalized(user.nombre)}</h2>
                                    )}
                                </div>
                                <div>
                                    <p className="name">{user.nombre}</p>
                                    <p>{user.email}</p>
                                </div>
                                <button onClick={() => handleDelete(user.id)}><BiX /></button>
                            </div>
                        ))}
                    </div>
                </section>
                <section className="section-info-two  seleccione-employe">
                    <div className="section-title">
                        <div>
                            <label>Lista Usuarios</label>
                            <input 
                                type="text" 
                                placeholder="Buscar usuarios..." 
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <button onClick={handleSelectAll} className="button-two"><BiPlus />Todos</button>
                    </div>
                    <div className="list-info">
                        {filteredEquipment.map((users) => (
                            <button key={users.id} onClick={() => handleAdd(users.id)} className="item-produc">
                                <div className="img-item-product">
                                    {users.img.length!==0 ? (
                                        <div className="avatar-img"> 
                                            <img alt="avatar" src={users.img}/>
                                        </div>
                                    ) : (
                                        <h2 className="avatar-letter">{getFirstLetterCapitalized(users.nombre)}</h2> // Llamada a la función aquí
                                    )}
                                </div>
                                <div>
                                    <p className="name">{users.nombre}</p>
                                    <p>{users.email}</p>
                                </div>
                                <div><BiPlus /></div>
                            </button>
                        ))}
                    </div>
                </section>
            </div>
            <div className="container-button-two">
                <button className="button-save" onClick={handleSave}>
                    <BiSave />Guardar
                </button>
            </div>
        </div>
    );
}