import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(localStorage.getItem('authToken') || '');
    
    const getAuthToken = async () => {
        try {
            const response = await axios.post('https://f.velocity-x.co/api/v1/auth', {}, {
                headers: {
                    'X-LOGISTIKO-API-KEY':"ewOfGswmdcmmT5D4zWxplWmXNtCsBiDl"
                }
            });
            
            if (response.data && response.data.token) {
                setAuthToken(response.data.token);
                localStorage.setItem('authToken', response.data.token);
            } else {
                console.log(' Esperando token no recibido de la API');
            }
        } catch (error) {
            console.log('Error al obtener el token:', error);
        }
    };

    
    useEffect(() => {
        getAuthToken();
    }, []);
    
    return (
        <AuthContext.Provider value={{ authToken, getAuthToken }}>
            {children}
        </AuthContext.Provider>
    );
};
