import { Link } from "react-router-dom";
import "./ItenReserve.scss";
import { BiCheckDouble, BiTransferAlt } from "react-icons/bi";
import { TiDeleteOutline } from "react-icons/ti";

function reduceLength(inputString, maxLength) {
    if (!inputString || typeof inputString !== 'string') return ""; // Retorna cadena vacía si inputString no está definido o no es una cadena
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.slice(0, maxLength);
    }
}
export const ItenReserve = ({name, lastname, lead , load, company, day_agend, img, id, approval}) => {
    const shortenedName1 = reduceLength((name || "").toUpperCase(), 1);
    const shortenedName2 = reduceLength(lastname? name + " " + lastname : name, 16);
    const shortenedEmail = reduceLength(lead|| "", 25);

    let element;

    if (typeof approval !== "boolean") {
        element = <p className="admin"><BiTransferAlt />Pendiente</p>;
    } else if (approval === true ) {
        element = <p className="usuario"><BiCheckDouble />Aprovado</p>;
    } else {
        element = <p className="super-admin"><TiDeleteOutline />Declinado</p>;
    }

    return (
        <Link to={`/startco/inversionista/reserva/${id}`} className="item-reserve">
            <div className="avatar">
                {img ? (
                    <div className="avatar-img"> 
                    <img alt="avatar" src={img}/>
                    </div>
                ) : (
                    <h2 className="avatar-letter">{shortenedName1}</h2>
                )}
            </div>
            <p className="name-user">{shortenedName2}</p>
            <p className="email">{shortenedEmail}</p>
            <p className="phone">{company}</p>
            <p className="rol">{element}</p>
            <p className="date">{day_agend}</p>
        </Link>
    );
}
