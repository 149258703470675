import "./CreatorUsers.scss";
import React, { useState } from 'react';
import Papa from 'papaparse';
import { db } from '../../../../firebase/config';
import { Timestamp,addDoc,collection,query,where,getDocs,doc, getDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { BiRefresh } from "react-icons/bi";
import { TbFileTypeCsv } from "react-icons/tb";

// Componente para mostrar el progreso con etiqueta
function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} size={"150px"} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
}
  
CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

export const BulkUpload = ({ idc, business_id, company , typecompany }) => {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [dataReady, setDataReady] = useState(false);
    const [entries, setEntries] = useState([]);
    const [textErrors, setTextErrors] = useState('');

    const csvURL = "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fplantilla%2FFormato_Carga_Masivo_Siigo.xlsx?alt=media&token=5db61456-b3f8-4760-bcd6-aabf6302dce7";

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                setEntries(results.data);
                setDataReady(true);  // Indica que los datos están listos para ser revisados
            }
        });
    };

    const handleUploadClick = async () => {
        setLoading(true);
        const total = entries.length;
        let processed = 0;

        for (const formData of entries) {
            if (await validateData(formData)) {
                try {
                    await processSingleEntry(formData);
                } catch (error) {
                    toast.error(`Error al procesar la entrada: ${error.message}`);
                }
            }
            processed++;
            setProgress((processed / total) * 100); // Actualizar el progreso
        }
        setLoading(false);
        setDataReady(false);
    };
    // Validar que los datos no existan
    const validateData = async (item) => {
        // Validaciones de formato y existencia
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(item.email) ||
            !/^[0-9]{10}$/.test(item.telefono) ||
            !/^[0-9]+$/.test(item.cc) ||
            await checkExists('email', item.email) ||
            await checkExists('telefono', item.telefono) ||
            await checkExists('cc', item.cc)) {
            toast.error(`Datos ya registrados ${item.email}`);
            return false;
        }
        return true;
    };
    const checkExists = async (field, value) => {
        const q = query(collection(db, "users"), where(field, "==", value));
        const docs = await getDocs(q);
        return !docs.empty;
    };

    const processSingleEntry = async (formData) => {
        try {
            // Función para generar un código aleatorio de letras y números
            const generateOrderCode = () => {
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let result = '';
                const length = 12;
                for (let i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * characters.length));
                }
                return result;
            };
            // Función para verificar si el código de orden ya existe
            const checkOrderCodeExists = async (code) => {
                const usersRef = collection(db, 'users');
                const q = query(usersRef, where('code_orden', '==', code));
                const querySnapshot = await getDocs(q);
                return !querySnapshot.empty;
            };
            const generateUniqueOrderCode = async () => {
                let newOrderCode = generateOrderCode();
                while (await checkOrderCodeExists(newOrderCode)) {
                    newOrderCode = generateOrderCode();
                }
                return newOrderCode;
            };
            const orderCode = await generateUniqueOrderCode();
            // Busqueda del id de los productos
            const idProducts = await fetchProductKits(formData.select_kit);
            // Armar array de productos para buscar la info
            const productDetails = await fetchProductsDetails(idProducts);
            // Carga de datos de Orden de productos de montaje
            let ordenProducts = productDetails.map(product => {
                return {
                    name: product.name,
                    price: product.price,
                    sku: product.SKU+"-"+orderCode,
                    picture_url:product.img,
                    quantity:1,
                };
            });
            let totalOrden = ordenProducts.reduce((sum, product) => sum + product.price, 0);
            // Montaje de usuario
            const { orden_id, ...dataToSave } = formData;
            
            // Chequear y manejar valores undefined
            for (const key in dataToSave) {
                if (dataToSave[key] === undefined) {
                    dataToSave[key] = ""; // O cualquier valor por defecto que consideres apropiado
                }
            }
            const userDoc = await addDoc(collection(db, "users"), {
                ...dataToSave,
                pais: "Colombia",
                edad: '',
                sexo: '',
                img: '',
                business_id: business_id,
                compañia: company,
                code_orden: orderCode,
                idc: idc,
                rol:'US-004',
                idg:'', 
                fechaDelete: '',
                has_completed_tour: true,
                typecompany: typecompany,
                status: true,
                fechaCreacion: Timestamp.now(),
                latitud:"",
                longitud:"",
                order_status_id:0,
                id_orden: [formData.orden_id],
            });
            // Creacion de la orden en registro firebase
            await addDoc(collection(db, 'orden'), {
                business_id: business_id,
                date_delivery:"",
                creation: Timestamp.now(),
                date_delete: "",
                deleveryData: {
                    name: formData.nombre,
                    lastname: formData.apellido,
                    country: "Colombia",
                    department:formData.departamento,
                    address:formData.direccion_1,
                    lastaddress:formData.direccion_2,
                    lat:"",
                    log:"",
                    phone:formData.telefono,
                    email:formData.email,
                    cc:formData.cc,
                },
                idc:idc,
                idk:formData.select_kit,
                idu:userDoc.id,
                order_pictures:"",
                order_product: ordenProducts,
                order_status_id:0,
                order_velocity:"",
                products: idProducts,
                total: totalOrden,
                status: true,
                updated_at:"",
                re_asign:true,
                order_acceptance:false,
                order_siigo_id: formData.orden_id,
            });
            toast.success(`Orden cargada para ${formData.email}`);
        } catch (error) {
            // Agregar un nuevo mensaje de error al array de errores
            console.log(error)
            setTextErrors(prevErrors => [...prevErrors, `Error 202 al crear: ${formData.email}:${error}`]);
        }
    };    

    const fetchProductKits = async (kitId) => {
        // Usar doc para obtener una referencia al documento específico por ID
        const docRef = doc(db, "produc_kits", kitId);
        // Obtener el documento
        const docSnapshot = await getDoc(docRef);
        // Verificar si el documento existe y retornar los datos
        if (docSnapshot.exists()) {
            const kitsData = docSnapshot.data().kits; // Asegúrate de que 'kits' es el campo correcto
            return kitsData; // Retorna los datos de los kits
        } else {
            throw new Error("Kit not found with ID: " + kitId);
        }
    };
    
    const fetchProductsDetails = async (prodIds) => {
        const products = [];
        // Iterar sobre cada ID de producto y obtener el documento correspondiente
        for (const id of prodIds) {
            // Obtener una referencia al documento específico por ID
            const docRef = doc(db, "produc", id);
            // Obtener el documento
            const docSnapshot = await getDoc(docRef);
            // Verificar si el documento existe y agregar sus datos al array de productos
            if (docSnapshot.exists()) {
                products.push({ id, ...docSnapshot.data() });
            } else {
                console.log(`No product found with ID: ${id}`); // Opcional: manejar el caso de que no se encuentre el documento
            }
        }
        return products;
    };
    return (
        <>
            {!loading
                ?<div className="container-bulkupload">
                    <h3 className="title-shofi-loanding">Carga de forma masiva usando esta Plantilla CSV:
                        <a
                            href={csvURL}
                            download="cargamasiva.csv"
                            className="button-download"
                            rel="noopener noreferrer"
                        >
                            Plantilla Excel
                        </a>
                    </h3>
                    <div className='input-upload'>
                        <label htmlFor="file" className="custom-file-upload">
                            {dataReady
                                
                                ?<><BiRefresh />Cambiar CVS</> : <><TbFileTypeCsv /> Cargar CSV</> 
                
                            }
                        </label>
                        <input id="file" type="file" onChange={handleFileChange} accept=".csv" style={{display: 'none'}} />
                    </div>
                    {dataReady&& (
                        <button className='button-load-cvs' onClick={handleUploadClick}>
                            <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                            Cargar Datos
                        </button>
                    )}
                    {textErrors.length!==0&&<p className="errortext">{textErrors}</p>}
                </div>
                :<div  className="container-bulkupload">
                    <h3 className="title-shofi-loanding">Carga de forma masiva usando esta Plantilla CSV:</h3>
                    <CircularProgressWithLabel value={progress} />
                </div>
            }
        </>
    );
};

