import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { InfoLoading } from "../../../../Loanding";
import { Link } from "react-router-dom";
import { BiPencil } from "react-icons/bi";
import { db } from "../../../../firebase/config";
import { toast } from "react-toastify";

export const ListaEncuestas = ({idc, id, compañia}) => {
    const [kits, setKits] = useState([]);
    const [answer, setAnswer] = useState ([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [answerIdMap, setAnswerIdMap] = useState(new Map());
    const [pendingKits, setPendingKits] = useState([]);
    const [processedKits, setProcessedKits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // Llamado de la API de busqueda de firebase
    useEffect(() => {
        let isMounted = true;
        setLoading(true); 
        const fetchEquipement = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "quizAnswer"));
                if (isMounted) {
                    const filteredUsers = querySnapshot.docs
                        .map(doc => ({ id: doc.id, ...doc.data() }))
                        .filter(user => user.idu === id);
                        setAnswer(filteredUsers);
                }
            } catch (error) {
                toast.error("Error fetching products: ", error);
            }
        };
        fetchEquipement();
        const fetchKits = async () => {
            try {
                if (!idc) throw new Error("idc is undefined");
                const db = getFirestore();
                const kitsCollectionRef = collection(db, 'quiz_application');
                // Ajusta la consulta para buscar en un array de users
                const q = query(kitsCollectionRef, where('idc', '==', idc), where('users', 'array-contains', id));
                const querySnapshot = await getDocs(q);
                const fetchedKits = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) {
                    setKits(fetchedKits);
                }
            } catch (err) {
                if (isMounted) {
                    setError(err);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchKits();
        return () => { 
            isMounted = false; 
        };
    }, [idc, id]);

    const filterQuiz = kits.filter(quiz => (
        quiz.comment.toLowerCase().includes(searchTerm.toLowerCase())
    ));

    useEffect(() => {
        const classifyKits = () => {
            // Crear un mapa de ide a id de respuestas
            const tempAnswerIdMap = new Map(answer.map(a => [a.ide, a.id]));
            const pendientes = filterQuiz.filter(kit => !tempAnswerIdMap.has(kit.assignment.id));
            const procesadas = filterQuiz.filter(kit => tempAnswerIdMap.has(kit.assignment.id));
            setPendingKits(pendientes);
            setProcessedKits(procesadas);
            setAnswerIdMap(tempAnswerIdMap); // Actualizar el estado de answerIdMap
        };
    
        classifyKits();
    }, [filterQuiz, answer]);

    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <>
            {(kits.length!==0)
                ?<div className="windons-admin-two">
                    <div className="windons-title-section">
                        <input
                            className="input-search"
                            type="text"
                            placeholder="Buscar grupo..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="windons-section-group">
                        <section className="section-info-group">
                            <h2 className="title2">Listado de encuestas</h2>
                            <div className="list-info-group">
                                {pendingKits.length!==0&&<p class="asigne">Pendientes: {pendingKits.length}</p>}
                                {pendingKits.map(group => (
                                    <Link key={group.id} className="item-quiz-group" to={`/contestador/${group.assignment.id}`} >
                                        <div><BiPencil className="search"/></div>
                                        <div>
                                            <p className="name">{group.comment}</p>
                                            <p>{group.id}</p>
                                        </div>
                                        <p className="date">{group.timestamp.toDate ? group.timestamp.toDate().toLocaleString() : new Date(group.timestamp.seconds * 1000).toLocaleString()}</p>
                                    </Link>
                                ))}
                                {processedKits.length!==0&&<p class="inactive">Procesadas: {processedKits.length}</p>}
                                {processedKits.map(group => (
                                    <Link key={group.id} className="item-quiz-group" to={`/encuesta/resultado/${answerIdMap.get(group.assignment.id)}`} >
                                        <div><BiPencil className="search"/></div>
                                        <div>
                                            <p className="name">{group.comment}</p>
                                            <p>{group.id}</p>
                                        </div>
                                        <p className="date">{group.timestamp.toDate ? group.timestamp.toDate().toLocaleString() : new Date(group.timestamp.seconds * 1000).toLocaleString()}</p>
                                    </Link>
                                ))}
                            </div>
                        </section>
                    </div>
                </div> 
                :<div className="windons">
                    <div className="encabezado-list">
                        <h2 className="title">Encuestas: {compañia}</h2>
                    </div>
                    <div className="windons-quiz-init">
                        <div className="section-quiz-init2">
                            <div className="quiz-init-img">
                                <img alt="Shofi Encuestas" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fgroud%2FPJ_To_do_Checklist-PhotoRoom.png-PhotoRoom.png?alt=media&token=af91c841-59f3-475c-ab05-7ad9334ca226&_gl=1*hcsaao*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAzODQ5NC4zNjYuMS4xNjk5MDQwNDI3LjEuMC4w"/>
                                <h2 className="title-shofi-loanding">No tienes encuestas asignadas<br></br>está al pendiente de próximas actividades</h2>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
