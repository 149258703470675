import "./CreatorUsers.scss";
// Configuracion de data
import { db } from "../../../../firebase/config";
import colombia from '../../../../firebase/colombia';
import { Timestamp, addDoc, collection, getDocs, query, where } from 'firebase/firestore';
// Paqueteria
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BiX } from "react-icons/bi";
// Componentes 
import { InfoLoading } from '../../../../Loanding';
import { useNavigate } from "react-router-dom";

export const CreatorUsers = ({idc, setIsCreatorUsers, business_id, company}) => {
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(false);
    const [ selectedDepartment, setSelectedDepartment ] = useState('');
    const [ cities, setCities ] = useState([]);
    const [ info, setInfo ] = useState([]);
    const [ products, setProducts]  = useState([]); 
    const [ productsDates, setProductsDates ] = useState([]);
    const [ orderCode, setOrderCode ] = useState('');
    const [ formData, setFormData ] = useState({
        nombre: '',
        email: '',
        telefono: '',
        apellido: '',
        pais: 'Colombia',
        departamento: '',
        ciudad: '',
        direccion_1: '',
        direccion_2: '',
        edad: '',
        sexo: '',
        img: '',
        business_id: business_id,
        compañia: company,
        idc: idc,
        rol:'US-004',
        latitud: '',
        longitud:'',
        idg:'', 
        cc:'',
        fechaCreacion:'',
        fechaDelete: '',
        has_completed_tour: true,
        typecompany: 'C000002',
        select_kit:'',
        code_orden: '',
        status: true,
        orderIdInput: '',
    });

    // Función para generar un código aleatorio de letras y números
    const generateOrderCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const length = 12;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    // Función para verificar si el código de orden ya existe
    const checkOrderCodeExists = async (code) => {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('code_orden', '==', code));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
    };
    // Función para verificar si el cc ya existe
    const checkCcExists = async (cc) => {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('cc', '==', cc));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
    };

    // Función para verificar si el email ya existe
    const checkEmailExists = async (email) => {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('email', '==', email));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
    };

    // Función para verificar si el teléfono ya existe
    const checkPhoneExists = async (telefono) => {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('telefono', '==', telefono));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
    };
     // Generar y establecer el código de orden cuando el componente se monte
     useEffect(() => {
        const generateUniqueOrderCode = async () => {
            let newOrderCode = generateOrderCode();
            while (await checkOrderCodeExists(newOrderCode)) {
                newOrderCode = generateOrderCode();
            }
            setFormData(prevState => ({
                ...prevState,
                code_orden: (newOrderCode),
            }));
            setOrderCode(newOrderCode);
        };
        generateUniqueOrderCode();
    }, []);
    // eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = useState({
        email: null,
        telefono: null,
        cc: null,
    });

    // Carga de ciudades 
    useEffect(() => {
        if (selectedDepartment) {
            setCities(colombia[selectedDepartment]);
        }
    }, [selectedDepartment]);

    // Boton de cerrar
    const toggleClose = () => {
        setIsCreatorUsers(false);
    };

    // Llamado de informacion de los productos
    useEffect(() => {
        let isMounted = true;
        const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "produc"));
                if (isMounted) {
                    const allProducts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setProductsDates(allProducts);
                }
            } catch (error) {
                toast.error("Error fetching products: ", error);
            }
        };
        fetchProducts();
        return () => { isMounted = false; };
    }, []);

    // Llamados de opciones de kits
    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const infoCollectionRef = collection(db, 'produc_kits');
                const q = query(infoCollectionRef, where('idc', '==', idc));
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) setInfo(fetchedInfo);
            } catch (err) {
                toast.error('Error al cargar datos de kits');
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, [idc]);
    // Verificador y crear datos de form
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'select_kit') {
            const selectedKit = info.find(kit => kit.id === value);
            setProducts(selectedKit);
            if (selectedKit) {
                setProducts(selectedKit.kits);
            }
        }
        if (name === "telefono" || name === "cc") {
            if (/^[0-9]*$/.test(value)) {
                setFormData(prevState => ({
                    ...prevState,
                    [name]: value
                }));
            }
        } else if (name === "orderIdInput") {
            setFormData(prevState => ({
                ...prevState,
                orderIdInput: value
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
        if (name === 'departamento') {
            setSelectedDepartment(value);
        }
    };
    // Datos de productos de orden
    let ordenProductsOrden = products.map(productId => {
        const product = productsDates.find(p => p.id === productId);
        return {
            name: product.name,
            price: product.price,
            sku: product.SKU+"-"+orderCode,
            picture_url:product.img,
            quantity:1,
        };
    });
    let totalOrden = ordenProductsOrden.reduce((sum, product) => sum + product.price, 0);
    // Acciones de guardado
    const handleSubmit = async (e) => {
        e.preventDefault();
         // Verificar si el formato email es correcto
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            toast.error('Por favor, introduce un email válido.');
            return;
        }
        // Verificar si la compañia este bien
        if (!formData.idc) {
            toast.error('Por favor, selecciona una empresa.');
            return;
        }
        // Verificar si el formato telefono es correcto
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(formData.telefono)) {
            toast.error('Por favor, introduce un número de teléfono válido.');
            return;
        }
        // Verificar si el formato cc es correcto
        const ccRegex = /^[0-9]+$/;
        if (!ccRegex.test(formData.cc)) {
            toast.error('Por favor, introduce un número de documento válido.');
            return;
        }
        // Verificar si el cc ya está registrado
        if (await checkCcExists(formData.cc)) {
            toast.error('El número de cédula ya está registrado.');
            return;
        }
        // Verificar si el email ya está registrado
        if (await checkEmailExists(formData.email)) {
            toast.error('El correo electrónico ya está registrado.');
            return;
        }
        // Verificar si el teléfono ya está registrado
        if (await checkPhoneExists(formData.telefono)) {
            toast.error('El número de teléfono ya está registrado.');
            return;
        }
        // Concatenar dirección para geocodificación
        const fullAddress = `${formData.direccion_1}, ${formData.ciudad}, ${formData.departamento}, ${formData.pais}`;
        let latTemp, lngTemp; // Variables temporales para lat y lng
        try {
            setLoading(true);
            const encodedAddress = encodeURIComponent(fullAddress);
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=AIzaSyDQnvwWu0NOA5v4NEMB0NqlQ_RSIk6dxlg`);
            const data = response.data;
            if (data.results && data.results.length > 0) {
                const { lat, lng } = data.results[0].geometry.location;
                latTemp = lat;
                lngTemp = lng;
            } else {
                toast.error("No se pudo obtener las coordenadas de la dirección proporcionada.");
                setLoading(false);
                return;
            }
        } catch (error) {
            toast.error("Error al obtener las coordenadas: " + error.message);
            return;
        }
        // Actualizar formData directamente con latitud y longitud
        if (latTemp && lngTemp) {
            formData.latitud = latTemp;
            formData.longitud = lngTemp;
        }
        // Recargando sistema
        setLoading(true);
        // Procesos de montaje
        try{
            const { orderIdInput, ...dataToSave } = formData;
            // Creacion usuario en registro firebase
            const docUsers = await addDoc(collection(db, "users"),{
                ...dataToSave,
                id_orden: [formData.orderIdInput],
                fechaCreacion: Timestamp.now(),
                order_status_id:0,
            });
            // Creacion de la orden en registro firebase
            await addDoc(collection(db, 'orden'), {
                business_id: business_id,
                creation: Timestamp.now(),
                date_delivery:"",
                date_delete: "",
                deleveryData: {
                    name: formData.nombre,
                    lastname: formData.apellido,
                    country: formData.pais,
                    department:formData.departamento,
                    address:formData.direccion_1,
                    lastaddress:formData.direccion_2,
                    lat:formData.latitud,
                    log:formData.longitud,
                    phone:formData.telefono,
                    email:formData.email,
                    cc:formData.cc,
                },
                idc:idc,
                idk:formData.select_kit,
                idu:docUsers.id,
                order_pictures:"",
                order_product: ordenProductsOrden,
                order_status_id:0,
                order_velocity:"",
                products: products,
                total: totalOrden,
                status: true,
                updated_at:"",
                re_asign:false,
                order_acceptance:false,
                order_siigo_id: formData.orderIdInput,
            });
            setIsCreatorUsers(false);
            // Volver al listado
            navigate('/usuarios');
        }catch (error) {
            console.error("Error creating the order or user: ", error);
            toast.error("Error creando la orden: " + error.message);
        } finally {
            setLoading(false);
        }        
    };

    if (loading) return <InfoLoading/>;
    return (
        <div className="container-login">
            <button onClick={toggleClose} className='button-x-close'>
                <BiX />
            </button>
            <div className='section-location'>
                <div className="container-location-users">
                    <div className="brand" >
                        <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4" />
                    </div>
                    <p className="subtitle2">¡Hola!, comencemos a llenar estos datos para crear su orden:</p>
                    <form className="form-contact" onSubmit={handleSubmit}>
                        <section className="form-contact-dates">
                            <div className='input-date'>
                                <label>Nombre:</label>
                                <input type="text" name="nombre" value={formData.nombre} onChange={handleChange} required />
                            </div>
                            <div className='input-date'>
                                <label>Apellido:</label>
                                <input type="text" name="apellido" value={formData.apellido} onChange={handleChange} required />
                            </div>
                            <div className='input-date'>
                                <label>Cedula CC / PEP:</label>
                                <input type="tel" name="cc" value={formData.cc} onChange={handleChange}  required />
                                {errors.cc && <p className='error-message'>{errors.cc}</p>}
                            </div>
                            <div className='input-date'>
                                <label>ID Orden:</label>
                                <input
                                    type="text"
                                    name="orderIdInput"
                                    value={formData.orderIdInput}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='input-date'>
                                <label>Email:</label>
                                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                                {errors.email && <p className='error-message'>{errors.email}</p>}
                            </div>
                            <div className='input-date'>
                                <label>Teléfono:</label>
                                <input type="tel" name="telefono" value={formData.telefono} onChange={handleChange} required  />
                                {errors.telefono && <p className='error-message'>{errors.telefono}</p>}
                            </div>
                            <div className='input-date'>
                                <label>Departamento:</label>
                                <select name="departamento" value={selectedDepartment} onChange={handleChange}  required >
                                    <option value="" disabled>Seleccione</option>
                                    {Object.keys(colombia).map((department) => (
                                    <option key={department} value={department}>{department}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-date'>
                                <label>Ciudad:</label>
                                <select name="ciudad" value={formData.ciudad} onChange={handleChange}  required >
                                    <option value="" disabled>Seleccione</option>
                                    {cities.map((city) => (
                                    <option key={city} value={city}>{city}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-date'>
                                <label>Dirección:</label>
                                <input type="text" name="direccion_1" value={formData.direccion_1} onChange={handleChange}   required/>
                            </div>
                            <div className='input-date'>
                                <label>Dirección detallada:</label>
                                <input type="text" name="direccion_2" value={formData.direccion_2} onChange={handleChange} />
                            </div>
                            <div className='input-date'>
                                <label>Kit asignado:</label>
                                <select name="select_kit" value={formData.select_kit} onChange={handleChange} required>
                                    <option value="" disabled hidden>Seleccionar kits</option>
                                    {info.map((kit) => (
                                        <option key={kit.id} value={kit.id}>{kit.name_kits}</option>
                                    ))}
                                </select>
                            </div>
                        </section>
                        <button className='button-form' type="submit">Confirmar</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
