import "./SectioBalance.scss"
import { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../../firebase/config'; // Asegúrate de ajustar esta ruta a tu configuración de Firebase
import { endOfMonth, startOfMonth, subMonths, differenceInCalendarDays } from 'date-fns';
import { Link } from "react-router-dom";

export const SectioBalance = ({ idc }) => {
    const [huellaCarbonoMesAnterior, setHuellaCarbonoMesAnterior] = useState(0);
    const [totalBalance, setTotalBalance] = useState(0); 

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                if (!idc) throw new Error("idc es indefinido");
                const data = collection(db, 'balance_service');
                const q = query(data, where('idc', '==', idc));
                const querySnapshot = await getDocs(q);
                if (isMounted) {
                    const items = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    // Calcular la suma total de balance
                    const total = items.reduce((acc, item) => acc + (item.balance || 0), 0);
                    setTotalBalance(total);
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchInfo();
        return () => {
            isMounted = false;
        };
    }, [idc]);

    useEffect(() => {
        let isMounted = true;

        const fetchUsers = async () => {
            try {
                // Asegúrate de que 'idc' es el campo correcto para filtrar en tu colección de usuarios
                const q = query(collection(db, 'users'), where('idc', '==', idc));
                const querySnapshot = await getDocs(q);
                const users = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    fechaCreacion: doc.data().fechaCreacion ? doc.data().fechaCreacion.toDate() : null,
                    fechaDelete: doc.data().fechaDelete && doc.data().fechaDelete !== '' ? doc.data().fechaDelete.toDate() : null,
                }));

                const startOfLastMonth = startOfMonth(subMonths(new Date(), 1));
                const endOfLastMonth = endOfMonth(subMonths(new Date(), 1));
                const valorHuellaCarbonoPorDia = 0.5; // Define aquí el valor real de huella de carbono por día trabajado

                let huellaCarbonoTotal = 0;

                users.forEach(user => {
                    const fechaInicio = user.fechaCreacion < startOfLastMonth ? startOfLastMonth : user.fechaCreacion;
                    const fechaFin = user.fechaDelete && user.fechaDelete < endOfLastMonth ? user.fechaDelete : endOfLastMonth;

                    if (user.fechaCreacion <= endOfLastMonth && (!user.fechaDelete || user.fechaDelete >= startOfLastMonth)) {
                        const diasTrabajados = differenceInCalendarDays(fechaFin, fechaInicio) + 1; // +1 para incluir ambos días
                        huellaCarbonoTotal += diasTrabajados * valorHuellaCarbonoPorDia;
                    }
                });

                if (isMounted) {
                    setHuellaCarbonoMesAnterior(huellaCarbonoTotal);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchUsers();

        return () => {
            isMounted = false;
        };
    }, [idc]);

    return (
        <section className="dashboard-sec4">
            <Link className='counter-siigo' to={"/movimientos/balance"}>
                <img src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/pages%2Fbrand%2Flogistica.png?alt=media&token=f3612ee8-111e-4528-ad40-fc5caaa2017a" alt="Logistica"/>
                <div>
                    <p className="p-contect">Créditos de logística</p>
                    <p className="text-title">{totalBalance}</p>
                    <p className="p-contect">Servicio inverso</p>
                </div>
            </Link>
            <div className='huella-carbono-siigo'>
                <p className="p-contect">Impacto disminución huella carbono</p>
                <p>CO2: {huellaCarbonoMesAnterior.toFixed(1)} Kg.</p>
            </div>
        </section>
    );
};
