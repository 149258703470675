import "./Group.scss";
import { collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link, useParams , useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { db } from "../../../../firebase/config";
import { InfoLoading } from "../../../../Loanding";
import { BiChevronLeft, BiPlus, BiSave, BiTrash, BiX } from "react-icons/bi";

export const EditorGroup = ({idc, rol}) => {
    const { id } = useParams();
    const [info, setInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [equipment, setEquipment] = useState([]);
    const [name, setName] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermG, setSearchTermG] = useState('');
    const [saving, setSaving] = useState(false);
    const [ idcSelect, setIdcSelect ] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                if (!id) throw new Error("Id Foil is undefined");

                const docRef = doc(db, 'equipment_group', id);
                const docData = await getDoc(docRef);

                if(docData.exists() && isMounted) {
                    setInfo(docData.data());
                    setName(docData.data().name_group);
                    setIdcSelect(docData.data().idc)
                }

            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => {isMounted = false;}
    }, [idc, id]);   


    useEffect(() => {
        let isMounted = true;
        const fetchEquipement = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "users"));
                if (isMounted) {
                    let filteredUsers;
                    if (rol === "SA-001") {
                        filteredUsers = querySnapshot.docs
                        .map(doc => ({ id: doc.id, ...doc.data() }))
                        .filter(user => user.idc === idcSelect);
                    } else {
                        filteredUsers = querySnapshot.docs
                        .map(doc => ({ id: doc.id, ...doc.data() }))
                        .filter(user => user.idc === idc);
                    }
                    setEquipment(filteredUsers);
                }
            } catch (error) {
                toast.error("Error fetching products: ", error);
            }
        };
        fetchEquipement();
        return () => { isMounted = false; };
    }, [idc, id, idcSelect, rol]); 

    const handleDelete = async (groupID) => {
        setInfo(prevInfo => ({
            ...prevInfo,
            group: prevInfo.group.filter(id => id !== groupID)
        }));
        // Obtener la referencia del usuario y actualizar el nombre del grupo e ID del grupo
        const userRef = doc(db, "users", groupID);
        await updateDoc(userRef, { groupName: "", idg: "" });
    };    

    const handleAdd = (groupId) => {
        const group = equipment.find(group => group.id === groupId);
        if (group) {
            setInfo(prevInfo => ({
                ...prevInfo,
                group: [...prevInfo.group, group.id] // Si no está en la lista, agregar el usuario
            }));
        }
    };

    const filteredGroupUsers = info && info.group ? 
        info.group.map(groupId => {
            return equipment.find(group => group.id === groupId);
        }).filter(user => 
            user && 
            (user.nombre.toLowerCase().includes(searchTermG.toLowerCase()) || 
            user.email.toLowerCase().includes(searchTermG.toLowerCase()) || 
            user.apellido.toLowerCase().includes(searchTermG.toLowerCase()))
        )
    : [];

    const filteredEquipment = info && info.group
        ? equipment.filter(user => (
            (user.idg === '' || user.idg === id) && (
                user.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.apellido.toLowerCase().includes(searchTerm.toLowerCase())
            ) &&
            !info.group.includes(user.id)
        ))
    : [];


    const handleSelectAll = () => {
        setInfo(prevInfo => ({
            ...prevInfo,
            group: equipment.map(group => group.id)
        })); // Selecciona todos los equipos
    };
    
    const handleDeleteAll = async () => {
        // Primero, establecemos el group a un array vacío en el estado.
        setInfo(prevInfo => ({
            ...prevInfo,
            group: []
        }));
        // y actualizamos el groupName y idg en la base de datos.
        if (info && info.group && info.group.length > 0) {
            for (const groupId of info.group) {
                const userRef = doc(db, "users", groupId);
                try {
                    await updateDoc(userRef, { groupName: "", idg: "" });
                } catch (error) {
                    toast.error(`Error al actualizar el usuario con ID ${groupId}: ${error.message}`);
                }
            }
        }
    };

    const handleDeleteGroup = async () => {
        setSaving(true);
        const userPromises = info.group.map(async (groupId) => {
            const userRef = doc(db, "users", groupId);
            return updateDoc(userRef, { groupName: "", idg: "" });
        });
    
        const groupDocRef = doc(db, 'equipment_group', id);
        const deleteGroupPromise = deleteDoc(groupDocRef);
    
        try {
            await Promise.all([deleteGroupPromise, ...userPromises]);
            setInfo(null);
            setSaving(false);
            toast.success('Grupo eliminado con éxito');
            navigate('/usuarios/grupos');
        } catch (error) {
            setSaving(false);
            toast.error("Error eliminando el grupo: ", error);
        }
    };
    
    const handleSave = async () => {
        setSaving(true);
        try {
            const userDocRef = doc(db, 'equipment_group', id);
            await updateDoc(userDocRef, {
                group: info.group,
                name_group: name // actualiza el campo name_group con el valor actual de nameKits
            });
            // Crear una lista de IDs de usuarios que están en el grupo actual
            const usersInGroup = info.group;
            // Iterar sobre todos los usuarios
            for (const equipm of equipment) {
                // Verificar si el usuario está en el grupo actual
                if (usersInGroup.includes(equipm.id)) { 
                    // Si el usuario está en el grupo, actualiza el nombre y ID del grupo
                    const userRef = doc(db, "users", equipm.id);
                    await updateDoc(userRef, { groupName: name, idg: id });
                }
            }
            setSaving(false);
            toast.success('Información actualizada con éxito');
            navigate('/usuarios/grupos'); 
        } catch (error) {
            setSaving(false);
            toast.error("Error updating document: ", error);
        }
    };

    // Función para obtener la primera letra en mayúscula
    const getFirstLetterCapitalized = (name) => {
        if (!name) return ''; // Retorna una cadena vacía si el nombre no está definido
        return name.charAt(0).toUpperCase(); // Toma el primer carácter y lo convierte en mayúscula
    };
    
    if (loading || saving ) return <InfoLoading  />;
    if (error) return <div>Error: {error.message}</div>;
    if (!info) return <div>Documento no encontrado.</div>;
    return (
        <div className="windons-two">
            <div className="windons-title-section">
                <div className="title-info-input">
                    <p>Nombre del grupo</p>
                    <input  type="text"  value={name}  onChange={(e) => setName(e.target.value)}  />
                </div>
                <div className="windons-title-info">
                    {rol === "SA-001" && (
                        <div className='input-date'>
                            <label>Empresa:</label>
                            <h2 className="title2">{info.nameCompany}</h2>
                        </div>
                    )}
                </div>
                <Link className='button-two' to="/usuarios/grupos"><BiChevronLeft /> Volver</Link>
            </div>
            <div className="windons-section-info">
                <section className="section-info-two  seleccione-group">
                    <div className="section-title">
                        <div>
                            <label>Seleccionados</label>
                            <input 
                            type="text" 
                            placeholder="Buscar usuarios..." 
                            value={searchTermG}
                            onChange={(e) => setSearchTermG(e.target.value)}
                            />
                        </div>
                        <button onClick={handleDeleteAll} className="button-two"><BiX /> Borrar</button>
                    </div>
                    <div className="list-info">
                        {info && info.group && filteredGroupUsers.map((groupInfo, index) => (
                            <div key={index} className="item-produc-selec" >
                                <div className="img-item-product">
                                    {groupInfo.img.length!==0 ? (
                                        <div className="avatar-img"> 
                                            <img alt="avatar" src={groupInfo.img}/>
                                        </div>
                                    ) : (
                                        <h2 className="avatar-letter">{getFirstLetterCapitalized(groupInfo.nombre)}</h2> // Llamada a la función aquí
                                    )}
                                </div>
                                <div>
                                    <p className="name">{groupInfo.nombre}</p>
                                    <p>{groupInfo.email}</p>
                                </div>
                                <button onClick={() => handleDelete(groupInfo.id)}><BiX /></button>
                            </div>
                        ))}
                    </div>
                </section>
                <section className="section-info-two   seleccione-employe">
                    <div className="section-title">
                        <div>
                            <label>Lista Usuarios</label>
                            <input 
                            type="text" 
                            placeholder="Buscar usuarios..." 
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <button onClick={handleSelectAll} className="button-two"><BiPlus />Agregar</button>
                    </div>
                    <div className="list-info">
                        {filteredEquipment.filter(product => !info.group.includes(product.id)).map((product) => (
                            <button key={product.id} onClick={() => handleAdd(product.id)} className="item-produc">
                                <div className="img-item-product">
                                    {product.img.length!==0 ? (
                                        <div className="avatar-img"> 
                                            <img alt="avatar" src={product.img}/>
                                        </div>
                                    ) : (
                                        <h2 className="avatar-letter">{getFirstLetterCapitalized(product.nombre)}</h2> // Llamada a la función aquí
                                    )}
                                </div>
                                <div>
                                    <p className="name">{product.nombre}</p>
                                    <p>{product.email}</p>
                                </div>
                                <div><BiPlus /></div>
                            </button>
                        ))}
                    </div>
                </section>
            </div>
            <div className="container-button-two">
                <button className="button-delete" onClick={handleDeleteGroup}>
                    <BiTrash /> Borrar
                </button>
                <button className="button-save" onClick={handleSave}>
                    <BiSave />Guardar
                </button>
            </div>
        </div>
    );
}