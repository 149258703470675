import React, { useEffect, useState } from "react";
import { db, storage } from "../../../../firebase/config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { BiChevronLeft, BiRefresh } from "react-icons/bi";
import { HiOutlinePhotograph } from "react-icons/hi";
import { LuSave } from "react-icons/lu";
import { FaRegFilePdf } from "react-icons/fa";
import colombia from '../../../../firebase/colombia';
import { useParams } from 'react-router-dom';
import PerfilCompany from '../equipo/PerfilCompany';

export const EditorMarca = () => {
    const { id } = useParams();

    const [newCompanyInfo, setNewCompanyInfo] = useState({
        namecompany: "",
        rut: "",
        telefono: "",
        business_id: "",
        pais: 'Colombia',
        departamento: '',
        ciudad: '',
        direccion1:"",
        direccion2:"",
        emailfacturacion: "",
        typecompany:"",
    });
    const [ img, setImg ] = useState(null); 
    const [ rutPdf, setRutPdf ] = useState(null); 
    const [ camaraComercioPdf, setCamaraComercioPdf ] = useState(null);
    const [ cedulaPdf, setCedulaPdf ] = useState(null);
    const [ imgPreviewUrl, setImgPreviewUrl ] = useState(null); 
    const [ rutPreviewUrl, setRutPreviewUrl ] = useState (null);
    const [ camaraComercioPreviewUrl , setCamaraComercioPreviewUrl ] = useState (null);
    const [ cedulaPreviewUrl  , setCedulaPreviewUrl ] = useState (null);
    const [ selectedDepartment, setSelectedDepartment ] = useState('');
    const [ cities, setCities ] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const loadCompanyData = async () => {
            try {
                const docRef = doc(db, 'company', id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const companyData = docSnap.data();
                    setNewCompanyInfo({
                        namecompany: companyData.namecompany,
                        rut: companyData.rut,
                        telefono: companyData.telefono,
                        business_id: companyData.business_id,
                        pais: companyData.pais,
                        departamento: companyData.departamento,
                        ciudad: companyData.ciudad,
                        direccion1: companyData.direccion1,
                        direccion2: companyData.direccion2,
                        emailfacturacion: companyData.emailfacturacion,
                        typecompany: companyData.typecompany,
                    });
                    // Actualiza selectedDepartment y cities
                    setSelectedDepartment(companyData.departamento);
                    setCities(colombia[companyData.departamento]);
                    if (companyData.imageUrl) {
                        setImgPreviewUrl(companyData.imageUrl);
                    }
                    if (companyData.rutUrl) {
                        setRutPreviewUrl(companyData.rutUrl);
                    }
                    if (companyData.camaraComercioUrl) {
                        setCamaraComercioPreviewUrl(companyData.camaraComercioUrl);
                    }
                    if (companyData.cedulaUrl) {
                        setCedulaPreviewUrl(companyData.cedulaUrl);
                    }
                } else {
                    toast.error('No se encontró la compañía');
                }
            } catch (error) {
                toast.error('Error al cargar los datos: ' + error.message);
            }
        };
        loadCompanyData();
    }, [id]);    

    useEffect(() => {
        if (selectedDepartment) {
            setCities(colombia[selectedDepartment]);
        }
    }, [selectedDepartment]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // Actualiza el estado del departamento seleccionado y las ciudades correspondientes
        if (name === 'departamento') {
            setSelectedDepartment(value);
            setNewCompanyInfo({ ...newCompanyInfo, departamento: value, ciudad: '' });
            setCities(colombia[value]);
        } else {
            setNewCompanyInfo({ ...newCompanyInfo, [name]: value });
        }
    };
    
    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImg(e.target.files[0]);
            const previewUrlImg = URL.createObjectURL(e.target.files[0]);
            setImgPreviewUrl(previewUrlImg);
        }
    };

    const handleCamaraPdfChange = (e) => {
        if (e.target.files[0]) {
            setCamaraComercioPdf(e.target.files[0]);
            const previewUrlCamaraComercioPdf = URL.createObjectURL(e.target.files[0]);
            setCamaraComercioPreviewUrl(previewUrlCamaraComercioPdf);
        }
    };

    const handlecedulaPdfdfChange = (e) => {
        if (e.target.files[0]) {
            setCedulaPdf(e.target.files[0]);
            const previewUrlRutPdf = URL.createObjectURL(e.target.files[0]);
            setCedulaPreviewUrl(previewUrlRutPdf);
        }
    };

    const handleRutPdfChange = (e) => {
        if (e.target.files[0]) {
            setRutPdf(e.target.files[0]);
            const previewUrlRutPdf = URL.createObjectURL(e.target.files[0]);
            setRutPreviewUrl(previewUrlRutPdf);
        }
    };

    const uploadFile = async (file, path) => {
        const fileRef = ref(storage, path);
        const snapshot = await uploadBytes(fileRef, file);
        return getDownloadURL(snapshot.ref);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let imageUrl = imgPreviewUrl; // Mantén la URL existente si no hay nueva imagen
            let rutUrl = rutPreviewUrl; // Mantén la URL existente si no hay nuevo PDF para el RUT
            let camaraComercioUrl = camaraComercioPreviewUrl; // Mantén la URL existente si no hay nuevo PDF para la Cámara de Comercio
            let cedulaUrl = cedulaPreviewUrl; // Mantén la URL existente si no hay nuevo PDF para la Cédula
    
            if (img) imageUrl = await uploadFile(img, `company/images/${img.name}`);
            if (rutPdf) rutUrl = await uploadFile(rutPdf, `company/rut/${rutPdf.name}`);
            if (camaraComercioPdf) camaraComercioUrl = await uploadFile(camaraComercioPdf, `company/camaracomercio/${camaraComercioPdf.name}`);
            if (cedulaPdf) cedulaUrl = await uploadFile(cedulaPdf, `company/cedula/${cedulaPdf.name}`);
    
            const companyRef = doc(db, "company", id); // Usa la referencia del documento existente
            await updateDoc(companyRef, {
                ...newCompanyInfo,
                timestamp: new Date(),
                imageUrl,
                rutUrl,
                camaraComercioUrl,
                cedulaUrl,
            });
    
            toast.success("Compañía actualizada con éxito");
            navigate("/marca/lista");
        } catch (error) {
            toast.error("Error al actualizar la compañía: " + error.message);
        }
    };

    return (
        <div className="equipo-admin-create">
            <div className="encabezado-list">
                <Link className='button-new' to="/marca/lista"><BiChevronLeft /> Volver</Link>
                <h2 className="title">Ficha de Seller</h2>
            </div>
            <form onSubmit={handleSubmit} className="form-contact-create">
                <div className='column-brand-create'>
                    <div className='section-img'>
                        <div className='input-brand'>
                            <img 
                                className='brand' 
                                alt='user' 
                                src={imgPreviewUrl ||'https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/users%2Fuser.jpg?alt=media&token=f37c3246-4c72-4270-a332-9bbac12290db&_gl=1*1rnd6m4*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5NTkwODQ1NS4yNjAuMS4xNjk1OTA4NDk1LjIwLjAuMA..'}
                            />
                        </div>
                        <div className='input-upload'>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <HiOutlinePhotograph />   {imgPreviewUrl ? 'Cambiar' : 'Subir Imagen'}
                            </label>
                            <input id="file-upload" type="file" onChange={handleImageChange} required style={{display: 'none'}} />
                        </div>
                    </div>
                </div>
                <div className='column-company-create'>
                    <div className='input-date'>
                        <label>Nombre de la compañia:</label>
                        <input type="text"  name="namecompany" value={newCompanyInfo.namecompany} onChange={handleChange}  required/>
                    </div>
                    <div className='input-date'>
                        <label>RUT o NIT:</label>
                        <input type="text"  name="rut" value={newCompanyInfo.rut} onChange={handleChange}  minLength={0} required/>
                    </div>
                    <div className='input-date'>
                        <label>Business ID:</label>
                        <input type="text" name="business_id" value={newCompanyInfo.business_id} onChange={handleChange} minLength={0}  required/>
                    </div>
                    <div className='input-date'>
                        <label>Tipo de compañia:</label>
                        <select name="typecompany" value={newCompanyInfo.typecompany} onChange={handleChange} required>
                            <option value="" disabled>Seleccione </option>
                            {PerfilCompany && Object.keys(PerfilCompany).map((type) => (
                                <option key={type} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='input-date'>
                        <label>Departamento:</label>
                        <select name="departamento" value={selectedDepartment} onChange={handleChange} required>
                            <option value="" disabled>Seleccione </option>
                            {Object.keys(colombia).map((department) => (
                            <option key={department} value={department}>{department}</option>
                            ))}
                        </select>
                    </div>
                    <div className='input-date'>
                        <label>Ciudad:</label>
                        <select name="ciudad" value={newCompanyInfo.ciudad} onChange={handleChange} required>
                            <option value="" disabled>Seleccione</option>
                            {cities.map((city) => (
                            <option key={city} value={city}>{city}</option>
                            ))}
                        </select>
                    </div>
                    <div className='input-date'>
                        <label>Dirección 1</label>
                        <input type="text"  name="direccion1" value={newCompanyInfo.direccion1} onChange={handleChange} required />
                    </div>
                    <div className='input-date'>
                        <label>Dirección 2</label>
                        <input type="text"  name="direccion2" value={newCompanyInfo.direccion2||''} onChange={handleChange}  />
                    </div>
                    <div className='input-date'>
                        <label>Email:</label>
                        <input type="email" name="emailfacturacion" value={newCompanyInfo.emailfacturacion} onChange={handleChange} required/>
                    </div>
                    <div className='input-date'>
                        <label>Teléfono:</label>
                        <input type="tel" name="telefono" value={newCompanyInfo.telefono} onChange={handleChange} minLength={10} maxLength={10} required/>
                    </div>
                    <div className="input-dat">
                        <div className='input-upload'>
                            <label htmlFor="rut-upload" className="custom-file-upload">
                                {rutPreviewUrl ? <><BiRefresh />Cambiar RUT</> : <><FaRegFilePdf /> Subir RUT </>}
                            </label>
                            <input id="rut-upload" type="file" onChange={(e) => handleRutPdfChange(e, setRutPreviewUrl, setRutPdf)} accept=".pdf" style={{display: 'none'}} />
                        </div>
                        <div className='input-upload'>
                            <label htmlFor="camara-comercio-upload" className="custom-file-upload">
                                {camaraComercioPreviewUrl ? <><BiRefresh />Cambiar cámara y comercio</> : <><FaRegFilePdf /> Subir cámara y comercio</>}
                            </label>
                            <input id="camara-comercio-upload" type="file" onChange={(e) => handleCamaraPdfChange(e, setCamaraComercioPreviewUrl, setCamaraComercioPdf)} accept=".pdf" style={{display: 'none'}} />
                        </div>
                        <div className='input-upload'>
                            <label htmlFor="cedula-upload" className="custom-file-upload">
                                {cedulaPreviewUrl ? <><BiRefresh />Cambiar CC. representate</> : <><FaRegFilePdf /> Subir CC. representate</>}
                            </label>
                            <input id="cedula-upload" type="file" onChange={(e) => handlecedulaPdfdfChange(e, setCedulaPreviewUrl , setCedulaPdf)} accept=".pdf" style={{display: 'none'}} />
                        </div>
                    </div>
                    <div className="input-date">
                        <button className='button-new' type="button" onClick={handleSubmit}>
                            <LuSave />
                            Guardar cambios
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
