import "./OrdenRegister.scss"
import { DeliveryStatusOrden } from "../../atoms/DeliveryStatusOrden/DeliveryStatusOrden";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { doc, updateDoc,  Timestamp  } from "firebase/firestore";
import { db } from "../../../../firebase/config";

export const OrdenRegister = ({order_velocity, id, order_product, order_pictures, order_status_id,creation, date_delete, date_delivery, rol, updated_at, order_siigo_id}) => {
    const [formData, setFormData] = useState({
        date_delivery: '',
    });

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }, []);

    // Fecha de creacion de users
    let fechaFormateada = "";
    if(creation) {
        try {
            const fecha = creation.toDate(); 
            fechaFormateada = fecha.toLocaleDateString('es-ES'); 
        } catch(e) {
            console.error("Error al convertir fecha:", e);
        }
    }

    // Fecha de creacion de users
    let fechaFormateadaDelivery = "";
    if(creation) {
        try {
            const fecha = date_delivery.toDate(); 
            fechaFormateadaDelivery = fecha.toLocaleDateString('es-ES'); 
        } catch(e) {
            console.error("Error al convertir fecha:", e);
        }
    }

    // Fecha de creacion de users
    let fechaFormateadaUpdated = "";
    if(creation) {
        try {
            const fecha = updated_at.toDate(); 
            fechaFormateadaUpdated = fecha.toLocaleDateString('es-ES'); 
        } catch(e) {
            console.error("Error al convertir fecha:", e);
        }
    }

    // Fecha de creacion de users
    let fechaFormateadaCancel= "";
    if(creation) {
         try {
            const fecha = date_delete.toDate(); 
            fechaFormateadaCancel = fecha.toLocaleDateString('es-ES'); 
         } catch(e) {
             console.error("Error al convertir fecha:", e);
         }
     }
 

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        const ordenDocRef = doc(db, 'orden', id);
         // Convertir la cadena de texto a un objeto Date
        const dateDelivery = new Date(formData.date_delivery);
         // Convertir el objeto Date a un objeto Timestamp de Firebase
        const timestampDelivery = Timestamp.fromDate(dateDelivery);
        try {
            await updateDoc(ordenDocRef, {
                date_delivery: timestampDelivery,
            });
            toast.success("Dato guardado");
        } catch (error) {
            toast.error("Error de guardado y correccion");
        } 
    }, [formData.date_delivery, id]);
    return (
        <>
            {id&&
                <div className="iten-orden-user">
                    <section className="detail-orden-date">
                        <article className="orden-date">
                            <h4 className="subtitle3" >ID orden: {id}</h4>
                            {order_siigo_id&&<p>Orden ID:{order_siigo_id}</p>}
                            <p>{order_velocity}</p>
                        </article>
                        <article className="orden-description">
                            < DeliveryStatusOrden order_status_id={order_status_id} />
                            <p className="date-orden">Fecha creación: {fechaFormateada}</p>
                            {order_status_id === 6 && 
                                <>
                                    <p className="date-sale">Fecha de entrega:{fechaFormateadaDelivery===""?fechaFormateadaUpdated:fechaFormateadaDelivery}</p>
                                    {rol==="SA-001"&&
                                        <form onSubmit={handleSubmit} className='input-date'>
                                            <label>New Fecha:</label>
                                            <input type="date"  name="date_delivery" value={formData.date_delivery} onChange={handleChange} required />
                                            <button className='button-form' type="submit">Solicitar</button>
                                        </form>
                                    }
                                </>
                            }
                            {(order_status_id === 7 || order_status_id === 8 )&& <p className="date-cancel">Fecha cancelado: {fechaFormateadaCancel}</p>}
                        </article>
                    </section>
                    <section className="detail-orden-products">
                        <div className="container-list-product">
                            <h2 className="subtitle3">Lista De Productos:</h2>
                            <div className="container-iten-produc">
                                <p>Img</p>
                                <p className="iten-produc-name">Nombre</p>
                                <p className="iten-produc-sku">SKU</p>
                            </div>
                            {order_product.map((item, index) => (
                                <div key={index + item.product_id} className="container-iten-produc">
                                    <img alt={item.name} src={item.picture_url} />
                                    <p className="iten-produc-name">{item.name}</p>
                                    <p className="iten-produc-sku">{item.sku}</p>
                                </div>
                            ))}
                        </div>
                        {order_pictures.length !==0 &&
                            <div className="container-orden-pintures">
                                <h2 className="subtitle3">Firma & Registro Fotográfico:</h2>
                                <div className="orden-pintures">
                                    {order_pictures.map((item, index) => (
                                        <img key={index+item.file} src={item.file} alt={item.file}/>
                                    ))}
                                </div>
                            </div>
                        }
                    </section>
                </div>
            }
        </>
    );
}
