import "./Reports.scss";
import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { CSVLink } from "react-csv";
import { db } from '../../../../firebase/config';
import { FiDownload } from "react-icons/fi";

export const ReportsOrder = ({ idc }) => {
    const [orders, setOrders] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]); // Fecha de hoy por defecto
    const [selectedFields, setSelectedFields] = useState([]);
    const [minStartDate, setMinStartDate] = useState('');

    const userFieldsOrder = ["creation", "idu", "name", "lastname", "cc", "email", "phone", "country", "department", "address", "lastaddress"];
    const orderFieldsOrder = ["id", "date_delivery", "deleteDate", "order_siigo_id", "order_status_id", "idk", "idkName", "product_sku", "product_name", "product_quantity", "order_pictures"];
    
    const allFieldsOrder = [...userFieldsOrder, ...orderFieldsOrder];

    useEffect(() => {
        const fetchOrders = async () => {
            let q = query(collection(db, "orden"), where('idc', '==', idc));

            const querySnapshot = await getDocs(q);
            let ordersData = querySnapshot.docs.map(doc => {
                const data = doc.data();

                // Convertir el timestamp de creación a una fecha formateada 
                const creationDate = data.creation?.toDate().toISOString().split('T')[0];
                
                // Convertir date_delete y date_delivery a fecha si es necesario, o dejar como string si ya lo es
                const deleteDate = data.date_delete?.toDate?.().toISOString().split('T')[0] ||
                                   (data.date_delete instanceof Date ? data.date_delete.toISOString().split('T')[0] : data.date_delete) ||
                                   "";

                const deliveryDate = data.date_delivery?.toDate?.().toISOString().split('T')[0] ||
                                   (data.date_delivery instanceof Date ? data.date_delivery.toISOString().split('T')[0] : data.date_delivery) ||
                                   "";

                // Combinar las URLs de order_pictures en una sola cadena separada por comas
                const orderPictures = data.order_pictures
                    ? data.order_pictures.map(picture => picture.file).join(', ')
                    : "";

                // Reemplazar order_status_id con la descripción correspondiente
                const statusMapping = (statusId) => {
                    if ([0, 1, 2, 13, 14, 15, 4].includes(statusId)) {
                        return "EN PROCESO";
                    } else if ([5, 12, 11].includes(statusId))  {
                        return "ENTREGADO";
                    } else if (statusId === 6) {
                        return "ENTREGADO";
                    } else {
                        return "CANCELADO";
                    }
                };

                return {
                    id: doc.id,
                    ...data,
                    creation: creationDate,
                    date_delivery: deliveryDate,
                    deleteDate: deleteDate,
                    order_pictures: orderPictures,
                    order_status_id: statusMapping(data.order_status_id), // Asignar el estado mapeado
                    ...data.deleveryData, 
                };
            });

            // Buscar la fecha de creación más antigua
            if (ordersData.length > 0) {
                const earliestOrder = ordersData.reduce((earliest, current) => {
                    return earliest.creation < current.creation ? earliest : current;
                });
                const earliestCreationDate = earliestOrder.creation;

                if (!startDate) {
                    setStartDate(earliestCreationDate);
                }
                setMinStartDate(earliestCreationDate); // Establecer la fecha mínima permitida
            }

            // Filtrado de fechas después de obtener los documentos
            if (startDate && endDate) {
                const startTimestamp = new Date(startDate).getTime();
                const endTimestamp = new Date(endDate).getTime();

                ordersData = ordersData.filter(order => {
                    const creationTime = new Date(order.creation).getTime();
                    return creationTime >= startTimestamp && creationTime <= endTimestamp;
                });
            }

            setOrders(ordersData);
        };

        fetchOrders();
    }, [idc, startDate, endDate]);

    const handleFieldSelect = (field) => {
        setSelectedFields(prevFields => {
            if (prevFields.includes(field)) {
                // Si el campo ya está seleccionado, se elimina
                return prevFields.filter(f => f !== field);
            } else {
                // Si no está seleccionado, se agrega al final
                return [...prevFields, field];
            }
        });
    };

    const flattenOrderData = (order) => {
        const flatData = [];
        if (order.order_product && order.order_product.length > 0) {
            order.order_product.forEach(product => {
                const flattenedProduct = { ...order };
                delete flattenedProduct.order_product; 
                flattenedProduct.product_name = product.name;
                flattenedProduct.product_quantity = product.quantity;
                flattenedProduct.product_sku = product.sku;
                flatData.push(flattenedProduct);
            });
        } else {
            flatData.push(order);
        }
        return flatData;
    };

    const generateCSVData = () => {
        const orderedFields = allFieldsOrder.filter(field => selectedFields.includes(field));
        return orders.flatMap(order => {
            const flattenedOrders = flattenOrderData(order);
            return flattenedOrders.map(flattenedOrder => {
                const filteredOrder = {};
                orderedFields.forEach(field => {
                    filteredOrder[field] = flattenedOrder[field];
                });
                return filteredOrder;
            });
        });
    };

    const renderFieldButton = (field, label) => (
        <button 
            className={selectedFields.includes(field) ? 'onselec-column' : 'selec-column'} 
            key={field} 
            onClick={() => handleFieldSelect(field)} 
            >
            {label}
        </button>
    );

    const selectAllUserFields = () => {
        setSelectedFields(prevFields => [...new Set([...prevFields, ...userFieldsOrder])]);
    };

    const selectAllOrderFields = () => {
        setSelectedFields(prevFields => [...new Set([...prevFields, ...orderFieldsOrder])]);
    };

    const clearUserFields = () => {
        setSelectedFields(prevFields => prevFields.filter(field => !userFieldsOrder.includes(field)));
    };

    const clearOrderFields = () => {
        setSelectedFields(prevFields => prevFields.filter(field => !orderFieldsOrder.includes(field)));
    };

    return (
        <div className="equipo-admin">
            <div className="list-users">
                <div className="contect-list-users">
                    <div className="encabezado-users-list">
                        <h2 className="title2">Informe de ordenes</h2>
                    </div>
                    <section className='contect-users-orden'>
                        <section className="date-contect-users-orden">
                            <div className="input-date-orden">
                                <label>Fecha creación:</label>
                                <input 
                                    type="date" 
                                    value={startDate} 
                                    onChange={e => setStartDate(e.target.value)} 
                                    min={minStartDate} // Establecer la fecha mínima como la del primer registro
                                />
                            </div>
                            <div className="input-date-orden">
                                <label>Fecha última creación:</label>
                                <input 
                                    type="date" 
                                    value={endDate} 
                                    onChange={e => setEndDate(e.target.value)} 
                                    max={new Date().toISOString().split('T')[0]} // Limitar la fecha máxima a hoy
                                />
                            </div>
                        </section>
                        <div className="container-contect-resport">
                            <div className="container-title-resport">
                                <h4>Datos del usuario:</h4>
                                <div className="container-title-resport-button">
                                    {selectedFields.some(field => userFieldsOrder.includes(field)) && (
                                        <button onClick={clearUserFields} className="button-delete">Borrar Todo</button>
                                    )}
                                    <button onClick={selectAllUserFields}  className="button-new">Seleccionar Todos</button>
                                </div>
                            </div>
                            <div className="container-botom-resport">
                                {renderFieldButton("creation", "Fecha de creación")}
                                {renderFieldButton("idu", "ID Usuario")}
                                {renderFieldButton("name", "Nombre")}
                                {renderFieldButton("lastname", "Apellido")}
                                {renderFieldButton("cc", "CC")}
                                {renderFieldButton("email", "Email")}
                                {renderFieldButton("phone", "Teléfono")}
                                {renderFieldButton("country", "País")}
                                {renderFieldButton("department", "Departamento")}
                                {renderFieldButton("address", "Dirección")}
                                {renderFieldButton("lastaddress", "Detalles")}
                            </div>
                        </div>
                        <div className="container-contect-resport">
                            <div className="container-title-resport">
                                <h4>Datos de orden:</h4>
                                <div className="container-title-resport-button">
                                    {selectedFields.some(field => orderFieldsOrder.includes(field)) && (
                                        <button onClick={clearOrderFields} className="button-delete">Borrar Todo</button>
                                    )}
                                    <button onClick={selectAllOrderFields} className="button-new">Seleccionar Todos</button>
                                </div>
                            </div>
                            <div className="container-botom-resport">
                                {renderFieldButton("id", "ID orden")}
                                {renderFieldButton("date_delivery", "Fecha Entrega")}
                                {renderFieldButton("deleteDate", "Fecha cancelado")}
                                {renderFieldButton("order_siigo_id", "Orden ID")}
                                {renderFieldButton("order_status_id", "Status entrega")}
                                {renderFieldButton("idk", "ID kit")}
                                {renderFieldButton("idkName", "Nombre del kit")}
                                {renderFieldButton("product_sku", "SKU del producto")}
                                {renderFieldButton("product_name", "Nombre del producto")}
                                {renderFieldButton("product_quantity", "Qty del producto")}
                                {renderFieldButton("order_pictures", "Registro fotográfico")}
                            </div>
                        </div>
                        <div className="buttom-orden-container">
                            {orders.length !== 0
                                ?<CSVLink data={generateCSVData()} filename={`orders_${startDate}_${endDate}.csv`} className="button-new">
                                    <FiDownload /> Descargar CSV
                                </CSVLink>
                                :<h3>
                                    No hay registros en este lapso de tiempo, intenta unas fechas diferente.
                                </h3>
                            }
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};
