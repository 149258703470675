/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../../../firebase/config';
import { BiCheckCircle } from "react-icons/bi";
import { FiSend } from "react-icons/fi";
import { toast } from "react-toastify";


const SEND_EMAIL_FUNCTION_URL = "https://us-central1-goshofi-c578e.cloudfunctions.net/sendWebinars"; 

async function sendRegister(data) {
    try {
        const response = await fetch(SEND_EMAIL_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (response.ok) {
            toast.success("Email enviado con exito");
        } else {
            toast.error("Error de envio email:", response.statusText);
            const errorBody = await response.text();
            toast.error("Error body:", errorBody);
        }
    } catch (error) {
        toast.error("Error de envio email:", error);
    }
}


export const FormWebinar = ({setLoading}) => {
    const [submitted, setSubmitted] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        load: '',
        numbemploye:'',
        date: new Date(),
    });

    useEffect(() => {
        const storedSubmittedData = localStorage.getItem('submitted');
        if (storedSubmittedData) {
            const { value, expiry } = JSON.parse(storedSubmittedData);
            const now = new Date();
            if (now.getTime() > expiry) {
                localStorage.removeItem('submitted');
                setSubmitted(false);
            } else {
                setSubmitted(value);
            }
        }
    }, []);

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }, []);
    
    const validateEmail = useCallback((email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    }, []);

    const validatePhone = useCallback((phone) => {
        const regex = /^[0-9]*$/;
        return regex.test(phone);
    }, []);

    const handlePhoneChange = useCallback((e) => {
        const value = e.target.value;
        if (validatePhone(value) || value === '') {
            handleChange(e);
        }
    }, [handleChange, validatePhone]);

    // Datos de Hubspot
    const HUBSPOT_PORTAL_ID = '44612660';
    const HUBSPOT_FORM_ID = 'b07c2ffb-b13f-45e9-87cb-e6ad63ce6e4b';
    const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${HUBSPOT_FORM_ID}`;

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (!recaptchaValue) {
            toast.error('Por favor, verifica el reCAPTCHA');
            return;
        }
        if (validateEmail(formData.email) && validatePhone(formData.phone)) {
            const hubspotFormData = {
                fields: [
                    { name: "email", value: formData.email },
                    { name: "firstname", value: formData.name },
                    { name: "phone", value: formData.phone },
                    { name: "company", value: formData.company },
                    { name: "jobtitle", value: formData.load}
                ],
                context: {
                    pageUri: window.location.href,
                    pageName: "Webinars de Treli"
                }
            };
            try {
                setLoading(true)
                await addDoc(collection(db, "form_webinars_treli" ), formData);
                const response = await fetch(HUBSPOT_ENDPOINT, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(hubspotFormData),
                });
                if (!response.ok) throw new Error("HubSpot submission error");

                // Enviar evento a Google Tag Manager para el formulario de registro
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'Webinars de Treli',
                    form: {
                        name: formData.name,
                        email: formData.email,
                        company: formData.company,
                        phone: formData.phone,
                        load: formData.load,
                        numbemploye: formData.numbemploye,
                        formType: 'Webinars de Treli'
                    }
                });

                setFormData({
                    name: '',
                    company: '',
                    email: '',
                    phone: '',
                    load: '',
                    numbemploye:'',
                    date: new Date(),
                });
                setSubmitted(true);
                await sendRegister(formData);
                const expiryTime = new Date().getTime() + 24 * 60 * 60 * 1000;
                localStorage.setItem('submitted', JSON.stringify({ value: true, expiry: expiryTime }));
                setLoading(false)
            } catch (error) {
                toast.error('Error', + error.message);
                setLoading(false)
            }
        } else {
            toast.error('Datos no válidos');
        }
    }, [formData, recaptchaValue]);


    return ( 
        <section className="section-contect-siigo">
            <div className="section-siigo-contect-container">
                <div className="section-siigo-title">
                    <div className="register-sigo-title">
                        <div className="register-sigo-title-img">
                            <img className="shofi espace" alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/pages%2Fsigo%2FSin%20ti%CC%81tulo-1-03.png?alt=media&token=fd0721e7-1e12-44e9-84ba-584e561d3c4c" />
                            <h1>+</h1>
                            <img className="siigo" alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/pages%2FSiigo%2FSin%20ti%CC%81tulo-1-01.png?alt=media&token=4392efa0-5a38-4bc0-a226-afac5116dc1e" />
                        </div>
                        <h2 className="title-page-1">
                            Webinar: <span>Flexibilidad y eficiencia en equipos híbridos</span>
                        </h2>
                        <p className="subtitle-page-1">
                            <span>Fecha:</span> Miércoles 21 de agosto
                        </p>
                        <p className="subtitle-page-1">
                            <span>Hora:</span> 8:00 - 9:00 AM
                        </p>
                        <p className="subtitle-page-1">
                            <span>Plataforma:</span> Google Meet
                        </p>
                    </div>
                    {!submitted &&  
                        <form className="container-form-siigo" onSubmit={handleSubmit}>
                            <div className="form-siigo">
                                <div className='input-date'>
                                    <label>Nombre:</label>
                                    <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                                </div>
                                <div className='input-date'>
                                    <label>Cargo:</label>
                                    <select name="load" value={formData.load} onChange={handleChange} required>
                                        <option value="" disabled>Seleccione</option>
                                        <option value="Director">Director</option>
                                        <option value="CEO">CEO</option>
                                        <option value="RRHH">RRHH</option>
                                        <option value="SST">SST</option>
                                        <option value="Financiero">Financiero</option>
                                        <option value="Otro">Otro</option>
                                    </select>
                                </div>
                                <div className='input-date'>
                                    <label>Empresa:</label>
                                    <input type="text" name="company" value={formData.company} onChange={handleChange} required />
                                </div>
                                <div className='input-date'>
                                    <label>Número de empleados:</label>
                                    <select name="numbemploye" value={formData.numbemploye} onChange={handleChange} required>
                                        <option value="" disabled>Seleccione</option>
                                        <option value="1-10">1-10</option>
                                        <option value="11-50">11-50</option>
                                        <option value="51-100">51-100</option>
                                        <option value="101-10">101-500</option>
                                        <option value="501-2000">501-2000</option>
                                        <option value="+2000">+2000</option>
                                    </select>
                                </div>
                                <div className='input-date'>
                                    <label>Teléfono:</label>
                                    <input type="text" name="phone" value={formData.phone} onChange={handlePhoneChange} required />
                                </div>
                                <div className='input-date'>
                                    <label>Email:</label>
                                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                                </div>
                            </div>
                            <div className='recaptcha-siigo'>
                                <ReCAPTCHA
                                    sitekey="6LdgvfwnAAAAAEIbajmXn1qZoeHC4gkRsPHKDond"
                                    onChange={value => setRecaptchaValue(value)}
                                />
                                <button className='button-tipe1' type="submit"><FiSend />Registrar</button>
                            </div>
                        </form>
                    }
                    {submitted &&  
                        <div className="container-form-siigo">
                            <div className="exict-form-sigo">
                                <BiCheckCircle />
                                <p className="title-page-1">Enviado con éxito</p>
                            </div>
                        </div>
                    }
                </div>
                <div className="section-siigo-img">
                    <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/pages%2Fsigo%2Fyoung-handsome-smiling-man-casual-outfit-sitting-table-working-laptop%201.png?alt=media&token=831f76e8-820c-48c8-bd08-ef3935abafbc" className="img-siigo"/>
                </div>
            </div>
        </section>
    );
}


