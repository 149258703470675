import "./ListReserve.scss";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ItenReserve } from "../../molecules/ItenReserve/ItenReserve";
import { useState } from "react";
// import { Link } from "react-router-dom";

export const ListReserve = ({ info , handleChange , numberOfPages, displayItems, currentPage}) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredReserve = displayItems.filter(user => (
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.company.toLowerCase().includes(searchTerm.toLowerCase()))
    );


    return (
        <div className="list-reserve">
            <div className="contect-list-users">
                <div className="encabezado-list">
                    <input
                        className="input-search"
                        type="text"
                        placeholder="Buscar usuarios..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {/* <Link to={"/startco/inversionista/creador"}>
                        Crear Inversor
                    </Link> */}
                    <h2>N° Registros: {info.length}</h2>
                </div>
                {info && (
                    <div>
                        <div className="title-user">
                            <p>Avatar</p>
                            <div className="filter">
                                <p>Nombre</p>
                            </div>
                            <p>
                                Inversor
                            </p>
                            <p>
                                Compañia
                            </p>
                            <p>
                                Cargo
                            </p>
                            <p>
                                Fecha
                            </p>
                        </div>
                        <div className="list">
                            {filteredReserve.map(user => (
                                <ItenReserve
                                    key={user.id}
                                    {...user}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className="pagination-numb">
                <Stack spacing={2}>
                    <Pagination count={numberOfPages} page={currentPage} onChange={handleChange} size="large" />
                </Stack>
            </div>
        </div>
    );
}