/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { getFunctions } from 'firebase/functions'; 

const firebaseConfig = {
    apiKey: "AIzaSyA-sAsjlHIL_6-cspVOTsUO2aKcwBQp444",
    authDomain: "goshofi-c578e.firebaseapp.com",
    databaseURL: "https://goshofi-c578e-default-rtdb.firebaseio.com",
    projectId: "goshofi-c578e",
    storageBucket: "goshofi-c578e.appspot.com",
    messagingSenderId: "41259739761",
    appId: "1:41259739761:web:3c6afdefc4a1e41cc809d0",
    measurementId: "G-4JY792D32H"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
const storage = getStorage(app);
export const auth = getAuth(app);
const functions = getFunctions(app);
export const googleProvider = new GoogleAuthProvider(); 
export const registerAuthInstance = getAuth(initializeApp(firebaseConfig));

// Export storage object
export { storage };
export const provider = new GoogleAuthProvider();
// Proceso de registro de usuarios
export { functions };