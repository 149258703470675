import "./SectionLog.scss";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import React, { useState } from 'react';
import { useLoginContext } from '../../../../context/LoginContext';
import { BiShow, BiHide, BiX } from "react-icons/bi";
import { FcGoogle } from "react-icons/fc";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { SectionLoading } from "../../../../Loanding";
import ReCAPTCHA from "react-google-recaptcha";

export  const SectionLog = ({setIsLogin}) => {
    const { login , sendPasswordResetEmail , googleLogin } = useLoginContext();
    const [values, setValues] = useState({
        email: '',
        password: ''
    })
    const [resetPassword, setResetPassword] = useState({
        email: ''
    })
    const [showPassword, setShowPassword] = useState(false);
    const [isPassword, setIsPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const togglePassword = () => {
        setIsPassword(prevIsLogin=> !prevIsLogin);
    };

    const handleInputChange =(e)=>{
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }
    const handleInputPassword =(e)=>{
        setResetPassword({
            ...resetPassword,
            [e.target.name]: e.target.value
        })
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        login (values)
    }

    const handlePassword = async (e) => {
        e.preventDefault();
        if (!recaptchaValue) {
            toast.error('Por favor, verifica el reCAPTCHA');
            return;
        }
        const email = resetPassword.email;
        if (email) {
            // Crear una consulta para buscar el usuario por email
            const q = query(collection(db, "users"), where("email", "==", email));
            try {
                const querySnapshot = await getDocs(q);
                if (querySnapshot.empty) {
                    // Si no hay usuarios con ese email
                    toast.error("No se encontró el usuario con ese correo electrónico.");
                } else {
                    setLoading(true)
                    // Usuario encontrado, obtener el nombre y proceder con el restablecimiento de contraseña
                    const userData = querySnapshot.docs[0].data();
                    const userName = userData.nombre;
                    const userLastName = userData.apellido;
                    const name = userName+" "+userLastName
                    // Aquí pasas tanto el email como el nombre al contexto
                    await sendPasswordResetEmail(email, name); 
                    toast.info("Correo de restablecimiento enviado.");
                    setIsPassword(false)
                    setLoading(false)
                }
            } catch (error) {
                // Manejo de errores
                toast.error("Ocurrió un error al buscar el usuario");
            }
        } else {
            toast.error("Por favor ingrese un correo electrónico.");
        }
    };

    const toggleLogin = () => {
        setIsLogin(prevIsLogin=> !prevIsLogin);
    };

    // Nueva función para manejar el inicio de sesión con Google
    const handleGoogleLogin = () => {
        // Limpiar el estado del formulario
        setValues({
            email: '',
            password: ''
        });
        // Llamada a la función googleLogin de tu contexto
        googleLogin();
        setLoading(false)
    };

    return (
        <div className="section-log">
            <article className="container-register">
                <button onClick={toggleLogin} className='button-x'>
                    <BiX />
                </button>
                <Link to="/" className="brand" >
                    <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4" />
                </Link>
                {!isPassword
                    ?<>
                        <h1 className="subtitle1" >Bienvenidos a <span>SHOFI</span></h1>
                        <p className="subtitle2">Ingresa a tu cuenta para continuar</p>
                        <form className="form-contact" onSubmit={handleSubmit}> 
                            <div className='input-date'>
                                <label>Ingresa tu email</label>
                                <input
                                    className='input'
                                    type="email"
                                    placeholder="Email"
                                    value={values.email}
                                    onChange={handleInputChange}
                                    name="email"
                                />
                            </div>
                            <div className='input-clave'>
                                <label className="label-t">Ingresa tu clave</label>
                                <input
                                    className='input-clave'
                                    type={showPassword ? "text" : "password"}
                                    value={values.password}
                                    onChange={handleInputChange}
                                    name="password"
                                />
                                <button className="button-password" type="button-password" onClick={toggleShowPassword}>
                                    {showPassword ? <BiHide /> : <BiShow />}
                                </button>
                            </div>
                            <button className='button-form' type="submit">Iniciar sesión</button>
                        </form>
                        {/* Botón para inicio de sesión con Google */}
                        <button className="button-google" onClick={handleGoogleLogin}>
                            <FcGoogle />Login Gmail
                        </button>
                        <button className="password-reset" onClick={togglePassword}>¿Olvidaste tu contraseña?</button>
                    </>
                    :<>
                        <h1 className="subtitle1" >Recupera tu cuenta</h1>
                        <p className="subtitle2">Ingresa tu correo electrónico <br></br>registrado para buscar tu cuenta.</p>
                            <form className="form-contact" onSubmit={handlePassword}>
                                {!loading
                                    ?
                                    <>
                                        <div className='input-date'>
                                            <label>Ingresa tu email</label>
                                            <input
                                                className='input'
                                                type="email"
                                                placeholder="Email"
                                                value={resetPassword.email}
                                                onChange={handleInputPassword}
                                                name="email"
                                            />
                                        </div>
                                        <div className='recaptcha'>
                                            <ReCAPTCHA
                                                sitekey="6LdgvfwnAAAAAEIbajmXn1qZoeHC4gkRsPHKDond"
                                                onChange={value => setRecaptchaValue(value)}
                                            />
                                        </div>
                                        <button className='button-form' type="submit">Recuperar cuenta</button>
                                    </>
                                    :<SectionLoading />
                                }
                            </form>
                        <button className="password-reset" onClick={togglePassword}>Ir a login</button>
                    </>
                }
            </article>
        </div>
    );
}


