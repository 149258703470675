/* eslint-disable react-hooks/exhaustive-deps */
import 'shepherd.js/dist/css/shepherd.css';
import { useEffect, useState } from 'react';
import Shepherd from 'shepherd.js';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from './firebase/config'; // Implementa estas funciones según tu lógica de negocio


const TourGuide = ({ userId }) => {
    const [tourInitialized, setTourInitialized] = useState(false);
    const getUserTourStatus = async () => {
        try {
          const userDocRef = doc(db, 'users', userId);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            return userDoc.data().has_completed_tour || false;
          }
          return false;
        } catch (error) {
          console.error("Error getting user tour status:", error);
          return false; // Maneja el error permitiendo que el tour se inicie de todos modos
        }
    };

    const updateUserTourStatus = async (status) => {
        try {
          const userDocRef = doc(db, 'users', userId);
          await updateDoc(userDocRef, { has_completed_tour: status });
        } catch (error) {
          console.error("Error updating user tour status:", error);
        }
    };


    useEffect(() => {
        const initializeTour = async () => {
            const hasCompletedTour = await getUserTourStatus(userId);
            if (hasCompletedTour || Shepherd.activeTour) {
                // Si el tour ya fue completado o está activo, no hacer nada
                return;
            }

            const tour = new Shepherd.Tour({
                useModalOverlay: true,
                defaultStepOptions: {
                    cancelIcon: {
                        enabled: true
                    },
                    classes: 'custom-step-class',
                    scrollTo: { behavior: 'smooth', block: 'center' },
                    popperOptions: {
                        modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
                    },
                },
                onComplete: async () => {
                    await updateUserTourStatus(userId, true);
                },
                onCancel: async () => {
                    await updateUserTourStatus(userId, false); // Cambiado a 'false' para reflejar la cancelación del tour
                },
            });
            // Configuración de los pasos del tour
            tour.addStep({
                title: 'Bienvenido a SHOFI',
                text: 'Iniciemos un breve recorrido por el sistema con el objetivo de guiarte en tus primeros pasos.',
                attachTo: {
                    on: 'center'
                },
                buttons: [
                    {
                        action() {
                            return this.complete();
                        },
                        classes: 'shepherd-button-secondary',
                        text: 'Cerrar'
                    },
                    {
                        action() {
                            return this.next();
                        },
                        text: 'Siguiente'
                    }
                ],
                id: 'creating'
            });

            tour.addStep({
                title: 'Navegación Principal',
                text: 'Aquí puedes encontrar accesos rápidos a las principales secciones de la plataforma.',
                attachTo: {
                    element: '.container-menu',
                    on: 'right'
                },
                buttons: [
                    {
                        action() {
                            return this.back();
                        },
                        text: 'Anterior'
                    },
                    {
                        action() {
                            return this.next();
                        },
                        text: 'Siguiente'
                    }
                ]
            });

            tour.addStep({
                title: 'Perfil de usuario',
                text: 'Aquí podrás editar los datos de tu usuario y conseguir un resumen de tu perfil y actividades.',
                attachTo: {
                    element: '.container-user',
                    on: 'left'
                },
                buttons: [
                    {
                        action() {
                            return this.back();
                        },
                        text: 'Anterior'
                    },
                    {
                        action() {
                            return this.next();
                        },
                        text: 'Siguiente'
                    }
                ]
            });

            tour.addStep({
                title: 'Tablero de actividades',
                text: 'Aquí conseguirás un resumen de datos y actividades en la plataforma.',
                attachTo: {
                    element: '.windows-admin',
                    on: 'left'
                },
                buttons: [
                    {
                        action() {
                            return this.back();
                        },
                        text: 'Anterior'
                    },
                    {
                        action() {
                            return this.next();
                        },
                        text: 'Siguiente'
                    }
                ]
            });

            tour.addStep({
                title: 'Comencemos a crear',
                text: 'Vamos a "Equipo" y agregamos todos los integrantes de nuestra compañía y armamos los grupos de trabajo.',
                attachTo: {
                    element: '.button-equipo',
                    on: 'left'
                },
                buttons: [
                    {
                        action() {
                            return this.back();
                        },
                        text: 'Anterior'
                    },
                    {
                        action() {
                            return this.complete();
                        },
                        text: 'Finalizar'
                    }
                ]
            });
            tour.on('complete', async () => {
                await updateUserTourStatus(true);
            });
            tour.on('cancel', async () => {
                await updateUserTourStatus(false);
            });

            tour.start();
            setTourInitialized(true);
        };

        if (!tourInitialized) {
            initializeTour();
        }
    }, [userId, tourInitialized]);

    return null;
};


export default TourGuide;
