/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import "./MapComponent.scss"

export const MarkerIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
})

const center = {
    lat: 51.505,
    lng: -0.09,
};
export  const MyMapComponent = ({users}) => {
    const defaultCenter = [4.5709, -74.2973]; // Nota: las coordenadas son [lat, lng]
    const [draggable, setDraggable] = useState(false)
    const [position, setPosition] = useState(center)
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                setPosition(marker.getLatLng())
                }
            },
        }),
        [],
    )
    const toggleDraggable = useCallback(() => {
        setDraggable((d) => !d)
    }, [])

    const locations =  users.map(user => ({
        lat: user.latitud,
        lng: user.longitud,
        id: user.id,
        name: user.nombre, 
        lastname : user.apellido,
        address : user.direccion_1,
    }))
    .filter(location => location.lat && location.lng);

    return (
        <MapContainer center={defaultCenter} zoom={6} style={{ width: '100%', height: '100%' }} className='style-mapcomponent'>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {locations.map((loc, index) => (
                <Marker key={index} position ={[loc.lat, loc.lng]}  icon={MarkerIcon} >
                    <Popup minWidth={90}>
                        <span onClick={toggleDraggable}>
                            {draggable
                                ? <>{loc.address}</>
                                : <>{loc.name} {loc.lastname}</>}
                        </span>
                    </Popup>
                </Marker>
            ))}
        </MapContainer>
    );
}