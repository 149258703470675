// Componentes de sistema
import { Loading } from '../Loanding';
import { HomeUser } from '../components/page/private/homeuser/HomeUser';
import { MenuHeader } from '../components/UI/organisms/MenuHeader/MenuHeader';
import { MenuLeft } from '../components/UI/molecules/MenuLeft/MenuLeft';
import { Encuestas } from '../components/page/private/encuestas/Encuestas';
import { Equipo } from '../components/page/private/equipo/Equipo';
import { TeamStatus } from '../components/page/private/equipo/TeamStatus';
import { Kits } from '../components/page/private/kits/Kits';
import { TraySupport } from '../components/page/private/traysupport/TraySupport';
import { Marca } from '../components/page/private/marca/Marca';
import { useLoginContext } from '../context/LoginContext';
import { CreadorEncuestas } from '../components/page/private/encuestas/CreadorEncuestas';
import { EditorEncuestas } from '../components/page/private/encuestas/EditorEncuestas';
import { EditorEquipo } from '../components/page/private/equipo/EditorEquipo';
import { CreadorEquipo } from '../components/page/private/equipo/CreadorEquipo';
import { DescriptionKits } from '../components/page/private/kits/DescriptionKits';
import { CreateKits } from '../components/page/private/kits/CreateKits';
import { Group } from '../components/page/private/group/Group';
import { CreadorGroup } from '../components/page/private/group/CreadorGroup';
import { ListBlogs } from '../components/page/private/blogs/ListBlogs';
// Bibliotes de react js
import { Route, Routes ,useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { AuthProvider } from '../context/AuthContext';
// Bases de datos
import { db } from '../firebase/config';
import { EditorGroup } from '../components/page/private/group/EditorGroup';
import { ConversationSupport } from '../components/page/private/traysupport/ConversationSupport';
import { ContestadorEncuestas } from '../components/page/private/encuestas/ContestadorEncuestas';
import { Perfil } from '../components/page/private/perfil/Perfil';
import { Password } from '../components/page/private/password/WimdonsPassword';
import { ListaEncuestas } from '../components/page/private/encuestas/ListaEncuestas';
import { ResultEncuesta } from '../components/page/private/encuestas/ResultEncuesta';
import { ListAsignaciones } from '../components/page/private/kits/ListAsignaciones';
import { ContestadorAsignaciones } from '../components/page/private/kits/ContestadorAsignaciones';
import { ListProduct } from '../components/page/private/kits/ListProduct';
import { Asiganaciones } from '../components/page/private/kits/Asiganciones';
import { CreateProduct } from '../components/page/private/product/base/CreateProduct';
import { CreatorBlogs } from '../components/page/private/blogs/CreatorBlogs';
import { EditBlogs } from '../components/page/private/blogs/EditBlogs';
import { CreadorMarca } from '../components/page/private/marca/CreadorMarca';
import { ListaMarca } from '../components/page/private/marca/ListaMarca';
import { EditorMarca } from '../components/page/private/marca/EditorMarca';
import { EditProduct } from '../components/page/private/product/base/EditProduct';
import { EditCategory } from '../components/page/private/product/base/EditCategory';
import { CreateCategory } from '../components/page/private/product/base/CreateCategory';
import { ListAsingProduct } from '../components/page/private/product/assigned/ListAsingProduct';
import { EdictAsingProduct } from '../components/page/private/product/assigned/EdictAsingProduct';
import { ListProductBass } from '../components/page/private/product/base/ListProductBass';
import { Category } from '../components/page/private/product/base/Category';
import { ListQuizOpen } from '../components/page/private/adminquizopen/ListQuizOpen';
// Tour de sistema SHOFI
import TourGuide from '../Tour';

// Rourtas de starco
import { CreadorCvs } from '../components/page/private/equipo/CreadorCvs';
import { LocationsUsers } from '../components/page/private/locationusers/LocationsUsers';
import { StarcoIversion } from '../components/page/private/startco/StarcoIversion';
import { StartcoList } from '../components/page/private/startco/StartcoList'
import { StartcoReserve } from '../components/page/private/startco/StartcoReserve';
import { StartcoListCowork } from '../components/page/private/startco/StartcoListCowork';
import { StartcoListSales } from '../components/page/private/startco/StartcoListSales';
import { DetailQuizOpen } from '../components/page/private/adminquizopen/DetailQuizOpen';
import { CreatorUserOpen } from '../components/page/private/adminquizopen/CreatorUserOpen';
import { CreadorUserCvs } from '../components/page/private/adminquizopen/CreatorUserCVS';
import { Manuals } from '../components/page/private/manuals/Manuals';
import { ListRegister } from '../components/page/private/listregister/ListRegister';
import { ListRegisterSiigo } from '../components/page/private/listregister/ListRegisterSiigo';
import { ListRegisterDemo } from '../components/page/private/listregister/ListRegisterDemo';
import { Balance } from '../components/page/private/balance/Balance';
import { DetailQuizUser } from '../components/page/private/encuestas/result/DetailQuizUser';
import { CreatorUsers } from '../components/page/private/creatorsusers/CreatorUsers';
import { KitList } from '../components/page/private/creatorsusers/KitList';
import { OrderConfirmationList } from '../components/page/private/creatorsusers/OrderConfirmationList';
import { OrderConfirmation } from '../components/page/private/creatorsusers/OrderConfirmation';
import { ReportsOrder } from '../components/page/private/reports/ReportsOrder';
import { StockCompany } from '../components/page/private/stockcompany/StockCompany';
import { UpdateOrden } from '../components/page/private/UpdateOrden/UpdateOrden';

const PrivateRouter = () => {
    const { user } = useLoginContext();
    const [ info, setInfo ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ isPassword, setIsPassword ] = useState(false);
    const [ isRegisterOpen, setIsRegisterOpen ] = useState(false);
    const [ isCreatorUsers, setIsCreatorUsers ] = useState(false);
    const [ isLocations, setIsLocations ] = useState();
    const [ error, setError ] = useState(null);
    const [ shouldReload, setShouldReload ] = useState(false);
    const location = useLocation ();

    useEffect(() => {
        let isMounted = true;
        const fetchUser = async () => {
            try {
                if(user && user.id) { // Si hay un usuario autenticado y tiene un UID
                    const userDocRef = doc(db, 'users', user.id);
                    const userDoc = await getDoc(userDocRef);
                    if(userDoc.exists() && isMounted) {
                        setInfo(userDoc.data());
                        // Verificar si latitud y longitud son números o cadenas vacías
                        const latitud = userDoc.data().latitud || "";
                        const longitud = userDoc.data().longitud || "";
                        setIsLocations(latitud === "" && longitud === "");
                    }
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchUser();
        setLoading(false);
        return () => { isMounted = false; };
    }, [user,  shouldReload]);

    const { idc, compañia, rol , email, business_id, code_orden, typecompany} = info;

    const shouldHideNavBarAndFooter = () => {
        const hideForRoutes = [
            "/manuales",
            new RegExp("/manuales/.+"),
            new RegExp("/contestador/.+")
        ];
        return hideForRoutes.some(route => {
            if (route instanceof RegExp) {
                return route.test(location.pathname);
            } else {
                return route === location.pathname;
            }
        });
    };

    const hideNavBar = shouldHideNavBarAndFooter();

    if (loading) return <Loading />;
    if (!idc) return <Loading />;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (
        <AuthProvider>
            {!isLocations&&<TourGuide  userId={user.id} />}
            <div className='main-admin'>
                {isPassword&&<Password id={user.id} email={email} idc={idc} setIsPassword={setIsPassword}/>}
                {isRegisterOpen&&<CreatorUserOpen id={user.id} idc={idc} company ={compañia} rol={rol} ide={info.ide} setIsRegisterOpen={setIsRegisterOpen}/>}
                {isLocations&&<LocationsUsers id={user.id} setIsLocations={setIsLocations} email={email}/>}
                {isCreatorUsers&&<CreatorUsers id={user.id}  idc={idc} company ={compañia} setIsCreatorUsers={setIsCreatorUsers} business_id={business_id}/>}
                {!hideNavBar&&<section className="container-menu">
                    <MenuLeft idc={idc} rol={rol} setLoading={setLoading} setError={setError}/>
                </section>}
                {!hideNavBar &&<section className="container-admin">
                    <MenuHeader nombre={info.nombre} apellido={info.apellido} email={info.email} img={info.img} rol={info.rol} id={user.id} setIsPassword={setIsPassword}/>
                    <div className="windows-admin">
                        <Routes>
                            {user ? (
                                <>
                                    <>
                                        <Route path="/soporte" element={<TraySupport to={"/soporte"} idc={idc} compañia={compañia} id={user.id} email={email} rol={rol}/> } />
                                        <Route path="/soporte/:id" element={<ConversationSupport to={"/soporte/:id"} idu={user.id} email={email} rol={rol} idc={idc}/>}/>
                                        <Route path="/perfil" element={<Perfil to={"/perfil"} id={user.id} idc={idc}/>}/>
                                    </>
                                    {(     rol === 'US-001'
                                        || rol === 'AA-001'  
                                        || rol === 'SA-001'
                                        || rol === 'SA-002'
                                        || rol === 'SA-003'
                                        || rol === 'SA-004'
                                        || rol === 'SA-005' ) && (
                                        <>
                                            <Route path="/encuesta/lista" element={<ListaEncuestas idc={idc} compañia={compañia} id={user.id} to={"/encuesta/lista"} />} />
                                            <Route path='/encuesta/resultado/:id' element={<ResultEncuesta to={"/encuesta/resultado/:id"}/>} />
                                            <Route path="/kits/lista" element={<ListAsignaciones  code_orden={code_orden} idc={idc} compañia={compañia} id={user.id} to={"/kits/lista"}/>} />
                                            <Route path="/kits/asignados/:id" element={<ContestadorAsignaciones  code_orden={code_orden} idc={idc} compañia={compañia} id={user.id} business_id={business_id} to={"/kits/asignados/:id"}/>} />
                                            <Route path="/kits/procesados/:id" element={<Asiganaciones code_orden={code_orden} idc={idc} rol={rol} email={email} compañia={compañia} idu={user.id} business_id={business_id} to={"/kits/procesados/:id"}/>} />
                                            <Route path="/equipos" element={<ListProduct code_orden={code_orden} idc={idc} compañia={compañia} id={user.id} rol={rol} email={email} to={"equipos"} />} />
                                        </>
                                    )}
                                    {( rol === 'AA-001' || rol === 'SA-001' ) && (
                                        <>
                                            <Route path="/kits/send" element={<Kits to={"/kits/send"} code_orden={code_orden} compañia={compañia} idc={idc} rol={rol}/>} />
                                            <Route path="/kits/:id" element={<DescriptionKits to={"/kits/:id"} code_orden={code_orden} business_id={business_id} compañia={compañia} idc={idc} rol={rol}/>}/>
                                            <Route path="/kits/creador" element={<CreateKits to={"/kits/creador"} idc={idc} compañia={compañia} business_id={business_id} uscompañia={compañia} rol={rol}/>} />
                                            <Route path="/marca/date" element={<Marca to={"/marca/date"}  idc={idc} />} />
                                            <Route path="/usuarios" element={<Equipo to={"/usuarios"}  typecompany={typecompany} idc={idc} compañia={compañia} id={user.id} rol={rol} setIsCreatorUsers={setIsCreatorUsers} isCreatorUsers={isCreatorUsers}/>} />
                                            <Route path="/usuarios/status" element={<TeamStatus to={"/usuarios/status"} idc={idc} compañia={compañia} id={user.id} rol={rol} typecompany={typecompany}/>}/>
                                            <Route path="/usuarios/:id" element={<EditorEquipo to={"/usuarios/:id"} idc={idc} rol={rol}/>} business_id={business_id} compañia={compañia}/>
                                            <Route path="/usuarios/grupos" element={<Group to={"/usuarios/grupos"}  idc={idc} compañia={compañia} id={user.id}  rol={rol} />} />
                                            <Route path="/usuarios/grupos/creador" element={<CreadorGroup to={"/usuarios/grupos/creador"}  idc={idc} compañia={compañia} id={user.id} business_id={business_id}  rol={rol} />} />
                                            <Route path="/usuarios/grupos/:id" element={<EditorGroup to={"/usuarios/grupos/:id"}  idc={idc} compañia={compañia} id={user.id} rol={rol} />} />
                                            <Route path="/usuarios/creador" element={<CreadorEquipo to={"/usuarios/creador" } idc={idc} uscompañia={compañia} rol={rol} business_id={business_id} typecompany={typecompany}/>} />
                                            <Route path="/usuarios/csv" element={<CreadorCvs to={"/usuarios/csv" } idc={idc} compañia={compañia} rol={rol} business_id={business_id} typecompany={typecompany}/>} />
                                            <Route path="/encuestas/send" element={<Encuestas to={"/encuestas/send"} idc={idc} compañia={compañia} id={user.id} rol={rol} />} />
                                            <Route path="/encuestas/:id" element={<EditorEncuestas to={"/encuestas/:id"} idc={idc} rol={rol} compañia={compañia} />} />
                                            <Route path="/encuestas/creador" element={<CreadorEncuestas to={"/encuestas/creador"} idc={idc} rol={rol} compañia={compañia}/>} />
                                            <Route path="/encuestas/diagnostic/:id" element={<DetailQuizUser to={"/encuestas/diagnostic/:id"}/>}/>
                                        </>
                                    )}
                                    {( rol === 'AA-003')&&(
                                        <>
                                            <Route path="/marca/date" element={<Marca to={"/marca/date"}  idc={idc} />} />
                                            <Route path="/movimientos/balance" element={<Balance to={"/movimientos/balance"} idc={idc} compañia={compañia} />}/>
                                            <Route path="/usuarios" element={<Equipo to={"/usuarios"}  typecompany={typecompany} idc={idc} compañia={compañia} id={user.id} rol={rol}  setIsCreatorUsers={setIsCreatorUsers}/>} />
                                            <Route path="/usuarios/:id" element={<EditorEquipo to={"/usuarios/:id"} idc={idc} rol={rol}/>} business_id={business_id} compañia={compañia} />
                                            <Route path="/usuarios/csv" element={<CreadorCvs to={"/usuarios/csv" } idc={idc} compañia={compañia} rol={rol} business_id={business_id} typecompany={typecompany} />} />
                                            <Route path="/kits/lista" element={<KitList to={"/kits/lista"} idc={idc} compañia={compañia} rol={rol} />} />
                                            <Route path="/kits/creador" element={<CreateKits to={"/kits/creador"} idc={idc} compañia={compañia} business_id={business_id} uscompañia={compañia} rol={rol}/>} />
                                            <Route path="/kits/:id" element={<DescriptionKits to={"/kits/:id"} code_orden={code_orden} business_id={business_id} compañia={compañia} idc={idc} rol={rol} typecompany={typecompany}/>}/>
                                            <Route path="/reportes" element={<ReportsOrder to={"/reportes"}  idc={idc} rol={rol} typecompany={typecompany}/>}/>
                                            <Route path="/inventario" element={<StockCompany  to={"/inventario"} idc={idc} rol={rol}/>}/>
                                        </>
                                    )}
                                    {(rol === 'SA-001'
                                        || rol === 'SA-002'
                                        || rol === 'SA-003'
                                        || rol === 'SA-004'
                                        || rol === 'SA-005')  && (
                                        <>
                                            <Route path="/blogs/lista" element={<ListBlogs  to={"/lista/blogs"} />} />
                                            <Route path="/blogs/creador" element={<CreatorBlogs to={"/blogs/creador"} />} />
                                            <Route path="/blogs/creador/:id" element={<EditBlogs to={"/blogs/creador/:id"} />} />
                                            <Route path="/marca/lista" element={<ListaMarca to={"/marca/lista"}/>} />
                                            <Route path="/marca/creador" element={<CreadorMarca to={"marca/creador"}/>} />
                                            <Route path="/marca/creador/:id" element={<EditorMarca to={"/marca/creador/:id"}/>}/>
                                            {/* Rutas de products base */}
                                            <Route path="/productos" element={<ListProductBass  to={"/productos"} rol={rol} idc={idc} compañia={compañia} id={user.id}/>} />
                                            <Route path="/productos/creador" element={<CreateProduct  to={"/productos/creador"}/>} />
                                            <Route path="/productos/:id" element={<EditProduct to={"/productos/:id"}/>} />
                                            {/* Rutas de categoria base */}
                                            <Route path="/productos/categoria" element={<Category to={"/productos/categoria"}/>} />
                                            <Route path="/productos/categoria/:id" element={<EditCategory to={"/productos/categoria/:id"}/>} />
                                            <Route path="/productos/categoria/creador" element={<CreateCategory to={"/productos/categoria/creador"}/>} />
                                            {/* Rutas de productos asignados*/}
                                            <Route path="/productos/asignados" element={<ListAsingProduct to={"/productos/asignados"}/>} />
                                            <Route path="/productos/asignados/:sku/:business_id" element={<EdictAsingProduct to={"/productos/asignados/:sku/:business_id"}/>} />
                                            {/* Rutas de Starco*/}
                                            <Route path="/startco/inversionista" element={<StartcoList to={"/startco/inversionista"}/>} />
                                            <Route path="/startco/inversionista/creador" element={<StarcoIversion to={"/startco/inversionista/creador"}/>} />
                                            <Route path="/startco/inversionista/:id" element={<StarcoIversion to={"/startco/inversionista/:id"}/>} />
                                            <Route path="/startco/inversionista/reserva/:id" element={<StartcoReserve to={"/startco/inversionista/reserva/:id"}/>} />
                                            <Route path="/startco/inversionista/agenda/:id" element={<StartcoList to={"/startco/inversionista/agenda/:id"}/>} />
                                            <Route path="/startco/spaces" element={<StartcoListCowork  to={"/startco/spaces"}/>} />
                                            <Route path="/startco/sales" element={<StartcoListSales  to={"/startco/sales"}/>} />
                                            <Route path="/registros/SHOFI" element={<ListRegister to={"/registros/SHOFI"}/>}/>
                                            <Route path="/registros/Siigo" element={<ListRegisterSiigo to={"/registros/Siigo"}/>} />
                                            <Route path="/registros/Demo" element={<ListRegisterDemo to={"/registros/Demo"}/>}/>
                                            {/* Control logistico */}
                                            <Route path='/order' element={<OrderConfirmationList to={"/order"}  rol={rol}/>}/>
                                            <Route path='/order/update' element={<UpdateOrden to={"/order/update"}  rol={rol}/>}/>
                                            <Route path='/order/:id/:ido' element={<OrderConfirmation to={"/order/:id/:ido"} idc={idc}  business_id={business_id}/>}/>
                                        </>
                                    )}
                                    {(rol === 'US-001' || rol === 'AA-002') && (
                                        <>
                                            <Route path='/open/invitations' element={<ListQuizOpen to={"/open/invitations" } setShouldReload={setShouldReload} idc={idc} compañia={compañia} id={user.id} name={info.nombre} lastname={info.apellido} phone={info.telefono} email={info.email} setIsRegisterOpen={setIsRegisterOpen} />} />
                                            <Route path='/open/invitations/csv' element={<CreadorUserCvs to={"/open/invitations/csv" }  setShouldReload={setShouldReload} idc={idc} ide={info.ide} company={compañia} id={user.id}/>} />
                                            <Route path='/open/invitations/:id' element={<DetailQuizOpen to={"/open/invitations/:id"}  setShouldReload={setShouldReload} idc={idc} compañia={compañia} id={user.id} />}/>
                                        </>
                                    )}
                                </>
                            ) : (
                                <Route path="/login" element={<HomeUser setShouldReload={setShouldReload}  to={"/login"} idc={idc} compañia={compañia} rol={rol} />} />
                            )}
                            <Route path="*" element={<HomeUser to={"/"} setShouldReload={setShouldReload}  typecompany={typecompany} idc={idc} compañia={compañia} rol={rol} business_id={business_id}  id={user.id}  setIsRegisterOpen={setIsRegisterOpen} name={info.nombre} lastname={info.apellido} phone={info.telefono} email={info.email} />} />
                        </Routes>
                    </div>
                </section>}
                {hideNavBar &&
                    <Routes>
                        <Route path="/contestador/:id" element={<ContestadorEncuestas to={"/contestador/:id"} idc={idc} compañia={compañia} id={user.id}/>}/>
                        <Route path="/manuales" element={<Manuals to={"/manuales"}/>}/>
                        <Route path="/manuales/:route" element={<Manuals to={"/manuales/:route"}/>}/>
                    </Routes>
                }
            </div>
        </AuthProvider>
    );
}

export default PrivateRouter;