import { Link } from "react-router-dom";
import "./ItenProducAsiganation.scss";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase/config";
// Alertas para componente
import { toast } from "react-toastify";

function reduceLength(inputString, maxLength) {
    if (!inputString || typeof inputString !== 'string') return ""; // Retorna cadena vacía si inputString no está definido o no es una cadena
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.slice(0, maxLength);
    }
}
export const ItenProducAsiganation = ({name, picture_url, id, external_id, business_id , sku}) => {
    const shortenedName1 = reduceLength((name || "").toUpperCase(), 1);
    const shortenedName2 = reduceLength( name, 16);
    const [nameCompany, setNameCompany] = useState(null);  

    useEffect(() => {
        const loadCompanyData = async () => {
            if (business_id) {
                const q = query(collection (db, "company"), where ("business_id", "==", business_id));
                const querySnapshot = await getDocs (q);
                if (!querySnapshot.empty) {
                    const companyData = querySnapshot.docs[0].data();
                    setNameCompany(companyData.namecompany); 
                } else {
                    toast.error("No se encontró la compañía con el ID proporcionado.");
                }
            }
        };
        loadCompanyData();
    }, [business_id]);

    return ( 
        <Link to={`/productos/asignados/${sku}/${business_id}`} className="item-product-asignate">
            <div className="avatar">
                {picture_url ? (
                    <div className="avatar-img"> 
                    <img alt="avatar" src={picture_url}/>
                    </div>
                ) : (
                    <h2 className="avatar-letter">{shortenedName1}</h2>
                )}
            </div>
            <p className="name-asignate">{shortenedName2}</p>
            <p className="id">{id}</p>
            <p className="idExtenernal">{external_id}</p>
            <p className="seller">{nameCompany}</p>
        </Link>
    );
}
