import "./ListRegisterShofi.scss";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ItenRegister } from "../../molecules/ItenRegister/ItenRegister";
import DownloadData from "../../../page/private/listregister/DownloadData";
// import { Link } from "react-router-dom";

export const ListRegisterShofi = ({ info , handleChange , numberOfPages, currentPage, searchTerm, setSearchTerm, filteredRegistros}) => {
    return (
        <div className="list-register-shofi">
            <div className="contect-list-users">
                <div className="encabezado-list">
                    <input
                        className="input-search"
                        type="text"
                        placeholder="Buscar usuarios..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <h2>N° Registros: {info.length}</h2>
                    <DownloadData />
                </div>
                
                {info && (
                    <div>
                        <div className="title-user">
                            <p>Nombre</p>
                            <p>Compañia</p>
                            <p>Cargo</p>
                            <p>Email</p>
                            <p>Empleados</p>
                            <p>Fecha</p>
                            <p>Teléfono</p>
                        </div>
                        <div className="list">
                            {filteredRegistros.map(user => (
                                <ItenRegister
                                    key={user.id}
                                    {...user}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className="pagination-numb">
                <Stack spacing={2}>
                    <Pagination count={numberOfPages} page={currentPage} onChange={handleChange} size="large" />
                </Stack>
            </div>
        </div>
    );
}