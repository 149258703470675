/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import "./Kits.scss"
import { useState, useEffect} from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase/config';
// Secciones componentes de kits
import { KSection_Description } from "../../UI/organisms/K_Section_Description/KSectionDescription";
import { KSection_0 } from "../../UI/organisms/K_Section_0/K_Section_0";
import { Carrucel_Kits } from "../../UI/organisms/Carrucel_Kits/Carrucel_Kits";
// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
// Component efectos de las secciones
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true, 
        threshold: 0.1,   
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 1200 }, 
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};

export const Kits = ({setIsOpenR, setIsCatalogue, setIdCatalog }) => {
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        setError(null);
        const specificDocRef = doc(db, 'pages', 'vKL2gI8Sf9hXTchohLLs');
        getDoc(specificDocRef)
            .then((document) => {
                if (document.exists) {
                    const data = {
                        ...document.data(),
                        id: document.id,
                    };
                    if (isMounted) {
                        setInfo([data]);  // Como "setInfo" espera un array, ponemos data en un array
                    }
                } else {
                    console.log("No such document!");
                }
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
        return () => { isMounted = false; };
    }, []);
    return (
        <div className="main-inte">
            <div className="ellipse-div-3"/>
            <FadeInSection>
                {info[0]?.kits&&<KSection_Description {...info[0]?.kits} setIsOpenR={setIsOpenR}/>}
            </FadeInSection>
            <FadeInSection>
                {info[0]?.kits?.section_0 && <KSection_0 {...info[0]?.kits.section_0} setIsCatalogue={setIsCatalogue}  setIdCatalog={setIdCatalog}/>}
            </FadeInSection>
            <FadeInSection>
                <Carrucel_Kits setIsOpenR={setIsOpenR}/>
            </FadeInSection>
        </div>
    );
}