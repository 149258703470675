import "./N_Section_0.scss"


export const N_Section_0 = ({item}) => {
    return ( 
        <section className="n-section-01">
            <div className="n-section-01-cotainer">
                {item&&
                    <div className="n-section-01-list-card">
                        {item.map((item, index) => (
                            
                            <div className="n-section-02-list-link" key={index} to={item.url}>
                                <div className="img-02">
                                    <img className="n-section-02-img" alt="card-contenido" src={item.img} />
                                </div>
                                <div className="n-section-02-contect">
                                    <h2 className="subtitle3">{item.title}</h2>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </section>
    );
}