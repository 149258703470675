import "./WindowsPost.scss";
import { FaWhatsapp } from "react-icons/fa";
// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import { BiX } from "react-icons/bi";
// Component efectos de las secciones
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500},
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};

export const WindowsPost = ({setIsWindowPost}) => {

    const toggleClose = () => {
        setIsWindowPost (prevIsLogin=> !prevIsLogin);
    };

    return (
        <div className="main-info-section">
            <FadeInSection>
                <section></section>
                <button onClick={toggleClose} className='button-x-flotant'>
                    <BiX />
                </button>
                <div className="container-pop">
                    <section className='container-register-demo-info-3'>
                        <div className="image-container-3-backgroud">
                            <img className="section-3-card-img" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/pages%2FSiigo%2FSiigo.png.png?alt=media&token=b6385ae2-08d8-4698-a7bd-d8d4952bc20c"  alt="Siigo Post"/>
                        </div>
                        <div className="container-info-3">
                            <div className="container-register-demo-info-3-title">
                                <h2  className="title-page-1">¡<span>Nuevo </span>servicio!</h2>
                            </div>
                            <h3 className="subtitle-page-3">Ahora puedes comprar tus equipos <span>compatibles con SiigoPOS</span> con hasta un <span>30% de descuento</span> en comparación con los precios del mercado</h3>
                            <div className="container-register-demo-info-3-title">
                                <h2  className="title-page-1"><span>con SHOFI</span></h2>
                            </div>
                            <div className="section-img">
                                <div>
                                    <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/pages%2Fsigo%2FMesa%20de%20trabajo%203.png?alt=media&token=25040840-695c-4d83-ba72-dd97a3f7f6aa" />
                                    <p>Tablet</p>
                                </div>
                                <div>
                                    <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/pages%2Fsigo%2FMesa%20de%20trabajo%205.png?alt=media&token=515b89c5-f683-4093-9bb7-9f6b098bcf18" />
                                    <p>Toma pedido<br/>e impresora</p>
                                </div>
                            </div>
                            <a href="https://wa.me/573058080419?text=Quiero%20comprar%20los%20equipos%20de%20Siigo%20POS" target="_blank" rel="noreferrer" className="button-new3">
                                <FaWhatsapp /> Adquiere tu equipo
                            </a>
                        </div>
                    </section>
                </div>
            </FadeInSection>
        </div>
    );
};