import questions from './PreguntasBase';
import { Link, useNavigate } from "react-router-dom";
import { BiPencil, BiPlus } from "react-icons/bi";
import { useEffect, useState } from "react";
import { addDoc, collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { InfoLoading } from "../../../../Loanding";
import { FormSend } from "../../../UI/organisms/FormSend/FormSend";
import { EmptyForm } from "../../../UI/atoms/EmptyForm/EmptyForm";

export const Encuestas  = ({ idc, compañia, rol}) => {
    const navigate = useNavigate(); 
    const [kits, setKits] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [asignacion, setAsignacion] = useState({ title: "", id: "", idc: "" , nameCompany: "" , initial_diagnostic: ""});
    const [updateQuiz, setupdateQuiz] = useState(0);

    const filteredQuiz = kits.filter(user => (
        user.title.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const assigned = filteredQuiz.filter(kit => !kit.editable);
    const draft = filteredQuiz.filter(kit => kit.editable);
    const trueStatus = assigned.filter(assig => assig.status);
    const falseStatus = assigned.filter(assig => !assig.status);

    // Llamado de la API de busqueda de firebase
    useEffect(() => {
        let isMounted = true;
        setLoading(true); 
        //Carga de las encuestas
        const fetchKits = async () => {
            try {
                if (!idc) throw new Error("idc is undefined");
                const kitsCollectionRef = collection(db, 'quiz');
                let q;
                if (rol === "SA-001") {
                    q = kitsCollectionRef;
                } else {
                    q = query(kitsCollectionRef, where('idc', '==', idc));
                }
                const querySnapshot = await getDocs(q);
                const fetchedKits = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                // Para cada grupo de equipos, busca la compañía correspondiente
                const infoWithCompany = await Promise.all(fetchedKits.map(async (kits) => {
                    const companyRef = doc(db, "company", kits.idc);
                    const companyDoc = await getDoc(companyRef);
                    return { ...kits, companyName: companyDoc.data()?.namecompany };
                }));

                if (isMounted) setKits(infoWithCompany);
                setLoading(false)
            } catch (err) {
                setLoading(false)
                if (isMounted) setError(err);
            } finally {
                setLoading(false)
                if (isMounted) setLoading(false);
            }
        };
        fetchKits();
        // Informacion de equipo
        return () => { isMounted = false; }
    }, [idc, updateQuiz, rol]);

    const handleSubmitQuiz = async (e) => {
        e.preventDefault();
        await handleSubmit();
        navigate('/encuestas/creador');
    };
    
    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        setLoading(true); // Activar el indicador de carga
        try {
            const creationDate = new Date(); // Obtener la fecha y hora actual
            await addDoc(collection(db, 'quiz'), {
                title:"Diagnóstico de puestos de trabajo",
                questions: questions,
                idc: idc,
                status: true,
                send: true,
                editable: false,
                creationDate,
                nameCompany: compañia,
                initial_diagnostic: true,
            });
            setupdateQuiz(prev => prev + 1);
        } catch (error) {
            console.error("Error al crear encuesta: ", error);
        }
        setLoading(false); 
    };

    //Manejo  de carga y errores de las encuestas
    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <>{(kits.length === 0)
            ?<div className="windons">
                <div className="windons-title-section">
                    <div className="title-info">
                        <h2 className="title">Encuestas: {compañia}</h2>
                    </div>
                </div>
                <div className="windons-quiz-init">
                    <div className="section-quiz-init">
                        <div className="quiz-init-img">
                            <img alt="Shofi Encuestas" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2FPJ_To_do_Checklist-removebg%20(1).png?alt=media&token=39d60d9c-42f2-4852-9d1e-9134462bc289&_gl=1*1f8wrtz*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5ODc4MDcyMi4zNDkuMS4xNjk4NzgyMTI2LjUyLjAuMA.."/>
                            <h2 className="title-shofi-loanding">Haz evaluaciones<br></br>a tu equipo de trabajo.</h2>
                        </div>
                        <div className="quiz-init-contect">
                            <h3 className="contect-shofi-loanding">Iniciemos haciendo una evaluación diagnóstica<br />de los puestos de trabajo de tus colaboradores</h3>
                            <button className='button-asing' onClick={handleSubmit}>
                                <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2F27470480_7236956-PhotoRoom.png-PhotoRoom%20(1).png?alt=media&token=bc0b305b-80fb-49a7-a6c8-cd8f28705713&_gl=1*1nh72nx*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5ODc4NTUyOS4zNTAuMS4xNjk4Nzg1NTM0LjU1LjAuMA.." />
                                Test diagnóstico
                            </button>
                            <h3 className="contect-shofi-loanding">Crea tus propios cuestionarios y<br/>haz las evaluaciones que necesites a tu equipo.</h3>
                            <button className='button-new'  onClick={handleSubmitQuiz}>
                                <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*ndeahk*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5ODc2MzcxMi4zNDcuMS4xNjk4NzYzNzIwLjUyLjAuMA.." />
                                Crear encuestas
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            :<div className="windons-admin-send">
                <div className="windons-title-send">
                    <div className="title-info">
                        <h2 className="title">Encuestas: {compañia}</h2>
                    </div>
                    <Link className='button-new'  to="/encuestas/creador">
                        <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*ndeahk*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5ODc2MzcxMi4zNDcuMS4xNjk4NzYzNzIwLjUyLjAuMA.." />
                        Crear
                    </Link>
                </div>
                <div className="windons-section-info">
                    <section className="section-info">
                        <input
                            className="search-input"
                            type="text" 
                            placeholder="Buscar encuesta..." 
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="list-info">
                            {draft.length!==0 && <p class="edit">Borradores</p>}
                            {draft.map(kit => (
                                <div key={kit.id} className="item-kits-as">
                                    <Link to={`/encuestas/${kit.id}`} ><BiPencil className="search"/></Link>
                                    <div>
                                        <p className="name">{kit.title}</p>
                                        <p>{kit.id}</p>
                                        {rol === "SA-001" && (
                                            <p className="name">{kit.companyName}</p>
                                        )}
                                    </div>
                                    <Link onClick={() => setAsignacion({ title: kit.title, id: kit.id, idc: kit.idc , nameCompany:kit.nameCompany , initial_diagnostic:kit.initial_diagnostic})}><BiPlus /></Link>
                                </div>
                            ))}
                            {trueStatus.length!==0&&<p class="asigne">Asignados</p>}
                            {trueStatus.map(kit => (
                                <div key={kit.id} className="item-kits-as">
                                    <Link to={`/encuestas/${kit.id}`} ><BiPencil className="search"/></Link>
                                    <div>
                                        <p className="name">{kit.title}</p>
                                        <p>{kit.id}</p>
                                        {rol === "SA-001" && (
                                            <p className="name">{kit.companyName}</p>
                                        )}
                                    </div>
                                    <Link onClick={() => setAsignacion({ title: kit.title, id: kit.id, idc: kit.idc , nameCompany:kit.nameCompany , initial_diagnostic:kit.initial_diagnostic  })}><BiPlus /></Link>
                                </div>
                            ))}
                            {falseStatus.length!==0&&<p class="inactive">Inactivas</p>}
                            {falseStatus.map(kit => (
                                <div key={kit.id} className="item-kits-as">
                                    <Link to={`/encuestas/${kit.id}`} ><BiPencil className="search"/></Link>
                                    <div>
                                        <p className="name">{kit.title}</p>
                                        <p>{kit.id}</p>
                                        {rol === "SA-001" && (
                                            <p className="name">{kit.companyName}</p>
                                        )}
                                    </div>
                                    <Link onClick={() => setAsignacion({ title: kit.title, id: kit.id, idc: kit.idc , nameCompany:kit.nameCompany , initial_diagnostic:kit.initial_diagnostic })}><BiPlus /></Link>
                                </div>
                            ))}
                        </div>
                    </section>
                    <section className="section-info1">
                        {(asignacion.id === "")
                            ?<EmptyForm />
                            :
                            <FormSend
                                idc={idc}
                                assignment={asignacion}
                                setAssignment={setAsignacion}
                                setLoading={setLoading}
                            />
                        }
                    </section>
                </div>
            </div> }
        </>
    );
}