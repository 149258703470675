import "./ButtonHome.scss"
import React from 'react';
import { Link } from 'react-router-dom';
import { BiX } from "react-icons/bi";

export  const ButtonHome= () => {
    return (
        <Link to="/" className='button-home'>
            <BiX />
        </Link>
    );
}


export  const ButtonHome2= () => {
    return (
        <Link to="/" className='button-home2'>
            <BiX />
        </Link>
    );
}

