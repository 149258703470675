import "./Reports.scss";
import { CSVLink } from "react-csv";
import { FiDownload } from "react-icons/fi";

export const ReportBalance = ({ info }) => {
    const selectedFields = [ "id", "name_user", "date_print", "balance", "status_balance"];

    const flattenOrderData = (order) => {
        const flatData = [];
        if (order.order_product && order.order_product.length > 0) {
            order.order_product.forEach(product => {
                const flattenedProduct = { ...order };
                delete flattenedProduct.order_product; 
                flattenedProduct.product_name = product.name;
                flattenedProduct.product_quantity = product.quantity;
                flattenedProduct.product_sku = product.sku;
                flatData.push(flattenedProduct);
            });
        } else {
            flatData.push(order);
        }
        return flatData;
    };

    const generateCSVData = () => {
        return info.flatMap(order => {
            const flattenedOrders = flattenOrderData(order);
            return flattenedOrders.map(flattenedOrder => {
                const filteredOrder = {};
                selectedFields.forEach(field => {
                    filteredOrder[field] = flattenedOrder[field];
                });
                return filteredOrder;
            });
        });
    };


    return (
        <div className="buttom-orden-container">
            {info.length !==0
                ?<CSVLink data={generateCSVData()} className="button-new">
                    <FiDownload /> Descargar CSV
                </CSVLink>
                :<div></div>
            }
        </div>
    );
};
