import { useEffect, useState } from "react";
import { InfoLoading } from "../../../../../Loanding";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../../firebase/config";
import { ListCategory } from "../../../../UI/organisms/ListCategory/ListCategory";

export const Category = () => {
    const itemsPerPage = 10;
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'category');
                const querySnapshot = await getDocs(q);
                    
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                    
                if (isMounted) setInfo(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, []);

    

    const handleChange = (event, value) => {
        setCurrentPage(value);
    }; 

    const numberOfPages = Math.ceil(info.length / itemsPerPage);
    const displayItems = info.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    if (loading) return <InfoLoading/>;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (
        <>
            {info&&
                <div className="product-admin-produc-bass">
                    <ListCategory
                        info = {info}
                        handleChange = {handleChange}
                        displayItems = {displayItems}
                        numberOfPages = {numberOfPages}
                        currentPage = {currentPage}
                    />
                </div>
            }
        </>
    );
}