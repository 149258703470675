/* eslint-disable react/jsx-no-target-blank */
import "./N_Section_1.scss"
import { ImLinkedin2 } from "react-icons/im";
export const N_Section_1 = ({item}) => {
    return (
        <section className="n-section">
            <div className="n-section-title">
                <h1 className="title-page">El <span>TEAM</span> que hace todo posible</h1>
            </div>
            {item&&
                <div className="n-section-list-01">
                {item.map((item, index) => (
                    <div key={index} className="n-section-item-01">
                        <div className="n-img-01">
                            <img className="n-section-02-img" alt="card-contenido" src={item.img} />
                        </div>
                        <div className="n-section-contect">
                            <div className="n-section-title">
                                <h2 className="subtitle-page">{item.name}</h2>
                                <p>{item.position}</p>
                            </div>
                            <div className="n-section-rrss" >
                                <a href={item.linkedin} className='redes' target='_blank'>
                                    <ImLinkedin2 />
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            }
        </section>
    );
}