import { Link } from "react-router-dom";
import "./ItenCowork.scss";

function reduceLength(inputString, maxLength) {
    if (!inputString || typeof inputString !== 'string') return ""; // Retorna cadena vacía si inputString no está definido o no es una cadena
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.slice(0, maxLength);
    }
}
export const ItenCowork = ({name, lastname, load, company, day_agend, img, id, date, time}) => {
    const shortenedName1 = reduceLength((name || "").toUpperCase(), 1);
    const shortenedName2 = reduceLength(lastname? name + " " + lastname : name, 16);
    const shortenedEmail = reduceLength(load|| "", 25);

    return (
        <Link to={`/startco/inversionista/reserva/${id}`} className="item-reserve">
            <div className="avatar">
                {img ? (
                    <div className="avatar-img">
                    <img alt="avatar" src={img}/>
                    </div>
                ) : (
                    <h2 className="avatar-letter">{shortenedName1}</h2>
                )}
            </div>
            <p className="name-user">{shortenedName2}</p>
            <p className="email">{shortenedEmail}</p>
            <p className="phone">{company}</p>
            <p className="rol">{date}</p>
            <p className="date">{time}</p>
        </Link>
    );
}
