import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { InfoLoading } from "../../../../Loanding";
import { Link } from "react-router-dom";
import { BiPencil } from "react-icons/bi";
import { db } from "../../../../firebase/config";
import { toast } from "react-toastify";

export const ListAsignaciones = ({ idc, compañia, id }) => {
    const [kits, setKits] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [processedKitIds, setProcessedKitIds] = useState(new Set());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        const fetchData = async () => {
            try {
                const ordenesSnapshot = await getDocs(query(collection(db, "orden"), where("idu", "==", id)));
                const newProcessedKitIds = new Set(ordenesSnapshot.docs.map(doc => doc.data().idk));
                const kitsSnapshot = await getDocs(query(collection(db, "kit_application"), where("idc", "==", idc)));
                const kitsData = [];
                kitsSnapshot.forEach(doc => {
                    const data = doc.data();
                    if (data.users.includes(id)) {
                        kitsData.push({ id: doc.id, ...data });
                    }
                });
                if (isMounted) {
                    setKits(kitsData);
                    setProcessedKitIds(newProcessedKitIds);
                }
            } catch (err) {
                if (isMounted) {
                    setError(err);
                    toast.error(`Error fetching products: ${err.message}`);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchData();
        return () => { isMounted = false; };
    }, [idc, id]);

    const filterkits = kits.filter(kit =>
        kit.comment.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    const pendingKits = filterkits.filter(kit => !processedKitIds.has(kit.idk));
    const processedKits = filterkits.filter(kit => processedKitIds.has(kit.idk));

    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <>
            {(kits.length!==0)
                ?<div className="windons-admin-two">
                    <div className="windons-title-section">
                        <input
                            className="input-search"
                            type="text"
                            placeholder="Buscar asignaciones..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="windons-section-group">
                        <section className="section-info-group">
                            <h2 className="title2">Listado de asignaciones</h2>
                            <div className="list-info-group">
                                {pendingKits.length!==0&&<p class="asigne">Pendientes: {pendingKits.length}</p>}
                                {pendingKits.map( group => (
                                    <Link key={group.id} className="item-quiz-group" to={`/kits/asignados/${group.assignment.id}`} >
                                        <div><BiPencil className="search"/></div>
                                        <div>
                                            <p className="name">{group.comment}</p>
                                            <p>{group.id}</p>
                                        </div>
                                        <p>{group.timestamp.toDate ? group.timestamp.toDate().toLocaleString() : new Date(group.timestamp.seconds * 1000).toLocaleString()}</p>
                                    </Link>
                                ))}
                                {processedKits.length!==0&&<p class="inactive">Procesadas: {processedKits.length}</p>}
                                {processedKits.map(group => (
                                    <Link key={group.id} className="item-quiz-group"  to={`/kits/procesados/${group.assignment.id}`}>
                                        <div><BiPencil className="search"/></div>
                                        <div>
                                            <p className="name">{group.comment}</p>
                                            <p>{group.id}</p>
                                        </div>
                                        <p>{group.timestamp.toDate ? group.timestamp.toDate().toLocaleString() : new Date(group.timestamp.seconds * 1000).toLocaleString()}</p>
                                    </Link>
                                ))}
                            </div>
                        </section>
                    </div>
                </div>
                :<div className="windons">
                    <div className="encabezado-list">
                        <h2 className="title">Asignación: {compañia}</h2>
                    </div>
                    <div className="windons-quiz-init">
                        <div className="section-quiz-init2">
                            <div className="quiz-init-img">
                                <img alt="Shofi Encuestas" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fgroud%2FPJ_To_do_Checklist-PhotoRoom.png-PhotoRoom.png?alt=media&token=af91c841-59f3-475c-ab05-7ad9334ca226&_gl=1*hcsaao*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAzODQ5NC4zNjYuMS4xNjk5MDQwNDI3LjEuMC4w"/>
                                <h2 className="title-shofi-loanding">No tienes productos asignados.<br></br>Revisa en próximas actividades.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}