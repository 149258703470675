export const KSection_Description = ({description, title, img, setIsOpenR}) => {
    const toggleRe = () => {
        setIsOpenR(prevIsOpenR => !prevIsOpenR);
    }; 
    return (
        <section className="section-contect">
            <div className="section-0N-contect-container">
                <div className="section-0N-img">
                    <img alt={title} src={img} className="img-0N"/>
                </div>
                <div className="section-0N-title">
                    <h1 className="title-page" dangerouslySetInnerHTML={{ __html: title }}></h1>
                    <p className="subtitle-page" dangerouslySetInnerHTML={{ __html: description }}></p>
                    <div className="container-button-nav">
                        <button className="button-tipe1"  onClick={toggleRe}>
                            Pide tu cotización
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}


