import { Link } from "react-router-dom";
import { PieChart } from '@mui/x-charts/PieChart';
import Stack from '@mui/material/Stack';
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { InfoLoading } from "../../../Loanding";
import { doc, getDoc, getFirestore} from "firebase/firestore";
import { GrFormNextLink } from "react-icons/gr";

export const ResponseStadistics = ({ide, user}) => {
    const [answer, setAnswer] = useState ([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchEquipment() {
            try {
                const db = getFirestore();
                const userDocRef = doc(db, 'quizanswer_open', user.ider);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    setAnswer(userDoc.data()); // Establecer solo los datos del documento
                } else {
                    toast.error('Documento no encontrado');
                    setError('Documento no encontrado');
                }
            } catch (error) {
                toast.error('Error fetching user data:', error);
                setError(error);
            } finally {
                setLoading(false); // Asegurar que se establezca el estado de carga
            }
        }
        if (ide) {
            fetchEquipment();
        }
        return () => { 
            // Aquí podrías limpiar algo si es necesario
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ user ]);
    // Preparar los datos para la gráfica circular
    const porcentajeRedondeado = Math.round(answer.resultado); // Redondea el porcentaje a un número entero
    const porcentajeRestanteRedondeado = 100 - porcentajeRedondeado; 
    const datosGrafica = [
        { label: 'Positivas', value: porcentajeRedondeado },
        { label: 'Negativas', value: porcentajeRestanteRedondeado }
    ];

    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="body-quiz">
            <section className="wimdons-quiz-img">
                <div className="wimdons-quiz-questions">
                    <p className="title-result">Esta es la calificación con respecto al <br></br>cumplimiento de la <span>situación ergonómica de tu puesto de trabajo.</span></p>
                    <div className='dates-dashboard'>
                        <div className="card-quiz-contador">
                            <Stack direction="row">
                                <PieChart
                                    colors={['#1E67E2', '#85D0D5']} // Colores de tu elección
                                    series={[{
                                    paddingAngle: 1,
                                    innerRadius: 60,
                                    outerRadius: 80,
                                    cornerRadius: 19,
                                    data: datosGrafica,
                                }]}
                                margin={{ right: 5 }}
                                width={200}
                                height={200}
                                legend={{ hidden: true }}
                                />
                            </Stack>
                            <div>
                                <h1>Puntuación:</h1>
                                <h2>{porcentajeRedondeado}Pts</h2>
                                <p>Esta es la calificación va con un maximo de 100pts. <br></br> </p>
                            </div>
                        </div>
                    </div>
                    <Link className="button-result" to={"/"}>Ir a web <GrFormNextLink /></Link>
                </div>
            </section>
        </div>
    );
}
