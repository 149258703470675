import "./Politcas.scss"
// Secciones componentes de Nosotros
export const Terminos = () => {
    return ( 
        <div className="main-politics">
            <section className="contect-politycs">
                {/* Sección Términos y Condiciones (SHOFI) */}
                <h1 className="title">Términos y Condiciones (SHOFI)</h1>
                
                {/* Introducción */}
                <p>Descripción general de los servicios de SHOFI y alcance de los términos y condiciones.</p>

                {/* Uso de la Plataforma y Servicios */}
                <h2 className="subtitle3">Uso de la Plataforma y Servicios:</h2>
                <p>Normas y restricciones en el uso de las herramientas, aplicaciones y plataformas digitales de SHOFI. Este apartado detalla las condiciones específicas de uso, las limitaciones, y las responsabilidades del usuario al acceder y utilizar los servicios de SHOFI.</p>

                {/* Propiedad Intelectual */}
                <h2 className="subtitle3">Propiedad Intelectual:</h2>
                <p>Información sobre derechos de autor y propiedad intelectual relacionados con SHOFI. Se incluyen detalles sobre el uso de materiales, marcas registradas, y contenido protegido por derechos de autor perteneciente a SHOFI.</p>

                {/* Limitación de Responsabilidad */}
                <h2 className="subtitle3">Limitación de Responsabilidad:</h2>
                <p>Detalles sobre las limitaciones en la responsabilidad de SHOFI respecto al uso de sus servicios. Se aborda el alcance de la responsabilidad de SHOFI en relación con el uso de la plataforma y los posibles daños o pérdidas derivados de dicho uso.</p>

                {/* Modificaciones a los Términos y Condiciones */}
                <h2 className="subtitle3">Modificaciones a los Términos y Condiciones:</h2>
                <p>Procedimientos y notificaciones para cambios en los términos y condiciones. Se describe cómo y cuándo SHOFI puede modificar estos términos y la forma en que estos cambios serán comunicados a los usuarios.</p>

                {/* Resolución de Disputas y Ley Aplicable */}
                <h2 className="subtitle3">Resolución de Disputas y Ley Aplicable:</h2>
                <p>Información sobre la jurisdicción y el mecanismo de resolución de disputas. Se detalla el proceso para resolver controversias relacionadas con el uso de la plataforma y las leyes que rigen estos términos y condiciones.</p>

                {/* Contacto */}
                <h2 className="subtitle3">Contacto:</h2>
                <p>Para consultas sobre privacidad y protección de datos, podras comunicarte al email: comunidad@goshofi.com</p>
            </section>
        </div>
    );
}
