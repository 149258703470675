import "./Section6.scss";
import { useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useState } from "react";
import { db } from "../../../../firebase/config";
import { useNavigate } from "react-router-dom";


export const Section6 = ({setLoading, setError, setIsOpenR}) => {
    const [info, setInfo] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const specificDocRef = doc(db, 'sections_page', 'g6gnnMfmhJ0FEExwwlwX');
        getDoc(specificDocRef)
            .then((document) => {
                const data = {
                    ...document.data(),
                    id: document.id,
                };
                setInfo(data); 
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
        return () => { isMounted = false; };
    }, [setError, setLoading]);

    const navigate = useNavigate();
    const toggleRe = () => {
        setIsOpenR(prevIsOpenR => !prevIsOpenR);
        navigate( `${info.button.route}`);
    };

    return (
        <section className="section-contect">
            <div className="section-6-contect">
                <div className="ellipse-div-3"/>
                <h1 className="title-page-1" dangerouslySetInnerHTML={{ __html: info.title }}></h1>
                <img className="section-6-contect-img" src={info.img} alt={info.title }/>
                <p className="subtitle-page-1" dangerouslySetInnerHTML={{ __html: info.contect}}></p>
                <div className="container-button-nav">
                    {info.button&&
                        <button className="button-tipe1"  onClick={toggleRe}>
                            {info.button.title}
                        </button>
                    }
                </div>
            </div>
        </section>
    );
};

