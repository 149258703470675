import {  collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { useParams , useNavigate } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { useEffect, useState } from "react";
import { BiChevronLeft, BiPlus, BiSave, BiX, BiCheck } from "react-icons/bi";
import { toast } from "react-toastify";
import { InfoLoading } from "../../../../Loanding";
import { KitStatistics } from "../../../UI/organisms/KitStatistics/KitStatistics";

export const DescriptionKits = ({rol, typecompany}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [nameKits, setNameKits] = useState('');
    const [idProduct, setIdProduct] = useState(null)
    const [open, setOpen] = useState(false);
    const [detail, setDetail] = useState([]);
    const [opendetail, setOpenDetail] = useState(false);
    const [searchTermG,  setSearchTermG] = useState('');
    const [searchTerm,  setSearchTerm] = useState('');
    const [info, setInfo] = useState({kits: []});  // Asegurándose de que info.kits siempre es un array
    const [products, setProducts] = useState([]);
    const [orden,setOrden] = useState("");

    const handleDetail = (idProduct) => {
        setOpen(!open);
        setIdProduct(idProduct);
    };

    const handleDetailClose = () => {
        setOpen(!open);
        setIdProduct(null);
        setOpenDetail(false);
    }; 

    const handleNoDetail = (idProduct) => {
        setOpen(!open);
        setOpenDetail(!opendetail);
        setIdProduct(idProduct);
    }; 
    
    useEffect(() => {
        let isMounted = true;
        const fetchKits = async () => {
            try {
                const userDocRef = doc(db, 'produc_kits', id);
                const userDoc = await getDoc(userDocRef);
                if(userDoc.exists() && isMounted) {
                    setInfo(userDoc.data());
                    setNameKits(userDoc.data().name_kits); // Aquí se establece el valor inicial de nameKits
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchKits();
        return () => { isMounted = false; };
    }, [id]);


    useEffect(() => {
        let isMounted = true;
        const fetchProducts = async () => {
            try {
                const ordenRef = collection(db, "orden"); 
                const q = query(ordenRef, where('idk', '==', id)); 
                const querySnapshot = await getDocs(q); 
                if (isMounted) {
                    const orders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setOrden(orders.length); 
                }
            } catch (error) {
                toast.error("Error fetching products: ", error);
            }
        };
        fetchProducts();
        return () => { isMounted = false; }; 
    }, [id]); 
    

    useEffect(() => {
        let isMounted = true;
        const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection (db, "produc"));
                if (isMounted) {
                    setProducts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
                }
            } catch (error) {
                toast.error("Error fetching products: ", error);
            }
        };
        fetchProducts();
        return () => { isMounted = false; };
    }, []);

    useEffect(() => {
        const fetchDetail = async () => {
            if (!idProduct) return;
            try {
                const docRef = doc(db, "produc", idProduct);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setDetail(docSnap.data());
                } else {
                    toast.error("No se encontró información del producto");
                }
            } catch (err) {
                toast.error(err);
            } finally {
                toast.error(false);
            }
        };
        fetchDetail();
    }, [idProduct]);

    const handleDelete = (kitId) => {
        setInfo(prevInfo => ({
            ...prevInfo,
            kits: prevInfo.kits.filter(id => id !== kitId)
        }));
    };
    const handleAdd = (productId) => {
        const product = products.find(product => product.id === productId);
        if (product) {
            setInfo(prevInfo => ({
                ...prevInfo,
                kits: [...prevInfo.kits, product.id] // Si no está en la lista, agregar el producto
            }));
        }
    };

    const handleAdds = (productId) => {
        const product = products.find(product => product.id === productId);
        if (product) {
            setInfo(prevInfo => ({
                ...prevInfo,
                kits: [...prevInfo.kits, product.id] // Si no está en la lista, agregar el producto
            })); // Añadir el producto seleccionado a la lista del kit
            setOpen(!open);
        }
    };

    const filterProductsS= products.filter(product => (
        product.name.toLowerCase().includes(searchTermG.toLowerCase())||
        product.category.toLowerCase().includes(searchTermG.toLowerCase())
    ));

    // Filtrar los productos seleccionados basados en searchTerm
    const selectedProducts = info.kits.map(kitId => products.find(product => product.id === kitId))
        .filter(product => product && (
            product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            product.category.toLowerCase().includes(searchTerm.toLowerCase())
        ));

    const handleSave = async () => {
        try {
            const userDocRef = doc(db, 'produc_kits', id);
            await updateDoc(userDocRef, {
                kits: info.kits, // actualiza el campo kits con el valor actual de info.kits
                name_kits: nameKits // actualiza el campo name_kits con el valor actual de nameKits
            });
            navigate('/kits');
            toast.success('Información actualizada con éxito');
        } catch (error) {
            toast.error("Error updating document: ", error);
        }
    };

    const handleToggleStatus = async () => {
        try {
            const kitDocRef = doc(db, 'produc_kits', id);
            await updateDoc(kitDocRef, {
                status: !info.status // Esto cambiará el estado de true a false y viceversa
            });
            setInfo({ ...info, status: !info.status }); // Actualizar el estado local
            navigate('/kits');
            toast.success('Estado del kit actualizado con éxito');
        } catch (error) {
            console.error("Error al actualizar el estado del kit: ", error);
            toast.error("Error al actualizar el estado del kit");
        }
    };

    const goBack = () => {
        navigate(-1); // Mueve el historial hacia atrás en una entrada
    };
    
    if (loading) return <InfoLoading />;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (
        <>
            {open && (
                <div className="wimdons-emerge-detail">
                    <section className="emerge-detail">
                        <div className="emerge-detail-img">
                            <img alt={detail.name}  src={detail.img}/>
                        </div>
                        <div className="emerge-detail-contect">
                            <h2 className="subtitle3">{detail.name}</h2>
                            <div className="text-emerge">
                                <h4>Categoría: {detail.category}</h4>
                                <p><span>Marca:</span> {detail.brand}</p>
                                <p><span>Descripción:</span> {detail.description}</p>
                            </div>
                            <div className="emerge-detail-button">
                                <button className="button-delete" onClick={handleDetailClose}>
                                    <BiX /> 
                                    Cerrar 
                                </button>
                                {(info.editable && !opendetail === true && orden===0) && <button className="button-new" onClick={() => handleAdds(idProduct)}>
                                    <BiPlus />
                                    Agregar
                                </button>}
                            </div>
                        </div>
                    </section>
                </div>
            )}
            <div className="windons-two">
                <div className="windons-title-section">
                    <div className="title-info">
                        <p>Nombre del kit</p>
                        <input  type="text"  value={nameKits}  onChange={(e) => setNameKits(e.target.value)}  {...((!info.editable || orden!==0 ) && { readOnly: true })} />
                    </div>
                    <div className="windons-title-info">
                        {rol === "SA-001" && (
                            <div className='input-date'>
                                <label>Empresa:</label>
                                <h2 className="title2">{info.nameCompany}</h2>
                            </div>
                        )}
                    </div>
                    <button className='button-new' onClick={goBack}><BiChevronLeft /> Volver</button>
                </div>
                <div className="windons-section-info">
                    <section className="section-info-two  seleccione-group">
                        <div className="section-title">
                            <div>
                                <label>Productos seleccionados</label>
                                <input 
                                type="text" 
                                placeholder="Buscar productos..." 
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <h2>{info.kits.length}</h2>
                        </div>
                        <div className="list-info">
                            {selectedProducts && selectedProducts.map((product, index) => (
                                <div key={index} className="item-produc-selec">
                                    <div className="img-item-product">
                                        <div className="avatar-img" onClick={() => handleNoDetail(product.id)}>
                                            <img alt={product.name} src={product.img} />
                                        </div>
                                    </div>
                                    <div>
                                        <p className="name">{product.name}</p>
                                        <p>Categoría: {product.category}</p>
                                        <p>SKU: {product.SKU}</p>
                                        <p>Marca: {product.brand}</p>
                                    </div>
                                    {( info.editable && orden===0 ) && <button onClick={() => handleDelete(product.id)}><BiX /></button>}
                                </div>
                            ))}
                        </div>
                    </section>
                    {( info.editable && orden===0 ) 
                        ?<section className="section-info-two   seleccione-employe">
                            <div className="section-title">
                                <div>
                                    <label>Productos para seleccionar</label>
                                    <input 
                                    type="text" 
                                    placeholder="Buscar productos..." 
                                    value={searchTermG}
                                    onChange={(e) => setSearchTermG(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="list-info">
                                {filterProductsS.filter(product => !info.kits.includes(product.id)).map((product) => (
                                    <div key={product.id} className="item-produc">
                                        <div className="img-item-product">
                                            <div className="avatar-img" onClick={() =>  handleDetail(product.id)} > 
                                                <img alt={product ? product.name : ''}  src={product ? product.img : ''} />
                                            </div>
                                        </div>
                                        <button  onClick={() => handleAdd(product.id)}>
                                            <p className="name">{product.name}</p>
                                            <p>Categoría: {product.category}</p>
                                            <p>SKU: {product.SKU}</p>
                                            <p>Marca: {product.brand}</p>
                                        </button>
                                        <BiPlus onClick={() => handleAdd(product.id)} />
                                    </div>
                                ))}
                            </div>
                        </section>
                        :<KitStatistics typecompany={typecompany} idk={id} ordenNumb={orden}/>
                    }
                </div>
                <div className="container-button-two">
                    {( info.editable && orden===0 ) &&<button className='button-save' onClick={handleSave}><BiSave />Guardar</button>}
                    {( info.editable && orden===0 ) &&
                        <button className={info.status ? 'button-delete' : 'button-save'} onClick={handleToggleStatus}>
                            {info.status ? <BiX />: <BiCheck /> }
                            {info.status ? "Inactivar" : "Activar"}
                        </button>
                    }
                </div>
            </div>
        </>
    );
}
