import "./Section_0_N.scss"
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase/config";

export const Section0N = ({setLoading, setError, setIsOpenR}) => {
    const [info, setInfo] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const specificDocRef = doc(db, 'sections_page', 'LNFnf2oA43QbsSyPj4Fc');
        getDoc(specificDocRef)
            .then((document) => {
                const data = {
                    ...document.data(),
                    id: document.id,
                };
                setInfo(data); 
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
        return () => { isMounted = false; };
    }, [setError, setLoading]);

    const toggleRe = () => {
        setIsOpenR(prevIsOpenR => !prevIsOpenR);
    };
    return (
        <section className="section-contect">
            <div className="section-0N-contect-container">
                <div className="section-0N-img">
                    <img alt={info.title} src={info.img} className="img-0N"/>
                </div>
                
                <div className="section-0N-title">
                    <h1 className="title-page" dangerouslySetInnerHTML={{ __html: info.title }}></h1>
                    <p className="subtitle-page" dangerouslySetInnerHTML={{ __html: info.subtitle }}></p>
                    <div className="container-button-nav">
                        {info.button&&<button className="button-tipe1"  onClick={toggleRe}>
                            {info.button.title}
                        </button>}
                    </div>
                </div>
            </div>
        </section>
    );
}

