import "./LocationsUsers.scss"
import axios from 'axios';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import colombia from '../../../../firebase/colombia';
import { db } from '../../../../firebase/config';
import { InfoLoading } from '../../../../Loanding';

export const LocationsUsers = ({ id,  setIsLocations, email }) => {
    const [ ciudad, setCiudad ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ departamento, setDepartamento ] = useState ('');
    const [ direccion_1, setDireccion_1 ] = useState('');
    const [ direccion_2, setDireccion_2 ] = useState('');
    const [ selectedDepartment, setSelectedDepartment ] = useState('');
    const [ cities, setCities ] = useState([]);
    const [ nombre, setNombre ] = useState('');
    const [ telefono, setTelefono ] = useState('');
    const [ apellido, setApellido] = useState('');
    const [ pais, setPais ] = useState('');
    const [ sexo, setSexo ] = useState('');
    const [ edad, setEdad ] = useState('');
    const [ cc, setCc ] = useState('');

    useEffect(() => {
        async function obtenerDatosDeUsuario(id) {
            try {
                const db = getFirestore();
                const userDocRef = doc(db, 'users', id);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    return userDoc.data();
                } else {
                    toast.error(`No user found with id: ${id}`);
                    return null;
                }
            } catch (error) {
                toast.error('Error fetching user data:', error);
                throw error;
            }
        }
        async function loadUserData() {
            try {
                const userData = await obtenerDatosDeUsuario(id);
                if (userData) {
                    setNombre(userData.nombre || '');
                    setTelefono(userData.telefono || '');
                    setApellido(userData.apellido || '');
                    setPais(userData.pais || '');
                    setDepartamento(userData.departamento || '')
                    setCiudad(userData.ciudad || '');
                    setDireccion_1(userData.direccion_1 || '');
                    setDireccion_2(userData.direccion_2 || '');
                    setEdad(userData.edad || '');
                    setSexo(userData.sexo || '');
                    setCc(userData.cc || '');
                }
            } catch (error) {
                toast.error("Error cargando los datos del usuario:", error);
            }
        }
        loadUserData();
    }, [id]);


    useEffect(() => {
        if (departamento) {
          setSelectedDepartment(departamento); // Asegura que selectedDepartment está sincronizado con departamento
          if (colombia[departamento]) setCities(colombia[departamento]); // Actualiza la lista de ciudades cuando se monta el componente y ya existe un departamento seleccionado.
        }
    }, [departamento]);

    useEffect(() => {
        if (selectedDepartment) {
          setCities(colombia[selectedDepartment] || []); // Actualiza la lista de ciudades cuando selectedDepartment cambia.
        }
    }, [selectedDepartment]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'departamento') {
            setDepartamento(value);
            setSelectedDepartment(value);
        }
    };

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        // Concatenar dirección para geocodificación
        const fullAddress = `${direccion_1}, ${ciudad}, ${departamento}, Colombia`;
        let latTemp, lngTemp; // Variables temporales para lat y lng
        try {
            const encodedAddress = encodeURIComponent(fullAddress);
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=AIzaSyDQnvwWu0NOA5v4NEMB0NqlQ_RSIk6dxlg`);
            const data = response.data;
            if (data.results && data.results.length > 0) {
                const { lat, lng } = data.results[0].geometry.location;
                latTemp = lat;
                lngTemp = lng;
            } else {
                toast.error("No se pudo obtener las coordenadas de la dirección proporcionada.");
                return;
            }
        } catch (error) {
            toast.error("Error al obtener las coordenadas: " + error.message);
            return;
        }
        // Define el documento a actualizar
        const userDocRef = doc(db, 'users', id);
        // Prepara los datos a actualizar
        const updatedData = {
            departamento,
            ciudad,
            direccion_1,
            direccion_2,
            latitud: latTemp,
            longitud: lngTemp,
        };
        try {
            // Actualizar el documento del usuario
            await updateDoc(userDocRef, updatedData);
            // Eliminar el ID del usuario del grupo anterior, si es necesario
            toast.success('Usuario editado con éxito');
        } catch (error) {
            console.error("Error al actualizar el usuario: ", error);
            toast.error('Error al editar el usuario: ' + error.message);
        } finally {
            setLoading(false); // Desactivar el indicador de carga
            setIsLocations (false);
        }
    };

    if (loading) return <InfoLoading/>;
    return (
        <div className="container-login">
            <div className='section-location'>
                <div className="container-location-users">
                    <div className="brand" >
                        <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4" />
                    </div>
                    <p className="subtitle2">¡Hola! Bienvenidos a SHOFi, completemos algunos datos:</p>
                    <form className="form-contact" onSubmit={handleSubmit}>
                        <section className="form-contact-dates">
                            <div className='input-date'>
                                <label>Nombre:</label>
                                <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                            </div>
                            <div className='input-date'>
                                <label>Apellido:</label>
                                <input type="text" value={apellido} onChange={(e) => setApellido(e.target.value)} />
                            </div>
                            <div className='input-date'>
                                <label>Email:</label>
                                <input type="email" value={email} disabled/>
                            </div>
                            <div className='input-date'>
                                <label>Teléfono:</label>
                                <input type="tel" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                            </div>
                            <div className='input-date'>
                                <label>Cedula CC / PEP:</label>
                                <input type="tel" name="cc" value={cc} onChange={(e) => setCc(e.target.value)} required />
                            </div>
                            <div className='input-date'>
                                <label>Sexo:</label>
                                <select value={sexo} onChange={(e) => setSexo(e.target.value)}>
                                    <option value="" disabled>Seleccione</option>
                                    <option value="hombre">Hombre</option>
                                    <option value="mujer">Mujer</option>
                                    <option value="otro">Otro</option>
                                </select>
                            </div>
                            <div className='input-date'>
                                <label>Edad:</label>
                                <input type="number" value={edad} onChange={(e) => setEdad(e.target.value)} min="18" max="99" />
                            </div>
                            <div className='input-date'>
                                <label>País:</label>
                                <input type="text" value={pais} onChange={(e) => setPais(e.target.value)} />
                            </div>
                            <div className='input-date'>
                                <label>Departamento:</label>
                                <select type="text" value={departamento} onChange={handleChange} name="departamento">
                                    <option value="" disabled>Seleccione</option>
                                    {Object.keys(colombia).map((department) => (
                                    <option key={department} value={department}>{department}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-date'>
                                <label>Ciudad:</label>
                                <select type="text" name='ciudad' value={ciudad} onChange={(e) => setCiudad(e.target.value)}>
                                    <option value="" disabled>Seleccione</option>
                                    {cities.map((city) => (
                                    <option key={city} value={city}>{city}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-date'>
                                <label>Dirección:</label>
                                <input type="text" value={direccion_1} onChange={(e) => setDireccion_1(e.target.value)} />
                            </div>
                            <div className='input-date'>
                                <label>Dirección detallada:</label>
                                <input type="text" value={direccion_2} onChange={(e) => setDireccion_2(e.target.value)} />
                            </div>
                        </section>
                        <button className='button-form' type="submit">Confirmar</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
