import "./NSectionDescription.scss"

export const NSection_Description = () => {
    return (
        <section className="section-nosotros">
            <div className="section-titles">
                <h2 className="title-page">Nuestra <span>Misión</span></h2>
                <p className="subtitle-page">
                    Habilitamos el teletrabajo de manera <span>simple e integral</span> para que empresas cumplan con la ley y mejoren el desempeño de sus equipos:
                </p>
            </div>
        </section>
    );
}