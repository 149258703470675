import { useEffect, useState } from "react";
import { collection, getDocs,query, where, } from "firebase/firestore";
import { db } from "../../../../firebase/config";
// Componentes
import "./HomeUser.scss";
import { InfoLoading, Loading } from "../../../../Loanding";
import { Rotation } from "../../../UI/atoms/Rotation/Rotation";
import { Sex } from "../../../UI/atoms/Sex/Sex";
import { MyMapComponent } from "../../../UI/atoms/MapComponent/MapComponent ";
import { TableIcon } from "../../../UI/atoms/TableIcon/TableIcon";
import { Carbon } from "../../../UI/atoms/Carbon/Carbon";
import { ContectAdminProvicional } from "./ContectAdminProvicional";
import { SectioBalance } from "../../../UI/atoms/SectionBalance/SectionBalance";

export const HomeUser = ({ idc, rol, compañia, id, setIsRegisterOpen , name, lastname, email, phone, setShouldReload, typecompany}) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        // Si idc no está definido, simplemente retorna
        if (!idc) {
            setLoading(false);
            return;
        }
        const fetchKits = async () => {
            try {
                const userCollectionRef = collection(db, 'users');
                let q;
                if (rol === "SA-001") {
                    q = userCollectionRef;
                } else {
                    q = query(userCollectionRef, where('idc', '==', idc));
                }
                const querySnapshot = await getDocs(q);
                const fetchedUsers = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
    
                if (isMounted) setUsers(fetchedUsers);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchKits();
        return () => { isMounted = false; }
    }, [idc, rol ]);


    if (!idc) return <Loading />;
    if (loading) return <Loading />;
    if (error) return <div>Error: {error.message}</div>;
    if (!users.length) return <InfoLoading/>;
    if ( rol==="AA-002" ) return <ContectAdminProvicional setShouldReload={setShouldReload} idc={idc} compañia={compañia} id={id} setIsRegisterOpen={setIsRegisterOpen} name={name} lastname={lastname} email={email} phone={phone}/>;
    return (
        <div className="home-admin">
            <div className="dashboard">
                <section className="dashboard-sec2">
                    <h3 className="subtitle2  title-sec">Activos:</h3>
                    <TableIcon idc={idc} rol={rol}/>
                </section>
                <section className="dashboard-sec1">
                    <Rotation idc={idc}/>
                    <Sex users={users} />
                </section>
                {typecompany === "C000002" 
                    ?<SectioBalance idc={idc}/>
                    :<section className="dashboard-sec3">
                        <Carbon idc={idc} />
                    </section>
                }
            </div>
            <section className="dashboard-map" >
                <MyMapComponent users={users} />
            </section>
        </div>
    );
}
