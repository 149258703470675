import "./ItenRegister.scss";

function reduceLength(inputString, maxLength) {
    if (!inputString || typeof inputString !== 'string') return ""; // Retorna cadena vacía si inputString no está definido o no es una cadena
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.slice(0, maxLength);
    }
}
export const ItenRegister = ({name, company, email, load , numbemploye ,phone, date}) => {
    const shortenedName1 = reduceLength((name || ""), 20);
    const shortenedEmail = reduceLength(email|| "", 25);

    let fechaFormateada = "";
    if(date) {
        try {
            const fecha = date.toDate(); // Convertir Timestamp a Date
            fechaFormateada = `${fecha.toLocaleDateString('es-ES')} ${fecha.toLocaleTimeString('es-ES')}`;
        } catch(e) {
            console.error("Error al convertir fecha:", e);
        }
    }
    return (
        <div  className="item-register-shofi">
            <p className="name-user">{shortenedName1}</p>
            <p className="name-company">{company}</p>
            <p className="load">{load}</p>
            <p className="email">{shortenedEmail}</p>
            <p className="employe">{numbemploye}</p>
            <p className="rol">{fechaFormateada}</p>
            <p className="phone">{phone}</p>
        </div>
    );
}
