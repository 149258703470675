import "./ListUsers.scss";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ItenUsers } from "../../molecules/ItenUsers/ItenUsers";
import { BiSortDown , BiSortUp } from "react-icons/bi";
import { MdOutlineCloudUpload } from "react-icons/md";
import { Link } from "react-router-dom";
import PerfilCompany from "../../../page/private/equipo/PerfilCompany";
import rolusers from "../../../page/private/equipo/RolUser";
import status from "../../../page/private/equipo/Status";

export const ListUsers = ({typecompany , filterOrderStatus ,setFilterOrderStatus, setSearchTerm, startDate, endDate, setStartDate, setEndDate, searchTerm ,info, setSortType , setSortDirection , setFilterRole, filterRole, handleChange , sortDirection, numberOfPages, displayItems, currentPage,  setIsCreatorUsers}) => {
    //Perfiles por compañia
    const allowedRolesCompany = PerfilCompany[typecompany];
    const filteredRolUsers  = rolusers.filter(ru =>  allowedRolesCompany.includes(ru.id));

    function findRolById(filterRole) {
        return rolusers.find(rol => rol.id === filterRole);
    }

    function findStatusById(filterOrderStatus) {
        return status.find( statu =>statu.id === filterOrderStatus);
    }


    const toggleClose = () => {
        setIsCreatorUsers(true);
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        if (new Date(endDate) < new Date(e.target.value)) {
            setEndDate('');
        }
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    return (
        <div className="list-users">
            <div className="contect-list-users">
                <div className="encabezado-users-list">
                    <input
                        className="input-search"
                        type="text"
                        placeholder="Buscar usuarios..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="encabezado-users-button">
                        <Link className='button-new2' to="/usuarios/csv">
                            <MdOutlineCloudUpload />
                            Carga masiva
                        </Link>
                        {typecompany=== "C000001"
                            ?<Link className='button-new' to="/usuarios/creador">
                                <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                                Crear
                            </Link>
                            :<button className='button-new' onClick={toggleClose}>
                                <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                                Crear
                            </button>
                        }
                        
                    </div>
                </div>
                {info && (
                    <>
                        <div className="title-user-list">
                            <p>Avatar</p>
                            <div className="filter">
                                <button onClick={() => setSortType('nombre')}>Nombre</button>
                                <button onClick={() => setSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'))}>
                                    {sortDirection === 'asc' ? <BiSortDown />  : <BiSortUp />}
                                </button>
                            </div>
                            <p>
                                Email
                            </p>
                            <div className="filter">
                                <select onChange={(e) => setFilterOrderStatus(e.target.value)}>
                                    <option value="">{!filterOrderStatus ? "Seleccione" : findStatusById(filterOrderStatus).name}</option>
                                    {status.filter(users => users.id !== filterOrderStatus).map((users) => (
                                        <option key={users.id} value={users.id}>{users.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="filter">
                                <select onChange={(e) => setFilterRole(e.target.value)}>
                                    <option value="">{!filterRole ? "Seleccione rol" : findRolById(filterRole).name_rol}</option>
                                    {filteredRolUsers.filter(users => users.id !== filterRole).map((users) => (
                                        <option key={users.id} value={users.id}>{users.name_rol}</option>
                                    ))}
                                    <option value="">Todos...</option>
                                </select>
                            </div>
                            <div className="filter">
                                <input 
                                    type="date" 
                                    value={startDate} 
                                    onChange={handleStartDateChange} 
                                />
                                <input 
                                    type="date" 
                                    value={endDate} 
                                    onChange={handleEndDateChange} 
                                    min={startDate}
                                />
                            </div>
                        </div>
                        {displayItems.length!==0
                            ?<>
                                <div className="list">
                                    {displayItems.map(users => (
                                        <ItenUsers key={users.id} {...users} />
                                    ))}
                                </div>
                                <div className="pagination-numb">
                                    <Stack spacing={2}>
                                        <Pagination count={numberOfPages} page={currentPage} onChange={handleChange} size="large" />
                                    </Stack>
                                </div>
                            </>
                            :<>
                                <div className="windons-quiz-init">
                                    <div className="quiz-init-img">
                                        <h2 className="title-shofi-loanding">No hay usuarios con estas características...</h2>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                )}
            </div>
        </div>
    );
}