import './QuizAnswer.scss';
import React from 'react';
import Stack from '@mui/material/Stack';
import { PieChart } from '@mui/x-charts/PieChart';

export const QuizAnswer = ({ respuestas }) => {
    // Colores para las respuestas
    const colores = ['#ff6384', '#36a2eb', '#ffcd56'];

    // Transforma las respuestas en un formato adecuado para la gráfica
    const data = Object.entries(respuestas).map(([label, value], index) => ({
        label: label,
        value: value,
        color: colores[index % colores.length] // Asigna un color de la lista de colores
    }));

    return (
        <div className='dashboard-section respuestas-encuesta'>
            <h2>Resultados de la Encuesta</h2>
            <div className='dates-dashboard'>
                <Stack direction="row">
                    <PieChart
                        colors={colores} 
                        series={[
                            {
                                data: data,
                                paddingAngle: 2,
                                innerRadius: 65,
                                outerRadius: 90,
                                cornerRadius: 19
                            }
                        ]}
                        margin={{ right: 5 }}
                        width={200}
                        height={200}
                        legend={{ hidden: true }}
                    />
                </Stack>
                <div className='info-dashboard'>
                    {data.map(d => (
                        <div key={d.label} className='datep'>
                            <div style={{ background: d.color, height: '20px', width: '20px', borderRadius: '50%' }}></div>
                            <p>{d.label}: {d.value}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
