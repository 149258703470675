import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import "./ProductStyle.scss"
// Importar db y storage desde tu archivo de configuración de Firebase
import { db, storage } from '../../../../../firebase/config';
import { collection, doc, getDoc, getDocs, updateDoc} from "firebase/firestore"; 
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// Alertas para componente
import { toast } from "react-toastify";
// Importar iconos
import { BiCheck, BiChevronLeft, BiPaperclip, BiX } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import { LuSave } from "react-icons/lu";
import { IoMdImages } from "react-icons/io";
import { InfoLoading } from "../../../../../Loanding";

export const EditProduct = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [ info, setInfo] = useState([]);
    const [ error, setError ] = useState(null);
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const [mainImage, setMainImage] = useState(null);
    const [mainImagePreviewUrl, setMainImagePreviewUrl] = useState(null);
    const [status, setStatus ] = useState('');
    const [formData, setFormData] = useState({
        name: "",
        SKU: "",
        brand: "",
        categoryName: "", 
        categoryId: "",
        description: "",
        price:"",
    });

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'category');
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) setInfo(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, []);


    useEffect(() => {
        const loadBlogData = async () => {
            const docRef = doc(db, "produc", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const blogData = docSnap.data();
                // Establecer los estados con los datos del blog
                setFormData({
                    name: blogData.name,
                    SKU: blogData.SKU,
                    brand: blogData.brand,
                    categoryName: blogData.categoryName, 
                    categoryId: blogData.categoryId,
                    description: blogData.description,
                    price:blogData.price,
                });
                setStatus(blogData.status);
                setMainImagePreviewUrl(blogData.img);
                setPreviewUrls(blogData.images);
            } else {
                toast.error("No such document!");
            }
        };
        loadBlogData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // Manejo especial para el selector de categorías
        if (name === 'selectcategoria') {
            const selectedCategory = info.find(category => category.id === value);
            if (selectedCategory) {
                setFormData(prev => ({
                    ...prev, 
                    categoryName: selectedCategory.name,
                    categoryId: selectedCategory.id
                }));
            }
        } else if (name === 'price') {
            const numberValue = parseFloat(value);
            if (!isNaN(numberValue)) {
                setFormData(prev => ({ ...prev, [name]: numberValue }));
            }
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleFileChange = (e) => {
        if (files.length + e.target.files.length > 4) {
            alert("Solo puedes subir un máximo de 4 imágenes");
            return;
        }
        const selectedFiles = Array.from(e.target.files);
        // Creamos un nuevo array de archivos con un identificador único para cada uno
        const newFilesWithId = selectedFiles.map(file => {
            return {
                id: new Date().getTime() + file.name,
                file: file
            };
        });
        // Filtramos los archivos que ya existen en nuestro estado
        const filteredNewFiles = newFilesWithId.filter(newFile => {
            return !files.some(existingFile => existingFile.id === newFile.id);
        });
        // Actualizamos el estado con los nuevos archivos
        setFiles(prevFiles => [...prevFiles, ...filteredNewFiles]);
        const newPreviewUrls = filteredNewFiles.map(newFile => URL.createObjectURL(newFile.file));
        setPreviewUrls(prevUrls => [...prevUrls, ...newPreviewUrls]);
    };       

    const handleRemoveFile = (index) => {
        const newFiles = files.filter((_, fileIndex) => fileIndex !== index);
        const newPreviewUrls = previewUrls.filter((_, urlIndex) => urlIndex !== index);
        setFiles(newFiles);
        setPreviewUrls(newPreviewUrls);
    }; 

    const handleMainImageChange = (e) => {
        setMainImage(null);
        const file = e.target.files[0];
        if (file) {
            setMainImagePreviewUrl(URL.createObjectURL(file));
        } else {
            setMainImagePreviewUrl(null);
        }
        setMainImage(file);
    };
    
    const handleRemoveMainImage = () => {
        setMainImage(null);
        setMainImagePreviewUrl(null);
    };   

    //---------------------------------------------------------------------------------------------------------------------------------
    // Boton desactivar
    const handleToggleStatus = async () => {
        setLoading(true); // Inicia el indicador de carga
        try {
            // Obtener referencia al documento de la encuesta
            const surveyDocRef = doc(db,"produc", id);
            // Invertir el estado actual y actualizar en Firestore
            const newStatus = !status;
            await updateDoc(surveyDocRef, {
                status: newStatus
            });
            // Actualizar el estado local
            setStatus(newStatus);
            toast.success(`Producto actulizado`);
            navigate('/productos');
        } catch (error) {
            navigate('/productos');
            toast.error("Error al cambiar el estado del producto");
        } finally {
            setLoading(false); // Finaliza el indicador de carga
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const randomId = Math.random().toString(36).substring(2, 15);
            const blogRef = doc(db, "produc", id);
            let mainImageUrl = mainImagePreviewUrl;
            if (mainImage && typeof mainImage === 'object') {
                const mainImageFileName = `main-image-${formData.name}-${new Date().toISOString()}`;
                const mainImageRef = ref(storage, `product/main_images/${mainImageFileName}`);
                await uploadBytes(mainImageRef, mainImage);
                mainImageUrl = await getDownloadURL(mainImageRef);
            }
            // Inicializa imageUrls con las URLs de las imágenes existentes
            let imageUrls = [...previewUrls];
            const newImageUrls = await Promise.all(files.map(async (file) => {
                if (file instanceof File && !previewUrls.includes(URL.createObjectURL(file))) {
                    const fileName = `image-${formData.title}-${randomId}-${new Date().toISOString()}`;
                    const fileRef = ref(storage,`product/images/${fileName}`);
                    await uploadBytes(fileRef, file);
                    return await getDownloadURL(fileRef);
                }
                return null;
            }));
            // Añade solo las nuevas URLs de imágenes al arreglo existente
            imageUrls = imageUrls.concat(newImageUrls.filter(url => url));
            const updateData = {
                name: formData.name,
                SKU: formData.SKU,
                description: formData.description,
                brand: formData.brand,
                category: formData.categoryName,
                categoryId: formData.categoryId,
                price: formData.price,
                img: mainImageUrl,// Asegúrate de que no sea undefined
                images: imageUrls || [], // Mantén las imágenes existentes si no hay nuevas
            };
            await updateDoc(blogRef, updateData); 
            toast.success(`Producto guardado con éxito: ${id}`);
            navigate('/productos');
        } catch (error) {
            toast.error("Error al guardar el prodcuto y adjuntos: ", error);
        } finally {
            setLoading(false);
        }
    };
    
    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    if (loading) return <InfoLoading />;
    return (
        <div className="foil-product">
            <div className="encabezado-list">
                <Link className='button-new' to="/productos"><BiChevronLeft /> Volver</Link>
                <h2 className="title">Nuevo producto</h2>
            </div>
            <form className="form-product" onSubmit={handleSubmit}>
                <div className="creator-form-product">
                    <section className="form-blogs-section">
                        <div className='input-date'>
                            <label>Name:</label>
                            <input name="name" value={formData.name} onChange={handleChange} required />
                        </div>
                        <div className='input-date'>
                            <label>SKU:</label>
                            <input name="SKU" value={formData.SKU} onChange={handleChange} required />
                        </div>
                        <div className='input-date'>
                            <label>Marca:</label>
                            <input name="brand" value={formData.brand} onChange={handleChange}/>
                        </div>
                        <div className='input-date'>
                            <label>Precio:</label>
                            <input name="price" type="number" value={formData.price} onChange={handleChange} required/>
                        </div>
                        <div className='input-date'>
                            <label>Categoría:</label>
                            <select name="selectcategoria" value={formData.categoryId} onChange={handleChange}>
                                <option value="" disabled hidden>Seleccione Categoria</option>
                                {info.map((category) => (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className='input-message'>
                            <label>Descripcion</label>
                            <textarea name="description" value={formData.description} onChange={handleChange} required />
                        </div>
                    </section>
                    <section className="form-blogs-section1">
                        <article className="button-load-section">
                            <h2 className="subtitle2">Cargan imagen principal</h2>
                            <div className='input-load'>
                                <label htmlFor="main-image-upload" className="custom-file-upload">
                                    <IoMdImages />Imagen
                                </label>
                                <input id="main-image-upload" type="file" accept="image/*" onChange={handleMainImageChange}/>
                            </div>
                        </article>
                        {mainImagePreviewUrl && (
                            <div className="container-input-img">
                                <div className="input-img">
                                    <img src={mainImagePreviewUrl} alt="Previsualización de la imagen principal" style={{ width: '100px', height: 'auto' }} />
                                    <button className="delete-img" onClick={handleRemoveMainImage}>
                                        <BiX />
                                    </button>
                                </div>
                            </div>
                        )}
                        <article className="button-load-section">
                            <div className='input-load'>
                                <label htmlFor="file-upload" className="custom-file-upload">
                                    <BiPaperclip />Imágenes
                                </label>
                                <input id="file-upload" type="file" accept=".jpg, .png" onChange={handleFileChange} multiple />
                            </div>
                        </article>
                        {previewUrls&&
                            <div className="container-input-img">
                                {previewUrls.map((url, index) => (
                                    <div key={index} className="input-img">
                                        <img src={url} alt={`Previsualización ${index}`} style={{ width: 'auto', height: '100px' }} />
                                        <button className="delete-img" onClick={() => handleRemoveFile(index)}>
                                            <BiX/>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        }
                    </section>
                    <div className="cotainer-button-mens">
                        <div className="botton-section">
                            <button className={status ? 'button-delete' : 'button-new'} onClick={handleToggleStatus}>
                                {status ? <BiX />: <BiCheck /> }
                                {status ? "Inactivar" : "Activar"}
                            </button>
                        </div>
                        <button className='button-new' type="submit"><LuSave />Guardar</button>
                    </div>
                </div>
            </form>
        </div>
    ); 
}