import { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../firebase/config";
import { collection, addDoc } from "firebase/firestore";
import { toast } from 'react-toastify';
import { Loading } from "../../../../../Loanding";
import { Link, useNavigate } from 'react-router-dom';
import { BiX } from "react-icons/bi";
import { PieChart } from '@mui/x-charts/PieChart';
import Stack from '@mui/material/Stack';
import { BiCheckDouble } from "react-icons/bi";

export const ReseposeAnsweGeneral = ({ id , idc, ide}) => {
    const [encuesta, setEncuesta] = useState(null);
    const [respuestas, setRespuestas] = useState({});
    const [preguntaActual, setPreguntaActual] = useState(0);
    const [subPreguntaActual, setSubPreguntaActual] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [totalMaximo, setTotalMaximo] = useState(0);
    const [totalRespuestas, setTotalRespuestas] = useState(0);
    const porcentaje = totalMaximo > 0 ? (totalRespuestas / totalMaximo) * 100 : 0;
    const pregunta = encuesta && encuesta.questions[preguntaActual];
    const subPreguntaActiva = pregunta && pregunta.activesub && subPreguntaActual[pregunta.id];
    const subPregunta = subPreguntaActiva ? pregunta.subquestions.find(sub => sub.id === subPreguntaActual[pregunta.id]) : null;
    const [divClass, setDivClass] = useState('container-button-quiz');
    const updateDivClass = (opciones) => {
        const hasLongOptions = opciones.some(opc => opc.opcione.length >= 4);
        setDivClass(hasLongOptions ? 'block-button-quiz' : 'container-button-quiz');
    };

    useEffect(() => {
        const cargarEncuesta = async () => {
            try {
                const docRef = doc(db, "quiz", ide);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setEncuesta(docSnap.data());
                } else {
                    toast.error("No se encontró la encuesta!");
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        cargarEncuesta();
    }, [ide]);

    useEffect(() => {
        if (subPregunta) {
            updateDivClass(subPregunta.opcion);
        } else if (pregunta) {
            updateDivClass(pregunta.opcion);
        }
    }, [pregunta, subPregunta]);

    useEffect(() => {
        if (encuesta) {
            let sumaMaxima = 0;
            encuesta.questions.forEach(pregunta => {
                sumaMaxima += Math.max(...pregunta.opcion.map(opc => opc.value));
                if (pregunta.activesub) {
                    pregunta.subquestions.forEach(subPregunta => {
                        sumaMaxima += Math.max(...subPregunta.opcion.map(subOpc => subOpc.value));
                    });
                }
            });
            setTotalMaximo(sumaMaxima);
        }
    }, [encuesta]);
    
    const handleRespuesta = (idPregunta, opcionSeleccionada, idSubpregunta = null) => {
        // Encuentra el valor de la opción seleccionada
        const valorSeleccionado = idSubpregunta 
        ? subPregunta.opcion.find(opc => opc.opcione === opcionSeleccionada).value
        : pregunta.opcion.find(opc => opc.opcione === opcionSeleccionada).value;
        // Preparar la nueva respuesta
        const nuevaRespuesta = { opcione: opcionSeleccionada, value: valorSeleccionado };
        // Actualizar el estado de respuestas
        setRespuestas(prevRespuestas => {
            const respuestasActualizadas = { ...prevRespuestas };
            if (!respuestasActualizadas[idPregunta]) {
                respuestasActualizadas[idPregunta] = { respuesta: {}, subquestions: {} };
            }
            if (idSubpregunta) {
                // Si estamos respondiendo a una subpregunta
                respuestasActualizadas[idPregunta].subquestions[idSubpregunta] = { respuesta: nuevaRespuesta };
            } else {
                // Si estamos respondiendo a una pregunta principal
                respuestasActualizadas[idPregunta] = { respuesta: nuevaRespuesta, subquestions: respuestasActualizadas[idPregunta].subquestions };
            }
            return respuestasActualizadas;
        });
        // Manejar la navegación entre preguntas y subpreguntas
        if (!idSubpregunta && encuesta.questions[preguntaActual].activesub) {
            if (opcionSeleccionada === encuesta.questions[preguntaActual].controlResponse) {
                // Si la respuesta coincide con controlResponse, muestra la primera subpregunta
                setSubPreguntaActual({ [idPregunta]: encuesta.questions[preguntaActual].subquestions[0].id });
            } else {
                // Si no coincide, salta a la siguiente pregunta principal
                setPreguntaActual(prev => prev + 1);
                setSubPreguntaActual({}); // Asegúrate de reiniciar las subpreguntas
            }
        } else if (idSubpregunta) {
            // Manejo de las subpreguntas (igual que antes)
            const subPreguntas = encuesta.questions[preguntaActual].subquestions;
            const indexActual = subPreguntas.findIndex(sub => sub.id === idSubpregunta);
            if (indexActual < subPreguntas.length - 1) {
                setSubPreguntaActual({ ...subPreguntaActual, [idPregunta]: subPreguntas[indexActual + 1].id });
            } else {
                setPreguntaActual(prev => prev + 1);
                setSubPreguntaActual({});
            }
        } else {
            // Si no hay subpreguntas, simplemente avanza a la siguiente pregunta principal
            setPreguntaActual(prev => prev + 1);
        }
    };
    
    useEffect(() => {
        let puntajeTotal = 0;
    
        // Iterar sobre cada pregunta y sus subpreguntas
        for (const idPregunta in respuestas) {
            const pregunta = respuestas[idPregunta];
    
            // Sumar el valor de la respuesta principal (si existe)
            if (pregunta.respuesta && pregunta.respuesta.value) {
                puntajeTotal += parseInt(pregunta.respuesta.value);
            }
    
            // Sumar los valores de las respuestas de las subpreguntas (si existen)
            if (pregunta.subquestions) {
                for (const idSubpregunta in pregunta.subquestions) {
                    const subpregunta = pregunta.subquestions[idSubpregunta];
                    if (subpregunta.respuesta && subpregunta.respuesta.value) {
                        puntajeTotal += parseInt(subpregunta.respuesta.value);
                    }
                }
            }
        }
        setTotalRespuestas(puntajeTotal)
        // Aquí puedes hacer lo que necesites con el puntaje total, como actualizar un estado.
    }, [respuestas]); // Este useEffect se ejecuta cada vez que cambia el objeto de respuestas
    

    // Preparar los datos para la gráfica circular
    const porcentajeRedondeado = Math.round(porcentaje); // Redondea el porcentaje a un número entero
    const porcentajeRestanteRedondeado = 100 - porcentajeRedondeado; 
    const datosGrafica = [
        { label: 'Positivas', value: porcentajeRedondeado },
        { label: 'Negativas', value: porcentajeRestanteRedondeado }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const respuestaDoc = {
                idu: id,
                idc: idc,
                ide: ide,
                respuestas: respuestas,
                fecha: new Date(),
                resultado:porcentaje,
                title:encuesta.title,
                initial_diagnostic: encuesta.initial_diagnostic,
            };
            const docRef = await addDoc(collection(db, 'quizAnswer'), respuestaDoc);
            // Obtén la referencia al documento en la colección 'alert'
            const alertDocRef = doc(db, 'alert', id);
            // Obtener el documento actual
            const alertDocSnapshot = await getDoc(alertDocRef);
            if (alertDocSnapshot.exists()) {
                const alertData = alertDocSnapshot.data();
                // Asumiendo que 'notification' es un array en tus datos de Firestore
                const notificationsArray = alertData.notification;
                // Encuentra y actualiza el objeto específico dentro del array
                const updatedNotifications = notificationsArray.map(notification => {
                    if (notification.id === ide) {
                        // Aquí realizas las modificaciones necesarias en el objeto
                        return { ...notification, route: `/encuesta/resultado/${docRef.id}` };
                    }
                    return notification;
                });
                // Actualiza el documento con el array modificado
                await updateDoc(alertDocRef, { notification: updatedNotifications });
                // Aquí actualizas el documento en la colección 'quiz'
                const quizDocRef = doc(db, 'quiz', ide);
                await updateDoc(quizDocRef, {
                    send: true
                });
                // Agregar algún mensaje de éxito o redirección
                navigate('/encuestas');
                toast.success("Respuestas guardadas con éxito");
            } else {
                toast.error("Documento de alerta no encontrado");
            }
        } catch (error) {
            toast.error("Error al guardar respuestas: ", error);
        }
    };
    

    if (loading) return <Loading/>;
    if (error) return <p>Error: {error.message}</p>;
    if (!encuesta) return <p>No user info</p>;
    return (
        <>
            {preguntaActual !== encuesta.questions.length && (
                <Link className="button-delete-x"  to="/encuesta/lista"><BiX/></Link>
            )}
            {subPregunta ? (
                // Mostrar solo la subpregunta activa
                <section className="wimdons-quiz">
                    <div key={subPregunta.id} className="wimdons-quiz-questions">
                        <p className={subPregunta.text.length >= 100 ? "title2": "title"}>{subPregunta.text}</p>
                        <div className={divClass}>
                            {subPregunta.opcion.map((subOpc) => (
                                <button
                                    key={subOpc.id}
                                    onClick={() => handleRespuesta(pregunta.id, subOpc.opcione, subPregunta.id)}
                                >
                                        <p className={subOpc.opcione.length >= 4 ? 'contect-quiz' : 'contect-quiz2'}>
                                            {subOpc.opcione}
                                        </p>
                                </button>
                            ))}
                        </div>
                    </div>
                </section>
            ) : (
                // Mostrar la pregunta principal si no hay subpregunta activa
                pregunta && (
                    <section className="wimdons-quiz">
                        <div key={pregunta.id} className="wimdons-quiz-questions">
                            <p className={pregunta.text.length >= 100 ? "title2": "title"}>{pregunta.text}</p>
                            <div className={divClass}>
                                {pregunta.opcion.map((opc) => (
                                    <button className={opc.opcione.length >= 4? 'contect-quiz' : 'contect-quiz2'}
                                        key={opc.id}
                                        onClick={() => handleRespuesta(pregunta.id, opc.opcione)}
                                    >
                                        <p className={opc.opcione.length >= 4 ? 'contect-quiz' : 'contect-quiz2'}>
                                            {opc.opcione}
                                        </p>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </section>
                )
            )}
            {preguntaActual >= encuesta.questions.length && (
                <section className="wimdons-quiz-contador">
                    <div className="wimdons-quiz-questions">
                        <p className="title-result">Resultados de la encuesta:<br></br><span>{encuesta.title}</span></p>
                        <div className='dates-dashboard'>
                            <Stack direction="row" className="grad">
                                <PieChart
                                    colors={['#1E67E2', '#85D0D5']} // Colores de tu elección
                                    series={[{
                                        paddingAngle: 1,
                                        innerRadius: 100,
                                        outerRadius: 120,
                                        cornerRadius: 19,
                                        data: datosGrafica,
                                    }]}
                                    margin={{ right: 5 }}
                                    width={250}
                                    height={250}
                                    legend={{ hidden: true }}
                                />
                            </Stack>
                            <h2 className="title  result">{porcentaje.toFixed(0)}%</h2>
                        </div>
                        <button className="button-result" onClick={handleSubmit}><BiCheckDouble />Finalizar</button>
                    </div>
                </section>
            )}
        </>
    );
};
