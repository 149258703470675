import "./ListStatusDelivery.scss";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ItenUsersStatus } from "../../molecules/ItenUsersStatus/ItenUsersStatus";
import {GeneratePdfButton} from '../../organisms/PDF/GenerateTeamStatus';
import { useState } from "react";

export const ListStatusDelivery = ({ compañia , info , handleChange , numberOfPages, displayItems, currentPage, selectedUsers, setSelectedUsers, orden}) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredEquipment = displayItems.filter(user => (
        user.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.apellido.toLowerCase().includes(searchTerm.toLowerCase()))
    );


    // Función para manejar clics en los usuarios
    const handleUserClick= (id) => {
        setSelectedUsers(prevSelectedUsers =>
            prevSelectedUsers.includes(id)
            ? prevSelectedUsers.filter(userId => userId !== id) // Si ya está seleccionado, lo quitamos
            : [...prevSelectedUsers, id] // Si no está seleccionado, lo agregamos
        );
    };

    return (
        <div className="list-users">
            <div className="contect-list-users">
                <div className="encabezado-list">
                    <input
                        className="input-search"
                        type="text"
                        placeholder="Buscar usuarios..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {selectedUsers.length !==0 &&<GeneratePdfButton orden={orden} />}
                </div>
                {info && (
                    <div>
                        <div className="title-user">
                            <p>Avatar</p>
                            <div className="filter">
                                <p>Nombre</p>
                            </div>
                            <p>
                                Email
                            </p>
                            <p>
                                Seller
                            </p>
                            <div className="filter">
                                Ordenes
                            </div>
                            <div className="filter">
                                <p>Fecha de Creación</p>
                            </div>
                        </div>
                        <div className="list">
                            {filteredEquipment.map(user => (
                                <ItenUsersStatus
                                    key={user.id}
                                    {...user}
                                    onClick={() => handleUserClick(user.id)}
                                    isSelected={selectedUsers.includes(user.id)}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className="pagination-numb">
                <Stack spacing={2}>
                    <Pagination count={numberOfPages} page={currentPage} onChange={handleChange} size="large" />
                </Stack>
            </div>
        </div>
    );
}