import "./StarcoIversion.scss"
/* eslint-disable react/jsx-no-target-blank */
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineLike } from "react-icons/ai";
import { AiOutlineDislike } from "react-icons/ai";
import { BiMailSend } from "react-icons/bi";
import { toast } from "react-toastify";
// Componentes
import { InfoLoading } from "../../../../Loanding";
import { db, storage } from "../../../../firebase/config";
import QRCode from "qrcode";
import { getDownloadURL, ref, uploadString } from "firebase/storage";


const SEND_EMAIL_FUNCTION_URL = "https://us-central1-goshofi-c578e.cloudfunctions.net/sendCalendarInversor";

async function sendCalendarInversor(data) {
    try {
        const response = await fetch(SEND_EMAIL_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (response.ok) {
            console.log("Email enviado con exito");
        } else {
            console.log("Error de envio email:", response.statusText);
            const errorBody = await response.text();
            console.log("Error body:", errorBody);
        }
    } catch (error) {
        console.log("Error de envio email:", error);
    }
}


const SEND_EMAIL_FUNCTION_URL_DECLINADO = "https://us-central1-goshofi-c578e.cloudfunctions.net/sendDeclinadoInversor";

async function sendDeclinadoInversor(data) {
    try {
        const response = await fetch(SEND_EMAIL_FUNCTION_URL_DECLINADO, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (response.ok) {
            console.log("Email enviado con exito");
        } else {
            console.log("Error de envio email:", response.statusText);
            const errorBody = await response.text();
            console.log("Error body:", errorBody);
        }
    } catch (error) {
        console.log("Error de envio email:", error);
    }
}

export const StartcoReserve = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [ info, setInfo] = useState([]);
    const [ cowor, setCowor] = useState([]);
    const [ sales, setSales] = useState([]);
    const [ inversor, setInversor ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ reserve, setReserve ] = useState();
    const [ error, setError ] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [availableTimeOptions, setAvailableTimeOptions] = useState([]);
    const [formData, setFormData] = useState({
        approval:'',
        fecha_reserve:'',
        hora_reserve: '',
    });

    const toggleReserve = () => {
        setReserve(true);
        setFormData({
            approval:true,
        });
    };

    const toggleCancel= () => {
        setReserve(false);
        setAvailableTimeOptions([]);
        setFormData({
            fecha_reserve:'',
            hora_reserve: '',
            approval:false,
        });
    };

    // Función para generar las opciones de hora
    const generateTimeOptions = (start, end) => {
        const options = [];
        let [startHours, startMinutes] = start.split(':').map(Number);
        const [endHours, endMinutes] = end.split(':').map(Number);
        while (startHours < endHours || (startHours === endHours && startMinutes < endMinutes)) {
            // Calcula la hora de finalización del intervalo actual
            let endIntervalMinutes = startMinutes + 30;
            let endIntervalHours = startHours;
            // Ajusta las horas y minutos si los minutos superan 59
            if (endIntervalMinutes >= 60) {
                endIntervalHours++;
                endIntervalMinutes = 0;
            }
            // Formatea la hora de inicio y fin del intervalo actual
            const startTime = `${startHours.toString().padStart(2, '0')}:${startMinutes.toString().padStart(2, '0')}`;
            const endTime = `${endIntervalHours.toString().padStart(2, '0')}:${endIntervalMinutes.toString().padStart(2, '0')}`;
            // Añade la opción al arreglo, representando el intervalo
            const timeInterval = `${startTime} - ${endTime}`;
            options.push(<option key={timeInterval} value={timeInterval}>{timeInterval}</option>);
            // Actualiza startHours y startMinutes para el próximo intervalo
            startHours = endIntervalHours;
            startMinutes = endIntervalMinutes;
        }

        return options;
    };

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }, []);

    const loadReservationsForDate = async (selectedDate) => {
        const reservationsRef = collection(db, "reserve_inversor");
        const q = query (reservationsRef, where("fecha_reserve", "==", selectedDate));
        const querySnapshot = await getDocs (q);
        const reservedHours = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.id_inverstor === info.id_inverstor) {
                reservedHours.push(data.hora_reserve);
            }
        });
        return reservedHours;
    };

    const handleDateChange = async (dateOption) => {
        setFormData(prevState => ({
            ...prevState,
            fecha_reserve: dateOption.value
        }));
        const reservedHours = await loadReservationsForDate (dateOption.value);
        const hourOptionsForSelectedDate = inversor.hourOptions ? inversor.hourOptions[dateOption.value] : null;
        if (hourOptionsForSelectedDate) {
            let newTimeOptions = generateTimeOptions(hourOptionsForSelectedDate.start, hourOptionsForSelectedDate.end);
            newTimeOptions = newTimeOptions.filter(option => !reservedHours.includes(option.props.value));
            setAvailableTimeOptions(newTimeOptions);
        }
    };

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const docRef = doc(db, 'reserve_inversor', id);
                const docSnapshot = await getDoc(docRef);
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    if (isMounted) setInfo(data);
                }
            } catch (err) {
                if (isMounted) setError(err);
            }
        };
        fetchInfo();
        let isInveror = true;
        if (info.id_inverstor) {
            const fetchInversor = async () => {
                try {
                    const docRef = doc(db, 'startco_inversion', info.id_inverstor);
                    const docSnapshot = await getDoc(docRef);
                    if (docSnapshot.exists()) {
                        const data = docSnapshot.data();
                        setInversor(data);
                        setSelectedDates(data.dayOptions || []);
                    }
                } catch (err) {
                    if (isInveror) setError(err);
                }
            };
            fetchInversor();
            return () => { isInveror = false; };
        }
        setLoading(false)
        return () => { isMounted = false; };
    }, [id , info.id_inverstor]);


    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const docRef = doc(db, 'reserve_space', id);
                const docSnapshot = await getDoc(docRef);
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    if (isMounted) setCowor(data);
                }
            } catch (err) {
                if (isMounted) setError(err);
            }
        };
        fetchInfo();
        setLoading(false)
        return () => { isMounted = false; };
    }, [id]);

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const docRef = doc(db, 'reserve_allies', id);
                const docSnapshot = await getDoc(docRef);
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    if (isMounted) setSales(data);
                }
            } catch (err) {
                if (isMounted) setError(err);
            }
        };
        fetchInfo();
        setLoading(false)
        return () => { isMounted = false; };
    }, [id]);

    // Función de ejemplo para generar el código QR como Data URL
    const generateQRCodeDataURL = async (text) => {
        return QRCode.toDataURL(text);
    };

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const blogRef = doc(db, "reserve_inversor", id);
            await updateDoc(blogRef, formData); // Asegúrate de que formData contiene los campos actualizados, incluido 'approval'
            const updatedDocRef = doc(db, "reserve_inversor", id);
            const docSnap = await getDoc(updatedDocRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                data.lead = inversor.lead;
                data.contect =inversor.contect;
                const qrCodeDataURL = await generateQRCodeDataURL(`https://goshofi.com/startco/reservas/${id}`);
                // Guarda el QR en Firebase Storage
                const qrImageRef = ref(storage , `reserveStarco/${id}.png`);
                const response = await uploadString(qrImageRef, qrCodeDataURL, 'data_url');
                const qrUrl = await getDownloadURL(response.ref);
                // Actualiza formData con el URL del QR y envía el correo
                data.routeUrl=`https://goshofi.com/startco/reservas/${id}`
                data.qrUrl = qrUrl;
                if(data.approval) {
                    await sendCalendarInversor(data);
                } else {
                    await sendDeclinadoInversor(data);
                }
            }
            toast.success(`Guardado con éxito: ${id}`);
            navigate('/startco/inversionista');
        } catch (error) {
            toast.error("Error al guardar: ", error);
        } finally {
            setLoading(false);
        }
    }, [formData, id, navigate, inversor]);


    if (loading) return <InfoLoading/>;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (
        <div className="equipo-admin">
            <div className="encabezado-list">
                <button className='button-new' onClick={() => navigate(-1)}>
                    <BiChevronLeft /> Volver
                </button>
                <h2 className="title">Información </h2>
            </div>
            {info.name&&
                <div className='scroll-equip-admin'>
                    <section className="file-reserve-startco">
                        <div className="file-reserve">
                            <p><span>Nombre & Apellido:</span> {info.name} {info.lastname}</p>
                            <p><span>Compañia:</span> {info.company}</p>
                            <p><span>Cargo:</span> {info.load}</p>
                            <p><span>Ciudad de Origen:</span> {info.city}</p>
                        </div>
                        <div className="file-reserve">
                            <p><span>Email:</span> {info.email}</p>
                            <p><span>Teléfono:</span> {info.phone}</p>
                            <p><span>Paises que opera:</span> {info.countries}</p>
                            <a href={info.url} target="_blank"><span>Web:</span> {info.url}</a>
                        </div>
                        <div className="file-reserve">
                            <p><span>Número de empleados:</span> {info.numbemploye}</p>
                            <p><span>Tipo de Startup:</span> {info.tipe_startup}</p>
                            <p><span>Ultima inversión(Si aplica):</span> {info.ronde_startup}</p>
                            <p><span>Facturación del ultimo año:</span> {info.ronde_entry}</p>
                            <p><span>Inversionista citado:</span> {inversor.lead}</p>
                        </div>
                        <div className="file-reserve">
                            <p><span>Decripción:</span> {info.description}</p>
                        </div>
                    </section>
                    { typeof info.approval !== "boolean"
                        ?<section className="file-reserve-notification">
                            <div className="button-reserve">
                                <button className="button-new" onClick={toggleReserve}>
                                    <AiOutlineLike />
                                    Aceptar
                                </button>
                                <button className="button-delete" onClick={toggleCancel}>
                                    <AiOutlineDislike />
                                    Declinar
                                </button>
                            </div>
                            <div className="file-reserve">
                                {reserve &&
                                    <div className='input-startco'>
                                        <div className='input-date'>
                                            <label>Fecha de reserva:</label>
                                            {selectedDates.map((option, index) => (
                                                <button
                                                    key={index}
                                                    type="button"
                                                    className={`${formData.fecha_reserve === option.value ? "date-selection-button" : "date-selection-button-send"}`}
                                                    onClick={() => handleDateChange({ label: option.label, value: option.value })}
                                                >
                                                    {option.label}
                                                </button>
                                            ))}
                                        </div>
                                        <div className='input-date'>
                                        <label>Hora de reserva:</label>
                                            <select name="hora_reserve" value={formData.hora_reserve} onChange={handleChange} required>
                                                <option value="">Seleccione disponibles</option>
                                                {availableTimeOptions.map((option, index) => (
                                                    <option key={index} value={option.key}>
                                                        {option.key}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                }
                            </div>
                            <>
                                {(reserve === true || reserve === false )&&
                                    <button className="button-new"
                                    onClick={handleSubmit}
                                >
                                    <BiMailSend  />
                                    Enviar Notficación
                                </button>
                                }
                            </>
                        </section>
                        :<section className="file-reserve-startco">
                            {info.approval === true
                                ?<>
                                    <div className="file-reserve">
                                        <p><span>Dia de reservado:</span> {info.fecha_reserve}</p>
                                    </div>
                                    <div className="file-reserve">
                                        <p><span>Hora de reservado:</span> {info.hora_reserve}</p>
                                    </div>
                                </>
                                :<div className="file-reserve">
                                    <p><span>Estatus:</span> Propuesta declinada</p>
                                </div>
                            }
                        </section>
                    }
                </div>
            }
            {cowor.name&&
                <div className='scroll-equip-admin'>
                    <section className="file-reserve-startco">
                        <div className="file-reserve">
                            <p><span>Nombre & Apellido:</span> {cowor.name} {cowor.lastname}</p>
                            <p><span>Compañia:</span> {cowor.company}</p>
                            <p><span>Cargo:</span> {cowor.load}</p>
                            <p><span>Email:</span> {cowor.email}</p>
                            <p><span>Teléfono:</span> {cowor.phone}</p>
                        </div>
                        <div className="file-reserve">
                            <p><span>Mesa:</span> {cowor.tableNumber}</p>
                            <p><span>Día:</span> {cowor.date}</p>
                            <p><span>Horario:</span> {cowor.time}</p>
                        </div>
                    </section>
                </div>
            }
            {sales.name&&
                <div className='scroll-equip-admin'>
                    <section className="file-reserve-startco">
                        <div className="file-reserve">
                            <p><span>Nombre & Apellido:</span> {sales.name} {sales.lastname}</p>
                            <p><span>Compañia:</span> {sales.company}</p>
                            <p><span>Cargo:</span> {sales.load}</p>
                            <p><span>Email:</span> {sales.email}</p>
                            <p><span>Teléfono:</span> {sales.phone}</p>
                        </div>
                        <div className="file-reserve">
                            <p><span>Número invitados:</span> {sales.numbguests}</p>
                            <p><span>Día:</span> {sales.date}</p>
                            <p><span>Horario:</span> {sales.time}</p>
                        </div>
                    </section>
                    <section className="file-reserve-startco">
                        {sales.guests.map(option => (
                            <div className="file-reserve">
                                <p><span>Nombre & Apellido:</span> {sales.name}</p>
                                <p><span>Compañia:</span> {sales.company}</p>
                                <p><span>Email:</span> {sales.email}</p>
                                <p><span>Teléfono:</span> {sales.phone}</p>
                            </div>
                        ))}
                    </section>
                </div>
            }
        </div>
    );
}
