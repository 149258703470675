import "./DeliveryStatus.scss";
import { BiMessageAltError ,  BiPackage, BiHome , BiRocket , BiLoader , BiMessageAltX} from "react-icons/bi";

export const DeliveryStatus = ({info}) => {
    // Impresion de status de envio o la orden
    let status;
    if ( info === 0)  {
        status = <p className="verification"><BiMessageAltError /> Verificación</p>;
    } else if ( info === 1 ||info === 2|| info === 15 ||  info === 13 || info === 14 || info === 4) {
        status = <p className="preparation">< BiPackage /> Preparación</p>;
    } else if ( info === 5 || info === 12 || info === 11) {
        status = <p className="route"><BiRocket /> En camino</p>;
    } else if ( info === 6 ) {
        status = <p className="delivered"><BiHome /> Entregado</p>;
    } else if ( info === 7 || info === 8 ) {
        status = <p className="cancelled"><BiMessageAltX /> Cancelado</p>;
    } else if ( info === "" ) {
        status = <p className="loanding">... Cargando</p>;
    } else {
        status = <p className="unassigned"><BiLoader /> Sin asignar</p>;
    }
    return (
        <div>
            {status}
        </div>
    );
};