import "./TableIcon.scss";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase/config";

export const TableIcon = ({idc, rol}) => {
    const [ info, setInfo ] = useState ([])

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                if (!idc) throw new Error("Company undefined");
                const infoCollectionRef = collection(db, 'products-assignment');
                let q
                    if (rol === "SA-001"){
                        q = query(infoCollectionRef, where('status', '==', true));
                    } else {
                        q = query(infoCollectionRef, where('idc', '==', idc), where('status', '==', true));
                    }
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) setInfo(fetchedInfo);
            } catch (err) {
                console.error(`Error al buscar los productos`, err);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, [idc, rol]);

    // Suponiendo que `products` es el estado que contiene todos los productos obtenidos
    const sillasCount = info.filter(product => product.category === "tBrwItGC8uUbSOhiBQXJ").length;
    const escritoriosCount = info.filter(product => product.category === "l00z7GUbpol1VlgQXcDG").length;
    const computadorCount = info.filter(product => product.category === "oKANbrQXHNeFdJWse0po").length;
    // Calcular la cantidad de productos que no son Sillas, Escritorios, ni Computadores
    const articulosCount = info.filter(product => 
        product.category !== "tBrwItGC8uUbSOhiBQXJ" &&
        product.category !== "l00z7GUbpol1VlgQXcDG" &&
        product.category !== "oKANbrQXHNeFdJWse0po"
    ).length;

    return (
        <div className="section-sec-status">
            <div className="sec-status">
                <img className="silla" alt="silla" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fhome%2Fsilla.png?alt=media&token=fb350dc5-592e-46b0-b32f-3b5af66f5051&_gl=1*15swx5x*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTA0NzgyOC4zNjcuMS4xNjk5MDQ3OTA3LjQ0LjAuMA.." />
                <p>Sillas</p>
                <p className="cant-sec">{sillasCount}</p>
            </div>
            <div className="sec-status">
                <img className="escritorio" alt="escritorio" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fhome%2Fescritorio.png?alt=media&token=b8f62cbf-5323-4b33-be64-7bfa55feeed2&_gl=1*11iugn1*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTA0NzgyOC4zNjcuMS4xNjk5MDQ3OTAwLjUxLjAuMA.." />
                <p>Escritorio</p>
                <p className="cant-sec">{escritoriosCount}</p>
            </div>
            <div className="sec-status">
                <img className="accesorio" alt="accesorio" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fhome%2Flampara.png?alt=media&token=47bae32d-d839-4c83-a020-09748b2bbfdb&_gl=1*1uthe7g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTA0NzgyOC4zNjcuMS4xNjk5MDQ4Mjg1LjU3LjAuMA.." />
                <p>Accesorios</p>
                <p className="cant-sec">{articulosCount}</p>
            </div>
            <div className="sec-status">
                <img className="computador" alt="computador" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fhome%2F3d_laptop-PhotoRoom.png-PhotoRoom%20(1)%20(1).png?alt=media&token=30b7f16a-e082-414f-880a-60e1d18369b7&_gl=1*wlnyax*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTA0NzgyOC4zNjcuMS4xNjk5MDQ4NzIyLjUwLjAuMA.." />
                <p>Computadores</p>
                <p className="cant-sec">{computadorCount}</p>
            </div>
        </div>
    );
}
