import { useEffect, useState } from "react";
import { InfoLoading } from "../../../../Loanding";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { ListCowork } from "../../../UI/organisms/ListCowork/ListCowork";

export const StartcoListSales = () => {
    const itemsPerPage = 10;
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);
    const [ info, setInfo] = useState([]);
    const [ currentPage, setCurrentPage ] = useState(1);

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'reserve_allies');
                const querySnapshot = await getDocs(q);
                if (isMounted) {
                    const items = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setInfo(items);
                }
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => {
            isMounted = false;
        };
    }, []);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    const numberOfPages = Math.ceil( info.length / itemsPerPage);
    const displayItems = info.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    if (loading) return <InfoLoading/>;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (
        <>
            { info && ( info.length!==0)
                ?<div className="equipo-admin">
                    <ListCowork
                        info = {info}
                        handleChange = {handleChange}
                        displayItems = {displayItems}
                        numberOfPages = {numberOfPages}
                        currentPage = {currentPage}
                    />
                </div>
                :<div className="windons">
                    <div className="encabezado-list">
                        <h2 className="title">STARCO: 2024</h2>
                    </div>
                    <div className="windons-quiz-init">
                        <div className="section-quiz-init">
                            <div className="quiz-init-img">
                                <img alt="Shofi Encuestas" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2FPA_Education_Study_001_Jane-PhotoRoom.png-PhotoRoom.png?alt=media&token=5dcc3fdc-e433-466c-a9e1-d9fa21789220&_gl=1*1q7d33j*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI1OTcwLjE5LjAuMA.."/>
                                <h2 className="title-shofi-loanding">Esperemos reserva<br></br>de espacios de inversinistas.</h2>
                            </div>
                            <div className="quiz-init-contect">
                                <Link to={"/startco/inversionistas/creador"} className='button-new'>
                                    <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                                    Crear Expositor
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}