import { useEffect, useState } from "react";
import "./Encuestas.scss"; 
import { collection, addDoc, getDocs} from 'firebase/firestore';
import { db } from "../../../../firebase/config";
import { Link, useNavigate } from 'react-router-dom';
import { BiChevronLeft, BiCustomize, BiPlus, BiSave, BiX } from "react-icons/bi";
import { InfoLoading, Loading } from "../../../../Loanding";
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";

export const CreadorEncuestas = ({idc, rol , compañia}) => {
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [ info, setInfo] = useState([]);
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ compania, setCompania ] = useState('');
    const [ idcSelect, setIdcSelect ] = useState('');
    const [ questions, setQuestions ] = useState([
        { 
            id: uuidv4(),
            text: '', 
            value: '0',
            opcion: [
                { opcione: '', value: '' },
                { opcione: '', value: '' }
            ],
            binding: true,
            activesub: true,
            controlResponse: '',
            subquestions: [
                {
                    id: uuidv4(),
                    text:'',
                    binding: true,
                    value:0,
                    opcion: [
                        { opcione: '', value: '' },
                        { opcione: '', value: '' }
                    ],
                }
            ],
        }
    ]);

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'company');
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) setInfo(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, []);

    // Activar de desactivar sub Preguntas
    const handleToggleSubquestion = (indexQuestion) => {
        const newQuestions = [...questions];
        newQuestions[indexQuestion].activesub = !newQuestions[indexQuestion].activesub;
        setQuestions(newQuestions);
    };
    //----------------------------------------------------------------------------------------------------------------------------------
    // Botton para agregar o eliminar preguntas y editar value de preguntas
    // Nueva Pregunta
    const handleAddQuestion = (index, newText, newValue) => {
        const newQuestions = [...questions];
        newQuestions[index].text = newText;
        const numericValue = parseInt(newValue, 10);
        if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 10) {
            newQuestions[index].value = numericValue.toString(); // o simplemente newValue si quieres mantenerlo como string
        }
        setQuestions(newQuestions);
    };
    // Pregunta editar
    const handleNewQuestion = () => {
        setQuestions([...questions, { 
            id: uuidv4(), 
            text: '', 
            value:'0',
            opcion: [
                { opcione: '', value:'0'},
                { opcione: '', value: '0'}
            ],
            binding: true,
            activesub: false,
            controlResponse: '',
            subquestions: [
                {
                    id: uuidv4(), 
                    text:'',
                    binding: true,
                    value:'0',
                    opcion: [
                        { opcione: '', value: '0'},
                        { opcione: '', value: '0'}
                    ],
                }
            ],
        }]);
    };    

    // Eliminar Pregunta
    const handleDeleteQuestion = (indexQuestion) => {
        const newQuestions = [...questions];
        newQuestions.splice(indexQuestion, 1);
        setQuestions(newQuestions);
    };
    //----------------------------------------------------------------------------------------------------------------------------------
    // Botton para agregar o eliminar opciones y editar value de opciones
     // Nueva opcion
    const handleNewOpcion = (indexQuestion) => {
        const newQuestions = [...questions];
        newQuestions[indexQuestion].opcion.push({ opcione: '', value: '0' });
        setQuestions(newQuestions);
    };
    // Opcion editar
    const handleAddOpcion = (indexQuestion, indexOpcion, newOpcionText, newOpcionValue) => {
        const newQuestions = [...questions];
        const updatedOpcion = {
            ...newQuestions[indexQuestion].opcion[indexOpcion],
            opcione: newOpcionText,
            value: newOpcionValue,
        };
        newQuestions[indexQuestion].opcion[indexOpcion] = updatedOpcion;
        setQuestions(newQuestions);
    };
    // Eliminar Opcines
    const handleDeleteOpcion = (indexQuestion, indexOpcion) => {
        const newQuestions = [...questions];
        newQuestions[indexQuestion].opcion.splice(indexOpcion, 1);
        setQuestions(newQuestions);
    }; 
    //----------------------------------------------------------------------------------------------------------------------------------
    // Botton para agregar o eliminar opciones y editar value de subpreguntas
    // Nueva SubPregunta
    const handleNewSubquestion = (indexQuestion) => {
        const newQuestions = [...questions];
        const newSubquestion = { id: uuidv4(), value:'0',  text: '', binding: true, opcion: [{ opcione: '', value: '0' },{ opcione: '', value: '0' }] };
        newQuestions[indexQuestion].subquestions.push(newSubquestion);
        setQuestions(newQuestions);
    };
    //Editar SubPregunta
    const handleEditSubquestion = (indexQuestion, indexSubquestion, newText, newValue) => {
        const newQuestions = [...questions];
        const subquestion = newQuestions[indexQuestion].subquestions[indexSubquestion];
        subquestion.text = newText;
        subquestion.value = newValue;
        setQuestions(newQuestions);
    };
    // Eliminar SubPregunta
    const handleDeleteSubquestion = (indexQuestion, indexSubquestion) => {
        const newQuestions = [...questions];
        newQuestions[indexQuestion].subquestions.splice(indexSubquestion, 1);
        setQuestions(newQuestions);
    };
    //----------------------------------------------------------------------------------------------------------------------------------
    // Botton para agregar o eliminar opciones y editar value de sub opciones
    //Nueva SubOpcion
    const handleNewSubquestionOption = (indexQuestion, indexSubquestion) => {
        const newQuestions = [...questions];
        newQuestions[indexQuestion].subquestions[indexSubquestion].opcion.push({ opcione: '', value: '0' });
        setQuestions(newQuestions);
    };
    //Editar SubOpcion
    const handleEditSubquestionOption = (indexQuestion, indexSubquestion, indexOption, newOptionText, newOptionValue) => {
        const newQuestions = [...questions];
        let subquestion = newQuestions[indexQuestion].subquestions[indexSubquestion];
        let option = subquestion.opcion[indexOption];
        option.opcione = newOptionText;
        option.value = newOptionValue;
        setQuestions(newQuestions);
    };
    //Eliminar SubOpcion
    const handleDeleteSubquestionOption = (indexQuestion, indexSubquestion, indexOption) => {
        const newQuestions = [...questions];
        let subquestion = newQuestions[indexQuestion].subquestions[indexSubquestion];
        subquestion.opcion.splice(indexOption, 1);
        setQuestions(newQuestions);
    };


    const handleCheckboxChange = (indexQuestion, indexOpcion) => {
        const updatedQuestions = questions.map((question, index) => {
            if (index === indexQuestion) {
                // Establecer la respuesta de control basada en el checkbox seleccionado
                const controlResponse = question.opcion[indexOpcion].opcione;
                return { ...question, controlResponse };
            }
            return question;
        });
        setQuestions(updatedQuestions);
    };
    const handleChange = (e) => {
        const companyId = e.target.value;
        const company = info.find(c => c.id === companyId);
        setIdcSelect(companyId); // Actualiza el estado de idc con el id de la nueva compañía seleccionada
        setCompania(company ? company.namecompany : ''); // Actualiza el estado de compania
    };

    let registerIdc = rol === 'AA-001' ?idc:idcSelect;

    //---------------------------------------------------------------------------------------------------------------------------------
    // Boton de guardar la actualizacion del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const creationDate = new Date(); // Obtener la fecha y hora actual
            await addDoc(collection(db, 'quiz'), {
                title,
                questions,
                idc: registerIdc, 
                nameCompany: rol === 'AA-001' ?compañia :compania,    
                status: true,
                send: false,
                editable:true,
                creationDate,
                initial_diagnostic: false,
            });
            setTitle('');
            setQuestions([
                { 
                    id:'',
                    text: '', 
                    value:'0',
                    opcion: [
                        { opcione: '', value: '' },
                        { opcione: '', value: '' }
                    ],
                    binding: true,
                    activesub: true,
                    controlResponse: '',
                    subquestions: [
                        {
                            id:'',
                            text:'',
                            binding: true,
                            value:'0',
                            opcion: [
                                { opcione: '', value: '' },
                                { opcione: '', value: '' }
                            ],
                        }
                    ],
                }
            ]);
            navigate('/encuestas/send');
            toast.success(`Encuesta guardada con éxito`);
        } catch (error) {
            toast.error("Error al crear encuesta: ", error);
        }
    };     

    if (!idc) return <Loading />;
    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <form onSubmit={handleSubmit} className="windons-admin-two">
            <div className="windons-title-section">
                <div className="title-info">
                    <p>Título de la Encuesta:</p>
                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div className="windons-title-info">
                    {rol === "SA-001" && (
                        <div className='input-date'>
                            <label>Empresa:</label>
                            <select name="selectcompañia" value={idcSelect} onChange={handleChange} required>
                                <option value="">Seleccionar Seller</option>
                                {info.map((empresa) => (
                                    <option key={empresa.id} value={empresa.id}>{empresa.namecompany}</option>
                                ))}
                            </select>
                        </div>                    
                    )}
                </div>
                <Link className='button-new' to="/encuestas/send"><BiChevronLeft />Volver</Link>
            </div>
            <div className="windons-section-encuesta">
                <div className="list-cuentions">
                    {questions &&
                        (questions.map((question, indexQuestion) => (
                            <div key={indexQuestion} className="cuestions">
                                <div className="container-cuestions">
                                    <div className="cues">
                                        <label><p>Pregunta {indexQuestion + 1}</p></label>
                                        <input
                                            type="text"
                                            value={question.text}
                                            onChange={(e) => handleAddQuestion(indexQuestion, e.target.value, question.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                
                                <div className="container-seleccion">
                                    { question.opcion && 
                                        (question.opcion.map((opcion, indexOpcion) => (
                                            <div key={indexOpcion}>
                                                <div className="cuestions-opcion">
                                                    <div>
                                                        <label><p>Opción {indexOpcion + 1}:</p></label>
                                                        <input
                                                        type="text"
                                                        value={opcion.opcione}
                                                        onChange={(e) => handleAddOpcion(indexQuestion, indexOpcion, e.target.value, opcion.value)}
                                                        required
                                                        />
                                                    </div>
                                                    <div>
                                                        <label><p>Valor:</p></label>
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            max="10"
                                                            value={opcion.value}
                                                            onChange={(e) => handleAddOpcion(indexQuestion, indexOpcion, opcion.opcione, e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                    {question.activesub === true &&
                                                        <div className="div-input-radio">
                                                            <label><p>Selección:</p></label>
                                                            <input
                                                                className="input-radio"
                                                                type="radio"
                                                                required
                                                                name={`controlResponse-${indexQuestion}`} // Asegúrate de que el nombre sea único por pregunta
                                                                checked={question.controlResponse === opcion.opcione}
                                                                onChange={() => handleCheckboxChange(indexQuestion, indexOpcion)}
                                                            />
                                                        </div>
                                                    }
                                                    <button type="button" onClick={() => handleDeleteOpcion(indexQuestion, indexOpcion)}><BiX/></button>
                                                </div>
                                            </div>
                                        )))
                                    }
                                    <button className="button-plus" type="button" onClick={() => handleNewOpcion(indexQuestion)}><BiPlus /></button>
                                </div>
                                <div>
                                    <label class="content-input">
                                        <input
                                            type="checkbox"
                                            checked={question.activesub}
                                            onChange={() => handleToggleSubquestion(indexQuestion)}
                                        /> Subpreguntas
                                        <i></i>
                                    </label>
                                </div>
                                {question.activesub === true &&
                                    <div>
                                        {question.subquestions.map((subquestion, indexSubquestion) => (
                                            <div key={indexSubquestion} className="subcuestions">
                                                <div className="container-subcuestions">
                                                    <div className="cues">
                                                        <label><p>Subpreguntas {indexSubquestion + 1}</p></label>
                                                        <input
                                                            type="text"
                                                            value={subquestion.text}
                                                            onChange={(e) => handleEditSubquestion(indexQuestion, indexSubquestion, e.target.value, subquestion.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="container-subseleccion">
                                                    {subquestion.opcion.map
                                                        ((option, indexOption) => (
                                                            <div key={indexOption}>
                                                                
                                                                <div className="cuestions-opcion">
                                                                    <div>
                                                                        <label><p>Opción {indexOption + 1}:</p></label>
                                                                        <input
                                                                            type="text"
                                                                            value={option.opcione}
                                                                            onChange={(e) => handleEditSubquestionOption(indexQuestion, indexSubquestion, indexOption, e.target.value, option.value)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <label><p>Valor:</p></label>
                                                                        <input
                                                                            type="number"
                                                                            min="0"
                                                                            max="10"
                                                                            value={option.value}
                                                                            onChange={(e) => handleEditSubquestionOption(indexQuestion, indexSubquestion, indexOption, option.opcione, e.target.value)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <button type="button" onClick={() => handleDeleteSubquestionOption(indexQuestion, indexSubquestion, indexOption)}><BiX/></button>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    <button className="button-plus" type="button" onClick={() => handleNewSubquestionOption(indexQuestion, indexSubquestion)}>
                                                        <BiPlus />
                                                    </button>
                                                </div>
                                                <button className="delete-subbutton" type="button" onClick={() => handleDeleteSubquestion(indexQuestion, indexSubquestion)}>
                                                    <BiX/> Eliminar
                                                </button>
                                            </div>
                                        ))}
                                        <div className="container-button">
                                            <button className='button-load' type="button" onClick={() => handleNewSubquestion(indexQuestion)}>
                                                <BiPlus /> Agregar Subpregunta
                                            </button>
                                        </div>
                                    </div>
                                }
                                <button className="delete-button" type="button" onClick={() => handleDeleteQuestion(indexQuestion)}><BiX/> Eliminar </button>
                            </div>
                        ))) 
                    }
                    <div className="container-button">
                        <button className='button-new' type="button" onClick={handleNewQuestion}><BiCustomize />Agregar Pregunta</button>
                    </div>
                </div>
                <div className="botton-section">
                    <button className='button-save' type="submit"><BiSave />Guardar</button>
                </div>
            </div>
        </form>
    );
}