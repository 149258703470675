import { Link } from "react-router-dom";

function reduceLength(inputString, maxLength) {
    if (!inputString || typeof inputString !== 'string') return ""; // Retorna cadena vacía si inputString no está definido o no es una cadena
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.slice(0, maxLength);
    }
}
export const ItenOrdenAprove = ({creation, deleveryData, idu, id}) => {
    const shortenedName1 = reduceLength((deleveryData.name || "").toUpperCase(), 1);
    const shortenedName2 = reduceLength(deleveryData.lastname ? deleveryData.name + " " + deleveryData.lastname : deleveryData.name, 16);
    const shortenedEmail = reduceLength(deleveryData.email|| "", 25);
    
    // Fecha de creacion de users
    let fechaFormateada = "";
    if(creation) {
        try {
            const fecha = creation.toDate(); 
            fechaFormateada = fecha.toLocaleDateString('es-ES'); 
        } catch(e) {
            console.error("Error al convertir fecha:", e);
        }
    }
    return (
        <Link key={idu} to={`/order/${idu}/${id}`} className="item-user">
            <div className="avatar">
                <h2 className="avatar-letter">{shortenedName1}</h2>
            </div>
            <p className="name-user">{shortenedName2}</p>
            <p className="email">{shortenedEmail}</p>
            <p className="rol">{deleveryData.phone}</p>
            <p className="phone">{deleveryData.department}</p>
            <p className="date">{fechaFormateada}</p>
        </Link>
    );
}
