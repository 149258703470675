import { SectionLog } from "../../UI/organisms/SectionLog/SectionLog";
import "./Login.scss"
// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
// Component efectos de las secciones
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true, 
        threshold: 0.1,   
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500 }, 
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};
export  const Login= ({setIsLogin}) => {
    return (
        <div className="container-login">
            <FadeInSection>
                <SectionLog  setIsLogin={setIsLogin} />
            </FadeInSection>
        </div>
    );
}