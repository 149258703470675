import "./Rotation.scss";
import Stack from '@mui/material/Stack';
import { db } from '../../../../firebase/config';
import { PieChart } from '@mui/x-charts/PieChart';
import { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { endOfMonth, startOfMonth, subMonths, isWithinInterval } from 'date-fns';

export const Rotation = ({idc}) => {
    const [mesActual, setMesActual] = useState({ datos: [], porcentaje: "0" });
    const [mesAnterior, setMesAnterior] = useState({ datos: [], porcentaje: "0" });

    useEffect(() => {
        let isMounted = true;

        const fetchUsers = async () => {
            try {
                const q = query(collection(db, 'users'), where('idc', '==', idc));
                const querySnapshot = await getDocs(q);
                const users = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    fechaCreacion: doc.data().fechaCreacion ? doc.data().fechaCreacion.toDate() : null,
                    fechaDelete: doc.data().fechaDelete && doc.data().fechaDelete !== '' ? doc.data().fechaDelete.toDate() : null,
                }));

                // Definir el inicio y fin del mes actual y el mes anterior
                const startOfCurrentMonth = startOfMonth(new Date());
                const endOfCurrentMonth = endOfMonth(new Date());
                const startOfLastMonth = startOfMonth(subMonths(new Date(), 1));
                const endOfLastMonth = endOfMonth(subMonths(new Date(), 1));

                // Función para calcular estadísticas
                const calculateStats = (userList, start, end) => {
                    const stats = { Salidas: 0, Continuidad: 0, Ingreso: 0 };

                    userList.forEach(user => {
                        if (user.fechaCreacion && isWithinInterval(user.fechaCreacion, { start, end })) {
                            stats.Ingreso++;
                        }
                        if (user.fechaDelete && isWithinInterval(user.fechaDelete, { start, end })) {
                            stats.Salidas++;
                        }
                        if (!user.fechaDelete || (user.fechaDelete && user.fechaDelete > end)) {
                            if (user.fechaCreacion < end) {
                                stats.Continuidad++;
                            }
                        }
                    });

                    // Calcula el total de empleados al inicio del período
                    const totalEmpleadosInicioPeriodo = stats.Continuidad + stats.Ingreso - stats.Salidas;
                    const porcentajeRotacion = totalEmpleadosInicioPeriodo > 0 ? (stats.Salidas / totalEmpleadosInicioPeriodo) * 100 : 0;

                    return {
                        datos: [
                            { label: 'Salidas', value: stats.Salidas },
                            { label: 'Continuidad', value: stats.Continuidad-stats.Ingreso},
                            { label: 'Ingreso', value: stats.Ingreso },
                        ],
                        porcentajeRotacion: porcentajeRotacion.toFixed(0) // Redondea al segundo decimal para claridad
                    };
                };

                if (isMounted) {
                    const statsMesActual = calculateStats(users, startOfCurrentMonth, endOfCurrentMonth);
                    const statsMesAnterior = calculateStats(users, startOfLastMonth, endOfLastMonth);
                    setMesActual({ datos: statsMesActual.datos, porcentaje: statsMesActual.porcentajeRotacion });
                    setMesAnterior({ datos: statsMesAnterior.datos, porcentaje: statsMesAnterior.porcentajeRotacion });
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchUsers();

        return () => {
            isMounted = false;
        };
    }, [idc]);


    return (
        <div className='dashboard-section  salidas'>
            <div className='text-dashboard'>
                <p>Rotación de personal</p>
            </div>
            <div className='dates-dashboard'>
                <Stack direction="row" className="stack-container">
                    <div className='dates-row'>
                        <p className='porce'>{mesAnterior.porcentaje}%</p>
                        <p className='mes'>Mes Anterior</p>
                    </div>
                    <PieChart
                        colors={['#ffffff4d','#ffffffa1','#ffffff']} 
                        series={[
                        {
                            paddingAngle: 2,
                            innerRadius: 50,
                            outerRadius: 60,
                            cornerRadius: 19,
                            data: mesAnterior.datos,
                        },
                        ]}
                        margin={{ right: 5 }}
                        width={120}
                        height={120}
                        legend={{ hidden: true }}
                    />
                </Stack>
                <Stack direction="row" className="stack-container">
                    <div className='dates-row'>
                        <p className='porce'>{mesActual.porcentaje}%</p>
                        <p className='mes'>Mes Actual</p>
                    </div>
                    <PieChart
                        colors={['#ffffff4d','#ffffffa1','#ffffff']} 
                        series={[
                        {
                            paddingAngle: 2,
                            innerRadius: 50,
                            outerRadius: 60,
                            cornerRadius: 19,
                            data: mesActual.datos,
                        },
                        ]}
                        margin={{ right: 5 }}
                        width={120}
                        height={120}
                        legend={{ hidden: true }}
                    />
                </Stack>
            </div>
        </div>
    );
}
