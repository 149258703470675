import "./Kits_Item.scss"

export  const Kits_Item = ({id, img, name, item, setIsOpenR}) => {
    const toggleRe = () => {
        setIsOpenR(prevIsOpenR => !prevIsOpenR);
    }; 
    return (
        <div key={id} className="kits-item">
            <img src={img} alt={name} />
            <div className="kits-item-contect">
                <section>
                    <h2 className="title-kits-item-contect">{name}</h2>
                    <ul>
                        {item.map((item, index) => (
                            <li key={index}>
                                {item.name} 
                            </li>
                        ))}
                    </ul>
                    <button className="button-tipe1"  onClick={toggleRe}>
                        Más información
                    </button>
                </section>
            </div>
        </div>
    );
}