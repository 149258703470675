import { Link } from "react-router-dom";

export const ListTickeUser = ({ticketItems , restSend, getStatusComponent}) => {
    return (
        <>
            {ticketItems.filter(tik => tik.status !== "close").length>=1&&<p className="title-status-ticket">En proceso</p>}
            {ticketItems.filter(tik => tik.status !== "close").map(tik => (
                <Link to={`/soporte/${tik.id}`} key={tik.id} className="item-ticket">
                    <div>{restSend(tik.last_answer)}</div>
                    <div>
                        <p className="name">{tik.issue}</p>
                        <p>Solicitud de: {tik.email}</p>
                        {tik.date&&<p>{new Date(tik.date.seconds * 1000).toLocaleString()}</p>}
                    </div>
                    <>{getStatusComponent(tik.status)}</>
                </Link>
            ))}
            {ticketItems.filter(tik => tik.status === "close").length>=1&&<p  className="title-status-ticket">Cerrados</p>}
            {ticketItems.filter(tik => tik.status === "close").map(tik => (
                <Link to={`/soporte/${tik.id}`} key={tik.id} className="item-ticket">
                    <div>{restSend(tik.last_answer)}</div>
                    <div>
                        <p className="name">{tik.issue}</p>
                        <p>Solicitud de: {tik.email}</p>
                        {tik.date&&<p>{new Date(tik.date.seconds * 1000).toLocaleString()}</p>}
                    </div>
                    <>{getStatusComponent(tik.status)}</>
                </Link>
            ))}
        </>
    );
};