import React, { useState } from 'react';
import { Link, useParams , useNavigate } from 'react-router-dom';
import { useLoginContext } from '../../../context/LoginContext';
// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import { BiHide, BiShow, BiX } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { PageLoading } from '../../../Loanding';
// Component efectos de las secciones
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 500 }, 
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};

export const PasswordResetPage = ({setIsLogin}) => {
    const navigate = useNavigate();
    const [ newPassword, setNewPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const { token, email } = useParams();
    const [ loading, setLoading ] = useState(false);
    const { sendPasswordReset } = useLoginContext();
    const [ showPassword, setShowPassword ] = useState(false);
    const passwordValidationRegex = /^(?=.*[A-Za-z])(?=.*\W)[A-Za-z\d\W]{6,}$/;

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (passwordValidationRegex.test(newPassword) && newPassword === confirmPassword) {
            setLoading(true); // Activar el indicador de carga
            const result = await sendPasswordReset(email, token, newPassword); // Llamar a la función de restablecimiento de contraseña
            setLoading(false); // Desactivar el indicador de carga después de recibir la respuesta
            if (result.success) {
                toast.success(result.message);
                navigate('/');
                setIsLogin(prevIsLogin => !prevIsLogin);
            } else {
                toast.error(result.message);
            }
        } else {
            // Manejo de errores específicos
            if (!passwordValidationRegex.test(newPassword)) {
                toast.error("La contraseña debe tener al menos 6 caracteres, incluyendo una letra y un carácter especial.");
            }
            if (newPassword !== confirmPassword) {
                toast.error("Las contraseñas no coinciden.");
            }
        }
    };


    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    if (loading) return <PageLoading />;
    return (
        <div className="container-login">
            <FadeInSection>
                <div className="section-log">
                    <article className="container-register">
                        <Link to="/" className='button-x'>
                            <BiX />
                        </Link>
                        <Link to="/" className="brand" >
                            <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4" />
                        </Link>
                        <h1 className="subtitle1" >Bienvenidos</h1>
                        <p className="subtitle2">Crea tu nueva contraseña</p>
                        <form className="form-contact" onSubmit={handleSubmit}>
                            <div className='input-date'>
                                <label>Email:</label>
                                <input 
                                    className='input'
                                    type="email"
                                    value={email}
                                    disabled
                                />
                            </div>
                            <div className='input-clave'>
                                <label className="label-t">Ingresa tu clave</label>
                                <input 
                                    className='input-clave'
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Nueva contraseña"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                />
                                <button className="button-password" type="button-password" onClick={toggleShowPassword}>
                                    {showPassword ? <BiHide /> : <BiShow />}
                                </button>
                            </div>
                            <div className='input-clave'>
                                <label className="label-t">Confirma tu clave</label>
                                <input 
                                    className='input-clave'
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Confirma contraseña"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                                <button className="button-password" type="button" onClick={toggleShowPassword}>
                                    {showPassword ? <BiHide /> : <BiShow />}
                                </button>
                            </div>
                            <button className='button-form' type="submit">Crear contraseña</button>
                        </form>
                    </article>
                </div>
            </FadeInSection>
        </div>
    );
}
