import "./FormSend.scss"
import { BiMailSend, BiReset } from "react-icons/bi";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where, addDoc, doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { HiOutlineX, HiPlus } from "react-icons/hi";
import { db } from "../../../../firebase/config";
import { toast } from "react-toastify";
// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
// Component efectos de las secciones
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true, 
        threshold: 0.1,   
    });
    const props = useSpring({

        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 1000 }, 
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};
const SEND_EMAIL_FUNCTION_URL = "https://us-central1-goshofi-c578e.cloudfunctions.net/sendQuiz"; 
const getUserDetailsFromUserIds = async (userIds) => {
    const userDetails = [];
    for (const userId of userIds) {
        const userRef = doc(db, 'users', userId);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
            const userData = docSnap.data();
            userDetails.push({
                email: userData.email,
                name: userData.nombre,
                lastName: userData.apellido,
            });
        }
    }
    return userDetails;
};

async function sendQuiz(data) {
    try {
        // Ya no necesitas mapear sobre 'emails', ya que 'data.users' es el array de usuarios
        const payload = {
            ...data,
        };
        const response = await fetch(SEND_EMAIL_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            toast.success("Email enviado con éxito");
        } else {
            const errorBody = await response.text();
            toast.error(`Error de envío de email: ${response.statusText}`);
            toast.error(`Detalle del error: ${errorBody}`);
        }
    } catch (error) {
        toast.error(`Error de envío de email: ${error.message}`);
    }
}

export  const FormSend = ({ assignment, setAssignment, setLoading}) => {
    const [error, setError] = useState(null);
    const [group, setGroup] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [searchBlurTimeout, setSearchBlurTimeout] = useState(null);
    const [searchTermGroups, setSearchTermGroups] = useState('');
    const [filteredGroups, setFilteredGroups] = useState(group);
    const [isSearch, setIsSearch] = useState(false);
    const [searchBlurTimeoutG, setSearchBlurTimeoutG] = useState(null);
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [unassignedUsers, setUnassignedUsers] = useState([]);

    useEffect(() => {
        let isMounted = true;
         //Carga de los grupos de users
        const fetchInfo = async () => {
            try {
                if (!assignment.idc) throw new Error("Company undefined");
                const infoCollectionRef = collection(db, 'equipment_group');
                const q = query(infoCollectionRef, where('idc', '==', assignment.idc));
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) setGroup(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
         //Carga de la informacion de users
        const fetchUser = async () => {
            try {
                if (!assignment.idc) throw new Error("Company undefined");
                const usersRef = collection(db, 'users');
                const q = query(usersRef, where('idc', '==', assignment.idc));
                const querySnapshot = await getDocs(q);
                const fetchedUsers = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) {
                    setUsers(fetchedUsers);
                    const assignedIds = new Set(assignedUsers);
                    const unassigned = fetchedUsers.filter(user => !assignedIds.has(user.id));
                    setUnassignedUsers(unassigned);
                }
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchUser();
        // Informacion de equipo
        setFilteredUsers(users.filter(user => selectedGroup.includes(user.id)));
        return () => { isMounted = false; }
    }, [selectedGroup, users, setLoading, assignedUsers, assignment]);

    useEffect(() => {
        const loadAssignedUsers = async () => {
            try {
                if (!assignment || !assignment.id) {
                    return;
                }
                const quizAppsRef = collection(db, 'quiz_application');
                const q = query(quizAppsRef, where('assignment.id', '==', assignment.id));
                const querySnapshot = await getDocs(q);
                let allAssignedUsers = [];
                querySnapshot.forEach((doc) => {
                    // Asegúrate de que los usuarios estén en la estructura de datos como esperas
                    allAssignedUsers.push(...doc.data().users);
                });
                setAssignedUsers(allAssignedUsers);
            } catch (error) {
                console.error("Error al cargar asignaciones");
            }
        };
        loadAssignedUsers();
    }, [assignment]);

    const handleSearchGroupsChange = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTermGroups(term);
        if (!term) {
            setFilteredGroups(group); // Si no hay término de búsqueda, muestra todos los grupos
        } else {
            const matchedGroups = group.filter(g =>
                g.name_group.toLowerCase().includes(term)
            );
            setFilteredGroups(matchedGroups);
        }
    };
    // Ahora, este useEffect solo se ejecutará cuando searchTermGroups cambie
    useEffect(() => {
        const term = searchTermGroups.toLowerCase();
        if (!term) {
            setFilteredGroups(group);
        } else {
            const matchedGroups = group.filter(g =>
                g.name_group.toLowerCase().includes(term)
            );
            setFilteredGroups(matchedGroups);
        }
    }, [searchTermGroups, group]);
    // Maneja el cambio en la búsqueda
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        if(event.target.value.trim() === '') {
            setIsSearching(false); // Oculta la lista si no hay término de búsqueda
        } else {
            setIsSearching(true); // Muestra la lista cuando hay término de búsqueda
        }
    };
    // Cuando el input de búsqueda está enfocado
    const handleSearchFocus = () => {
        setIsSearching(true); // Asegura que la lista se muestre si hay texto
        // Limpia el timeout si el usuario vuelve a enfocar el input rápidamente
        if (searchBlurTimeout) {
            clearTimeout(searchBlurTimeout);
            setSearchBlurTimeout(null);
        }
    };
    const handleSearchBlur = () => {
        // Establece un timeout para ocultar la lista, lo que permite que los clics se registren
        const timeoutId = setTimeout(() => {
            setIsSearching(false);
        }, 150); // 150ms debería ser suficiente, pero puedes ajustarlo según sea necesario
        setSearchBlurTimeout(timeoutId);
    };

    const handleSearchGroupsFocus = () => {
        setIsSearch(true); // Asegura que la lista se muestre si hay texto
        // Limpia el timeout si el usuario vuelve a enfocar el input rápidamente
        if (searchBlurTimeoutG) {
            clearTimeout(searchBlurTimeoutG);
            setSearchBlurTimeoutG(null);
        }
    };
    const handleSearchGroupsBlur = () => {
        // Establece un timeout para ocultar la lista, lo que permite que los clics se registren
        const timeoutIdG = setTimeout(() => {
            setIsSearch(false);
        }, 150); // 150ms debería ser suficiente, pero puedes ajustarlo según sea necesario
        setSearchBlurTimeoutG(timeoutIdG);
    };
    // Filtra los users que no han sido seleccionados y que coinciden con el término de búsqueda
    const availableUsers = users.filter(user => {
        const userEmail = user.email ? user.email.toLowerCase() : "";
        const userName = user.name ? user.name.toLowerCase() : "";
        // Verifica que el usuario no esté ya seleccionado, no esté asignado y que coincida con el término de búsqueda
        return (
            !selectedGroup.includes(user.id) &&
            !assignedUsers.includes(user.id) &&
            (userEmail.includes(searchTerm.toLowerCase()) || userName.includes(searchTerm.toLowerCase()))
        );
    });
    // Manejador para el término de búsqueda de grupos
    const addUserToSelectedGroup = (userId) => {
        if (assignedUsers.includes(userId)) {
            toast.error("El usuario ya está asignado a un quiz.");
            return;
        }
        if (!selectedGroup.includes(userId)) {
            setSelectedGroup([...selectedGroup, userId]);
        }
    };
    // Función para agregar o eliminar todos los users de selectedGroup
    const toggleAllUsersInSelectedGroup = () => {
        const unassignedUserIds = unassignedUsers.map(user => user.id);
        const allUsersSelected = unassignedUserIds.every(userId => selectedGroup.includes(userId));
        if (allUsersSelected) {
            setSelectedGroup(selectedGroup.filter(id => !unassignedUserIds.includes(id)));
        } else {
            // Si no todos están seleccionados, añádelos a selectedGroup
            setSelectedGroup([...new Set([...selectedGroup, ...unassignedUserIds])]);
        }
    };
    // Informacion del users seleccionados
    const handleGroupSelect = (groupId) => {
        const groupToToggle = group.find(g => g.id === groupId);
        if (!groupToToggle) return; // Si no se encuentra el grupo, salir.
            const userIDs = groupToToggle.group || [];
            const alreadySelected = userIDs.some(id => selectedGroup.includes(id));
        if (!alreadySelected) {
            setSelectedGroup(prev => [...new Set([...prev, ...userIDs])]); // Añade los IDs de users del grupo.
        } else {
            setSelectedGroup(prev => prev.filter(id => !userIDs.includes(id))); // Elimina los IDs de users del grupo.
        }
    };
    // Informacion de grupos completos para su carga
    const isGroupSelected = (group) => {
        const userIDs = group.group || [];
        return userIDs.some(id => selectedGroup.includes(id));
    };
    // Elimina un usuario seleccionado por su ID
    const removeSelectedUser = (userId) => {
        setSelectedGroup(prevSelected => prevSelected.filter(id => id !== userId));
    };

    // Limpiar Asignacion
    const handleNull = () =>{
        setAssignment({ title: "", id: "", idc: "" , nameCompany: "" , initial_diagnostic: ""});
    }



    // Función mejorada con manejo de errores y promesas
    const handleSubmit = async (e) => {
        if (!assignment) {
            throw new Error("El nombre de la compañía (nameCompany) está indefinido.");
        }
        e.preventDefault();
        setLoading(true);
        // Recoge los datos del formulario
        const affair = e.target.affair.value;
        const comment = e.target.comment.value;
        try {
            // Asegúrate de que getUserDetailsFromUserIds y sendQuiz estén definidas y funcionen correctamente
            const userDetails = await getUserDetailsFromUserIds(selectedGroup);
            // Asegúrate de que todos los valores necesarios, especialmente nameCompany, estén definidos
            const payload = {
                // Asegúrate de que assignment se define correctamente antes de usarlo
                assignment,
                users: userDetails,
                affair,
                comment,
                ide: assignment.id,
                idc: assignment.idc,
                nameCompany: assignment.nameCompany,
                timestamp: new Date(),
                initial_diagnostic: assignment.initial_diagnostic,
            };
            // Suponiendo que sendQuiz es una operación asincrónica que no necesita resultado para la siguiente línea
            await sendQuiz(payload);
            // Agrega un nuevo documento a la colección 'quiz_application'
            await addDoc(collection(db, 'quiz_application'), {
                ...payload, // Utiliza el spread operator para reutilizar el payload
                users: selectedGroup // Reemplaza userDetails con selectedGroup para mantener la consistencia de datos
            });
            // Actualiza el documento en la colección 'quiz'
            const quizDocRef = doc(db, 'quiz', assignment.id); // Asegúrate de que assignment.id exista
            await updateDoc(quizDocRef, {
                editable: false
            });
            // Actualiza los documentos en la colección 'alert' para cada ID en selectedGroup
            const notificationPayload = {
                id: assignment.id,
                date: new Date(),
                affair: affair,
                functions: "quiz",
                message: comment,
                nameCompany: assignment.nameCompany,
                route: `/contestador/${assignment.id}`,
                status: true,
                initial_diagnostic: assignment.initial_diagnostic,
            };

            // Asegúrate de que las actualizaciones en 'alert' se manejen correctamente
            for (const userId of selectedGroup) {
                const alertDocRef = doc(db, 'alert', userId);
                await updateDoc(alertDocRef, {
                    notification: arrayUnion(notificationPayload)
                });
            }
            setLoading(false);
            setAssignment({ title: "", id: "", idc: "" , nameCompany: "" , initial_diagnostic: ""}); // Limpia los campos después de enviar
        } catch (error) {
            setLoading(false);
            console.error("Error al enviar o actualizar la encuesta", error);
            toast.error("Error al enviar o actualizar la encuesta: " + error.message);
        }
    };
    //Manejod e carga y errores de las encuestas
    if (error) return <div>Error: {error.message}</div>;

    return (
        <>
        {unassignedUsers&&(unassignedUsers.length ===0
        ?<FadeInSection>
            <div className="empty-form-quiz">
                <img className="img-shofi-loanding" alt="Quiz Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2FPJ_To_do_Checklist-removebg%20(1).png?alt=media&token=39d60d9c-42f2-4852-9d1e-9134462bc289&_gl=1*1f8wrtz*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5ODc4MDcyMi4zNDkuMS4xNjk4NzgyMTI2LjUyLjAuMA.." />
                <h2 className="title-shofi-loanding">Esta encuestas ya fue<br></br>enviada a todos los usuarios</h2>
                <button className='button-new' onClick={handleNull}><BiReset /> Buscar otra asignación</button>
            </div>
        </FadeInSection>
        :<FadeInSection>
            <div className="title-form-send">
                <h4>{assignment.title}</h4>
                <p>({assignment.id})</p>
            </div>
            <form className="form-contact" onSubmit={handleSubmit}>
                    <div className='input-date'>
                        <label>Grupos:</label> 
                        <input 
                            type="text" 
                            onChange={handleSearchGroupsChange}
                            onFocus={handleSearchGroupsFocus} 
                            onBlur={handleSearchGroupsBlur}
                            value={searchTermGroups}
                            />
                        {isSearch && (
                            <div className="container-input-bottom">
                                <div className="input-bottom">
                                    <div className="display-button">
                                        <button onClick={toggleAllUsersInSelectedGroup} className={unassignedUsers.every(user => selectedGroup.includes(user.id)) ? 'opcion-delete' : 'opcion-button'}>
                                            {unassignedUsers.every(user => selectedGroup.includes(user.id)) ? <>Eliminar Todos <HiOutlineX /></> : <>Agregar Todos <HiPlus /></>}
                                        </button>
                                        {filteredGroups.map((group) => (
                                            <button 
                                                key={group.id} 
                                                onClick={() => handleGroupSelect(group.id)}
                                                className={isGroupSelected(group) ? 'opcion-delete' : 'opcion-button'}
                                            >
                                            {group.name_group} {isGroupSelected(group) ?<HiOutlineX /> :<HiPlus />}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {!users.every(user => selectedGroup.includes(user.id)) && (
                        <div className='input-date'>
                            <label>Buscar usuario:</label>
                            <input 
                                type="text" 
                                onChange={handleSearchChange} 
                                onFocus={handleSearchFocus} 
                                onBlur={handleSearchBlur}
                            />
                            {isSearching && (
                                <div className="container-input-bottom">
                                    <div className="input-bottom">
                                        <div className="display-button">
                                            {availableUsers.map((user) => (
                                                <button 
                                                    className="opcion-button"
                                                    key={user.id} 
                                                    onClick={() => addUserToSelectedGroup(user.id)}
                                                >
                                                {user.email} <HiPlus />
                                                </button>                  
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="container-input-bottom">
                        <div className='input-date'>
                            <label>Asignados:</label> 
                        </div>
                        <div className="input-select">
                            <div className="display-button">
                                {filteredUsers.map(user => (
                                    <button className="opcion-opcion" onClick={() => removeSelectedUser(user.id)}>
                                        <HiOutlineX /> {user.email}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='input-date'>
                        <label>Asunto:</label>
                        <input 
                            type="text" 
                            name="affair"  
                            required 
                        />
                    </div>
                    <div className='input-date'>
                        <label>Comentarios:</label>
                        <textarea 
                            type="text" 
                            name="comment" 
                            required 
                        />
                    </div>
                    <button className='button-new' type="submit"><BiMailSend />Enviar</button>
            </form>
        </FadeInSection>
        )}
        </>
    );
}

