import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState, useCallback } from "react";
import { db } from "../../../../firebase/config";
import { ListUsersAprove } from "../../../UI/organisms/ListOrderAprove/ListOrderAprove";
import { InfoLoading } from "../../../../Loanding";

export const OrderConfirmationList = () => {
    const itemsPerPage = 20;
    const [info, setInfo] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortType, setSortType] = useState('nombre');
    const [sortDirection, setSortDirection] = useState('asc');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');

    const fetchInfo = useCallback(async () => {
        let isMounted = true;
        try {
            setLoading(true);
            const q = collection(db, 'orden');
            const u = query(q, where('order_acceptance', '==', false));
            const querySnapshot = await getDocs(u);
            const fetchedInfo = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    ...data.deleveryData
                };
            });

            let filteredUsers = fetchedInfo;

            // Filtro por fechas
            if (startDate) {
                const start = new Date(startDate);
                filteredUsers = filteredUsers.filter(user => user.creation?.toDate() >= start);
            }
            if (endDate) {
                const end = new Date(endDate);
                filteredUsers = filteredUsers.filter(user => user.creation?.toDate() <= end);
            }

            // Filtro por fecha
            const sortedUsers = filteredUsers.sort((a, b) => {
                let comparison = 0;
                if (sortType === 'name') {
                    comparison = a.name.localeCompare(b.name);
                } 
                return sortDirection === 'desc' ? comparison : -comparison;
            });
            // Extraer departamentos únicos
            const departmentsSet = new Set(fetchedInfo.map(user => user.department));
            setDepartments([...departmentsSet]);
            if (isMounted) setInfo(sortedUsers);
        } catch (err) {
            if (isMounted) setError(err);
        } finally {
            if (isMounted) setLoading(false);
        }
        return () => { isMounted = false; }
    }, [startDate, endDate, sortType, sortDirection]);

    useEffect(() => {
        fetchInfo();
    }, [fetchInfo]);

    const numberOfPages = Math.ceil(info.length / itemsPerPage);

    const usersItems = info.filter(user => {
        const name = user.name ? user.name.toLowerCase() : '';
        const lastname = user.lastname ? user.lastname.toLowerCase() : '';
        const email = user.email ? user.email.toLowerCase() : '';

        // Filtro por término de búsqueda
        const searchTermMatch = name.includes(searchTerm.toLowerCase()) || 
                                lastname.includes(searchTerm.toLowerCase()) || 
                                email.includes(searchTerm.toLowerCase());

        // Filtro por fechas
        const startDateMatch = startDate ? user.creation?.toDate() >= new Date(startDate) : true;
        const endDateMatch = endDate ? user.creation?.toDate() <= new Date(endDate) : true;
 
        // Filtro por departamento
        const departmentMatch = selectedDepartment ? user.department === selectedDepartment : true;
 
        return searchTermMatch && startDateMatch && endDateMatch && departmentMatch;
    });

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    const displayItems = usersItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    if (loading) return <InfoLoading />;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="equipo-admin">
            
            <ListUsersAprove
                info={info}
                handleChange={handleChange}
                sortDirection={sortDirection}
                setSortType = {setSortType}
                setSortDirection={setSortDirection}
                displayItems={displayItems}
                numberOfPages={numberOfPages}
                currentPage={currentPage}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                setSelectedDepartment={setSelectedDepartment}
                selectedDepartment={selectedDepartment}
                departments={departments}
            />
        </div>
    );
};
