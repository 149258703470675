import "./SectionSupport.scss"
import { useEffect, useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, updateDoc, doc, arrayUnion, query, where, getDocs, getDoc } from "firebase/firestore";
import { db , storage } from "../../../../firebase/config";
import { BiPaperPlane, BiPaperclip, BiX } from "react-icons/bi";
import { toast } from "react-toastify";
import { InfoLoading } from "../../../../Loanding";

const SEND_EMAIL_FUNCTION_URL = "https://us-central1-goshofi-c578e.cloudfunctions.net/sendSupportInfo"; 

const getUserDetailsFromUserIds = async (userIds) => {
    const userDetails = [];
    for (const userId of userIds) {
        const userRef = doc(db, 'users', userId);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
            const userData = docSnap.data();
            userDetails.push({
                email: userData.email,
                name: userData.nombre,
                lastName: userData.apellido,
            });
        }
    }
    return userDetails;
};

async function sendSupport(data) {
    try {
        // Ya no necesitas mapear sobre 'emails', ya que 'data.users' es el array de usuarios
        const payload = {
            ...data,
        };
        const response = await fetch(SEND_EMAIL_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            toast.success("Ticket enviado con éxito");
        } else {
            const errorBody = await response.text();
            toast.error(`Error de envío de ticket: ${response.statusText}`);
            toast.error(`Detalle del error: ${errorBody}`);
        }
    } catch (error) {
        console.error("Error en Ticket:", error);
        toast.error(`Error de envío de email: ${error.message}`);
    }
}

export const SectionSupportEquip = ({ id, idc, compañia, email, idProduct, setIdProduct, setOpen , open}) => {
    const [files, setFiles] = useState([]);
    const [info, setInfo] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchGroup = async () => {
            setLoading(true);
            if (!idc) throw new Error("idc es indefinido");
            try {
                const kitsCollectionRef = collection(db, 'users');
                const company = query(kitsCollectionRef, where('idc', '==', idc));
                const q = query(company, where('rol', 'in', ["AA-001", "AA-002", "AA-003"]));
                const querySnapshot = await getDocs(q);

                const fetchedIds = querySnapshot.docs.map(doc => doc.id);
                setSelectedGroup(fetchedIds);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchGroup();
    }, [idc]); 

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const userDocRef = doc(db, 'products-assignment', idProduct);
                const userDoc = await getDoc(userDocRef);
                if(userDoc.exists() && isMounted) {
                    setInfo(userDoc.data());
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchInfo();
        return () => { isMounted = false; };
    }, [idProduct]);

    const [formData, setFormData] = useState({
        issue: "Reporte de avería de equipo",
        event: "",
        description: "",
    });

    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        // Agregar los nuevos archivos a los ya existentes
        setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
        // Crear previsualizaciones para los nuevos archivos y agregarlas a las existentes
        const newPreviewUrls = selectedFiles.map(file => URL.createObjectURL(file));
        setPreviewUrls(prevUrls => [...prevUrls, ...newPreviewUrls]);
    };
    
    const handleRemoveFile = (index) => {
        const newFiles = files.filter((_, fileIndex) => fileIndex !== index);
        const newPreviewUrls = previewUrls.filter((_, urlIndex) => urlIndex !== index);
        setFiles(newFiles);
        setPreviewUrls(newPreviewUrls);
    };
    
    const handleDetailClose = (idProduct) => {
        setOpen(!open);
        setIdProduct(idProduct);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const fileUrls = await Promise.all(files.map(async (file) => {
            const fileRef = ref(storage, `support/${file.name}`);
            await uploadBytes(fileRef, file);
            return getDownloadURL(fileRef);
        }));
        try {
            const fechaCreacion = new Date();
            const ticketsCollectionRef = collection(db, "tickets");
            const docRef = await addDoc(ticketsCollectionRef, {
                issue: `${formData.issue} ${info.sku}`,
                event: formData.event,
                description: `Serial del equipo${info.serial} ||  ${formData.description}`,
                idu: id,
                idc,
                compañia,
                email,
                status: "send",
                fechaCreacion,
                respuestas: [
                    { mensaje: 
                        formData.description, 
                        fecha: fechaCreacion , 
                        idu: id , 
                        archivos: fileUrls, 
                        email: email,
                    }
                ],
            });
            const newDocumentId = docRef.id;
            console.log(newDocumentId)
            // Actualiza los documentos en la colección 'alert' para cada ID en selectedGroup
            const notificationPayload = {
                date: new Date(), 
                affair: formData.event,
                functions: "soporte",
                message: `Serial del equipo${info.serial} ||  ${formData.description}`,
                route: `/soporte/${newDocumentId}`,
                status: true,
            };
            // Aquí actualizas el documento en la colección 'alert'
            for (const userId of selectedGroup) {
                const alertDocRef = doc(db, 'alert', userId);
                await updateDoc(alertDocRef, {
                    notification: arrayUnion(notificationPayload)
                });
            }
            const userDetails = await getUserDetailsFromUserIds(selectedGroup);
            const payload = {
                event: `${formData.issue} ${info.sku}`,
                users: userDetails,
                affair: formData.event,
                comment: `Serial del equipo${info.serial} ||  ${formData.description}`,
                id:newDocumentId,
                timestamp: new Date()
            };
            await sendSupport(payload);
            // Restablecer el formulario y los archivos
            setFormData({
                issue: "",
                event: "",
                description: "",
            });
            setFiles([]);
            setPreviewUrls([]);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className='container-support'>
            <button onClick={handleDetailClose} className='button-x'>
                <BiX />
            </button>
            <div className="brand" >
                <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4" />
            </div>
            <form className="form-equipo" onSubmit={handleSubmit}>
                <h2>Reporte de avería de equipo</h2>
                <div className="form-scroll1">
                    <div className='input-date'>
                        <label>Suceso:</label>
                        <input name="event" value={formData.event} onChange={handleChange} required />
                    </div>
                    <div className='input-message'>
                        <label>Mensaje</label>
                        <textarea name="description" value={formData.description} onChange={handleChange} required />
                    </div>
                    <div className='input-load2'>
                        <label htmlFor="file-upload" className="custom-file-upload">
                            <BiPaperclip />Imágenes
                        </label>
                        <input id="file-upload" type="file" accept=".jpg, .png" onChange={handleFileChange} multiple />
                    </div>
                    {previewUrls&&
                        <div className="container-input-img1">
                            {previewUrls.map((url, index) => (
                                <div key={index} className="input-img">
                                    <img src={url} alt={`Previsualización ${index}`} style={{ width: 'auto', height: '100px' }} />
                                    <button className="delete-img" onClick={() => handleRemoveFile(index)}>
                                        <BiX/>
                                    </button>
                                </div>
                            ))}
                        </div>
                    }
                </div>
                <div className="cotainer-button2">
                    <button className='button-new' type="submit"><BiPaperPlane />Reportar avería</button>
                </div>
            </form>
        </div>
    );
}
