import Slider from "react-slick";
import { useEffect, useState } from "react";
import { Loading } from "../../../../Loanding";
export const SliderArt = ({slider, settings}) => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (slider && slider.length > 0) {
            setIsLoading(false); 
        }
    }, [slider]); 
    if (isLoading) return <Loading />;
    return (
        <div className="slider-2">
            <Slider {...settings} className="section-2-slider">
                {slider.map((item, index) => (
                    <div className="img-section-2" key={item+index}>
                        <img alt="card-contenido" src={item} /><p>{index}</p>
                    </div>
                ))}
            </Slider>
        </div>
    );
}