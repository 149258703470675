import React, { useState } from 'react';
import Papa from 'papaparse';
import { toast } from 'react-toastify'; // Ajusta la ruta según tu estructura de proyecto
import { useLoginContext } from '../../../../context/LoginContext';
import { BiRefresh } from 'react-icons/bi';
import { TbFileTypeCsv } from "react-icons/tb";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../../firebase/config';
import { useNavigate } from 'react-router-dom';

function UploadUsers({idc, compañia, business_id, setLoading , typecompany}) {
  const navigate = useNavigate();
  const [ file, setFile ] = useState(null);
  const { registerUsersMassively } = useLoginContext(); // Usando el contexto

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const checkEmailExists = async (email) => {
    const q = query(collection(db, "users"), where("email", "==", email));
    const docs = await getDocs(q);
    return !docs.empty;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      toast.error("Por favor, selecciona un archivo CSV.");
      return;
    }
    setLoading(true);
    // Procesar el archivo CSV
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (result) => {
        const validUsers = [];
        const emailChecks = result.data.map(async (row) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(row.email)) {
            setLoading(false);
            return `Email inválido: ${row.email}`;
          }
          const emailExists = await checkEmailExists(row.email);
          if (!emailExists) {
            // Solo agrega el usuario si el correo no existe
            validUsers.push({
              apellido: row.apellido,
              nombre: row.nombre,
              email: row.email,
              compañia: compañia,
              idc: idc,
              business_id: business_id, 
              has_completed_tour:false,
              typecompany:typecompany,
            });
          } else {
            setLoading(false);
            return `Email ya registrado: ${row.email}`;
          }
        });

        // Espera a que todas las verificaciones de email se completen
        const emailCheckResults = await Promise.all(emailChecks);

        // Filtra y muestra errores
        const errors = emailCheckResults.filter((e) => e);
        if (errors.length > 0) {
          toast.error(`Errores encontrados:\n${errors.join('\n')}`);
          return;
        }

        try {
          await registerUsersMassively(validUsers);
          toast.success("Usuarios registrados exitosamente.");
          setLoading(false);
          navigate('/usuarios');
        } catch (error) {
          console.error("Error al registrar usuarios masivamente", error);
          toast.error("Error al procesar la solicitud de registro masivo.");
          setLoading(false);
        }
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='input-upload'>
          <label htmlFor="rut-upload" className="custom-file-upload">
            {file ?
              <>
              <BiRefresh />Cambiar CVS</> : <><TbFileTypeCsv /> Cargar CSV
              </>
            }
          </label>
          <input id="rut-upload" type="file" onChange={handleFileChange} accept=".csv" style={{display: 'none'}} />
        </div>
        {file && <button className='button-load-cvs' type="submit">
          <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
          Cargar Usuarios
        </button>}
      </form>
    </>
  );
}

export default UploadUsers;
