// import { useState } from "react";
import "./ItenUsersStatus.scss";
import { toast } from "react-toastify";

function reduceLength(inputString, maxLength) {
    if (!inputString || typeof inputString !== 'string') return ""; // Retorna cadena vacía si inputString no está definido o no es una cadena
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.slice(0, maxLength);
    }
}

export const ItenUsersStatus = ({nombre, apellido, email, compañia, fechaCreacion, img, id, orders, onClick, isSelected}) => {
    const shortenedName1 = reduceLength((nombre || "").toUpperCase(), 1);
    const shortenedName2 = reduceLength(apellido ? nombre + " " + apellido : nombre, 16);
    const shortenedEmail = reduceLength(email|| "", 25);
    const buttonClass = isSelected ? "item-user-status offactive " : "item-user-status onactive";
    let fechaFormateada = "";

    if(fechaCreacion) {
        try {
            const fecha = fechaCreacion.toDate(); // Convertir Timestamp a Date
            fechaFormateada = `${fecha.toLocaleDateString('es-ES')} ${fecha.toLocaleTimeString('es-ES')}`;
        } catch(e) {
            toast.error("Error al convertir fecha:", e);
        }
    }

    return (
        <button
            className={buttonClass}
            onClick={onClick} // Usar onClick pasado por el componente padre
        >
            <div className="avatar">
                {img ? (
                    <div className="avatar-img">
                    <img alt="avatar" src={img}/>
                    </div>
                ) : (
                    <h2 className="avatar-letter">{shortenedName1}</h2>
                )}
            </div>
            <p className="name-user">{shortenedName2}</p>
            <p className="email">{shortenedEmail}</p>
            <p className="phone">{compañia}</p>
            <div className="rol">
                {orders.length}
            </div>
            <p className="date">{fechaFormateada}</p>
        </button>
    );
}
