/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import "./Kits.scss";
import { BiPencil, BiPlus} from "react-icons/bi";
import { useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs, query, where} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { InfoLoading } from "../../../../Loanding";
import { EmptyFormK } from "../../../UI/atoms/EmptyFormK/EmptyFormK";
import { FormSendKit } from "../../../UI/organisms/FormSendKit/FormSendKit";

export const Kits = ({ idc, compañia, rol}) => {
    const [ kits, setKits] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [previousKits, setPreviousKits] = useState([]);
    const [asignacion, setAsignacion] = useState({ title: '', id: '' });

    const fetchKits = async () => {
        let isMounted = true;
        setLoading(true); 
        try {
            if (!idc) throw new Error("idc es indefinido");
            const kitsCollectionRef = collection(db, 'produc_kits');
            let q;
                if (rol === "SA-001") {
                    q = kitsCollectionRef;
                } else {
                    q = query(kitsCollectionRef, where('idc', '==', idc));
                }
            const querySnapshot = await getDocs(q);

            const fetchedKits = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            // Para cada grupo de equipos, busca la compañía correspondiente
            const infoWithCompany = await Promise.all(fetchedKits.map(async (kits) => {
                const companyRef = doc(db, "company", kits.idc);
                const companyDoc = await getDoc(companyRef);
                return { ...kits, companyName: companyDoc.data()?.namecompany };
            }));

            if (isMounted) setKits(infoWithCompany);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        if (isMounted) {
            fetchKits();
        }
        setLoading(false);
        return () => { isMounted = false; }
    }, [idc]);

    // Filtrado de kits
    const editable = kits.filter(kit => kit.editable);
    const draft = kits.filter(kit => !kit.editable);
    const statusTrue = draft.filter(draftKit => draftKit.status);
    const statusFalse = draft.filter(draftKit => !draftKit.status);

    // Detectar cambios en los kits filtrados
    useEffect(() => {
        setLoading(true);
        const hasEditableChanged = JSON.stringify(editable) !== JSON.stringify(previousKits.filter(kit => kit.editable));
        const hasStatusTrueChanged = JSON.stringify(statusTrue) !== JSON.stringify(previousKits.filter(kit => !kit.editable && kit.status));
        const hasStatusFalseChanged = JSON.stringify(statusFalse) !== JSON.stringify(previousKits.filter(kit => !kit.editable && !kit.status));

        if (hasEditableChanged || hasStatusTrueChanged || hasStatusFalseChanged) {
            fetchKits();
        }
        setPreviousKits(kits);
        setLoading(false);
    }, [kits]);

    //Manejo  de carga y errores de kits
    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <>{(!kits.length)
            ?<div className="windons">
                <div className="windons-title-section">
                    <div className="title-info">
                        <h2 className="title">Kits: {compañia}</h2>
                    </div>
                </div>
                <div className="windons-quiz-init">
                    <div className="section-quiz-init">
                        <div className="quiz-init-img">
                            <img alt="Shofi Encuestas" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2FPJ_To_do_Checklist-removebg%20(1).png?alt=media&token=39d60d9c-42f2-4852-9d1e-9134462bc289&_gl=1*1f8wrtz*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5ODc4MDcyMi4zNDkuMS4xNjk4NzgyMTI2LjUyLjAuMA.."/>
                            <h2 className="title-shofi-loanding">Haz tu primer kit<br></br>para tu equipo de trabajo.</h2>
                        </div>
                        <div className="quiz-init-contect">
                            <h3 className="contect-shofi-loanding">Iniciemos creando tu primer Kit<br />o paquete de productos para tus colaboradores</h3>
                            <Link className='button-asing' to="/kits/creador">
                                <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2F27470480_7236956-PhotoRoom.png-PhotoRoom%20(1).png?alt=media&token=bc0b305b-80fb-49a7-a6c8-cd8f28705713&_gl=1*1nh72nx*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5ODc4NTUyOS4zNTAuMS4xNjk4Nzg1NTM0LjU1LjAuMA.." />
                                Crear Kit
                            </Link>
                            <h3 className="contect-shofi-loanding">Así podrás asignar a cada uno de ellos<br/>los activos que desees por grupo de trabajo</h3>
                        </div>
                    </div>
                </div>
            </div>
            :<div className="windons-admin-send">
                <div className="windons-title-send">
                    <div className="title-info">
                        <h2 className="title">Kits: {compañia}</h2>
                    </div>
                    <Link className='button-new'  to="/kits/creador">
                        <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*ndeahk*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5ODc2MzcxMi4zNDcuMS4xNjk4NzYzNzIwLjUyLjAuMA.." />
                        Crear Kit
                    </Link>
                </div>
                <div className="windons-section-info">
                    <section className="section-info">
                        <input
                            className="search-input"
                            type="text" 
                            placeholder="Buscar kits..." 
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />

                        <div className="list-info">
                        {editable.length!==0&&<p class="edit">En borrador</p>}
                        {editable.map(kit => (
                            <div key={kit.id} className="item-kits-as">
                                <Link to={`/kits/${kit.id}`} ><BiPencil className="search"/></Link>
                                <div>
                                    <p className="name">{kit.name_kits}({kit.kits.length})</p>
                                    <p>{kit.id}</p>
                                    {rol === "SA-001" && (
                                        <p className="name">{kit.companyName}</p>
                                    )}
                                </div>
                                {kit.status&&<Link onClick={() => setAsignacion({ title: kit.name_kits, id: kit.id , idc:kit.idc, nameCompany:kit.companyName})}><BiPlus /></Link>}
                            </div>
                        ))}
                        {statusTrue.length!==0&&<p class="asigne">Asignados</p>}
                        {statusTrue.map(kit => (
                            <div key={kit.id} className="item-kits-as">
                                <Link to={`/kits/${kit.id}`} ><BiPencil className="search"/></Link>
                                <div>
                                    <p className="name">{kit.name_kits}  ({kit.kits.length})</p>
                                    <p>{kit.id}</p>
                                    {rol === "SA-001" && (
                                        <p className="name">{kit.companyName}</p>
                                    )}
                                </div>
                                {kit.status&&<Link onClick={() => setAsignacion({  title: kit.name_kits, id: kit.id , idc:kit.idc, nameCompany:kit.nameCompany})}><BiPlus /></Link>}
                            </div>
                        ))}
                        {statusFalse.length!==0&&<p class="inactive">Asignados: Inactivos</p>}
                        {statusFalse.map(kit => (
                            <div key={kit.id} className="item-kits-as">
                                <Link to={`/kits/${kit.id}`} ><BiPencil className="search"/></Link>
                                <div>
                                    <p className="name">{kit.name_kits}  ({kit.kits.length})</p>
                                    <p>{kit.id}</p>
                                    {rol === "SA-001" && (
                                        <p className="name">{kit.companyName}</p>
                                    )}
                                </div>
                                {kit.status&&<Link onClick={() => setAsignacion({  title: kit.name_kits, id: kit.id , idc:kit.idc, nameCompany:kit.nameCompany })}><BiPlus /></Link>}
                            </div>
                        ))}
                    </div>
                    </section>
                    <section className="section-info1">
                        {(asignacion.id === "")
                            ?<EmptyFormK />
                            :
                            <FormSendKit
                                idc={idc}
                                assignment={asignacion}
                                setAssignment={setAsignacion}
                                setLoading={setLoading}
                            />
                        }
                    </section>
                </div>
            </div>
        }</>
    );
}