import "./ListCategory.scss";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Link } from "react-router-dom";
import { ItenCategory } from "../../molecules/ItenCategory/ItenCategory";

export const ListCategory = ({ info, handleChange , numberOfPages, displayItems, currentPage}) => {
    return (
        <div className="list-product-category">
            <div className="contect-list-product-category">
                <div className="encabezado-list">
                    <h2 className="title">Categorías</h2>
                    <Link className='button-new' to="/productos/categoria/creador"> 
                    <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                        Nuevo categoria
                    </Link>
                </div>
                {info && (
                    <div>
                        <div className="title-product-category">
                            <p className="avatar">Avatar</p>
                            <p className="name-category">Nombre</p>
                            <p className="id-category">Id</p>
                        </div>
                        <div className="list">
                            {displayItems.map(info => (
                                <ItenCategory key={info.id} {...info} />
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className="pagination-numb">
                <Stack spacing={2}>
                    <Pagination count={numberOfPages} page={currentPage} onChange={handleChange} size="large" />
                </Stack>
            </div>
        </div>
    );
}