import "./ListProductAsignate.scss";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ItenProducAsiganation } from "../../molecules/ItenProducAsiganation/ItenProducAsiganation";

export const ListProductAsignate = ({ products, handleChange , numberOfPages, displayItems, currentPage, info, handleSelectChange, businessId}) => {
    return (
        <div className="list-product-asignate">
            <div className="contect-list-product-asignate">
                <div className="encabezado-list">
                    <h2 className="title">Categorías</h2>
                    <div className='input-date-compañia'>
                        <label>Seller:</label>
                        <select name="selectcompañia" onChange={handleSelectChange} value={businessId}>
                            <option value="" disabled hidden>Seleccione una empresa</option>
                            {info.map((empresa) => (
                                <option key={empresa.id} value={empresa.business_id}>{empresa.namecompany}</option>
                            ))}
                        </select>
                    </div>  
                </div>
                {products && (
                    <div>
                        <div className="title-product-asignate">
                            <p className="avatar">Avatar</p>
                            <p className="name-asignate">Nombre</p>
                            <p className="id">Id Velocity</p>
                            <p className="idExtenernal">Id SHOFI</p>
                            <p className="seller">Seller</p>
                        </div>
                        <div className="list">
                            {displayItems.map(products => (
                                <ItenProducAsiganation key={products.id} {...products} />
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className="pagination-numb">
                <Stack spacing={2}>
                    <Pagination count={numberOfPages} page={currentPage} onChange={handleChange} size="large" />
                </Stack>
            </div>
        </div>
    );
}