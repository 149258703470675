import { useEffect, useState } from "react";
import "./Section_4.scss"
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { useNavigate } from "react-router-dom";

export const Section4 = ({setLoading, setError, setIsOpenR}) => {
    const [info, setInfo] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const specificDocRef = doc(db, 'sections_page', 'GObUGagRetNHRjeaqqCS');
        getDoc(specificDocRef)
            .then((document) => {
                const data = {
                    ...document.data(),
                    id: document.id,
                };
                setInfo(data); 
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
        return () => { isMounted = false; };
    }, [setError, setLoading]);
    const navigate = useNavigate();
    const toggleRe = () => {
        setIsOpenR(prevIsOpenR => !prevIsOpenR);
        navigate( `${info.button.route}`);
    };
    return ( 
        <section className="section-contect">
            {info&&
                <section className="section-4">
                    <div className="section-4-contect">
                        <h1 className="title-page-1" dangerouslySetInnerHTML={{ __html: info.title }}></h1>
                    </div>
                    <div className="ellipse-div-2"/>
                    {info.card&&
                        <div className="section-4-contect-card">
                            {info.card.map((item) => (
                                <div className="info-section-4-card">
                                    <div className="section-4-card-img-container">
                                        <img className="section-4-card-img" src={item.img} alt={item.title}/>
                                    </div>
                                    <h2 className="title-page-2  title-card-4" dangerouslySetInnerHTML={{ __html: item.title }}></h2>
                                    <div className="section-4-card-numb"><h4>{item.numb}</h4></div>
                                    <p className="subtitle-page-2" dangerouslySetInnerHTML={{ __html: item.contect}}></p>
                                </div>
                            ))}
                        </div>
                    }
                    <div className="container-button-nav">
                        {info.button&&<button className="button-tipe1"  onClick={toggleRe}>
                            {info.button.title}
                        </button>}
                    </div>
                </section>
            }
        </section>
    );
}


