import { useState } from "react";
import "./Section_5.scss";
import { useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase/config";


export const Section5 = ({setLoading, setError}) => {
    const [info, setInfo] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const specificDocRef = doc(db, 'sections_page', 'O5VMy3ID8YbhHC25aLa5');
        getDoc(specificDocRef)
            .then((document) => {
                const data = {
                    ...document.data(),
                    id: document.id,
                };
                setInfo(data); 
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
        return () => { isMounted = false; };
    }, [setError, setLoading]);

    return (
        <section className="info-section-5">
            <div className="info-section-5-container">
                {info.contect&&
                    <div className="info-left-section-5">
                        <div className="left-section-5-contect">
                            {info.contect.map((item) => (
                                <div className="info-left-section-5-contect">
                                    <h2 className="title-page-2" dangerouslySetInnerHTML={{ __html: item.title }}></h2>
                                    <p className="subtitle-page-2" dangerouslySetInnerHTML={{ __html: item.subtitle }}></p>
                                </div>
                            ))}
                        </div>
                        <div className="left-section-5-contect-img">
                            <img src={info.img} alt="Mapa SHOFI"/>
                        </div>
                    </div>
                }
                {info.card&&<div className="info-right-section-5">
                    {info.card.map((item, index) => (
                        <div className={`info-left-section-5-card bg-${index}`}>
                            <h2 dangerouslySetInnerHTML={{ __html: item.title }}></h2>
                            <p dangerouslySetInnerHTML={{ __html: item.subtitle }}></p>
                        </div>
                    ))}
                </div>}
            </div>
        </section>
    );
};