const questions = [
    // Q00000000001
    {
        "text": "Actualmente cuando trabajas en casa, ¿cuentas con escritorio?",
        "opcion":[
            {
                "opcione": "SI", 
                "value": 10
            },
            {
                "opcione": "NO", 
                "value": 0
            },
        ],
        "binding": true,
        "activesub":true,
        "value":10,
        "controlResponse" : "SI", 
        "id": "Q00000000001",
        "img" : [
            "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FEscritorio_SHOFI.png?alt=media&token=b4aabe88-2a98-4ff3-b97c-a0c669e4fe2c",
        ],
        "subquestions":[
            {
                "text":"¿Tiene tu escritorio una profundidad de al menos 50 cm?",
                "opcion":[
                    {
                        "opcione": "SI", 
                        "value": 5
                    },
                    {
                        "opcione": "NO", 
                        "value": 0
                    },
                ],
                "binding": "true",
                "value":5,
                "id": "Q00000000001-1",
                "img" : [
                    "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FEscritorio%20profundidad_SHOFI.png?alt=media&token=08732856-ce24-471e-80a7-4245f61ae998",
                ],
            },
            {
                "text":"¿Tiene tu escritorio un largo de al menos 100 cm?",
                "opcion":[
                    {
                        "opcione": "SI", 
                        "value": 5
                    },
                    {
                        "opcione": "NO", 
                        "value": 0
                    },
                ],
                "binding": "true",
                "value":5,
                "id": "Q00000000001-2",
                "img" : [
                    "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FEscritorio%20ancho_SHOFI.png?alt=media&token=95f70c8c-0315-4c95-a088-a8ab82c9303e",
                ],
            },
            {
                "text":"¿Tiene tu escritorio una altura entre 70,5 cm y 73,5 cm?",
                "opcion":[
                    {
                        "opcione": "SI", 
                        "value": 5
                    },
                    {
                        "opcione": "NO", 
                        "value": 0
                    },
                ],
                "binding": "true",
                "value":5,
                "id": "Q00000000001-3",
                "img" : [
                    "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FEscritorio%20altura_SHOFI.png?alt=media&token=e7fe7d34-1d87-4793-8ba6-dca3b5e508ba",
                ],
            },
        ],
    },
    // Q00000000002
    {
        "text": "Actualmente cuando trabajas en casa, ¿cuentas con una silla ergonómica?",
        "opcion":[
            {
                "opcione": "SI", 
                "value": 10
            },
            {
                "opcione": "NO", 
                "value": 0
            },
        ],
        "binding": true,
        "activesub":true,
        "value":10,
        "controlResponse" : "SI", 
        "id": "Q00000000002",
        "img" : [
            "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-09.png?alt=media&token=11b3f133-21e9-4eb8-ac6a-9997c469596d",
        ],
        "subquestions":[
            {
                "text":"¿Se puede ajustar en altura tu silla ergonómica?",
                "opcion":[
                    {
                        "opcione": "SI", 
                        "value": 5
                    },
                    {
                        "opcione": "NO", 
                        "value": 0
                    },
                ],
                "binding": "true",
                "value":5,
                "id": "Q00000000002-1",
                "img" : [
                    "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-14.png?alt=media&token=a5138538-50f4-418e-b312-d2671cd0fae2",
                ],
            },
            {
                "text":"¿Tu silla ergonómica tiene soporte lumbar?",
                "opcion":[
                    {
                        "opcione": "SI", 
                        "value": 5
                    },
                    {
                        "opcione": "NO", 
                        "value": 0
                    },
                ],
                "binding": "true",
                "value":5,
                "id": "Q00000000002-2",
                "img" : [
                    "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-15.png?alt=media&token=4caf643e-d521-4068-8f53-1b875bf2e5ce",
                ],
            },
            {
                "text":"¿Tu silla ergonómica tiene una base con cinco ruedas?",
                "opcion":[
                    {
                        "opcione": "SI", 
                        "value": 5
                    },
                    {
                        "opcione": "NO", 
                        "value": 0
                    },
                ],
                "binding": "true",
                "value":5,
                "id": "Q00000000002-3",
                "img" : [
                    "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-12.png?alt=media&token=51141dbc-2748-4544-a9b7-683c6d41f8a5",
                ],
            },
            {
                "text":"¿La altura del respaldo de tu silla ergonómica cubre al menos el 75% de tu espalda?",
                "opcion":[
                    {
                        "opcione": "SI", 
                        "value": 5
                    },
                    {
                        "opcione": "NO", 
                        "value": 0
                    },
                ],
                "binding": "true",
                "value":5,
                "id": "Q00000000002-4",
                "img" : [
                    "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-13.png?alt=media&token=8fd60fae-6481-495b-b189-0447b66eb57c",
                ],
            },
            {
                "text":"¿Tu silla ergonómica tiene ajuste de altura de los brazos?",
                "opcion":[
                    {
                        "opcione": "SI", 
                        "value": 5
                    },
                    {
                        "opcione": "NO", 
                        "value": 0
                    },
                ],
                "binding": "true",
                "value":5,
                "id": "Q00000000002-5",
                "img" : [
                    "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-10.png?alt=media&token=2910fe4c-15e5-44ce-8a10-f26c65bb4ea9",
                ],
            },
            {
                "text":"¿Cuando te sientas a trabajar, tu posición te permite poner los codos cómodamente en ángulo recto, apoyando las muñecas sobre una superficie, mientras utilizas el teclado?",
                "opcion":[
                    {
                        "opcione": "SI", 
                        "value": 5
                    },
                    {
                        "opcione": "NO", 
                        "value": 0
                    },
                ],
                "binding": "true",
                "value":5,
                "id": "Q00000000002-6",
                "img" : [
                    "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-11.png?alt=media&token=254b6016-78e9-402f-893c-f849ae502591",
                ],
            },
        ],
    },
    // Q00000000003
    {
        "text":"Selecciona las respuestas que apliquen en tu caso. ¿Cuando te sientas en tu puesto de trabajo?",
        "opcion":[
            {
                "opcione": "Tus rodillas se posicionan en un ángulo de 90ª, como la img. izquierda", 
                "value": 5
            },
            {
                "opcione": "Tus piernas no caben cómodamente debajo del escritorio, como la img. derecha", 
                "value": 0
            },
        ],
        "binding": "true",
        "value":5,
        "id": "Q00000000003",
        "img" : [
            "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-01.png?alt=media&token=7ec37182-5a6f-4985-a92e-b19383de1941",
        ],
    },
    // Q00000000004
    {
        "text":"Selecciona la respuestas que aplique a tu caso: Cuando te sientas en tu puesto de trabajo...",
        "opcion":[
            {
                "opcione": "Tus pies caen rectos sobre el piso", 
                "value": 5
            },
            {
                "opcione": "Tus pies no alcanzan a tocar completamente contra el suelo", 
                "value": 0
            },
        ],
        "binding": "true",
        "value":5,
        "id": "Q00000000004",
        "img" : [
            "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-02.png?alt=media&token=f24ce419-8e2d-4988-9a85-30f997431437",
        ],
    },
    // Q00000000005
    {
        "text": "Actualmente cuando trabajas en casa, ¿cuentas con iluminación adecuada?",
        "opcion":[
            {
                "opcione": "SI", 
                "value": 5
            },
            {
                "opcione": "NO", 
                "value": 0
            },
        ],
        "binding": true,
        "activesub":true,
        "value":5,
        "controlResponse" : "SI", 
        "id": "Q00000000005",
        "img" : [
            "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-03.png?alt=media&token=2bd3c724-18b3-417b-b60b-0b1770c30a41",
        ],
        "subquestions":[
            {
                "text":"¿La iluminación es artificial?",
                "opcion":[
                    {
                        "opcione": "SI", 
                        "value": 5
                    },
                    {
                        "opcione": "NO", 
                        "value": 0
                    },
                ],
                "binding": "true",
                "value":5,
                "id": "Q00000000005-1",
                "img" : [
                    "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FLampara%201.png?alt=media&token=fc5505fa-9c5f-491b-b43e-827e008bee34",
                ],
            },
        ],
    },
    // Q00000000006
    {
        "text": "Actualmente cuando trabajas en casa, ¿cuentas con un monitor externo?",
        "opcion":[
            {
                "opcione": "SI", 
                "value": 5
            },
            {
                "opcione": "NO", 
                "value": 0
            },
        ],
        "binding": true,
        "activesub":false,
        "value":5,
        "id": "Q00000000006",
        "subquestions":[],
        "img" : [
            "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-16.png?alt=media&token=7314c80f-d10e-42aa-8d3d-1c1b1537ac55",
        ],
    },
    // Q00000000007
    {
        "text": "Actualmente cuando trabajas en casa, ¿cuentas con mouse externo?",
        "opcion":[
            {
                "opcione": "SI", 
                "value": 5
            },
            {
                "opcione": "NO", 
                "value": 0
            },
        ],
        "binding": true,
        "activesub":false,
        "value":5,
        "id": "Q00000000007",
        "subquestions":[],
        "img" : [
            "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-18.png?alt=media&token=4a6e7cdd-350a-4c2a-b5fc-393543871d49",
        ],
    },
    // Q00000000008
    {
        "text": "Actualmente cuando trabajas en casa, ¿cuentas con teclado externo?",
        "opcion":[
            {
                "opcione": "SI", 
                "value": 5
            },
            {
                "opcione": "NO", 
                "value": 0
            },
        ],
        "binding": true,
        "activesub":false,
        "value":5,
        "id": "Q00000000008",
        "subquestions":[],
        "img" : [
            "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-17.png?alt=media&token=7721d6eb-dfee-4f3f-b495-91ecf03eefeb",
        ],
    },
    // Q00000000009
    {
        "text": "Actualmente cuando trabajas en casa, ¿experimentas mucho ruido, ya sea proveniente del exterior o de interiores?",
        "opcion":[
            {
                "opcione": "SI", 
                "value": 0
            },
            {
                "opcione": "NO", 
                "value": 5
            },
        ],
        "binding": true,
        "activesub":false,
        "value":5,
        "id": "Q00000000009",
        "subquestions":[],
        "img" : [
            "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-04.png?alt=media&token=c1b331ce-7658-44da-a29b-2e899441e991",
        ],
    },
    // Q00000000010
    {
        "text": "Actualmente cuando trabajas en casa, ¿hay cortes frecuentes de energía e internet inestable?",
        "opcion":[
            {
                "opcione": "SI", 
                "value": 0
            },
            {
                "opcione": "NO", 
                "value": 5
            },
        ],
        "binding": true,
        "activesub":false,
        "value":5,
        "id": "Q00000000010",
        "subquestions":[],
        "img" : [
            "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-06.png?alt=media&token=72b068df-e606-44de-a7f4-801539dda73e",
        ],
    },
    // Q00000000011
    {
        "text": "Cerca a tu espacio de trabajo en casa, ¿hay cables u otros obstáculos que generen riesgo de tropiezo?",
        "opcion":[
            {
                "opcione": "SI", 
                "value": 0
            },
            {
                "opcione": "NO", 
                "value": 5
            },
        ],
        "binding": true,
        "activesub":false,
        "value":5,
        "id": "Q00000000011",
        "subquestions":[],
        "img" : [
            "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-08.png?alt=media&token=fe607cd8-7608-4895-a4f7-1b8c08036c08",
        ],
    },
    // Q00000000012
    {
        "text": "Cuando trabajas en tu computador, ¿tus ojos se alinean a la altura de la parte superior de tu monitor?",
        "opcion":[
            {
                "opcione": "SI", 
                "value": 5
            },
            {
                "opcione": "NO", 
                "value": 0
            },
        ],
        "binding": true,
        "activesub":false,
        "value":5,
        "id": "Q00000000012",
        "subquestions":[],
        "img" : [
            "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz_base%2FImagenes%20diagnostico%20trabajo%20en%20casa%20SHOFI-05.png?alt=media&token=8cc62683-8d62-4813-be44-893f3ec9b2f6",
        ],
    },
    // Q00000000013
    {
        "text": "Califica cómo ha sido tu experiencia de trabajo en casa hasta el momento",
        "opcion":[
            {
                "opcione": "Excelente", 
                "value": 0
            },
            {
                "opcione": "Muy Buena", 
                "value": 0
            },
            {
                "opcione": "Buena", 
                "value": 0
            },
            {
                "opcione": "Mala", 
                "value": 0
            },
            {
                "opcione": "Muy Mala", 
                "value": 0
            },
        ],
        "binding": true,
        "activesub":false,
        "value":0,
        "id": "Q00000000013",
        "subquestions":[],
        "img" : [
            "",
        ],
    },
]

export default questions;