import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../../../firebase/config";
import { Link } from "react-router-dom";
import { BiPencil} from "react-icons/bi";
import { InfoLoading } from "../../../../Loanding";

export const KitList = ({idc , compañia, rol}) => {
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    
    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                if (!idc) throw new Error("Company undefined");
                const infoCollectionRef = collection(db, 'produc_kits');
                const q = query(infoCollectionRef, where('idc', '==', idc));
                const querySnapshot = await getDocs(q);
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                if (isMounted) setInfo(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, [idc, rol]);

    const usersItems = info.filter(user => (
        user.name_kits.toLowerCase().includes(searchTerm.toLowerCase())
    ));


    if (loading) return <InfoLoading/>;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (
        <>
        {info.length!==0 
            ?<div className="windons-admin-two">
                <div className="windons-title-section">
                    <input
                        className="input-search"
                        type="text"
                        placeholder="Buscar kit..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div></div>
                    <Link className='button-new'  to="/kits/creador">
                        <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                        Crear
                    </Link>
                </div>
                <div className="windons-section-group">
                    <section className="section-info-group">
                        <h2 className="title2">Listado de kits</h2>
                        <div className="list-info-group">
                            {usersItems.map(group => (
                                <Link key={group.id} className="item-kits-group" to={`/kits/${group.id}`} >
                                    <div><BiPencil className="search"/></div>
                                    <div>
                                        <p className="name">{group.name_kits}</p>
                                        <p>{group.id}</p>
                                    </div>
                                    <div className="default-seller">
                                        {rol === "SA-001" && (
                                            <div className="contect-seller-item-kits">
                                                <p>Seller</p>
                                                <p className="name">{group.name_kits}</p>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <p className="name">{group.kits.length}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </section>
                </div>
            </div>
            :<div className="windons">
                <div className="encabezado-list">
                    <h2 className="title">Kits: {compañia}</h2>
                </div>
                <div className="windons-quiz-init">
                    <div className="section-quiz-init">
                        <div className="quiz-init-img">
                            <img alt="Shofi Encuestas" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fgroud%2FPJ_To_do_Checklist-PhotoRoom.png-PhotoRoom.png?alt=media&token=af91c841-59f3-475c-ab05-7ad9334ca226&_gl=1*hcsaao*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAzODQ5NC4zNjYuMS4xNjk5MDQwNDI3LjEuMC4w"/>
                            <h2 className="title-shofi-loanding">Crear kits<br></br>para tu equipo de trabajo</h2>
                        </div>
                        <div className="quiz-init-contect">
                            <h3 className="subtitle2">Crea kit de productos<br/>así, facilitarás tu trabajo.</h3>
                            <Link className='button-asing' to="/kits/creador">
                                <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fgroud%2Fia_book_009-PhotoRoom.png-PhotoRoom.png?alt=media&token=932e3fef-b7eb-429c-b725-4aac9dd3ae78&_gl=1*1fmcut6*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAzODQ5NC4zNjYuMS4xNjk5MDQwNjMzLjYwLjAuMA.." />
                                Crear tu primer kit
                            </Link>
                            <h3 className="subtitle2">"Una vez que tengas tus kits,<br/>podras asiganarlos a tus colaboradores.</h3>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
}