import "./K_Section_0.scss";
import { useState, useEffect} from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../../firebase/config';
import { Loading } from "../../../../Loanding";

export const KSection_0 = ({setIsCatalogue,  setIdCatalog}) => {
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const fetchKits = async () => {
            try {
                const catalogsCollectionRef = collection(db, 'catalogs');
                const querySnapshot = await getDocs(catalogsCollectionRef)
                const catalogs = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                if (isMounted) {
                    setInfo(catalogs);
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchKits()
        return () => { isMounted = false; };
    }, []);

    const handleClick = (id) => {
        setIdCatalog(id);
        setIsCatalogue(prevIsLogin => !prevIsLogin);
    };

    const handleMouseEnter = (e) => {
        e.currentTarget.className = "list-link-grand";
    };
    
    const handleMouseLeave = (e) => {
        e.currentTarget.className = "list-link";
    };
    
    if (loading) return <Loading />;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (
        <section className="section-0">
            <div className="section-0-container">
                <h2 className="title-page">Conoce nuestro <span>catálogo</span></h2>
                <div className="sectionk-0-contect">
                    {info.map((item, id) => (
                        <button
                            onClick={() => handleClick(item.id)}
                            className="list-link" 
                            key={id}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            style={{
                                position: 'relative',
                                overflow: 'hidden'
                            }}
                        >
                            <img 
                                src={item.img} 
                                alt={item.title}
                                style={{
                                    height: '100%',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                }}
                            />
                            <p className="list-title" style={{ position: 'relative' }}>
                                {item.title}
                            </p>
                        </button>
                    ))}
                </div>
            </div>
        </section>
    );
}