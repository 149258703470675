import { useEffect, useState } from "react";
import { doc, getDoc} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loading } from "../../../../Loanding";
import { ReseposeAnsweGeneral } from "./response/ReseposeAnsweGeneral";
import { ReseposeDiagnostic } from "./response/ReseposeDiagnostic";

export const ContestadorEncuestas = ({ id , idc }) => {
    const { id: ide } = useParams();
    const [encuesta, setEncuesta] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const cargarEncuesta = async () => {
            try {
                const docRef = doc(db, "quiz", ide);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setEncuesta(docSnap.data());
                } else {
                    toast.error("No se encontró la encuesta!");
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        cargarEncuesta();
    }, [ide]);
    // Rutinas de carga
    if ( error ) return <p>Error: {error.message}</p>;
    if ( loading ) return <Loading/>;


    if (encuesta.initial_diagnostic) return <ReseposeDiagnostic ide={ide}  id={id}  idc={idc}/>;
    return (
        <ReseposeAnsweGeneral  ide={ide}  id={id} idc={idc}/>
    );
};
