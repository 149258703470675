import { Link } from "react-router-dom";
import "./User.scss";
import { BiBell } from "react-icons/bi";
import { ButtonLogout } from "../../atoms/ButtonLogout/ButtonLogout";
import { FiSettings } from "react-icons/fi";
import { RiLockPasswordLine } from "react-icons/ri";
import { CgFileDocument } from "react-icons/cg";
import { useEffect, useState } from "react";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { toast } from 'react-toastify';
import { ItemAlert } from "../../atoms/ItemAlert/ItemAlert";

function reduceLength(inputString, maxLength) {
    if (!inputString || typeof inputString !== 'string') return ""; // Retorna cadena vacía si inputString no está definido o no es una cadena
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.slice(0, maxLength);
    }
}

export const User = ({nombre, apellido, email, img, setIsPassword, id} ) => {
    const [info, setInfo] = useState([]);
    const [alertCount, setAlertCount] = useState(0);
    const [emNoti, setEmNoti] = useState(false);
    const [emOpena, setEmOpena] = useState(false);
    const maxLength1 = 1;
    const maxLength2 = 16;
    const emailUse = 20;

    const shortenedName1 = reduceLength((nombre || "").toUpperCase(), maxLength1);
    const shortenedName2 = reduceLength(nombre+" "+apellido|| "", maxLength2);
    const shortenedEmail = reduceLength(email|| "", emailUse);

    const toggleEmergen = () => {
        setEmNoti(false);
        setEmOpena(!emOpena);
    }; 

    const toggleNoti = async () => {
        setEmOpena(false);
        setEmNoti(!emNoti);
        setAlertCount(0)
        const db = getFirestore();
        const alertDocRef = doc(db, 'alert', id);
        try {
            const docSnap = await getDoc(alertDocRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                // Asegúrate de que 'notification' es un array
                if (Array.isArray(data.notification)) {
                    // Cambia todos los 'status' a false
                    const updatedNotifications = data.notification.map(notification => ({
                        ...notification,
                        status: false
                    }));
                    // Actualiza el documento en Firestore
                    await updateDoc(alertDocRef, { notification: updatedNotifications });
                    // Opcionalmente, actualiza el estado local si es necesario
                    setInfo(prevInfo => ({
                        ...prevInfo,
                        notification: updatedNotifications
                    }));
                }
            }
        } catch (err) {
            toast.error('Error al actualizar el documento:', err);
        }
    };    

    const togglePassword = () => {
        setIsPassword(true);
        setEmOpena(false);
    };
    useEffect(() => {
        const fetchData = async () => {
            const db = getFirestore();
            const userDocRef = doc(db, 'alert', id);
            try {
                const docSnap = await getDoc(userDocRef);
                
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setInfo(data);
                    // Calcular y establecer el conteo de alertas
                    const count = data.notification.reduce((acc, item) => item.status === true ? acc + 1 : acc, 0);
                    setAlertCount(count);
                }
            } catch (err) {
                console.log('Error en el documento:', err);
            }
        };
        // Ejecutar la primera vez inmediatamente
        fetchData();
        // Configurar el intervalo
        const intervalId = setInterval(fetchData, 30000); 
        return () => clearInterval(intervalId);
    }, [id]);

    return (
        <div className="container-user">
            <div className="user-button">
                <button className="alert-user" onClick={toggleNoti}>
                    {alertCount !== 0 && (<p className="numb-alert-user">{alertCount}</p>)}
                    <BiBell/>
                </button>
                <button className="dates-user" onClick={toggleEmergen}>
                    <p className="name-user">{shortenedName2}</p>
                    <p className="email-name">{shortenedEmail}</p>
                </button>
                <button className="avatar-user" onClick={toggleEmergen}>
                    {img ? (
                        <div className="avatar-img"> 
                            <img alt="avatar" src={img}/>
                        </div>
                    ) : (
                        <h2 className="avatar-letter">{shortenedName1}</h2>
                    )}
                </button>
            </div>
            {emNoti&&(
                <div className="wimdons-user">
                    <section className="nav-wimdons-alert">
                        <article className="wimdons-alert">
                        {info.notification && Array.isArray(info.notification) && 
                            info.notification
                            .sort((a, b) => b.date.seconds - a.date.seconds)
                            .map(users => (
                                <ItemAlert 
                                    setEmOpena={setEmOpena}
                                    setEmNoti ={setEmNoti}
                                    key={users.id} 
                                    {...users} 
                                />
                            ))
                        }
                        </article>
                    </section>
                </div>
            )}
            {emOpena && (
                <div className="wimdons-user">
                    <section className="nav-wimdons-user">
                        <Link className="button-nav-user2" to={"/perfil"} onClick={toggleEmergen} ><FiSettings /><p>Mi Perfil</p></Link>
                        <button className="button-nav-user2" onClick={togglePassword} ><RiLockPasswordLine /><p>Seguridad</p></button>
                        <Link className="button-nav-user2" to={"/manuales"} onClick={toggleEmergen}><CgFileDocument /><p>Manuales</p></Link>
                        <ButtonLogout />
                    </section>
                </div>
            )}
        </div>
    );
}

