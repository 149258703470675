const PerfilCompany = {
    "SH00001":[
        "SA-001",
        "SA-002",
        "SA-003",
        "SA-004",
        "SA-005",
        "AA-001",
        "AA-002",
        "AA-003",
        "US-001",
        "US-002",
    ],
    "C000001": [
        "AA-001",
        "US-001",
    ],
    "C000002" : [
        "US-002",
        "AA-003",
        "US-004",
    ],
    "C000003" : [
        "US-004",
        "AA-002",
    ],
}

export default PerfilCompany;