import "./Politcas.scss"
// Secciones componentes de Nosotros
export const Tratamiento = () => {
    return ( 
        <div className="main-politics">
            <section className="contect-politycs">
                <h1 className="title">Políticas de Tratamiento de Datos Personales</h1>
                <p>Nuestro sitio web www.goshofi.com y nuestros otros desarrollos tecnológicos, como por 
                    ejemplo nuestra app SHOFI, podrán utilizar cookies. 
                    Una cookie es un pequeño archivo de texto que se almacena en su computadora, tableta o 
                    teléfono cuando visita un sitio web. Estas cookies nos permiten distinguirlo de otros usuarios 
                    del sitio web. Esto nos ayuda a brindarle una buena experiencia cuando navega por nuestra 
                    página y también nos permite mejorar nuestro sitio web</p>
                <h2 className="subtitle3">Existen dos tipos principales de cookies:</h2>
                <p>Cookies de sesión: se eliminan cuando termina de navegar por un sitio web y no se almacenan 
                    en su computadora por más tiempo.
                    Cookies persistentes: se almacenan en su computadora después de que haya terminado de 
                    usar un sitio web para que el proveedor de la página pueda recordar sus preferencias la 
                    próxima vez que lo use.
                    Las cookies pueden ser configuradas por el sitio web que está navegando, es decir, el sitio 
                    web que se muestra en la URL del navegador. Estas se llaman cookies de origen. Las cookies 
                    de terceros son establecidas por un sitio web diferente al que está navegando. Si desea 
                    conocer más información sobre cookies, sugerimos que visite la siguiente página:
                    www.allaboutcookies.org</p>
                <h2 className="subtitle3">Con su consentimiento:</h2>
                <p>Si continúa utilizando nuestro sitio web, asumiremos que está de acuerdo en recibir todas las 
                    cookies de nuestro sitio. Sin embargo, si prefiere cambiar la configuración de sus cookies, 
                    puede hacerlo en cualquier momento; consulte a continuación "Control de nuestro uso de 
                    cookies".</p>
                <h2 className="subtitle3">Control de nuestro uso de cookies:</h2>
                <p>La mayoría de los navegadores aceptan cookies automáticamente a menos que cambie la 
                    configuración de su navegador de Internet. Si desea restringir, bloquear o eliminar las cookies 
                    establecidas por cualquier sitio web, generalmente puede hacerlo a través de la configuración 
                    de su navegador. Estas configuraciones se encuentran en el menú "opciones" o "preferencias" 
                    de su navegador de Internet.
                    Si configura las preferencias de su navegador de Internet para bloquear todas las cookies, es 
                    posible que no pueda acceder a parte de nuestro sitio web.
                    Si elimina las cookies relacionadas con este sitio, no recordaremos cosas sobre usted, 
                    incluidas sus preferencias de cookies, y será tratado como visitante por primera vez la 
                    próxima vez que visite nuestra página.
                </p>
                <h2 className="subtitle3">Contacto:</h2>
                <p>Si tiene alguna pregunta o comentario sobre esta política de cookies, envíenos un correo 
                    electrónico a comunidad@goshofi.com </p>
            </section>
        </div>
    );
}
