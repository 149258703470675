import "./Carbono.scss"
import { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../../firebase/config'; // Asegúrate de ajustar esta ruta a tu configuración de Firebase
import { endOfMonth, startOfMonth, subMonths, differenceInCalendarDays } from 'date-fns';

export const Carbon = ({ idc }) => {
    const [huellaCarbonoMesAnterior, setHuellaCarbonoMesAnterior] = useState(0);

    useEffect(() => {
        let isMounted = true;

        const fetchUsers = async () => {
            try {
                // Asegúrate de que 'idc' es el campo correcto para filtrar en tu colección de usuarios
                const q = query(collection(db, 'users'), where('idc', '==', idc));
                const querySnapshot = await getDocs(q);
                const users = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    fechaCreacion: doc.data().fechaCreacion ? doc.data().fechaCreacion.toDate() : null,
                    fechaDelete: doc.data().fechaDelete && doc.data().fechaDelete !== '' ? doc.data().fechaDelete.toDate() : null,
                }));

                const startOfLastMonth = startOfMonth(subMonths(new Date(), 1));
                const endOfLastMonth = endOfMonth(subMonths(new Date(), 1));
                const valorHuellaCarbonoPorDia = 0.5; // Define aquí el valor real de huella de carbono por día trabajado

                let huellaCarbonoTotal = 0;

                users.forEach(user => {
                    const fechaInicio = user.fechaCreacion < startOfLastMonth ? startOfLastMonth : user.fechaCreacion;
                    const fechaFin = user.fechaDelete && user.fechaDelete < endOfLastMonth ? user.fechaDelete : endOfLastMonth;

                    if (user.fechaCreacion <= endOfLastMonth && (!user.fechaDelete || user.fechaDelete >= startOfLastMonth)) {
                        const diasTrabajados = differenceInCalendarDays(fechaFin, fechaInicio) + 1; // +1 para incluir ambos días
                        huellaCarbonoTotal += diasTrabajados * valorHuellaCarbonoPorDia;
                    }
                });

                if (isMounted) {
                    setHuellaCarbonoMesAnterior(huellaCarbonoTotal);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchUsers();

        return () => {
            isMounted = false;
        };
    }, [idc]);

    return (
        <div className='huella-carbono'>
            <p>Disminución CO2 : {huellaCarbonoMesAnterior.toFixed(2)} Kg.</p>
        </div>
    );
};
