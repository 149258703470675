import { createContext, useContext, useEffect, useState } from "react";
import { signInWithEmailAndPassword, onAuthStateChanged, signOut, signInWithPopup } from "firebase/auth";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/config";
import { auth, provider } from "../firebase/config";
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase/config'; // Firebase Functions
import { toast } from "react-toastify";

export const LoginContext = createContext()
export const useLoginContext = () => {
    return useContext(LoginContext)
}
export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);
export const LoginProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [message, setMessage] = useState(null);
    const [user, setUser] = useState({
        email: null,
        logged: false,
        error: null
    })
    // Login con google
    const googleLogin = () => {
        signInWithPopup(auth, provider)
            .then(async (result) => {
                const user = result.user;
                // Suponiendo que tienes un campo 'email' en tus documentos de Firestore
                const usersRef = collection (db, "users");
                const q = query(usersRef, where ("email", "==", user.email));
                const querySnapshot = await getDocs (q);
                if (querySnapshot.empty) {
                    // Manejo específico cuando el email no está registrado
                    throw new Error("El email no está registrado en nuestra base de datos.");
                }
                // El documento existe, usuario verificado correctamente
                setUser({
                    email: user.email,
                    logged: true,
                    error: null
                });
                loadUserData(user);
                setLoading(false);
                toast.success("Inicio de sesión exitoso.");
            })
            .catch((error) => {
                setUser({
                    email: null,
                    logged: false,
                    error: error.message
                });
                toast.error(error.message); // Usa el mensaje de error del catch
            })
            .finally(() => {
                setLoading(false);
            });
    };
    // Cargar datos del usuario
    const loadUserData = async (user) => {
        try {
            const userDoc = await getDoc(doc(db, "users", user.uid));
            if (!userDoc.exists()) {
                throw new Error("El documento del usuario no existe.");
            }
            const userData = userDoc.data();
            setUser({
                id: user.uid,
                email: user.email,
                logged: true,
                error: null,
                rol: userData ? userData.rol : null,
            });
        } catch (error) {
            toast.error("Error cargando datos del usuario: " + error.message);
            setUser({
                email: null,
                logged: false,
                error: "Hubo un error cargando los datos del usuario.",
            });
        }
    };
    // Login en el sistema
    const login = (values) => {
        if (!values.email || !values.password) {
            toast.error("Email y Password son requeridos.");
            setUser({
                email: null,
                logged: false,
                error: "Email y Password son requeridos."
            })
            return;
        }
        signInWithEmailAndPassword(auth, values.email, values.password)
            .then((userCredential) => {
                // El inicio de sesión fue exitoso
                const user = userCredential.user;
                toast.success("Email o clave correcta");
                setUser({
                    email: user.email,
                    logged: true,
                    error: null
                })
            })
            .catch((error) => {
                toast.error("Email o clave incorrecta", error.message);
                setUser({
                    email: null,
                    logged: false,
                    error: error.message
                })
            })
        .finally(() => setLoading(false))
    }
    // Salida o cerrar sesion
    const logout = () => {
        signOut(auth)
            .then(() => {
                setUser({
                    email: null,
                    logged: false,
                    error: null
                })
            }
        )
    }
    // Cambios de clave para usuarios con login
    const changePassword = async (newPassword) => {
        try {
            // Llamada a la función de Firebase Functions
            const changePasswordFunction = httpsCallable(functions, 'changePassword');
            const result = await changePasswordFunction({ newPassword });
            toast.success("Contraseña actualizada con éxito.");
            return result.data;
        } catch (error) {
            toast.error("Error al cambiar la contraseña: " + error.message);
            throw error;
        }
    };
    // Registros de nuevos usuarios
    const register = async (values) => {
        setIsRegistering(true);
        try {
            const registerUserFunction = httpsCallable(functions, 'registerUser');
            await registerUserFunction(values);
            setMessage({ type: 'success', text: 'Usuario registrado con éxito' });
        } catch (error) {
            setMessage({ type: 'error', text: error.message });
            setUser({
                email: null,
                logged: false,
                error: error.message
            });
        } finally {
            setIsRegistering(false);
        }
    };
    // Registros de nuevos usuarios para carga sin notificacion
    const registerProv = async (values) => {
        setIsRegistering(true);
        try {
            const registerUserFunction = httpsCallable(functions, 'registerUserProv');
            await registerUserFunction(values);
            setMessage({ type: 'success', text: 'Usuario registrado con éxito' });
        } catch (error) {
            setMessage({ type: 'error', text: error.message });
            setUser({
                email: null,
                logged: false,
                error: error.message
            });
        } finally {
            setIsRegistering(false);
        }
    };
    // Registros masivo de usuarios
    const registerUsersMassively = async (users) => {
        setLoading(true);
        try {
            const registerUsersSend = httpsCallable(functions, 'registerUsersSend');
            const result = await registerUsersSend({ users });
            // Manejo de la respuesta de la función
            if (result.data.success) {
                toast.success('Usuarios registrados exitosamente.');
            } else {
                console.log('Algunos usuarios no se pudieron registrar.');
            }
        } catch (error) {
            console.error('Error al registrar usuarios:', error);
            toast.error(`Error al registrar usuarios: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };
    // Registros masivo de usuarios para carga sin notificacion
    const registerUsersMassivelyProv = async (users) => {
        setLoading(true);
        try {
            const registerUsersSend = httpsCallable(functions, 'registerUsersSendProv');
            const result = await registerUsersSend({ users });
            // Manejo de la respuesta de la función
            if (result.data.success) {
                toast.success('Usuarios registrados exitosamente.');
            } else {
                console.log('Algunos usuarios no se pudieron registrar.');
            }
        } catch (error) {
            console.error('Error al registrar usuarios:', error);
            toast.error(`Error al registrar usuarios: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };
    // Registros invitaciones masivas
    const registerUsersMassivelyOpen = async (users) => {
        setLoading(true);
        try {
            const registerUsersSendOpen = httpsCallable(functions, 'registerUsersSendOpen');
            const result = await registerUsersSendOpen({ users });
            // Manejo de la respuesta de la función
            if (result.data.success) {
                toast.success('Usuarios registrados exitosamente.');
            } else {
                console.log('Algunos usuarios no se pudieron registrar.');
            }
        } catch (error) {
            console.error('Error al registrar usuarios:', error);
            toast.error(`Error al registrar usuarios: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };
    // Email para restablecer contraseña
    const sendPasswordResetEmail = async ( email, name ) => {
        try {
            // Llamada a tu función personalizada de Firebase Functions
            const sendPasswordReset = httpsCallable(functions, 'sendPasswordReset');
            await sendPasswordReset({  email, name  });
            toast.success({ type: 'success', text: 'Se ha enviado un correo para restablecer la contraseña.' });
        } catch (error) {
            toast.error("Error al enviar correo para restablecer contraseña: ", error);
        }
    };
    // Restablecer contraseña para otros usuarios
    const sendPasswordReset = async (email, token, newPassword) => {
        try {
            const updateUserPassword = httpsCallable(functions, 'updateUserPassword');
            await updateUserPassword({ email, token, newPassword });
            // Retorna un objeto indicando éxito y el mensaje correspondiente
            return { success: true, message: 'Contraseña actualizada correctamente.' };
        } catch (error) {
            // Retorna un objeto indicando fracaso y el mensaje de error
            return { success: false, message: error.message };
        }
    };
    // Carga de datos con login activo
    useEffect(() => {
        // Mantener la sesión del usuario y cargar datos adicionales desde Firestore
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (isRegistering) {
                // Estás en el proceso de registrar un usuario, así que no hagas nada
                toast.info("Registrando Usuario");
                return;
            }
            if (user) {
                try {
                    // Cargar datos adicionales del usuario desde Firestore
                    const userDoc = await getDoc(doc(db, "users", user.uid));
                    if (!userDoc.exists) {
                        toast.error("El documento del usuario no existe.");
                        // Puedes decidir qué hacer en este caso, como cerrar la sesión y redirigir al usuario.
                        return logout();
                    }
                    const userData = userDoc.data();
                    // Actualizar el estado del usuario
                    setUser({
                        id: user.uid, 
                        email: user.email,
                        logged: true,
                        error: null,
                        rol: userData ? userData.rol : null,
                    });
                } catch (error) {
                    toast.error("Error cargando datos del usuario:", error);
                    setUser({
                        email: null,
                        logged: false,
                        error: "Hubo un error cargando los datos del usuario.",
                    });
                }
            } else {
                // Si no hay usuario, es decir, si el usuario actual cierra la sesión, actualiza el estado a valores predeterminados
                logout();
            }
        });
        // Limpiar la suscripción al desmontar el componente
        return () => unsubscribe();
    }, [isRegistering]); // Añadir isRegistering como dependencia    
    return (
        <LoginContext.Provider value={{
            googleLogin, 
            user,
            login,
            logout,
            loading,
            register,
            message,
            setMessage,
            sendPasswordResetEmail,
            sendPasswordReset,
            changePassword,
            registerUsersMassively,
            registerUsersMassivelyOpen,
            // Registro de usuarios sin notificaciones
            registerProv,
            registerUsersMassivelyProv
            }}>
            {children}
        </LoginContext.Provider>
    )
}