/* eslint-disable jsx-a11y/iframe-has-title */
import "./Manuals.scss";
import { IoClose } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";

export const Manuals = () => {
    const { route } = useParams ();
    const navigate = useNavigate();


    const link_routes = [
        {
            url: "como-crear-usuario",
            title: "¿Como crear un usuario?",
            video:"https://www.youtube.com/watch?v=UVwPNYYJvx4&list=PLCQLz5oU9yDQWnIDNHcKKG7MuKDHT0GT7&index=1",
        },
        {
            url: "como-crear-un-grupo",
            title: "¿Como crear un grupo de trabajo?",
            video: "https://www.youtube.com/watch?v=j2BlpRr4dXY&list=PLCQLz5oU9yDQWnIDNHcKKG7MuKDHT0GT7&index=2",
        },
        {
            url: "como-cargar-usuarios-masivo",
            title: "¿Como hacer una carga masiva de usuarios?",
            video: "https://www.youtube.com/watch?v=KtqOPnaymSU&list=PLCQLz5oU9yDQWnIDNHcKKG7MuKDHT0GT7&index=3",
        },
        {
            url: "como-crear-y-asignar-kit",
            title: "¿Cómo crear y asignar un Kit en la plataforma SHOFI?",
            video: "https://www.youtube.com/watch?v=KtqOPnaymSU&list=PLCQLz5oU9yDQWnIDNHcKKG7MuKDHT0GT7&index=4",
        },
        {
            url: "como-crear-y-asignar-encuesta",
            title: "¿Cómo crear y asignar una encuesta en la plataforma SHOFI?",
            video: "https://www.youtube.com/watch?v=KtqOPnaymSU&list=PLCQLz5oU9yDQWnIDNHcKKG7MuKDHT0GT7&index=5",
        },
        {
            url: "como-crear-y-asignar-ticket-de-soporte",
            title: "¿Cómo crear un ticket de soporte en la plataforma SHOFI?",
            video: "https://www.youtube.com/watch?v=KtqOPnaymSU&list=PLCQLz5oU9yDQWnIDNHcKKG7MuKDHT0GT7&index=6",
        },
        {
            url: "como-activar-usuario",
            title: "¿Cómo activar tu usuario en la plataforma SHOFI?",
            video: "https://www.youtube.com/watch?v=3hAN4MO-kB4&list=PLCQLz5oU9yDQWnIDNHcKKG7MuKDHT0GT7&index=7",
        },
        {
            url: "como-crear-orden-de-elementos",
            title: "¿Cómo crear una orden de elementos en la plapaforma SHOFI?",
            video: "https://www.youtube.com/watch?v=3hAN4MO-kB4&list=PLCQLz5oU9yDQWnIDNHcKKG7MuKDHT0GT7&index=8",
        },
    ];

    const manualInfo = link_routes.find(manual => manual.url === route);

    return (
        <div className="windons-manuals">
            {!route?
                <>
                    <div className="windos-manuales-encabezado">
                        <img class="brand" alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&amp;token=1a9a4e13-db69-44e8-8896-57a72d5caaf4"/>
                        <h1 className="windons-manuals-title">
                            Ayuda del sistema SHOFI
                        </h1>
                    </div>
                    <div className="windos-manuales-setion">
                        <section className="windons-manuals-menu">
                            <h2 className="windons-manuals-subtitle">Administrar tu información</h2>
                            {link_routes&&
                                <>
                                    {link_routes.map((info, index) => (
                                        <Link key={index+info.title} to={`/manuales/${info.url}`} >{info.title}</Link>
                                    ))}
                                </>
                            }
                        </section>
                    </div>
                </>
                :<>
                    <div className="windos-manuales-encabezado">
                        <img class="brand" alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&amp;token=1a9a4e13-db69-44e8-8896-57a72d5caaf4"/>
                        <h1 className="windons-manuals-title">
                            Ayuda del sistema SHOFI
                        </h1>
                    </div>
                    <div className="windos-manuales-setion">
                        {manualInfo ? (
                            <section className="windons-manuals-content-video">
                                <iframe
                                    src={manualInfo.video.replace("watch?v=", "embed/")}
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>
                                </iframe>
                                <h2 className="windons-manuals-subtitle">{manualInfo.title}</h2>
                            </section>
                        ) : (
                            <section className="windons-manuals-error">
                                <p>No se encontró el manual solicitado.</p>
                            </section>
                        )}
                    </div>
                </>
            }
            <button className="close-button-xx" onClick={() => navigate(-1)}>
                <IoClose />
            </button>
        </div>
    );
};