import { Link } from "react-router-dom";
import "./ItenCategory.scss";

function reduceLength(inputString, maxLength) {
    if (!inputString || typeof inputString !== 'string') return ""; // Retorna cadena vacía si inputString no está definido o no es una cadena
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.slice(0, maxLength);
    }
}
export const ItenCategory = ({name, lengthProduct, img , id }) => {
    const shortenedName1 = reduceLength((name || "").toUpperCase(), 1);
    const shortenedName2 = reduceLength( name, 16);

    return ( 
        <Link to={`/productos/categoria/${id}`} className="item-product-category">
            <div className="avatar">
                {img ? (
                    <div className="avatar-img"> 
                    <img alt="avatar" src={img}/>
                    </div>
                ) : (
                    <h2 className="avatar-letter">{shortenedName1}</h2>
                )}
            </div>
            <p className="name-category">{shortenedName2}</p>
            <p className="id-category">{id}</p>
        </Link>
    );
}
