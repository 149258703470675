import { addDoc, collection,getDocs, query, where } from "firebase/firestore";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { FiArrowRight } from "react-icons/fi";
import { db } from '../../../../firebase/config';
import { InfoLoading } from "../../../../Loanding";
import { BiX } from "react-icons/bi";

const SEND_EMAIL_FUNCTION_URL = "https://us-central1-goshofi-c578e.cloudfunctions.net/createUserOpen"; 

async function createUserOpen(data) {
    try {
        const response = await fetch(SEND_EMAIL_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (response.ok) {
            toast.success("Email enviado con exito");
        } else {
            toast.error("Error de envio email:", response.statusText);
            const errorBody = await response.text();
            toast.error("Error body:", errorBody);
        }
    } catch (error) {
        toast.error("Error de envio email:", error);
    }
}

export const CreatorUserOpen = ({company, idc , setIsRegisterOpen, ide}) => {
    const [ loading, setLoading ] = useState(false);
    const [ formData, setFormData ] = useState({
        answer:false,
        cc: "",
        company:company,
        date:new Date(),
        email: "",
        height: "",
        idc: idc,
        ide:ide,
        ider:"",
        lastname:"",
        name:"",
        phone:"",
        sex:"",
        status:true,
        weight:"",
        year:"",
        pais:'Colombia',
        department: '',
        city: '',
        address: '',
        address2: '',
    });

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }, []);

    // eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = useState({
        email: null,
        name: null,
        lastname: null,
    }); 

    const toggleRegister = () => {
        setIsRegisterOpen(false);
    };

    const checkEmailExists = async (email) => {
        const q = query(collection(db, "users_open"), where("email", "==", email));
        const docs = await getDocs(q);
        return !docs.empty;
    };

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            toast.error('Por favor, introduce un email válido.');
            setLoading(false);
            return;
        }
        const emailExists = await checkEmailExists(formData.email);
        if (emailExists) {
            toast.error('Este email ya está registrado.');
            setLoading(false);
            return;
        }
        try {
            // Crar en data
            const docRef = await addDoc(collection(db, "users_open"), formData);
            await createUserOpen({ ...formData, id: docRef.id });
            toast.success(`Invitación enviada correctamente. ID del documento: ${docRef.id}`);
            setFormData({
                answer:false,
                cc: "",
                company:company,
                date:new Date(),
                email: "",
                height: "",
                idc: idc,
                ide:ide,
                ider:"",
                lastname:"",
                name:"",
                phone:"",
                sex:"",
                status:true,
                weight:"",
                year:"",
                pais:'Colombia',
                department: '',
                city: '',
                address: '',
                address2: '',
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error.message)
            toast.error("Error creando el documento: " + error.message);
        }
    };

    if (loading) return <InfoLoading />;
    return (
        <div className="container-quiz-open">
            <button onClick={toggleRegister} className='button-x-close'>
                <BiX />
            </button>
            <div className='section-log'>
                <div className="quiz-open-users">
                    <div className="brand" >
                        <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4" />
                    </div>
                    <h2 className="title-page-2">Enviar una nueva <span>encuesta</span></h2>
                    <form onSubmit={handleSubmit} className="form-open-users">
                        <div className='input-date-open'>
                            <label >Nombre:</label>
                            <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                            {errors.name && <p className='error-message'>{errors.name}</p>}
                        </div>
                        <div className='input-date-open'>
                            <label>Apellido:</label>
                            <input type="text" name="lastname" value={formData.lastname} onChange={handleChange} required />
                            {errors.lastname && <p className='error-message'>{errors.lastname}</p>}
                        </div>
                        <div className='input-date-open'>
                            <label>Email:</label>
                            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                            {errors.email && <p className='error-message'>{errors.email}</p>}
                        </div>
                        <button  className="button-form-user-open"  type="submit">
                            Enviar Invitación <FiArrowRight />
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};