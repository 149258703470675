import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import "./ProductStyle.scss"
// Importar db y storage desde tu archivo de configuración de Firebase
import { db, storage } from '../../../../../firebase/config';
import { doc, getDoc, updateDoc} from "firebase/firestore"; 
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// Alertas para componente
import { toast } from "react-toastify";
// Importar iconos
import {BiChevronLeft, BiX } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import { LuSave } from "react-icons/lu";
import { IoMdImages } from "react-icons/io";
import { InfoLoading } from "../../../../../Loanding";

export const EditCategory = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [mainImage, setMainImage] = useState(null);
    const [mainImagePreviewUrl, setMainImagePreviewUrl] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        description: "",
    });

    useEffect(() => {
        const loadBlogData = async () => {
            const docRef = doc(db, "category", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const blogData = docSnap.data();
                // Establecer los estados con los datos del blog
                setFormData({
                    name: blogData.name,
                    description: blogData.description,
                });
                setMainImagePreviewUrl(blogData.img);
            } else {
                toast.error("No such document!");
            }
        };
        loadBlogData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };
    
    const handleMainImageChange = (e) => {
        setMainImage(null);
        const file = e.target.files[0];
        if (file) {
            setMainImagePreviewUrl(URL.createObjectURL(file));
        } else {
            setMainImagePreviewUrl(null);
        }
        setMainImage(file);
    };
    
    const handleRemoveMainImage = () => {
        setMainImage(null);
        setMainImagePreviewUrl(null);
    };   

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            console.log("Datos del formulario:", formData); // Depuración
            const blogRef = doc(db, "category", id);
    
            let mainImageUrl = mainImagePreviewUrl;
            if (mainImage && typeof mainImage === 'object') {
                const mainImageFileName = `main-image-${formData.name}-${new Date().toISOString()}`;
                const mainImageRef = ref(storage, `product/category/${mainImageFileName}`);
                await uploadBytes(mainImageRef, mainImage);
                mainImageUrl = await getDownloadURL(mainImageRef);
            }
    
            const updateData = {
                name: formData.name,
                description: formData.description,
                img: mainImageUrl,
            };
            await updateDoc(blogRef, updateData);

            toast.success(`Categoría guardada con éxito: ${id}`);
            navigate('/productos/categoria');
        } catch (error) {
            toast.error("Error al guardar la categoría y adjuntos: ", error);
        } finally {
            setLoading(false);
        }
    };
    
    if (loading) return <InfoLoading />;
    return (
        <div className="foil-product">
            <div className="encabezado-list">
                <Link className='button-new' to="/productos/categoria"><BiChevronLeft /> Volver</Link>
                <h2 className="title">Nuevo producto</h2>
            </div>
            <form className="form-product" onSubmit={handleSubmit}>
                <div className="creator-form-product">
                    <section className="form-blogs-section">
                        <div className='input-date'>
                            <label>Name:</label>
                            <input name="name" value={formData.name} onChange={handleChange} required />
                        </div>
                        <div className='input-message'>
                            <label>Descripcion</label>
                            <textarea name="description" value={formData.description} onChange={handleChange} required />
                        </div>
                    </section>
                    <section className="form-blogs-section1">
                        <article className="button-load-section">
                            <h2 className="subtitle2">Cargan imagen principal</h2>
                            <div className='input-load'>
                                <label htmlFor="main-image-upload" className="custom-file-upload">
                                    <IoMdImages />Imagen
                                </label>
                                <input id="main-image-upload" type="file" accept="image/*" onChange={handleMainImageChange}/>
                            </div>
                        </article>
                        {mainImagePreviewUrl && (
                            <div className="container-input-img">
                                <div className="input-img">
                                    <img src={mainImagePreviewUrl} alt="Previsualización de la imagen principal" style={{ width: '100px', height: 'auto' }} />
                                    <button className="delete-img" onClick={handleRemoveMainImage}>
                                        <BiX />
                                    </button>
                                </div>
                            </div>
                        )}
                    </section>
                    <div className="cotainer-button-mens">
                        <button className='button-new' type="submit"><LuSave />Guardar</button>
                    </div>
                </div>
            </form>
        </div>
    ); 
}