
import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Función para convertir una URL de imagen a Data URL
const convertImageToDataUrl = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`No se pudo cargar la imagen: ${url}`);
    }
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error al convertir la imagen a Data URL:", error);
    return null; // O maneja el error de manera que se ajuste a tu aplicación
  }
};


export const GeneratePdfButton = ({ orden }) => {
  const generatePDF = async () => { // Marcar la función como async
    for (const dato of orden) {
      for (const order of dato.orders) {
        for (const pic of order.order_pictures) {
          pic.dataUrl = await convertImageToDataUrl(pic.file); // Convierte cada imagen a Data URL
        }
      }
    }

    const orderContent = orden.flatMap((dato) => {
      return dato.orders.flatMap(async (orden, index) => {
        const headers = [
          { text: 'Producto', style: 'tableHeader' },
          { text: 'Cantidad', style: 'tableHeader' },
          { text: 'Precio', style: 'tableHeader' },
        ];

        const body = orden.order_details.map((detail) => [
          detail.product.name,
          detail.quantity.toString(),
          detail.price.toString(),
        ]);

        const orderSection = [
          { text: `Orden ${index + 1}: Estado - ${orden.order_status.name}, Total - ${orden.total}`, margin: [0, 10, 0, 5], bold: true },
          {
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto'],
              body: [headers, ...body],
            },
            layout: 'lightHorizontalLines',
          },
        ];

        // Agregar imágenes debajo de la tabla de órdenes
        if (orden.order_pictures && orden.order_pictures.length) {
          const imagesRows = orden.order_pictures.map((pic, idx) => {
            // Verificar si la conversión fue exitosa antes de agregar la imagen
            if (pic.dataUrl) {
              return {
                image: pic.dataUrl, // Usar la Data URL convertida
                width: 180,
                margin: [0, 5, 10, 10],
              };
            } else {
              // Omitir la imagen o usar una imagen de marcador de posición
              console.error('Una imagen no se pudo convertir y se omitirá.');
              return null; // O reemplazar con un objeto que contenga una imagen de marcador de posición
            }
          }).filter(image => image !== null); // Filtrar elementos nulos

          if (imagesRows.length > 0) {
            orderSection.push({
              columns: imagesRows,
              columnGap: 10,
            });
          }
        }


        return orderSection;
      });
    });

    const documentDefinition = {
      content: await Promise.all(orderContent), // Asegurarse de resolver las promesas
      styles: {
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: 'black',
        },
      },
    };

    pdfMake.createPdf(documentDefinition).download('Lista_de_Usuarios_y_Ordenes.pdf');
  };

  return (
    <button className="button-new" onClick={generatePDF}>
      Descargar PDF
    </button>
  );
};
