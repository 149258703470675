import "./DeliveryStatusOrden.scss";
import { BiMessageAltError ,  BiPackage, BiHome , BiRocket , BiLoader , BiMessageAltX} from "react-icons/bi";

export const DeliveryStatusOrden = ({order_status_id}) => {
    // Impresion de status de envio o la orden
    let status;
    if ( order_status_id === 0)  {
        status = <p className="verification-o"><BiMessageAltError /> Verificación</p>;
    } else if ( order_status_id === 1 ||order_status_id === 2|| order_status_id === 15 ||  order_status_id === 13 || order_status_id === 14 || order_status_id === 4) {
        status = <p className="preparation-o">< BiPackage /> Preparación</p>;
    } else if ( order_status_id === 5 || order_status_id === 12 || order_status_id === 11) {
        status = <p className="route-o"><BiRocket /> En camino</p>;
    } else if ( order_status_id === 6 ) {
        status = <p className="delivered-o"><BiHome /> Entregado</p>;
    } else if ( order_status_id === 7 || order_status_id === 8 ) {
        status = <p className="cancelled-o"><BiMessageAltX /> Cancelado</p>;
    } else if ( order_status_id === "" ) {
        status = <p className="loanding-o">... Cargando</p>;
    } else {
        status = <p className="unassigned-o"><BiLoader /> Sin asignar</p>;
    }
    return (
        <div className="status-orden">
            {status}
        </div>
    );
};