// @flow 
import * as React from 'react';
import { Link } from 'react-router-dom';

export const ListTickeAdmin = ({noAsignation, teamAsignation, restSend, getStatusComponent, asignation}) => {
    return (
        <>
            {/* Tickets que NOOOO han sido contestados por el administrado */}
            {noAsignation.filter(tik => tik.status !== "close").length>=1&&<p className="title-status-ticket">Sin procesar</p>}
            {noAsignation.filter(tik => tik.status !== "close").map(tik => (
                <Link to={`/soporte/${tik.id}`} key={tik.id} className="item-ticket">
                    <div>{restSend(tik.last_answer)}</div>
                    <div>
                        <p className="name">{tik.issue}</p>
                        <p>Solicitud de: {tik.email}</p>
                        {tik.date&&<p>{new Date(tik.date.seconds * 1000).toLocaleString()}</p>}
                    </div>
                    <>{getStatusComponent(tik.status)}</>
                </Link>
            ))}
            {/* Tickets que SIII han sido contestados por el administrado  o fueron montados por el*/}
            {asignation.filter(tik => tik.status !== "close").length>=1&&<p className="title-status-ticket">Mis procesados</p>}
            {asignation.filter(tik => tik.status !== "close").map(tik => (
                <Link to={`/soporte/${tik.id}`} key={tik.id} className="item-ticket">
                    <div>{restSend(tik.last_answer)}</div>
                    <div>
                        <p className="name">{tik.issue}</p>
                        <p>Solicitud de: {tik.email}</p>
                        {tik.date&&<p>{new Date(tik.date.seconds * 1000).toLocaleString()}</p>}
                    </div>
                    <>{getStatusComponent(tik.status)}</>
                </Link>
            ))}
            {/* Tickets que fueron contestados por otro administrador */}
            {teamAsignation.filter(tik => tik.status !== "close").length>=1&&<p className="title-status-ticket">Procesado por equipo</p>}
            {teamAsignation.filter(tik => tik.status !== "close").map(tik => (
                <Link to={`/soporte/${tik.id}`} key={tik.id} className="item-ticket">
                    <div>{restSend(tik.last_answer)}</div>
                    <div>
                        <p className="name">{tik.issue}</p>
                        <p>Solicitud de: {tik.email}</p>
                        {tik.date&&<p>{new Date(tik.date.seconds * 1000).toLocaleString()}</p>}
                    </div>
                    <>{getStatusComponent(tik.status)}</>
                </Link>
            ))}
            {/* Tickets CERRADOS que SIII han sido contestados por el administrado  o fueron montados por el */}
            {asignation.filter(tik => tik.status === "close").length>=1&&<p  className="title-status-ticket">Mis cerrados</p>}
            {asignation.filter(tik => tik.status === "close").map(tik => (
                <Link to={`/soporte/${tik.id}`} key={tik.id} className="item-ticket">
                    <div>{restSend(tik.last_answer)}</div>
                    <div>
                        <p className="name">{tik.issue}</p>
                        <p>Solicitud de: {tik.email}</p>
                        {tik.date&&<p>{new Date(tik.date.seconds * 1000).toLocaleString()}</p>}
                    </div>
                    <>{getStatusComponent(tik.status)}</>
                </Link>
            ))}
            {/* Tickets CERRADOS que NOOOO han sido contestados por el administrado */}
            {noAsignation.filter(tik => tik.status === "close").length>=1&&<p  className="title-status-ticket">Cerrado sin procesar</p>}
            {noAsignation.filter(tik => tik.status === "close").map(tik => (
                <Link to={`/soporte/${tik.id}`} key={tik.id} className="item-ticket">
                    <div>{restSend(tik.last_answer)}</div>
                    <div>
                        <p className="name">{tik.issue}</p>
                        <p>Solicitud de: {tik.email}</p>
                        {tik.date&&<p>{new Date(tik.date.seconds * 1000).toLocaleString()}</p>}
                    </div>
                    <>{getStatusComponent(tik.status)}</>
                </Link>
            ))}
            {/* Tickets CERRADOS que fueron contestados por otro administrador */}
            {teamAsignation.filter(tik => tik.status === "close").length>=1&&<p  className="title-status-ticket">Cerrados por equipo</p>}
            {teamAsignation.filter(tik => tik.status === "close").map(tik => (
                <Link to={`/soporte/${tik.id}`} key={tik.id} className="item-ticket">
                    <div>{restSend(tik.last_answer)}</div>
                    <div>
                        <p className="name">{tik.issue}</p>
                        <p>Solicitud de: {tik.email}</p>
                        {tik.date&&<p>{new Date(tik.date.seconds * 1000).toLocaleString()}</p>}
                    </div>
                    <>{getStatusComponent(tik.status)}</>
                </Link>
            ))}
        </>
    );
};