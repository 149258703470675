import "./Reports.scss";
import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { CSVLink } from "react-csv";
import { db } from '../../../../firebase/config';
import { FiDownload } from "react-icons/fi";

export const ReportStockCompany = ({ idc }) => {
    const [orders, setOrders] = useState([]);
    const selectedFields = [ "idu", "name", "date_create", "date_delivery", "date_delete", "kit" , "sku" , "counter", "img"];


    useEffect(() => {
        const fetchOrders = async () => {
            let q = query(collection(db, "stock_company"), where('idc', '==', idc));
            const querySnapshot = await getDocs(q);
            let ordersData = querySnapshot.docs.map(doc => {
                const data = doc.data();
                // Convertir el timestamp de creación a una fecha formateada 
                const date_create = data.date_create?.toDate().toISOString().split('T')[0];
                const date_delivery = data.date_delivery?.toDate().toISOString().split('T')[0]; 
                const date_delete = data.date_delete?.toDate().toISOString().split('T')[0]; 
                return {
                    id: doc.id,
                    ...data,
                    date_create: date_create,
                    date_delivery: date_delivery,
                    date_delete: date_delete,
                };
            });
            setOrders(ordersData);
        };
        fetchOrders();
    }, [idc]);



    const flattenOrderData = (order) => {
        const flatData = [];
        if (order.order_product && order.order_product.length > 0) {
            order.order_product.forEach(product => {
                const flattenedProduct = { ...order };
                delete flattenedProduct.order_product; 
                flattenedProduct.product_name = product.name;
                flattenedProduct.product_quantity = product.quantity;
                flattenedProduct.product_sku = product.sku;
                flatData.push(flattenedProduct);
            });
        } else {
            flatData.push(order);
        }
        return flatData;
    };

    const generateCSVData = () => {
        return orders.flatMap(order => {
            const flattenedOrders = flattenOrderData(order);
            return flattenedOrders.map(flattenedOrder => {
                const filteredOrder = {};
                selectedFields.forEach(field => {
                    filteredOrder[field] = flattenedOrder[field];
                });
                return filteredOrder;
            });
        });
    };


    return (
        <div className="buttom-orden-container">
            {orders.length !==0 &&
                <CSVLink data={generateCSVData()} className="button-new">
                    <FiDownload /> Descargar CSV
                </CSVLink>
            }
        </div>
    );
};
