import "./RestQuizOpen.scss";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";

export const CloseRestQuiz= ({company}) => {
    return (
        <div className="body-quiz">
            <section className="contenct-body-quiz">
                <Link to="/" className="brand-quiz" >
                    <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4" />
                </Link>
                <h2 className="title-shofi-quiz">Email no esta registrado, comunicarte con el administrador de {company.namecompany}:</h2>
                <p className="link-shofi-quiz">
                <a href={`mailto:${company.emailfacturacion}`} target="_blank" rel="noreferrer">
                    <span className="il">{company.emailfacturacion}</span>
                </a>
                </p>
                <Link className='button-shofi-quiz' to={"/"}><FiArrowLeft /> Ir a web</Link>
            </section> 
        </div>
    );
};