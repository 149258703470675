import axios from 'axios';
import { Link, useParams , useNavigate} from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../../context/AuthContext';
import { InfoLoading } from '../../../../../Loanding';
import { LuSave } from 'react-icons/lu';
import { BiChevronLeft } from 'react-icons/bi';
// Alertas para componente
import { toast } from "react-toastify";

export const EdictAsingProduct = () => {
    const { sku } = useParams();
    const navigate = useNavigate();
    const { business_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);  
    const {authToken } = useContext(AuthContext);
    const [product, setProduct] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        external_id: "",
        sku: "",
        tax: "",
        cost: "",
        price: "",
        weight: "",
        large: "",
        width: "",
        height: "",
        business_id: "",
        bundle:[],
    });

    useEffect(() => {
        setLoading(true);
        const fetchProducts = async () => {
            try {
                let url = `https://f.velocity-x.co/api/v1/products/${sku}`;
                if (business_id) {
                    url += `?business_id=${business_id}`;
                }
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                });
                setProduct(response.data);
                // Actualizar formData aquí
                setFormData({
                    name: response.data.name || "",
                    sku:response.data.sku,
                    external_id: response.external_id || "",
                    tax: response.data.tax|| 0,
                    cost: response.data.cost || 0,
                    price: response.data.price || 0,
                    weight: response.data.weight|| 0,
                    large: response.data.large || 0,
                    width: response.data.width|| 0,
                    height: response.data.height || 0,
                    business_id: response.business_id || business_id,
                });
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchProducts();
    }, [authToken, sku, business_id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            let url = `https://f.velocity-x.co/api/v1/products/${sku}`;
            if (business_id) {
                url += `?business_id=${business_id}`;
            }
            const updatedProductData = {
                ...formData,
                name: formData.name,
                sku: formData.sku,
                external_id: formData.external_id,
                picture_url: product.picture_url,
                price: parseFloat(formData.price),
                tax: parseFloat(formData.tax),
                cost: parseFloat(formData.cost),
                weight: parseFloat(formData.weight),
                large: parseFloat(formData.large),
                width: parseFloat(formData.width),
                height: parseFloat(formData.height),
                business_id: formData.business_id,
                bundle:formData.bundle,
            };
    
            const response = await axios.put(url, updatedProductData, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });
            console.log(response.data);
            toast.success(`Cambios de producto guardado con éxito`);
            navigate('/productos/asignados');
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };
    if (loading) return <InfoLoading/>;
    if (error) return <p>Error: {error.message}</p>;
    if (!product) return <p>No user info</p>;
    return (
        <div className="foil-product">
            <div className="encabezado-list">
                <Link className='button-new' to="/productos/asignados"><BiChevronLeft /> Volver</Link>
                <h2 className="title">Nuevo producto</h2>
            </div>
            <form className="form-product" onSubmit={handleSubmit}>
                <div className="creator-form-product">
                    <section className="form-blogs-section">
                        <div className='input-date'>
                            <label>Name:</label>
                            <input name="name" value={formData.name} onChange={handleChange} />
                        </div>
                        <div className='input-date'>
                            <label>SKU:</label>
                            <input name="sku" value={formData.sku} onChange={handleChange}/>
                        </div>
                        <div className='input-date'>
                            <label>Tax:</label>
                            <input name="tax" value={formData.tax} onChange={handleChange} />
                        </div>

                        <div className='input-date'>
                            <label>Cost:</label>
                            <input name="cost" value={formData.cost} onChange={handleChange} />
                        </div>
                        <div className='input-date'>
                            <label>Price:</label>
                            <input name="price" value={formData.price} onChange={handleChange} />
                        </div>
                        <div className='input-date'>
                            <label>Weight:</label>
                            <input name="weight" value={formData.weight} onChange={handleChange} />
                        </div>

                        <div className='input-date'>
                            <label>Large:</label>
                            <input name="large" value={formData.large} onChange={handleChange} />
                        </div>
                        <div className='input-date'>
                            <label>Width:</label>
                            <input name="width" value={formData.width} onChange={handleChange} />
                        </div>
                        <div className='input-date'>
                            <label>Height:</label>
                            <input name="height" value={formData.height} onChange={handleChange} />
                        </div>
                    </section>
                    <div className="cotainer-button-mens">
                        <button className='button-new' type="submit"><LuSave />Guardar</button>
                    </div>
                </div>
            </form>
        </div>
    );
}