import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../../../firebase/config';
import { ButtonHome2 } from "../../atoms/ButtonHome/ButtonHome";
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

export const RegisterDownload = ({ onRegisterSuccess }) => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [userName, setUserName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false); // Nuevo estado

    const handleRegister = async (e) => {
        e.preventDefault();

        if (isSubmitting) return; // Evita el envío si ya está en proceso
        if (!recaptchaValue) {
            console.log('Por favor, verifica el reCAPTCHA');
            return;
        }

        setIsSubmitting(true); // Indica que el formulario se está enviando

        try {
            await addDoc(collection(db, 'register_dowload'), { userName, companyName, email, phone });
            onRegisterSuccess();
        } catch (error) {
            console.error("Error al registrar usuario: ", error);
        } finally {
            setIsSubmitting(false); // Finaliza el estado de envío, independientemente del resultado
        }
    };

    return (
        <div className="main-register">
            <ButtonHome2 />
            <div className='container-register'>
                <Link to="/" className="brand" >
                    <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2Fblanco%20SHOFI.png?alt=media&token=10552f6d-e2f8-432f-b592-95588b3d9a4a" />
                </Link>
                <h1 className="title">Registrate</h1>
                <h3 className="subtitle2">Descarga nuestras guias para trabajo remoto</h3>
                <form onSubmit={handleRegister} className="form-contact">
                    <div className='input-date'>
                        <label>Nombre:</label>
                        <input 
                            type="text" 
                            value={userName} 
                            onChange={e => setUserName(e.target.value)} 
                            placeholder="Nombre" 
                            required 
                        />
                    </div>
                    <div className='input-date'>
                        <label>Empresa:</label>
                        <input 
                            type="text" 
                            value={companyName} 
                            onChange={e => setCompanyName(e.target.value)} 
                            placeholder="Nombre de Empresa"                         
                            required 
                        />
                    </div>
                    <div className='input-date'>
                        <label>Email:</label>
                        <input 
                            type="email" 
                            value={email} 
                            onChange={e => setEmail(e.target.value)} 
                            placeholder="Email"                         
                            required 
                        />
                    </div>
                    <div className='input-date'>
                        <label>Teléfono:</label>
                        <input 
                            type="tel" 
                            value={phone} 
                            onChange={e => {
                                if (!isNaN(e.target.value) || e.target.value === '') {
                                    setPhone(e.target.value);
                                }
                            }} 
                            placeholder="Teléfono"
                            maxLength="10"
                            minLength="10"
                            required 
                        />
                    </div>
                    <div className='recaptcha'>
                        <ReCAPTCHA
                            sitekey="6LdgvfwnAAAAAEIbajmXn1qZoeHC4gkRsPHKDond"
                            onChange={value => setRecaptchaValue(value)}
                        />
                    </div>
                    <button className='button-form2' type="submit" disabled={isSubmitting}>Registrarse</button>
                </form>
            </div>
        </div>
    );
}