import Stack from '@mui/material/Stack';
import { PieChart } from '@mui/x-charts/PieChart';
import { GrRestroomMen, GrRestroomWomen  } from "react-icons/gr";
import { IoIosPeople } from "react-icons/io";
import "./Sex.scss";

export const Sex = ({users}) => {
    const hombres = users.filter(user => user.sexo === "hombre").length;
    const mujeres = users.filter(user => user.sexo === "mujer").length;
    const otros = users.filter(user => user.sexo === "otro").length;
    
    const data = [
        { label: 'Hombre', value: hombres },
        { label: 'Mujer', value: mujeres },
        { label: 'Otros', value: otros },
    ];

    return (
        <div className='dashboard-section  sex'>
            <div className='text-dashboard'>
                <p>Sexo: Empleados</p>
            </div>
            <div className='dates-dashboard'>
                <Stack direction="row">
                    <PieChart
                        colors={['#ffffff4d','#ffffffa1','#ffffff']} 
                        series={[
                        {
                            paddingAngle: 2,
                            innerRadius: 50,
                            outerRadius: 60,
                            cornerRadius: 19,
                            data,
                        },
                        ]}
                        margin={{ right: 5 }}
                        width={120}
                        height={120}
                        legend={{ hidden: true }}
                    />
                </Stack>
                <div className='info-dashboard'>
                    <div className='datep'><GrRestroomWomen />Mujer(s): {mujeres}</div>
                    <div className='datep'><GrRestroomMen/>Hombre(s): {hombres}</div>
                    <div className='datep'><IoIosPeople />Otros: {otros}</div>
                </div>
            </div>
        </div>
    );
}
