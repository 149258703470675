import React, { useState } from 'react';
import Papa from 'papaparse';
import { toast } from 'react-toastify'; // Ajusta la ruta según tu estructura de proyecto
import { useLoginContext } from '../../../../context/LoginContext';
import { BiRefresh } from 'react-icons/bi';
import { TbFileTypeCsv } from "react-icons/tb";
import { db } from '../../../../firebase/config'; // Asegúrate de importar la configuración de firebase
import { collection, getDocs, query, where } from "firebase/firestore";

function SendUsersOpen({ idc, ide, company }) {
  const [file, setFile] = useState(null);
  const { registerUsersMassivelyOpen } = useLoginContext(); // Usando el contexto

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const checkEmailExists = async (email) => {
    const q = query(collection(db, "users_open"), where("email", "==", email));
    const docs = await getDocs(q);
    return !docs.empty;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      toast.error("Por favor, selecciona un archivo CSV.");
      return;
    }

    // Procesar el archivo CSV
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (result) => {
        const validUsers = [];
        const emailChecks = result.data.map(async (row) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(row.email)) {
            // Acumula errores si el correo no es válido
            return `Email inválido: ${row.email}`;
          }
          const emailExists = await checkEmailExists(row.email);
          if (!emailExists) {
            // Solo agrega el usuario si el correo no existe
            validUsers.push({
              answer: false,
              company: company,
              email: row.email,
              idc: idc,
              ide: ide,
              lastname: row.apellido,
              name: row.nombre,
              status: true,
            });
          } else {
            return `Email ya registrado: ${row.email}`;
          }
        });

        // Espera a que todas las verificaciones de email se completen
        const emailCheckResults = await Promise.all(emailChecks);

        // Filtra y muestra errores
        const errors = emailCheckResults.filter((e) => e);
        if (errors.length > 0) {
          toast.error(`Errores encontrados:\n${errors.join('\n')}`);
          return;
        }

        try {
          await registerUsersMassivelyOpen(validUsers);
          toast.success("Usuarios registrados exitosamente.");
        } catch (error) {
          console.error("Error al registrar usuarios masivamente", error);
          toast.error("Error al procesar la solicitud de registro masivo.");
        }
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='input-upload'>
          <label htmlFor="csv-upload" className="custom-file-upload">
            {file ? <BiRefresh /> : <TbFileTypeCsv />}
            {file ? 'Cambiar CSV' : 'Cargar CSV'}
          </label>
          <input id="csv-upload" type="file" onChange={handleFileChange} accept=".csv" style={{ display: 'none' }} />
        </div>
        {file && <button className='button-load-cvs' type="submit">
          Cargar Usuarios
        </button>}
      </form>
    </>
  );
}

export default SendUsersOpen;
