import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import AppRouter from './router/AppRouter';
import { LoginProvider } from './context/LoginContext';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';

function gtag_report_conversion(url) {
  const callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
      'send_to': 'AW-16535374219',
      'event_callback': callback
  });
  return false;
}

window.gtag_report_conversion = gtag_report_conversion; // Hacerla global si es necesario en otros componentes

function App() {
  return (
    <LoginProvider>
      <Helmet>
        {/* Google Tag Manager */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16535374219"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'AW-16535374219');
          `}
        </script>

        {/* Meta Pixel Code */}
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1597741034298193');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>{`
          <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=1597741034298193&ev=PageView&noscript=1" />
        `}</noscript>
      </Helmet>
      
      <AppRouter />
      <ToastContainer />
    </LoginProvider>
  );
}

export default App;
