import "./Politcas.scss"
// Secciones componentes de Nosotros
export const Politicas = () => {
    return ( 
        <div className="main-politics">
            <section className="contect-politycs">
                <h1 className="title">Políticas De Seguridad De La Información</h1>
                <p>La adopción de políticas, normas y procedimientos de seguridad de la 
                    información obedece a una decisión de SHOFI, con el fin de definir el 
                    Sistema de Gestión de la Seguridad de la Información (SGSI), a través del 
                    análisis, diseño e implementación de los objetivos, requisitos de seguridad, 
                    procesos, procedimientos, planes, políticas, controles con formatos, la 
                    tecnología y estructura de esta. 
                    Se ha definido que las políticas de seguridad de la información deben 
                    identificar responsabilidades y establecer los objetivos para una 
                    protección apropiada de los activos de información de la entidad, 
                    contando además con manuales para usuarios finales. La implementación 
                    de las políticas busca reducir el riesgo de que en forma accidental o 
                    intencional se divulgue, modifique, destruya o use en forma indebida la 
                    información de la entidad.
                    Con base en lo anterior, se debe integrar a todo el personal de SHOFI para 
                    que, conozca, participe y cumpla los lineamientos, políticas, 
                    procedimientos y demás directrices estipuladas en el SGSI</p>
                <h1 className="title">Objetivos</h1>
                <h2 className="subtitle3">Objetivo General</h2>
                <p>Establecer los lineamientos que permitan proteger, asegurar y 
                    salvaguardar la confidencialidad, integridad y disponibilidad de los activos 
                    de información de SHOFI, teniendo en cuenta los procesos, la operación, 
                    los objetivos de negocio y los requisitos legales vigentes en la entidad.
                </p>
                <h2 className="subtitle3">Objetivos Específicos</h2>
                <p>-Definir la política de seguridad y privacidad de la información de 
                    SHOFI.<br></br>
                    - Definir los lineamientos a ser considerados para diseñar e 
                    implementar el Sistema de Gestión de Seguridad de la información 
                    alineado con las necesidades, los procesos, los objetivos y la 
                    operación de SHOFI.<br></br>
                    - Política de seguridad de la información– P.G.5.22 <br></br>
                    - Proteger los activos de información de SHOFI.<br></br>
                    - Mantener un sistema de políticas, manuales, procedimientos y 
                    estándares actualizados, a efectos de asegurar su vigencia y un 
                    nivel de eficacia, que permitan minimizar el nivel de riesgo de los 
                    activos de información de SHOFI<br></br>
                </p>
                <h2 className="subtitle3"> Alcance</h2>
                <p>La política de Seguridad de la Información es aplicable en todo el ciclo de 
                    vida de los activos de información de SHOFI, incluyendo creación, 
                    distribución, almacenamiento y destrucción. De igual forma para todos los 
                    funcionarios, contratistas y terceros que desempeñen alguna labor en la 
                    compañía. EI alcance abarca desde el enunciado de la política, pasando 
                    por los lineamientos para la implementación del Sistema Seguridad y 
                    Privacidad de la Información, la matriz de riesgo, la definición de los 
                    indicadores para el monitoreo de cumplimiento de la política hasta la 
                    definición de una estrategia para la adopción de la política en la entidad. 
                </p>
                <h2 className="subtitle3">Marco regulatorio y normativo</h2>
                <p>SHOFI se encuentra cubierta por un marco normativo y regulatorio en todo 
                    10 relacionado con la seguridad de la información, como también un marco 
                    de referencia de las mejores prácticas para el desarrollo e implementación 
                    del Sistema de Gestión de Seguridad de la Información.
                    A continuación, se relacionan las demás normas, leyes, decretos y 
                    resoluciones que aplican para el establecimiento, implementación y 
                    operación del SGSI en SHOFI<br></br>
                    <lu>
                        <li>NTC-ISO/IEC 27001 – 27002: 2013</li>
                        <li>Decreto 2693 de 2012 MinTic </li>
                        <li>Decreto 1008 de 2018 MinTic. </li>
                        <li>Decreto 1414 de 2017 de MinTic.</li>
                        <li>Ley 1712 de 2014. </li>
                        <li>Ley 1273 de 2009.</li>
                        <li>Decreto 1078 de 2015 MinTic. </li>
                        <li>Ley 1581 de 2012. </li>
                        <li>SHOFI - POLITICA DE SEGURIDAD DE LA INFORMACIÓN – P.G.5.22</li>
                        <li>Decreto 415 de 2016 MinTic.</li>
                        <li>Decreto 1377 de 2013.</li>
                        <li>Ley 1266 de 2008. </li>
                    </lu>
                    Se tendrán en cuenta e incluirán nuevas normas, leyes, decretos y 
                    resoluciones que se generen en esta materia y temas relacionados, a 
                    través del tiempo.
                </p>
                <h1 className="title">Políticas de Seguridad de la Información</h1>
                <h2 className="subtitle3">Políticas General</h2>
                <p>Las Políticas de Seguridad de la Información y Ciberseguridad tienen por 
                    objetivo la protección de los activos estratégicos de SHOFI que dependen 
                    o usan las tecnologías de la información y las comunicaciones, mediante 
                    la adopción e implementación de un Modelo de Seguridad y Privacidad de 
                    la Información enmarcado en el Sistema de Gestión de Seguridad de la 
                    información, proteger, preservar y administrar la confidencialidad, 
                    integridad, disponibilidad, autenticidad y no repudio de la información.
                    SHOFI asume el compromiso de implementar el sistema de Gestión de la 
                    Seguridad de la Información para proteger los activos de información de 
                    los procesos misionales, comprometiéndose a:
                    <lu>
                        <li>Establecer directrices generales relacionadas con seguridad de 
                        la información y ciberseguridad</li>
                        <li>El fomento de la cultura y toma de conciencia entre el personal 
                        (funcionarios, contratistas, proveedores y terceros) sobre la 
                        importancia de la seguridad de la información</li>
                        <li>Las responsabilidades frente a la seguridad de la información
                        serán definidas, compartidas, publicadas y aceptadas por cada 
                        uno de los empleados, proveedores, socios de negocio o terceros.</li>
                        <li>Proteger la información generada, procesada o resguardada por 
                        los procesos de negocio, su infraestructura tecnológica y activos 
                        del riesgo que se genera de los accesos otorgados a terceros (ej.
                        proveedores o clientes), o como resultado de un servicio interno 
                        en Outsourcing.</li>
                        <li>Se generará conciencia para el cambio organizacional requerido 
                        para la apropiación de la Seguridad y Privacidad de la 
                        Información.</li>
                        <li>Se protegerá las instalaciones de procesamiento y la 
                        infraestructura tecnológica que soporta sus procesos críticos.
                        </li>
                        <li>Se implementará control de acceso a la información, sistemas y 
                        recursos de red
                        </li>
                        <li> Se garantizará que la seguridad sea parte integral del ciclo de 
                        vida de los sistemas de información.
                        </li>
                        <li>Se garantizará el cumplimiento de las obligaciones legales, 
                        regulatorias y contractuales establecidas.
                        </li>
                        <li>Establecer y comunicar la responsabilidad y autoridad sobre el 
                        manejo de la seguridad de la información y la ciberseguridad de 
                        SHOFI.
                        </li>
                        <li>Orientar el debido cuidado y la debida diligencia en la gestión de 
                        la seguridad de la información y la ciberseguridad
                        </li>
                        <li> Establecer un orden y marco de actuación en temas de seguridad 
                        de la información y ciberseguridad, para todas las personas que 
                        presten sus servicios a SHOFI.
                        </li>
                        <li>Garantizar la confiabilidad, imagen y credibilidad de SHOFI con 
                        sus empleados, clientes y con la sociedad en general
                        </li>
                        <li>Definir un lenguaje común sobre la seguridad de la información y 
                        la ciberseguridad dentro de la organización.
                        </li>
                    </lu>
                </p>
                <h2 className="subtitle3"> Deberes individuales de los usuarios de la información:</h2>
                <p>
                    <ul>
                        <li>
                        Usar la información de SHOFI únicamente para propósitos del 
                        negocio autorizado y en cumplimiento de su labor.
                        </li>
                        <li>
                        Respetar la confidencialidad de la información de SHOFI.
                        </li>
                        <li>
                        No compartir perfiles de usuario, contraseñas, sesiones en 
                        estaciones de trabajo, documentos o cualquier tipo de 
                        información confidencial
                        </li>
                        <li>
                        No anotar y/o almacenar en logares visibles las contraseñas de 
                        acceso a los sistemas.
                        </li>
                        <li>
                        Bloquear la sesión de la estación de trabajo al momento de 
                        ausentarse de la misma.
                        </li>
                        <li>
                        Esta estrictamente prohibido la divulgación, cambio, retiro o 
                        pérdida no autorizada de información de la Entidad almacenada 
                        en medios físicos removibles, como USB, cintas magnéticas, 
                        entre otros.
                        </li>
                    </ul>
                </p>
                <h2 className="subtitle3"> Deberes de los responsables de personal:</h2>
                <p>
                    <ul>
                        <li>
                        Conceder autorizaciones de acceso a la información acorde con 
                        las funciones a ser realizadas por las personas a quienes se 
                        coordinan el trabajo
                        </li>
                        <li>
                        Restringir el acceso del personal a aquellas áreas que hayan sido 
                        restringidas por razones de seguridad
                        </li>
                        <li>
                        Ser el responsable de conocer, solicitar y ratificar los privilegios 
                        de acceso a los empleados que se reportan.
                        </li>
                    </ul>
                </p>
                <h2 className="subtitle3">Directrices relacionadas con el manejo de la información 
                confidencial:</h2>
                <p>
                    <ul>
                        <li>
                        Los documentos con esta información no pueden ser dejados 
                        desatendidos o inseguros.
                        </li>
                        <li>
                        Debe indicar el usuario dueño o fuente de información en la 
                        primera página o cubierta, o en algún repositorio central
                        </li>
                        <li>
                        Debe ser apropiadamente autorizado para la divulgación de 
                        acuerdo con los estándares de clasificación de la información por 
                        parte de los propietarios.
                        </li>
                        <li>
                        La divulgación cualquiera que fuere su medio, verbal, escrita, 
                        telefónica o electrónica, debe ser efectuada sobre la base de la 
                        necesidad de conocerla de acuerdo con sus funciones.
                        </li>
                        <li>
                        No debe ser accedida enviada a través de cualquier tecnología 
                        de fácil acceso, tales como teléfonos celulares o inalámbricos
                        </li>
                        <li>
                        Documentos que contengan información Confidencial deben ser 
                        impresos en un área segura o con la supervisión adecuada
                        </li>
                    </ul>
                </p>
                <h2 className="subtitle3">Control de virus:</h2>
                <p>
                    <ul>
                        <li>
                        Los servidores de archives, groupware y correo electrónico deben 
                        mantener activo un software antivirus
                        </li>
                        <li>
                        Los computadores personales y servidores deben ser analizados 
                        contra virus periódica y automáticamente.
                        </li>
                        <li>
                        Es responsabilidad de los usuarios reportar todos los incidentes 
                        de infección de virus a las áreas encargadas.
                        </li>
                        <li>
                        Ningún usuario puede escribir, distribuir introducir software que 
                        conozca sospeche que tiene virus.
                        </li>
                    </ul>
                </p>
                <h2 className="subtitle3">Acuerdo de confidencialidad:</h2>
                <p>
                    <ul>
                        <li>
                        Todos los funcionarios, contratistas, proveedores y terceros, que 
                        realicen actividades laborales para SHOFI, ya sea por medios 
                        lógicos o físicos que involucren el manejo de información, deben 
                        conocer, entender, firmar y aceptar el correspondiente acuerdo 
                        de confidencialidad de la información.
                        </li>
                        <li>
                        Se debe revisar a intervalos de tiempo regulares el texto, de los 
                        acuerdos de confidencialidad, avalando que reflejan las 
                        necesidades de la entidad para la protección y seguridad de la 
                        información.
                        </li>
                    </ul>
                </p>
                <h2 className="subtitle3"> Revisión:</h2>
                <p>Los directivos de SHOFI deben revisar y actualizar las normas, 
                procedimientos, estándares, controles, formatos y procedimientos, deben 
                ser revisados y actualizados sistemáticamente, de forma periódica y 
                planificada (mínimo una vez por periodo o cada vez que ocurra un cambio 
                sustancial en los activos de información). </p>
            </section>
        </div>
    );
}
