import { BiArchive, BiListPlus, BiMessageSquareDetail, BiUserPlus } from "react-icons/bi";
import { Link } from "react-router-dom";
import { LuAlertCircle } from "react-icons/lu";

export const ItemAlert = ({route, index, affair, message, date, setEmNoti, setEmOpena, functions}) => {
    function shortenText(inputString, maxLength) {
        if (!inputString || typeof inputString !== 'string') return "";
        return inputString.length > maxLength ? inputString.slice(0, maxLength) + "..." : inputString;
    } 

    const toggleBotton = () => {
        setEmNoti(false);
        setEmOpena(false);
    };

    let rolElement;
    if (functions === "soporte") {
        rolElement = <BiMessageSquareDetail />;
    } else if (functions === "quiz") {
        rolElement = <BiListPlus />;
    } else if (functions === "kit") {
        rolElement = <BiArchive />;
    } else if (functions === "equipo") {
        rolElement = <BiUserPlus />;
    } else {
        rolElement = <LuAlertCircle />;
    }
    return (
        <Link className="button-nav-user" to={`${route}`} key={index + affair} onClick={toggleBotton}>
            {rolElement}
            <div className="dates-alert">
                <p className="action">{shortenText(affair, 22)}</p>
                <p className="assing">{shortenText(message, 20)}</p>
                <p className="date">
                {date.toDate ? date.toDate().toLocaleString() : new Date(date.seconds * 1000).toLocaleString()}
                </p>
            </div>
        </Link>
    );
}
