import { useEffect, useState } from "react";
import { InfoLoading } from "../../../../Loanding";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { ListRegisterShofi } from "../../../UI/organisms/ListRegisterShofi/ListRegisterShofi";

export const ListRegisterDemo = () => {
    const itemsPerPage = 50;
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);
    const [ info, setInfo] = useState([]);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'form-demo');
                const querySnapshot = await getDocs(q);
                if (isMounted) {
                    const items = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
    
                    // Ordenar los elementos por fecha
                    const sortedItems = items.sort((a, b) => {
                        if (!a.date && !b.date) return 0;
                        if (!a.date) return 1;
                        if (!b.date) return -1;
                        return b.date.toDate() - a.date.toDate();
                    });
    
                    setInfo(sortedItems);
                }
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => {
            isMounted = false;
        };
    }, []);
    
    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    const numberOfPages = Math.ceil( info.length / itemsPerPage);
    const displayItems = info.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const filteredRegistros = displayItems.filter(user => (
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.company.toLowerCase().includes(searchTerm.toLowerCase())||
        user.load.toLowerCase().includes(searchTerm.toLowerCase()))
    );


    if (loading) return <InfoLoading/>;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (
        <>
            { info && ( info.length!==0)
                ?<div className="equipo-admin">
                    <ListRegisterShofi
                        info = {info}
                        handleChange = {handleChange}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        filteredRegistros = {filteredRegistros }
                        numberOfPages = {numberOfPages}
                        currentPage = {currentPage}
                    />
                </div>
                :<div className="windons">
                    <div className="encabezado-list">
                        <h2 className="title">Registro web SHOFI</h2>
                    </div>
                    <div className="windons-quiz-init">
                        <div className="section-quiz-init">
                            <div className="quiz-init-img">
                                <img alt="Shofi Encuestas" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2FPA_Education_Study_001_Jane-PhotoRoom.png-PhotoRoom.png?alt=media&token=5dcc3fdc-e433-466c-a9e1-d9fa21789220&_gl=1*1q7d33j*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI1OTcwLjE5LjAuMA.."/>
                                <h2 className="title-shofi-loanding">Esperemos reserva<br></br>de espacios de inversinistas.</h2>
                            </div>
                            <div className="quiz-init-contect">
                                <Link to={"/startco/inversionistas/creador"} className='button-new'>
                                    <img alt="Encuesta" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2Fwepik-export-20231031144702euDy-removebg-preview%20(2).png?alt=media&token=61cd801d-4fea-4490-815a-cff920b75925&_gl=1*1g4zb2g*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI3MTAxLjQuMC4w" />
                                    Crear Expositor
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}