/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import "./Nosotros.scss"
import { useState, useEffect} from 'react';
import { collection, getDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase/config';
// Secciones componentes de Nosotros
import { NSection_Description } from "../../UI/organisms/N_Section_Description/NSectionDescription";
import { N_Section_0 } from "../../UI/organisms/N_Section_0/N_Section_0";
import { N_Section_1 } from "../../UI/organisms/N_Section_1/N_Section_1";
import { N_Section_2 } from "../../UI/organisms/N_Section_2/N_Section_2";
// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import { Section0N } from "../../UI/organisms/Section_0_N/Section_0_N";
import { PageLoading } from "../../../Loanding";

// Component efectos de las secciones
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true, 
        threshold: 0.1,   
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 1000 }, 
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};

export const Nosotros = ({setIsOpenR } ) => {
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        const specificDocRef = doc(db, 'pages', 'GhVf56edFrvTSvlmeRTD');
        getDoc(specificDocRef)
            .then((document) => {
                if (document.exists) {
                    const data = {
                        ...document.data(),
                        id: document.id,
                    };
                    if (isMounted) {
                        setInfo([data]);  // Como "setInfo" espera un array, ponemos data en un array
                    }
                } else {
                    console.log("No such document!");
                }
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
        return () => { isMounted = false; };
    }, []);

    if (loading) return <PageLoading />;
    if (error) return <p>Error: {error.message}</p>;
    return ( 
        <div className="main-home">
            <div className="ellipse-div-3"/>
            <FadeInSection>
                <Section0N setLoading={setLoading} setError={setError} setIsOpenR={setIsOpenR}/>
            </FadeInSection>
            <FadeInSection>
                {info[0]?.nosotros&&<NSection_Description {...info[0]?.nosotros} />}
            </FadeInSection>
            <FadeInSection>
                {info[0]?.nosotros?.section_0 && <N_Section_0 {...info[0]?.nosotros.section_0}/>}
            </FadeInSection>
            <FadeInSection>
                {info[0]?.nosotros?.section_1 && <N_Section_1 {...info[0]?.nosotros.section_1}/>}
            </FadeInSection>
        </div>
    );
}
