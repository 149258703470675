/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLoginContext } from '../../../../context/LoginContext';
import { useState } from 'react';
import { BiExit} from "react-icons/bi";
import { Link } from 'react-router-dom';
import "./ButtomLogout.scss"

export const ButtonLogout = () => {
    const { logout } = useLoginContext()
    const [showSubMenu, setShowSubMenu] = useState(null);
    const handleClick = (subMenu) => {
        if (showSubMenu === subMenu) {
        setShowSubMenu(null);
        } else {
        setShowSubMenu(subMenu);
        }
    };
    return (
        <Link className='button-logout' onClick={() => handleClick("subUser")}>
            <a onClick={logout} className='logout'>
                <BiExit /><p>Cerrar Sesión</p>
            </a>
        </Link>
    );
}