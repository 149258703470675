import { doc, updateDoc } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../../firebase/config";
import { FiArrowRight } from "react-icons/fi";
import colombia from '../../../firebase/colombia';

export const DatesUser = ({user, idu, setLoading, onUpdateSuccess}) => {
    const [ selectedDepartment, setSelectedDepartment ] = useState('');
    const [ cities, setCities ] = useState([]);
    const [formData, setFormData] = useState({
        year: '',
        phone: '',
        cc: '',
        weight: '',
        height: '',
        sex: '',
        pais:'Colombia',
        department: '',
        city: '',
        address: '',
        address2: '',
    });

    useEffect(() => {
        if (selectedDepartment) {
            setCities(colombia[selectedDepartment]);
        }
    }, [selectedDepartment]);

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        if (name === "phone" || name === "cc" || name === "height" || name === "weight" ) {
            // Solo permite caracteres numéricos
            if (/^[0-9]*$/.test(value)) {
                setFormData(prevState => ({
                    ...prevState,
                    [name]: value
                }));
            }
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
        // Manejo de otros cambios en el formulario
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
        if (name === 'department') {
            setSelectedDepartment(value);
        }
    }, []);


    // eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = useState({
        email: null,
        phone: null,
        cc: null,
        weight: null,
        height: null,
    }); 

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(formData.phone)) {
            toast.error('Por favor, introduce un número de teléfono válido.');
            setLoading(false);
            return;
        }
        const ccRegex = /^[0-9]+$/;
        if (!ccRegex.test(formData.cc)) {
            toast.error('Por favor, introduce un número de documento válido.');
            setLoading(false);
            return;
        }
        const heightRegex = /^[0-9]+$/;
        if (!heightRegex.test(formData.height)) {
            toast.error('Por favor, introduce una altura válida.');
            setLoading(false);
            return;
        }
        const  weightRegex = /^[0-9]+$/;
        if (! weightRegex.test(formData.weight )) {
            toast.error('Por favor, introduce un peso válido.');
            setLoading(false);
            return;
        }
        try {
            const userDocRef = doc(db, 'users_open', idu);
            await updateDoc(userDocRef, {
                ...formData,
                status:false,
            });
            onUpdateSuccess(); 
        } catch (error) {
            setLoading(false);
            toast.error("Error updating document: ", error);
        }
    };

    return (
        <div className="body-quiz">
            <section className="contenct-body-quiz">
                <div className="brand-quiz" >
                    <img alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4" />
                </div>
                <h2 className="title-shofi-quiz">
                    "Hola {user.name} {user.lastname}, bienvenido a la encuesta diagnóstica. Iniciemos con algunos datos personales que necesitaremos de ti:"
                </h2>
                <form onSubmit={handleSubmit} className="dates-quiz">
                    <section  className="form-dates-quiz">
                        <div className='input-date'>
                            <label>Teléfono:</label>
                            <input  name="phone" value={formData.phone} onChange={handleChange} required />
                            {errors.phone && <p className='error-message'>{errors.phone}</p>}
                        </div>
                        <div className='input-date'>
                            <label>Cedula CC / PEP:</label>
                            <input name="cc" value={formData.cc} onChange={handleChange} required />
                            {errors.cc && <p className='error-message'>{errors.cc}</p>}
                        </div>
                        <div className='input-date'>
                            <label>Sexo:</label>
                            <select name="sex" value={formData.sex} onChange={handleChange} required>
                                <option value="" disabled>Seleccione</option>
                                <option value="hombre">Hombre</option>
                                <option value="mujer">Mujer</option>
                                <option value="otro">Otro</option>
                            </select>
                        </div>
                        <div className='input-date'>
                            <label>Tu edad:</label>
                            <select name="year" value={formData.year} onChange={handleChange} required>
                                <option value="" disabled>Edad</option>
                                {
                                    Array.from({length: 65 - 18 + 1}, (v, k) => 18 + k).map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))
                                }
                            </select>
                        </div>
                        <div className='input-date'>
                            <label>País:</label>
                            <select name="pais" value={formData.pais} onChange={handleChange} required>
                                <option value="Colombia">Colombia</option>
                            </select>
                        </div>
                        <div className='input-date'>
                            <label>Departamento:</label>
                            <select name="department" value={selectedDepartment} onChange={handleChange} required>
                                <option value="" disabled>Seleccione </option>
                                {Object.keys(colombia).map((department) => (
                                <option key={department} value={department}>{department}</option>
                                ))}
                            </select>
                        </div>
                        <div className='input-date'>
                            <label>Ciudad:</label>
                            <select name="city" value={formData.city} onChange={handleChange} required>
                                <option value="" disabled>Seleccione</option>
                                {cities.map((city) => (
                                <option key={city} value={city}>{city}</option>
                                ))}
                            </select>
                        </div>
                        <div className='input-date'>
                            <label>Dirección:</label>
                            <input type="text" name="address" value={formData.address} onChange={handleChange} required />
                        </div>
                        <div className='input-date'>
                            <label>Dirección detallada:</label>
                            <input type="text" name="address2" value={formData.address2} onChange={handleChange} />
                        </div>
                        <div className='input-date'>
                            <label>Tu altura:(en centimentros)</label>
                            <input  name="height" value={formData.height} onChange={handleChange} required />
                            {errors.height && <p className='error-message'>{errors.height}</p>}
                        </div>
                        <div className='input-date'>
                            <label>Tu peso:(en kilos)</label>
                            <input name="weight" value={formData.weight} onChange={handleChange} required />
                            {errors.weight && <p className='error-message'>{errors.weight}</p>}
                        </div>
                    </section>
                    <button className='button-shofi-quiz' type="submit" >
                        Continuar
                        <FiArrowRight />
                    </button>
                </form>
           </section>
        </div>
    );
};
