const rolusers =[
    // Perfil de Super Admin
    {
        id:"SA-001",
        name_rol:"Developer",
        limit:null,
    },
    {
        id:"SA-002",
        name_rol:"Comercial",
        limit:null,
    },
    {
        id:"SA-003",
        name_rol:"Administrativo",
        limit:null,
    },
    {
        id:"SA-004",
        name_rol:"Logistica",
        limit:null,
    },
    {
        id:"SA-005",
        name_rol:"Marketing",
        limit:null,
    },
    // Perfiles de Admin de compañias
    {
        id:"AA-001",
        name_rol:"Admin",
        limit:3,
    },
    {
        id:"AA-002",
        name_rol:"Provicional",
        limit:1,
    },
    {
        id:"AA-003",
        name_rol:"Admin Central",
        limit:1,
    },
    // Perfiles de Usuario
    {
        id:"US-001",
        name_rol:"Usuario",
        limit:null,
    },
    {
        id:"US-002",
        name_rol:"Usuario Central",
        limit:null,
    },
    {
        id:"US-003",
        name_rol:"Usuario Provicional",
        limit:null,
    },
    {
        id:"US-004",
        name_rol:"Colaborador",
        limit:null,
    }
];


export default rolusers;

