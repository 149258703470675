import "./ItenOrdenReasiganation.scss";
import { useEffect, useState } from "react";
import {doc, getDoc} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { formateDate } from "../../../../formateDate";


export const ItenOrdenReasiganation = ({date_delivery, date_create, date_delete ,kit ,products}) => {
    const [nameKit, setNameKit] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const fetchKits = async () => {
            try {
                const userDocRef = doc(db, 'produc_kits', kit);
                const userDoc = await getDoc(userDocRef);
                if(userDoc.exists() && isMounted) {
                    setNameKit(userDoc.data().name_kits); // Aquí se establece el valor inicial de nameKits
                }
            } catch (error) {
                if (isMounted) {
                    console.log(error);
                }
            } 
        };
        fetchKits();
        return () => { isMounted = false; };
    }, [kit]);


    const fechaCreate= formateDate(date_create);
    const fechaDelivery= formateDate(date_delivery);
    const fechaDelete= formateDate(date_delete);
    return ( 
        <div className="iten-asiganation">
            <div className="iten-asiganation-contect">
                <div className="iten-asiganation-contect-title">
                    <p className="subtitle3">Nombre kit: {nameKit}</p>
                </div>
                <div className="iten-asiganation-contect-date">
                    <p className="date-creation">Creacion: {fechaCreate}</p>
                    <p className="date-delivery">Entrega: {fechaDelivery}</p>
                    <p className="date-delete">Recoleccón: {fechaDelete}</p>
                </div>
            </div>
            <div className="container-list-product-asignation">
                <div className="iten-asiganation-produc">
                    <p>img.</p>
                    <p>Descripción</p>
                    <p>SKU</p>
                    <p>Qty.</p>
                </div> 
                {products.map(items => (
                    <div className="iten-asiganation-produc">
                        <img src={items.img} alt={items.name}/>
                        <p>{items.name}</p>
                        <p>{items.sku}</p>
                        <p>{items.counter}</p>
                    </div> 
                ))}
            </div>
        </div>
    );
}