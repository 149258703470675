import Slider from "react-slick";
import "./Section_1.scss";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase/config";

export const Section1 = ({ image, settings,  setLoading, setError}) => {
    const [info, setInfo] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const specificDocRef = doc(db, 'sections_page', 'BBKMjZNZRJdmBMS3ldpm');
        getDoc(specificDocRef)
            .then((document) => {
                const data = {
                    ...document.data(),
                    id: document.id,
                };
                setInfo(data); 
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
        return () => { isMounted = false; };
    }, [setError, setLoading]);


    return (
        <section className="section-1-contect">
            <div className="section-1">
                {info.slider &&
                    <Slider {...settings}>
                        {info.slider.map((item, index) => (
                            <div className="img-carrusel">
                                {/* <a href={item.url} target="_blank" rel="noopener noreferrer"> */}
                                <a>
                                    <img className="img-section-1" key={index} alt="card-contenido" src={item.img} />
                                </a>
                            </div>
                        ))}
                    </Slider>
                }
            </div>
        </section>
    );
}
