import { ButtonLogout } from "../../atoms/ButtonLogout/ButtonLogout";
import { NavMenuAdmin } from "../../atoms/NavMenuAdmin/NavMenuAdmin";
import "./MovileMenuAdmin.scss";
export const MovileMenuAdmin = ({ closeMovila , isOpena, rol, setIsOpena}) => {
    return (
        <>
            {isOpena && (<div className="movile-menu-admin">
                <NavMenuAdmin
                    setIsOpena={setIsOpena}
                    rol={rol}
                />
                <ButtonLogout />
            </div>)}
        </>
    );
}