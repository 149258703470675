const restquestions=[
    {
        "id": "Q00000000001",
        "mainQuestion": "SI",
        "subquestions": {
            "Q00000000001-1": "SI",
            "Q00000000001-2": "SI",
            "Q00000000001-3": "SI"
        }
    },
    {
        "id": "Q00000000002",
        "mainQuestion": "SI",
        "subquestions": {
            "Q00000000002-1": "SI",
            "Q00000000002-2": "SI",
            "Q00000000002-3": "SI",
            "Q00000000002-4": "SI",
            "Q00000000002-5": "SI",
            "Q00000000002-6": "SI",
        }
    },
    {
        "id": "Q00000000003",
        "mainQuestion": "Tus rodillas se posicionan en un ángulo de 90ª, como la img. izquierda",
        "subquestions": {}
    },
    {
        "id": "Q00000000004",
        "mainQuestion": [
            "Tus pies caen rectos sobre el piso",
        ],
        "subquestions": {}
    },
    {
        "id": "Q00000000005",
        "mainQuestion": "SI",
        "subquestions": {
            "Q00000000005-1": "SI"
        }
    },
    {
        "id": "Q00000000006",
        "mainQuestion": "SI",
        "subquestions": {}
    },
    {
        "id": "Q00000000007",
        "mainQuestion": "SI",
        "subquestions": {}
    },
    {
        "id": "Q00000000008",
        "mainQuestion": "SI",
        "subquestions": {}
    },
    {
        "id": "Q00000000009",
        "mainQuestion": "NO",
        "subquestions": {}
    },
    {
        "id": "Q00000000010",
        "mainQuestion": "SI",
        "subquestions": {}
    },
    {
        "id": "Q00000000011",
        "mainQuestion": "NO",
        "subquestions": {}
    },
    {
        "id": "Q00000000012",
        "mainQuestion": "SI",
        "subquestions": {}
    },
    {
        "id": "Q00000000013",
        "mainQuestion": ["Excelente", "Muy Buena", "Buena"],
        "subquestions": {}
    },    
]


export default restquestions;