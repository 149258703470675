import "./TraySupport.scss"
import { doc, getDoc, updateDoc, Timestamp, arrayUnion, collection, query, where, getDocs  } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db , storage } from "../../../../firebase/config";
import { InfoLoading } from "../../../../Loanding";
import { toast } from "react-toastify";
import { BiChevronLeft, BiPaperclip, BiSend, BiX } from "react-icons/bi";
import { FiMessageSquare } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';

const SEND_EMAIL_FUNCTION_URLADMIN = "https://us-central1-goshofi-c578e.cloudfunctions.net/sendSupportInfo"; 

const getUserDetailsFromUserIdsSuper = async (userIds) => {
    const userDetails = [];
    for (const userId of userIds) {
        const userRef = doc(db, 'users', userId);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
            const userData = docSnap.data();
            userDetails.push({
                email: userData.email,
                name: userData.nombre,
                lastName: userData.apellido,
            });
        }
    }
    return userDetails;
};

async function sendSupportSuperAdmin(data) {
    try {
        const payload = {
            ...data,
        };
        const response = await fetch(SEND_EMAIL_FUNCTION_URLADMIN, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        if (response.ok) {
            toast.success("Ticket enviado con éxito");
        } else {
            const errorBody = await response.text();
            toast.error(`Error de envío de ticket: ${response.statusText}`);
            toast.error(`Detalle del error: ${errorBody}`);
        }
    } catch (error) {
        console.error("Error en Ticket:", error);
        toast.error(`Error de envío de email: ${error.message}`);
    }
}


const SEND_EMAIL_FUNCTION_URL = "https://us-central1-goshofi-c578e.cloudfunctions.net/sendSupportRest"; 

const getUserDetailsFromUserIds = async (userIds) => {
    const userDetails = [];
    for (const userId of userIds) {
        const userRef = doc(db, 'users', userId);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
            const userData = docSnap.data();
            userDetails.push({
                email: userData.email,
                name: userData.nombre,
                lastName: userData.apellido,
            });
        }
    }
    return userDetails;
};

async function sendSupport(data) {
    try {
        // Ya no necesitas mapear sobre 'emails', ya que 'data.users' es el array de usuarios
        const payload = {
            ...data,
        };
        const response = await fetch(SEND_EMAIL_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            toast.success("Ticket enviado con éxito");
        } else {
            const errorBody = await response.text();
            toast.error(`Error de envío de ticket: ${response.statusText}`);
            toast.error(`Detalle del error: ${errorBody}`);
        }
    } catch (error) {
        console.error("Error en Ticket:", error);
        toast.error(`Error de envío de email: ${error.message}`);
    }
}

export const ConversationSupport = ({idu, rol, email, idc}) => {
    const { id : ticketId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ticketInfo, setTicketInfo] = useState({ respuestas: [] });
    const [response, setResponse] = useState("");
    const [files, setFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [userEmail, setUserEmail] = useState ([]);
    const [isTicketClosed, setIsTicketClosed] = useState(false);
    const [commerce, setCommerce]= useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTicketInfo = async () => {
            try {
                const ticketDocRef = doc(db, "tickets", ticketId);
                const ticketDoc = await getDoc(ticketDocRef);
                if (!ticketDoc.exists()) {
                    throw new Error("Ticket no encontrado.");
                }
                const ticketData = ticketDoc.data();
                setTicketInfo(ticketData);
                // Asegúrate de que 'respuestas' es un array y extrae los 'idu'
                if (Array.isArray(ticketData.answers_chat)) {
                    const userIds = ticketData.answers_chat
                        .map(answers_chat => answers_chat.idu)
                        .filter(uniqueId => uniqueId !== idu); // Filtra para excluir 'excludedId'
                    // Elimina los duplicados
                    const uniqueUserIds = Array.from(new Set(userIds));
                    setSelectedGroup(uniqueUserIds);
                }
                
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchTicketInfo();
    }, [ticketId , idu]);
    
    useEffect(() => {
        const fetchGroup = async () => {
            try {
                if (!idc) throw new Error("idc es indefinido");
                    const kitsCollectionRef = collection(db, 'users');
                    const company = query(kitsCollectionRef, where('idc', '==', idc));
                    const q = query(company,  where('rol', 'in', ["AA-001", "AA-002", "AA-003"]));
                    const querySnapshot = await getDocs(q);
                    const fetchedIds = querySnapshot.docs.map(doc => doc.id);
                setUserEmail(fetchedIds);
            } catch (err) {
                setError(err);
            }
        };
        fetchGroup();
        const loadBlogData = async () => {
            try {
                if (!idc) throw new Error("idc es indefinido");
                const docRef = doc(db, "company", idc);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setCommerce(docSnap.data());
                } else {
                    toast.error("No such document!");
                }
            } catch (error) {
                toast.error("Failed to fetch data: " + error.message);
            }
        };
        loadBlogData();
    }, [idc]); 

    const handleResponseChange = (e) => {
        setResponse(e.target.value);
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        // Agregar los nuevos archivos a los ya existentes
        setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
        // Crear previsualizaciones para los nuevos archivos y agregarlas a las existentes
        const newPreviewUrls = selectedFiles.map(file => URL.createObjectURL(file));
        setPreviewUrls(prevUrls => [...prevUrls, ...newPreviewUrls]);
    };
    
    
    const handleRemoveFile = (index) => {
        const newFiles = files.filter((_, fileIndex) => fileIndex !== index);
        const newPreviewUrls = previewUrls.filter((_, urlIndex) => urlIndex !== index);
        setFiles(newFiles);
        setPreviewUrls(newPreviewUrls);
    };    



    let idAdmin = (rol === "AA-001" || rol === "AA-002" || rol === "AA-003") ? idu : "";
    let idAsignationAdmin;
    if ( ticketInfo.assigned_id === "" ){
        idAsignationAdmin = idAdmin
    } else {
        idAsignationAdmin = ticketInfo.assigned_id
    }

    const functions_team  =[
        {
            value:"Avería de equipos",
            id_team:[
                "yK3rZNV80XSNWhIxOH71m4bA6lm2",
                "QPbqy71UXwOPNscDzfrZeXfCQAx1",
                "0YbxmJDyabQIsF2FlVCjU8Mod7q1",
                commerce.id_commercial,
            ],
        },
        {
            value:"Contabilidad & Facturacion",
            id_team:[
                "yK3rZNV80XSNWhIxOH71m4bA6lm2",
                commerce.id_commercial,
            ],
        },
        {
            value:"Falla en plataforma",
            id_team:[
                "QPbqy71UXwOPNscDzfrZeXfCQAx1",
                commerce.id_commercial,
            ],
        },
        {
            value:"Contactar con Comercial",
            id_team:[
                commerce.id_commercial,
            ],
        },
    ];

    const filteredTeamsEscale = functions_team
        .filter(team => team.value === ticketInfo.issue)
        .map(team => (team.id_team));
    
    let SuperAdmin_id = [];
    if (ticketInfo.scalability === true && Array.isArray(ticketInfo.super_admin_id)) {
        SuperAdmin_id = ticketInfo.super_admin_id;
    }

    const sendEmailAdmin = [...selectedGroup.flat(), ...(Array.isArray(SuperAdmin_id) ? SuperAdmin_id.flat() : []), idu, ticketInfo.assigned_id, ticketInfo.last_answer];
    // Filtramos elementos vacíos y el id específico 'idu'
    const filterEmptyAndSpecificId = sendEmailAdmin.filter(emailId => emailId && emailId !== idu);
    // Filtramos elementos duplicados
    const sendAdminFiltered = [...new Set(filterEmptyAndSpecificId)];

    let sendEmail;
    if (rol === "US-001" || rol === "US-002"){
        sendEmail=(ticketInfo.assigned_id ==="")? userEmail : sendAdminFiltered;
    } else {
        sendEmail=sendAdminFiltered
    }
    
    const handleEscaleTicket = async ()=>{
        const sendEmailSuperAdmin =filteredTeamsEscale.flat();
        const EmailSuperAdmin = [...new Set(sendEmailSuperAdmin)];
        try{
            const ticketDocRef = doc(db, "tickets", ticketId);
            await updateDoc(ticketDocRef, {
                scalability:true,
                date: Timestamp.fromDate(new Date()),
                super_admin_id:EmailSuperAdmin,
                assigned_id: idAsignationAdmin,
            });
            // Actualiza los documentos en la colección 'alert' para cada ID en selectedGroup
            const notificationPayload = {
                date: new Date(), 
                affair: `Un ticket a escalado de la compañia ${commerce.namecompany}`,
                functions: "soporte",
                message: response ,
                route: `/soporte/${ticketId}`,
                status: true,
            };
            // Aquí actualizas el documento en la colección 'alert'
            for (const userId of EmailSuperAdmin) {
                const alertDocRef = doc(db, 'alert', userId);
                await updateDoc(alertDocRef, {
                    notification: arrayUnion(notificationPayload)
                });
            }
            const userDetails = await getUserDetailsFromUserIdsSuper(EmailSuperAdmin);
            const payload = {
                users: userDetails,
                affair: `Un ticket a escalado de la compañia ${commerce.namecompany}`,
                comment: ticketInfo.description,
                id:ticketId,
                timestamp: new Date(),
                event: ticketInfo.issue,
            };
            await sendSupportSuperAdmin(payload);
            navigate('/soporte');
            toast.success("Ticket escalado al equipo de SHOFI");
        } catch (err) {
            toast.error("Error al escalar el ticket. Por favor, inténtelo de nuevo.");
        }
    }
    // Proceso de cierre de ticket
    const handleCloseTicket = async () => {
        try {
            const ticketDocRef = doc(db, "tickets", ticketId);
            await updateDoc(ticketDocRef, {
                status: 'close',
                dateClose: Timestamp.fromDate(new Date()),
                assigned_id: idAsignationAdmin,
            });
            // Actualiza los documentos en la colección 'alert' para cada ID en selectedGroup
            const notificationPayload = {
                date: new Date(), 
                affair: "Ticket Cerrado",
                functions: "soporte",
                message: response ,
                route: `/soporte/${ticketId}`,
                status: true,
            };
            // Aquí actualizas el documento en la colección 'alert'
            for (const userId of sendEmail) {
                const alertDocRef = doc(db, 'alert', userId);
                await updateDoc(alertDocRef, {
                    notification: arrayUnion(notificationPayload)
                });
            }
            const userDetails = await getUserDetailsFromUserIds(sendEmail);
            const payload = {
                users: userDetails,
                affair: `Eventualidad cerrada ${ticketId}`,
                comment: response,
                id:ticketId,
                timestamp: new Date()
            };
            await sendSupport(payload);
            setIsTicketClosed(true);
            navigate('/soporte');
        } catch (err) {
            toast.error("Error al cerrar el ticket. Por favor, inténtelo de nuevo.");
        }
    };    
    // Proceso de cierre de respuesta
    const handleSubmitResponse = async () => {
        if (!response) {
            toast.error("Por favor, ingrese una respuesta.");
            return;
        }
        const fileUrls = await Promise.all(files.map(async (file) => {
            const fileRef = ref(storage, `support/${file.name}`);
            await uploadBytes(fileRef, file);
            return getDownloadURL(fileRef);
        }));
        try {
            const ticketDocRef = doc(db, "tickets", ticketId);
            const newResponse = {
                message: response,
                date: Timestamp.fromDate(new Date()),
                idu : idu,
                email: email,
                files: fileUrls,
            };
            await updateDoc(ticketDocRef, {
                answers_chat: [...ticketInfo.answers_chat, newResponse],
                last_answer:idu,
                date:new Date(),
                assigned_id: idAsignationAdmin,
            });
            setTicketInfo({
                ...ticketInfo, 
                answers_chat: [...ticketInfo.answers_chat, newResponse],
            });
            // Actualiza los documentos en la colección 'alert' para cada ID en selectedGroup
            const notificationPayload = {
                date: new Date(), 
                affair: ticketInfo.event,
                functions: "soporte",
                message: response ,
                route: `/soporte/${ticketId}`,
                status: true,
            };
            // Aquí actualizas el documento en la colección 'alert'
            for (const userId of sendEmail) {
                const alertDocRef = doc(db, 'alert', userId);
                await updateDoc(alertDocRef, {
                    notification: arrayUnion(notificationPayload)
                });
            }
            const userDetails = await getUserDetailsFromUserIds(sendEmail);
            const payload = {
                users: userDetails,
                affair: ticketInfo.event,
                comment: response,
                id:ticketId,
                timestamp: new Date()
            };
            await sendSupport(payload);
            // Resetear el estado del formulario
            setResponse("");
            setFiles([]); // Añadir esta línea para limpiar los archivos
            setPreviewUrls([]);
        } catch (err) {
            toast.error("Error al enviar la respuesta. Por favor, inténtelo de nuevo.");
        }
    };

    const messagesEndRef = useRef(null);
    // Función para desplazar el scroll al final
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    // useEffect para actualizar el scroll cuando cambien las respuestas
    useEffect(() => {
        scrollToBottom();
    }, [ticketInfo.respuestas]);

    if (loading) return <InfoLoading />;
    if (error) return <p>Error: {error.message}</p>;
    if (!ticketInfo) return <p>El ticket solicitado no está disponible.</p>;

    return (
        <div className="windons-messenger">
            <div className="windons-title-messenger">
                <div className="title-info-messenger">
                    <h2 className="subtitle3">Ticket:{ticketId}</h2>
                    <p className="subtitle4">Asunto:{ticketInfo.issue}</p>
                </div>
                <div className="container-button-messenger">
                    <button className='button-delete' onClick={handleCloseTicket}><BiX/><p>Cerrar</p></button>
                    {!ticketInfo.scalability && (rol === "AA-001" || rol === "AA-002" || rol === "AA-003") && (
                        <button className="button-save" onClick={handleEscaleTicket}>
                            <BiSend /><p>Escalar</p>
                        </button>
                    )}
                    <Link className='button-new' to="/soporte"><BiChevronLeft /><p>Volver</p></Link>
                </div>
            </div>
            <div className="windons-section-info-messenger">
                <section className="section-info-messenger">
                    <div className="messenger">
                        <div className="container-messenger">
                            {(ticketInfo.respuestas)&&ticketInfo.respuestas.map((resp, index) => (
                                <div key={index + idu} className={(idu === resp.idu) ? "container-messenger-hot" : "container-messenger-send"}>
                                    <section className={(idu === resp.idu) ? "mensseger-hot" : "mensseger-send"}>
                                        {/* Renderizar solo si hay imágenes */}
                                        {resp.archivos && resp.archivos.length > 0 && (
                                            <div className="messenger-img" style={{ gridTemplateColumns: `repeat(${Math.min(resp.archivos.length, 4)}, 1fr)` }}>
                                                {resp.archivos.map((img, index) => (
                                                    <a key={index + img} href={img} target="_blank" rel="noopener noreferrer">
                                                        <img alt="Soporte" src={img} />
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                        <p className="messenger">{resp.mensaje}</p>
                                        <p className="date-messenger">{resp.email}<br></br>{resp.fecha.toDate ? resp.fecha.toDate().toLocaleString() : new Date(resp.fecha.seconds * 1000).toLocaleString()}</p>
                                    </section>
                                    <div ref={messagesEndRef} />
                                </div>
                            ))}
                            {(ticketInfo.answers_chat)&&ticketInfo.answers_chat.map((resp, index) => (
                                <div key={index + idu} className={(idu === resp.idu) ? "container-messenger-hot" : "container-messenger-send"}>
                                    <section className={(idu === resp.idu) ? "mensseger-hot" : "mensseger-send"}>
                                        {/* Renderizar solo si hay imágenes */}
                                        {resp.files && resp.files.length > 0 && (
                                            <div className="messenger-img" style={{ gridTemplateColumns: `repeat(${Math.min(resp.files.length, 4)}, 1fr)` }}>
                                                {resp.files.map((img, index) => (
                                                    <a key={index + img} href={img} target="_blank" rel="noopener noreferrer">
                                                        <img alt="Soporte" src={img} />
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                        <p className="messenger">{resp.message}</p>
                                        <p className="date-messenger">{resp.email}<br></br>{resp.date.toDate ? resp.date.toDate().toLocaleString() : new Date(resp.fecha.seconds * 1000).toLocaleString()}</p>
                                    </section>
                                    <div ref={messagesEndRef} />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section className="section-info-messenger1">
                    {(ticketInfo.status!=="close")
                    ?<article className="form-info-messenger">
                        <div className="form-scroll-messenger">
                            <div className='input-date-messenger'>
                                <label>Mensaje</label>
                                <textarea value={response} onChange={handleResponseChange} required disabled={isTicketClosed}/>
                            </div>
                            <div className='input-load-messenger'>
                                <label htmlFor="file-upload" className="custom-file-upload">
                                    <BiPaperclip />Imágenes
                                </label>
                                <input id="file-upload" type="file" accept=".jpg, .png" onChange={handleFileChange} multiple disabled={isTicketClosed}/>
                            </div>
                            {previewUrls&&
                                <div className="container-input-img-messenger">
                                    {previewUrls.map((url, index) => (
                                        <div key={index} className="input-img">
                                            <img src={url} alt={`Previsualización ${index}`} style={{ width: 'auto', height: '100px' }} />
                                            <button className="delete-img" onClick={() => handleRemoveFile(index)}>
                                                <BiX/>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            }
                            <div className="cotainer-button-messenger">
                                <button className='button-new' onClick={handleSubmitResponse} disabled={isTicketClosed}>Enviar mensaje <FiMessageSquare /></button>
                            </div>
                        </div>
                    </article>
                    :<div className="empty-form">
                        <img className="img-shofi-loanding" alt="Quiz Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2FPA_Education_Study_001_Jane-PhotoRoom.png-PhotoRoom.png?alt=media&token=5dcc3fdc-e433-466c-a9e1-d9fa21789220&_gl=1*1q7d33j*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI1OTcwLjE5LjAuMA.." />
                        <h2 className="title-shofi-loanding">Novedad cerrada<br></br>ó resuelta, fecha de cierre:</h2>
                        <p>{ticketInfo.email}<br/>{ticketInfo.dateClose.toDate ? ticketInfo.dateClose.toDate().toLocaleString() : new Date(ticketInfo.dateClose.toDate * 1000).toLocaleString()}</p>
                    </div>
                    }
                </section>
            </div>
        </div>
    );
};
