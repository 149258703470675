import { useEffect, useState } from "react";
import { InfoLoading } from "../../../../../Loanding";
import "./ProductStyle.scss";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../../firebase/config";
import { ListProductBase } from "../../../../UI/organisms/ListProductBase/ListProductBase";


export const ListProductBass  = () => {
    const itemsPerPage = 10;
    const [info, setInfo] = useState([]);
    const [productBassList, setProductBassList ] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);  
    const [currentPage, setCurrentPage] = useState(1);
    const [sortType, setSortType] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');
    const [filterCategory, setFilterCategory] = useState('');

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        const fetchUsers = async () => {
            try {
                const usersCollectionRef = collection(db, 'produc');
                const querySnapshot = await getDocs(usersCollectionRef);
                const users = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                const sortedProduct = users.sort((a, b) => {
                    let comparison = 0;
                    if (sortType === 'name') {
                        comparison = a.name.localeCompare(b.name);
                    } else if (sortType === 'SKU') {
                        const dateA = a.SKU || '';
                        const dateB = b.SKU || '';
                        comparison = dateA.localeCompare(dateB);
                    }
                    return sortDirection === 'desc' ? -comparison : comparison;
                });
                const finalUsersList = filterCategory
                    ? sortedProduct.filter((produc) => produc.category === filterCategory)
                    : sortedProduct;
                if (isMounted) setInfo(finalUsersList);
                setProductBassList(sortedProduct);
                setLoading(false);
            } catch (error) {
                if (isMounted) setError(error);
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchUsers();
        return () => { isMounted = false; };
    }, [sortType, sortDirection, filterCategory]);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    }; 

    const numberOfPages = Math.ceil(info.length / itemsPerPage);
    const displayItems = info.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    if (loading) return <InfoLoading/>;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (
        <>
            {productBassList&&
                <div className="product-admin-produc-bass">
                    <ListProductBase
                        info = {info}
                        setSortType = {setSortType}
                        setSortDirection = {setSortDirection}
                        setFilterCategory = {setFilterCategory}
                        handleChange = {handleChange}
                        sortDirection = {sortDirection}
                        displayItems = {displayItems}
                        numberOfPages = {numberOfPages}
                        currentPage = {currentPage}
                        filterCategory = {filterCategory}
                    />
                </div>
            }
        </>
    );
}