import "./Encuestas.scss"; 
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from "../../../../firebase/config";
import { LoginContext } from "../../../../context/LoginContext";
import { Link, useNavigate } from 'react-router-dom';
import { BiCheck, BiChevronLeft, BiCustomize, BiPlus, BiSave, BiX } from "react-icons/bi";
import { InfoLoading } from "../../../../Loanding";
import { v4 as uuidv4 } from 'uuid';
import { QuizAnswer } from "../../../UI/organisms/QuizAnswer/QuizAnswer";
import { toast } from "react-toastify";
import { ResultDiagnostic } from "./result/ResultDiagnostic"

export const EditorEncuestas = ({rol}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [ quizAnswer, setQuizAnswer] = useState({});
    const [ info, setInfo ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);
    const { user } = useContext(LoginContext);
    const { idc } = info;
    const [ title, setTitle ] = useState('');
    const [ nameCompany, setNameCompany] = useState('');
    const [ editable, setEditable] = useState('');
    const [ status, setStatus ] = useState('');
    const [ send, setSend ] = useState('');
    const [ initialDiagnostic, setInitialDiagnostic] = useState('');
    const [ questions, setQuestions] = useState([
        { 
            id: uuidv4(),
            text: '',
            value: 0,
            opcion: [
                { opcione: '', value: '' },
                { opcione: '', value: '' }
            ],
            binding:true,
            activesub:true,
            controlResponse: '',
            subquestions: [
                {
                    id: uuidv4(),
                    text:'',
                    value: 0,
                    binding:true,
                    opcion: [
                        { opcione: '', value: '' },
                        { opcione: '', value: '' }
                    ],
                }
            ],
        }
    ]);
    //----------------------------------------------------------------------------------------------------------------------------------
    // Llamado a colecciones de datos
    useEffect(() => {
        const fetchUser = async () => {
            if (user && user.id) {
                try {
                const userDocRef = doc(db, 'users', user.id);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    setInfo(userDoc.data());
                }
                } catch (error) {
                setError(error);
                } finally {
                setLoading(false);
                }
            }
        };
        fetchUser();
        const fetchSurvey = async () => {
            setLoading(true);
            if (id) {
                try {
                const surveyDocRef = doc(db, 'quiz', id);
                const surveyDoc = await getDoc(surveyDocRef);
                    if (surveyDoc.exists()) {
                        const data = surveyDoc.data();
                        setTitle(data.title);
                        setQuestions(data.questions);
                        setEditable(data.editable);
                        setSend(data.send);
                        setStatus(data.status);
                        setNameCompany(data.nameCompany)
                        setInitialDiagnostic(data.initial_diagnostic)
                    }
                } catch (error) {
                setError(error);
                } finally {
                setLoading(false);
                }
            }
        };
        fetchSurvey();const loadAnswer = async () => {
            if (id || idc) {
                try {
                    const respuestasRef = collection(db, 'quizAnswer');
                    const q = query(respuestasRef, where('ide', '==', id));
                    const e = query(q, where('idc', '==', idc));
                    const querySnapshot = await getDocs(e);
                    let conteoRespuestas = {};
                    querySnapshot.forEach(doc => {
                        const respuestasUsuario = doc.data().respuestas;
                        Object.entries(respuestasUsuario).forEach(([idPregunta, respuestaObjeto]) => {
                            const respuesta = respuestaObjeto.respuesta;
                            // Inicializa conteo para la pregunta si no existe
                            if (!conteoRespuestas[idPregunta]) {
                                conteoRespuestas[idPregunta] = { respuestas: {}, subquestions: {} };
                            }
                            // Conteo de respuestas principales
                            const respuestaUsuario = respuesta.opcione;
                            conteoRespuestas[idPregunta].respuestas[respuestaUsuario] = (conteoRespuestas[idPregunta].respuestas[respuestaUsuario] || 0) + 1;
                            if (respuestaObjeto.subquestions) {
                                Object.entries(respuestaObjeto.subquestions).forEach(([idSubpregunta, subRespuestaObjeto]) => {
                                    if (typeof subRespuestaObjeto === 'object' && subRespuestaObjeto !== null) {
                                        const subRespuesta = subRespuestaObjeto.respuesta;
                                        // Validación adicional
                                        if (subRespuesta && subRespuesta.opcione) {
                                            if (!conteoRespuestas[idPregunta].subquestions[idSubpregunta]) {
                                                conteoRespuestas[idPregunta].subquestions[idSubpregunta] = {};
                                            }
                                            const subRespuestaUsuario = subRespuesta.opcione;
                                            conteoRespuestas[idPregunta].subquestions[idSubpregunta][subRespuestaUsuario] = (conteoRespuestas[idPregunta].subquestions[idSubpregunta][subRespuestaUsuario] || 0) + 1;
                                        } else {
                                            toast.error(`Formato inesperado para subrespuesta`);
                                        }
                                    } else {
                                        toast.error(`Subrespuesta no es un objeto válido:`);
                                    }
                                });
                            }
                        });
                    });
                    setQuizAnswer(conteoRespuestas);
                    setLoading(false);
                } catch (error) {
                    console.log("Cargando respuestas: ", error);
                }
            }
        };
        loadAnswer();
    }, [user, id, idc]);
    // Activar de desactivar sub Preguntas
    const handleToggleSubquestion = (indexQuestion) => {
        const newQuestions = [...questions];
        newQuestions[indexQuestion].activesub = !newQuestions[indexQuestion].activesub;
        setQuestions(newQuestions);
    };
    //----------------------------------------------------------------------------------------------------------------------------------
    // Botton para agregar o eliminar preguntas y editar value de preguntas
    // Nueva Pregunta
    const handleAddQuestion = (index, newText, newValue) => {
        const newQuestions = [...questions];
        newQuestions[index].text = newText;
        const numericValue = parseInt(newValue, 10);
        if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 10) {
            newQuestions[index].value = numericValue.toString(); // o simplemente newValue si quieres mantenerlo como string
        }
        setQuestions(newQuestions);
    };
    // Pregunta editar
    const handleNewQuestion = () => {
        setQuestions([...questions, { 
            id: uuidv4(), 
            text: '', 
            opcion: [
                { opcione: '', value:'0'},
                { opcione: '', value: '0'}
            ],
            binding: true,
            activesub: false,
            value: 0,
            controlResponse: '',
            subquestions: [
                {
                    id: uuidv4(), 
                    text:'',
                    binding: true,
                    value: 0,
                    opcion: [
                        { opcione: '', value: '0'},
                        { opcione: '', value: '0'}
                    ],
                }
            ],
        }]);
    };    
    // Eliminar Pregunta
    const handleDeleteQuestion = (indexQuestion) => {
        const newQuestions = [...questions];
        newQuestions.splice(indexQuestion, 1);
        setQuestions(newQuestions);
    };
    //----------------------------------------------------------------------------------------------------------------------------------
    // Botton para agregar o eliminar opciones y editar value de opciones
     // Nueva opcion
    const handleNewOpcion = (indexQuestion) => {
        const newQuestions = [...questions];
        newQuestions[indexQuestion].opcion.push({ opcione: '', value: '0' });
        setQuestions(newQuestions);
    };
    // Opcion editar
    const handleAddOpcion = (indexQuestion, indexOpcion, newOpcionText, newOpcionValue) => {
        const newQuestions = [...questions];
        const updatedOpcion = {
            ...newQuestions[indexQuestion].opcion[indexOpcion],
            opcione: newOpcionText,
            value: newOpcionValue,
        };
        newQuestions[indexQuestion].opcion[indexOpcion] = updatedOpcion;
        setQuestions(newQuestions);
    };
    // Eliminar Opcines
    const handleDeleteOpcion = (indexQuestion, indexOpcion) => {
        const newQuestions = [...questions];
        newQuestions[indexQuestion].opcion.splice(indexOpcion, 1);
        setQuestions(newQuestions);
    }; 
    //----------------------------------------------------------------------------------------------------------------------------------
    // Botton para agregar o eliminar opciones y editar value de subpreguntas
    // Nueva SubPregunta
    const handleNewSubquestion = (indexQuestion) => {
        const newQuestions = [...questions];
        const newSubquestion = { id: uuidv4(), text: '', binding: true, value: 0, opcion: [{ opcione: '', value: '0' },{ opcione: '', value: '0' }] };
        newQuestions[indexQuestion].subquestions.push(newSubquestion);
        setQuestions(newQuestions);
    };
    //Editar SubPregunta
    const handleEditSubquestion = (indexQuestion, indexSubquestion, newText, newValue) => {
        const newQuestions = [...questions];
        const subquestion = newQuestions[indexQuestion].subquestions[indexSubquestion];
        subquestion.text = newText;
        subquestion.value = newValue;
        setQuestions(newQuestions);
    };
    // Eliminar SubPregunta
    const handleDeleteSubquestion = (indexQuestion, indexSubquestion) => {
        const newQuestions = [...questions];
        newQuestions[indexQuestion].subquestions.splice(indexSubquestion, 1);
        setQuestions(newQuestions);
    };
    //----------------------------------------------------------------------------------------------------------------------------------
    // Botton para agregar o eliminar opciones y editar value de sub opciones
    //Nueva SubOpcion
    const handleNewSubquestionOption = (indexQuestion, indexSubquestion) => {
        const newQuestions = [...questions];
        newQuestions[indexQuestion].subquestions[indexSubquestion].opcion.push({ opcione: '', value: '0' });
        setQuestions(newQuestions);
    };
    //Editar SubOpcion
    const handleEditSubquestionOption = (indexQuestion, indexSubquestion, indexOption, newOptionText, newOptionValue) => {
        const newQuestions = [...questions];
        let subquestion = newQuestions[indexQuestion].subquestions[indexSubquestion];
        let option = subquestion.opcion[indexOption];
        option.opcione = newOptionText;
        option.value = newOptionValue;
        setQuestions(newQuestions);
    };
    //Eliminar SubOpcion
    const handleDeleteSubquestionOption = (indexQuestion, indexSubquestion, indexOption) => {
        const newQuestions = [...questions];
        let subquestion = newQuestions[indexQuestion].subquestions[indexSubquestion];
        subquestion.opcion.splice(indexOption, 1);
        setQuestions(newQuestions);
    };


    //---------------------------------------------------------------------------------------------------------------------------------
    // Boton desactivar
    const handleToggleStatus = async () => {
        setLoading(true); // Inicia el indicador de carga
        try {
            // Obtener referencia al documento de la encuesta
            const surveyDocRef = doc(db, 'quiz', id);
            // Invertir el estado actual y actualizar en Firestore
            const newStatus = !status;
            await updateDoc(surveyDocRef, {
                status: newStatus
            });
            // Actualizar el estado local
            setStatus(newStatus);
            toast.success(`Encuesta ${newStatus ? 'activada' : 'inactivada'} con éxito`);
            navigate('/encuestas/send');
        } catch (error) {
            navigate('/encuestas/send');
            toast.error("Error al cambiar el estado de la encuesta");
        } finally {
            setLoading(false); // Finaliza el indicador de carga
        }
    };

    const handleCheckboxChange = (indexQuestion, indexOpcion) => {
        const updatedQuestions = questions.map((question, index) => {
            if (index === indexQuestion) {
                // Establecer la respuesta de control basada en el checkbox seleccionado
                const controlResponse = question.opcion[indexOpcion].opcione;
                return { ...question, controlResponse };
            }
            return question;
        });
        setQuestions(updatedQuestions);
    };
    
    //---------------------------------------------------------------------------------------------------------------------------------
    // Boton de guardar la actualizacion del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (editable) { // Cambiar a 'true' para permitir guardar cuando sea editable
            try {
                if (id) { // Verificar que el id exista
                    const docRef = doc(db, 'quiz', id);
                    await updateDoc(docRef, { 
                        title, 
                        questions, 
                        idc, 
                        editable,
                    });
                    toast.success("Guardadon con exito la encuesta");
                    // Agregar algún mensaje de éxito o redirección
                    navigate('/encuestas/send');
                }
            } catch (error) {
                toast.error("Error al guardar encuesta: ", error);
            }
        }
    };    
    //---------------------------------------------------------------------------------------------------------------------------------
    if (loading) return <InfoLoading />;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;

    // Resultados en caso de encuesta diagnostica
    if (initialDiagnostic) return <ResultDiagnostic idc={idc} ide={id}/>;
    return (
        <form onSubmit={handleSubmit} className="windons-admin-two">
            <div className="windons-title-section">
                <div className="title-info">
                    <p>Título de la Encuesta:</p>
                    <input 
                        type="text" 
                        value={title} 
                        onChange={(e) => setTitle(e.target.value)} 
                        disabled={!editable} 
                    />
                </div>
                <div className="windons-title-info">
                    {rol === "SA-001" && (
                        <div className='input-date'>
                            <label>Empresa:</label>
                            <h2 className="title2">{nameCompany}</h2>
                        </div>                    
                    )}
                </div>
                <Link className='button-new' to="/encuestas/send"><BiChevronLeft />Volver</Link>
            </div>
            <div className="windons-section-encuesta">
                <div className="list-cuentions">
                    {questions &&
                        (questions.map((question, indexQuestion) => (
                            <div key={indexQuestion} className="cuestions">
                                <div className="container-cuestions">
                                    <div className="cues">
                                        <label><p>Pregunta {indexQuestion + 1}</p></label>
                                        <input
                                            type="text"
                                            value={question.text}
                                            onChange={(e) => handleAddQuestion(indexQuestion, e.target.value, question.value)}
                                            required
                                            disabled={!editable} 
                                        />
                                    </div>
                                </div>
                                {!editable&&
                                    <div className="resultados-encuesta">
                                        {quizAnswer[question.id] && 
                                            <div>
                                                <QuizAnswer respuestas={quizAnswer[question.id].respuestas}/>
                                            </div>
                                        }
                                    </div>
                                }
                                {!send &&(
                                    <div className="container-seleccion">
                                        { question.opcion && 
                                            (question.opcion.map((opcion, indexOpcion) => (
                                                <div key={indexOpcion}>
                                                    <div className="cuestions-opcion">
                                                        <div>
                                                            <label><p>Opción {indexOpcion + 1}:</p></label>
                                                            <input
                                                            type="text"
                                                            value={opcion.opcione}
                                                            onChange={(e) => handleAddOpcion(indexQuestion, indexOpcion, e.target.value, opcion.value)}
                                                            required
                                                            disabled={!editable} 
                                                            />
                                                        </div>
                                                        <div>
                                                            <label><p>Valor:</p></label>
                                                            <input
                                                                type="number"
                                                                min="0"
                                                                max="10"
                                                                value={opcion.value}
                                                                onChange={(e) => handleAddOpcion(indexQuestion, indexOpcion, opcion.opcione, e.target.value)}
                                                                required
                                                                disabled={!editable} 
                                                            />
                                                        </div>
                                                        {question.activesub === true &&
                                                            <div className="div-input-radio">
                                                                <label><p>Selección:</p></label>
                                                                <input
                                                                    className="input-radio"
                                                                    type="radio"
                                                                    required
                                                                    disabled={!editable} 
                                                                    name={`controlResponse-${indexQuestion}`} // Asegúrate de que el nombre sea único por pregunta
                                                                    checked={question.controlResponse === opcion.opcione}
                                                                    onChange={() => handleCheckboxChange(indexQuestion, indexOpcion)}
                                                                />
                                                            </div>
                                                        }
                                                        {editable && (
                                                            <button type="button" onClick={() => handleDeleteOpcion(indexQuestion, indexOpcion)}>
                                                                <BiX/>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            )))
                                        }
                                        {editable && (
                                            <button className="button-plus" type="button" onClick={() => handleNewOpcion(indexQuestion)}>
                                                <BiPlus />
                                            </button>
                                        )}
                                    </div>
                                )}
                                <div>
                                    {editable && (
                                        <label class="content-input">
                                            <input
                                                type="checkbox"
                                                checked={question.activesub}
                                                onChange={() => handleToggleSubquestion(indexQuestion)}
                                            /> SubPreguntas
                                            <i></i>
                                        </label>
                                    )}
                                </div>
                                {question.activesub === true &&
                                    <div className={`${send ? 'list-subcuestions' : ''}`}>
                                        {question.subquestions.map((subquestion, indexSubquestion) => (
                                            <div key={indexSubquestion} className="subcuestions">
                                                <div className="container-subcuestions">
                                                    <div className="cues">
                                                        <label><p>SubPreguntas {indexSubquestion + 1}</p></label>
                                                        <input
                                                            type="text"
                                                            value={subquestion.text}
                                                            onChange={(e) => handleEditSubquestion(indexQuestion, indexSubquestion, e.target.value, subquestion.value)}
                                                            required
                                                            disabled={!editable} 
                                                        />
                                                    </div>
                                                </div>
                                                {!editable&&
                                                    <div>
                                                        {quizAnswer[question.id] && quizAnswer[question.id].subquestions[subquestion.id] && 
                                                            <div>
                                                                <QuizAnswer respuestas={quizAnswer[question.id].subquestions[subquestion.id]}/>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {!send &&(
                                                    <div className="container-subseleccion">
                                                        {subquestion.opcion.map
                                                            ((option, indexOption) => (
                                                                <div key={indexOption}>
                                                                    
                                                                    <div className="cuestions-opcion">
                                                                        <div>
                                                                            <label><p>Opción {indexOption + 1}:</p></label>
                                                                            <input
                                                                                type="text"
                                                                                value={option.opcione}
                                                                                onChange={(e) => handleEditSubquestionOption(indexQuestion, indexSubquestion, indexOption, e.target.value, option.value)}
                                                                                required
                                                                                disabled={!editable} 
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <label><p>Valor:</p></label>
                                                                            <input
                                                                                type="number"
                                                                                min="0"
                                                                                max="10"
                                                                                value={option.value}
                                                                                onChange={(e) => handleEditSubquestionOption(indexQuestion, indexSubquestion, indexOption, option.opcione, e.target.value)}
                                                                                required
                                                                                disabled={!editable} 
                                                                            />
                                                                        </div>
                                                                        {editable && (
                                                                            <button type="button" onClick={() => handleDeleteSubquestionOption(indexQuestion, indexSubquestion, indexOption)}>
                                                                                <BiX/>
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                        {editable && (
                                                            <button className="button-plus" type="button" onClick={() => handleNewSubquestionOption(indexQuestion, indexSubquestion)}>
                                                                <BiPlus />
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                                {editable && (
                                                    <button className="delete-subbutton" type="button" onClick={() => handleDeleteSubquestion(indexQuestion, indexSubquestion)}>
                                                        <BiX/> Eliminar
                                                    </button>
                                                )}
                                            </div>
                                        ))}
                                        <div className="container-button">
                                            {editable && (
                                                <button className='button-load' type="button" onClick={() => handleNewSubquestion(indexQuestion)}>
                                                    <BiPlus /> Agregar Subpregunta
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                }
                                {editable && (
                                    <button className="delete-button" type="button" onClick={() => handleDeleteQuestion(indexQuestion)}>
                                        <BiX/> Eliminar
                                    </button>
                                )}
                            </div>
                        ))) 
                    }
                    <div className="container-button">
                        {editable && (<button className='button-new' type="button" onClick={handleNewQuestion}><BiCustomize />Agregar Pregunta</button>)}
                    </div>
                </div>
                
            </div>
            <div className="botton-section">
                {send&&
                    <button className={status ? 'button-delete' : 'button-new'} onClick={handleToggleStatus}>
                        {status ? <BiX />: <BiCheck /> }
                        {status ? "Inactivar" : "Activar"}
                    </button>
                }
                {editable && (<button className='button-save' type="submit"><BiSave />Guardar</button>)}
            </div>
        </form>
    );
}

