/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
import { useState, useEffect} from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../../firebase/config';
import "./Carrucel_Kits.scss";
import { Kits_Item } from '../../molecules/Kits_Item/Kits_Item';
import Slider from "react-slick";
export const Carrucel_Kits = ({setIsOpenR}) => {
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        let isMounted = true;
        const fetchKits = async () => {
            try {
                const kitsCollectionRef = collection(db, 'kits');
                const querySnapshot = await getDocs(kitsCollectionRef);
                // Transforma los documentos en un array de objetos
                const kits = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
    
                if (isMounted) {
                    setInfo(kits);
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        fetchKits()
        return () => { isMounted = false; };
    }, []);

    const settings = {
        dots: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
    };

    return (
        <section className='carrucel-kits'>
            <h2 className='title-page'>Nuestros <span>Kits</span></h2>
            {info&&<div className='carrucel-item-kits'>
                <Slider {...settings} className="carrucel-item-kits-2-slider">
                    {info.map(kit => (
                        <Kits_Item key={kit.id} {...kit} setIsOpenR={setIsOpenR}/>
                    ))}
                </Slider>
            </div>}
        </section>
    );    
}