import { useEffect, useState } from "react";
import { InfoLoading } from "../../../../Loanding";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { ListProductStock } from "../../../UI/organisms/ListProductStock/ListProductStock";

export const StockCompany = ({idc}) => {
    const itemsPerPage = 10;
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);
    const [ info, setInfo] = useState([]);
    const [ currentPage, setCurrentPage ] = useState(1);

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'stock_company');
                const u = query(q, where("idc", "==", idc));
                const querySnapshot = await getDocs(u);
                if (isMounted) {
                    const items = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setInfo(items);
                }
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => {
            isMounted = false;
        };
    }, [idc]);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    const numberOfPages = Math.ceil( info.length / itemsPerPage);
    const items = info.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    if (loading) return <InfoLoading />;
    if (error) return <p>Error: {error.message}</p>;
    if (!info) return <p>No user info</p>;
    return (
        <div className="equipo-admin">
            <ListProductStock
                idc={idc}
                items={items}
                numberOfPages={numberOfPages}
                handleChange={handleChange}
                currentPage={currentPage}
            />
        </div>
    );
};