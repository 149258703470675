import { collection, addDoc, getDocs, doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { useEffect, useState } from "react";
import { BiChevronLeft, BiPlus, BiSave, BiX } from "react-icons/bi";
import { toast } from "react-toastify";
import { Timestamp } from "firebase/firestore";
import { InfoLoading } from "../../../../Loanding";
// import { ShippingInfomation } from "../../../UI/organisms/ShippingInfomation/ShippingInfomation";


export const CreateKits = ({idc, compañia, rol}) => {
    const navigate = useNavigate();
    const [nameKits, setNameKits] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]); // Lista de productos seleccionados para el kit
    const [products, setProducts] = useState([]); // Lista de todos los productos disponibles
    const [idProduct, setIdProduct] = useState(null)
    const [open, setOpen] = useState(false);
    const [detail, setDetail] = useState([]);
    const [opendetail, setOpenDetail] = useState(false);
    const [ info, setInfo] = useState([]);
    const [ compania, setCompania ] = useState('');
    const [ idcSelect, setIdcSelect ] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTermG,  setSearchTermG] = useState('');
    const [searchTerm,  setSearchTerm] = useState('');

    const handleDetail = (idProduct) => {
        setOpen(!open);
        setIdProduct(idProduct);
    };

    const handleDetailClose = () => {
        setOpen(!open);
        setIdProduct(null);
        setOpenDetail(false);
    }; 

    const handleNoDetail = (idProduct) => {
        setOpen(!open);
        setOpenDetail(!opendetail);
        setIdProduct(idProduct);
    }; 

    useEffect(() => {
        let isMounted = true;
        const fetchInfo = async () => {
            try {
                const q = collection(db, 'company');
                const querySnapshot = await getDocs(q);
                    
                const fetchedInfo = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                    
                if (isMounted) setInfo(fetchedInfo);
            } catch (err) {
                if (isMounted) setError(err);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
        fetchInfo();
        return () => { isMounted = false; }
    }, []);


    useEffect(() => {
        let isMounted = true;
        const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection (db, "produc"));
                if (isMounted) {
                    setProducts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
                }
            } catch (error) {
                toast.error("Error fetching products: ", error);
            }
        };
        fetchProducts();
        return () => { isMounted = false; };
    }, []);

    useEffect(() => {
        const fetchDetail = async () => {
            if (!idProduct) return;
            try {
                const docRef = doc(db, "produc", idProduct);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setDetail(docSnap.data());
                } else {
                    toast.error("No se encontró información del producto");
                }
            } catch (err) {
                toast.error(err);
            } finally {
                toast.error(false);
            }
        };
        fetchDetail();
    }, [idProduct]);
    
    const handleAdd = (productId) => {
        const product = products.find(product => product.id === productId);
        if (product) {
            setSelectedProducts(prevSelected => [...prevSelected, product.id]); // Añadir el producto seleccionado a la lista del kit
        }
    };

    const handleAdds = (productId) => {
        const product = products.find(product => product.id === productId);
        if (product) {
            setSelectedProducts(prevSelected => [...prevSelected, product.id]); // Añadir el producto seleccionado a la lista del kit
            setOpen(!open);
        }
    };

    const handleDelete = (productId) => {
        setSelectedProducts(prevSelected => prevSelected.filter(id => id !== productId)); // Remover el producto seleccionado de la lista del kit
    };

    const handleChange = (e) => {
        const companyId = e.target.value;
        const company = info.find(c => c.id === companyId);
        setIdcSelect(companyId); // Actualiza el estado de idc con el id de la nueva compañía seleccionada
        setCompania(company ? company.namecompany : ''); // Actualiza el estado de compania
    };

    let registerIdc = (rol === 'AA-003' || rol === 'AA-001')? idc : idcSelect;

    const filterProductsS= products.filter(product => (
        product.name.toLowerCase().includes(searchTermG.toLowerCase())||
        product.category.toLowerCase().includes(searchTermG.toLowerCase())
    ));

    const goBack = () => {
        navigate(-1); // Mueve el historial hacia atrás en una entrada
    };
   
    const handleSave = async () => {
        if (!nameKits || selectedProducts.length === 0) {
            toast.error("El nombre del kit y los productos son obligatorios");
            return;
        }
        const currentDate = Timestamp.now();
        try {
            await addDoc(collection(db, 'produc_kits'), {
                name_kits: nameKits,
                kits: selectedProducts,
                idc:registerIdc,
                nameCompany:(rol === 'AA-003' || rol === 'AA-001')?compañia :compania,    
                creation: currentDate,
                editable: true,
                status: true,
                responder: true,
            });
            navigate(-1);
            toast.success('Kit creado con éxito');
        } catch (error) {
            toast.error("Error creando el kit: ", error);
        }
    };
    
    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <>
            {open && (
                <div className="wimdons-emerge-detail">
                    <section className="emerge-detail">
                        <div className="emerge-detail-img">
                            <img alt={detail.name}  src={detail.img}/>
                        </div>
                        <div className="emerge-detail-contect">
                            <h2 className="subtitle3">{detail.name}</h2>
                            <div className="text-emerge">
                                <h4>Categoría: {detail.category}</h4>
                                <p><span>Marca:</span> {detail.brand}</p>
                                <p><span>Descripción:</span> {detail.description}</p>
                            </div>
                            <div className="emerge-detail-button">
                                <button className="button-delete" onClick={handleDetailClose}>
                                    <BiX /> 
                                    Cerrar 
                                </button>
                                {(!opendetail === true) && <button className="button-new" onClick={() => handleAdds(idProduct)}>
                                    <BiPlus />
                                    Agregar
                                </button>}
                            </div>
                        </div>
                    </section>
                </div>
            )}
            <div className="windons-two">
                <div className="windons-title-section">
                    <div className="title-info">
                        <p>Nombre del kit</p>
                        <input  type="text"  value={nameKits}  onChange={(e) => setNameKits(e.target.value)}  />
                    </div>
                    <div className="windons-title-info">
                        {rol === "SA-001" && (
                            <div className='input-date'>
                                <label>Empresa:</label>
                                <select name="selectcompañia" value={idcSelect} onChange={handleChange} required>
                                    <option value="">Seleccionar Seller</option>
                                    {info.map((empresa) => (
                                        <option key={empresa.id} value={empresa.id}>{empresa.namecompany}</option>
                                    ))}
                                </select>
                            </div>                    
                        )}
                    </div>
                    <div>
                        <button className='button-new' onClick={goBack}><BiChevronLeft /> Volver</button>
                    </div>
                </div>
                <div className="windons-section-info">
                    <section className="section-info-two  seleccione-group">
                        <div className="section-title">
                            <div>
                                <label>Productos seleccionados</label>
                                <input 
                                type="text" 
                                placeholder="Buscar productos..." 
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <h2>{selectedProducts.length}</h2>
                        </div>
                        <div className="list-info">
                            {selectedProducts
                                .map(productId => products.find(product => product.id === productId))
                                .filter(product => product.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                .map((product, index) => (
                                    <div key={index} className="item-produc-selec">
                                        <div className="img-item-product">
                                            <div className="avatar-img" onClick={() => handleNoDetail(product.id)}>
                                                <img alt={product.name} src={product.img} />
                                            </div>
                                        </div>
                                        <div>
                                            <p className="name">{product.name}</p>
                                            <p>{product.category}</p>
                                            <p>{product.SKU}</p>
                                            <p>Marca: {product.brand}</p>
                                        </div>
                                        <button onClick={() => handleDelete(product.id)}><BiX /></button>
                                    </div>
                                ))
                            }
                        </div>
                    </section>
                    <section className="section-info-two   seleccione-employe">
                        <div className="section-title">
                            <div>
                                <label>Productos para seleccionar</label>
                                <input 
                                type="text" 
                                placeholder="Buscar productos..." 
                                value={searchTermG}
                                onChange={(e) => setSearchTermG(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="list-info">
                            {filterProductsS.filter(product => !selectedProducts.includes(product.id)).map((product) => (
                                <div key={product.id} className="item-produc">
                                    <div className="img-item-product">
                                        <div className="avatar-img" onClick={() =>  handleDetail(product.id)} > 
                                            <img alt={product ? product.name : ''}  src={product ? product.img : ''} />
                                        </div>
                                    </div>
                                    <button onClick={() => handleAdd(product.id)}>
                                        <p className="name">{product.name}</p>
                                        <p>{product.category}</p>
                                        <p>{product.SKU}</p>
                                        <p>Marca: {product.brand}</p>
                                    </button>
                                    <BiPlus onClick={() => handleAdd(product.id)} />
                                </div>
                            ))}
                        </div>
                    </section>
                </div>
                <div className="container-button">
                    <button className='button-new' onClick={handleSave}><BiSave />Guardar</button>
                </div>
            </div>
        </>
    );
}
