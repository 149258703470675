// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { useEffect, useState } from 'react';
import { BiX } from 'react-icons/bi';
import { useInView } from 'react-intersection-observer';
import { db } from '../../../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
// Component efectos de las secciones
const FadeInSection = ({ children}) => {
    const [ref, inView] = useInView({
        triggerOnce: true, 
        threshold: 0.1,   
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 1000 }, 
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};

export const Catalogue = ({setIsCatalogue, idCatalog}) => {
    const id = idCatalog;
    const [ userData, setUserData ] = useState(null);
    const [ galleryData, setGalleryData ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
          if (id) {
            try {
              const userDocRef = doc(db, 'catalogs', id);
              const userDoc = await getDoc(userDocRef);
              if (userDoc.exists()) {
                const data = userDoc.data();
                setUserData(data);
                if (data.gallery && data.gallery.length > 0) {
                  const galleryDocs = await Promise.all(
                    data.gallery.map(async (galleryId) => {
                      const galleryDocRef = doc(db, 'catalogs_product', galleryId);
                      const galleryDoc = await getDoc(galleryDocRef);
                      return galleryDoc.exists ? galleryDoc.data() : null;
                    })
                  );
                  setGalleryData(galleryDocs.filter(doc => doc !== null));
                }
              } else {
                console.log('No se encontró el documento!');
              }
            } catch (error) {
                console.log('Error al obtener el documento: ' + error.message);
            } finally {
              setLoading(false);
            }
          }
        };
        fetchUser();
    }, [id]);

    const toggleLogin = () => {
        setIsCatalogue (prevIsLogin=> !prevIsLogin);
    };
    return (
        <div className="container-login">
            <button onClick={toggleLogin} className='button-x-flotant'>
                <BiX />
            </button>
            <FadeInSection>
                <div className="section-log">
                    {!loading&&<section className="container-gallery-catalog">
                        <h2 className='gallery-title-catalog'>
                            {userData&&<>{userData.title}</>}
                        </h2>
                        {userData.type==="001"&&
                            <div className='gallery-two-column'>
                                {galleryData.map((item, index) => (
                                    <div key={index} className='container-scroll-column-produc'>
                                        <div className='gallery-two-column-produc'>
                                            {item.imagens && item.imagens.map((img, imgIndex) => (
                                                <img className='gallery-two-column-produc-img' key={imgIndex} src={img} alt={item.name} />
                                            ))}
                                        </div>
                                        <div className='contect-two-column-produc'>
                                            <h4>{item.name}</h4> 
                                            <p>{item.numb}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                        {userData.type==="002"&&
                            <div className='gallery-two-column'>
                                {galleryData.map((item, index) => (
                                    <div key={index} className='container-scroll-column-produc'>
                                        <div className='gallery-one-column-produc'>
                                            {item.imagens && item.imagens.map((img, imgIndex) => (
                                                <img className='gallery-one-column-produc-img' key={imgIndex} src={img} alt={item.name} />
                                            ))}
                                        </div>
                                        <div className='contect-two-column-produc'>
                                            <h4>{item.name}</h4> 
                                            <p>{item.numb}</p>
                                        </div>
                                        <div className='description-two-column-produc'>
                                            <p>{item.content}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                        {userData.type==="003"&&
                            <div className='gallery-four-column'>
                                {galleryData.map((item, index) => (
                                    <div key={index} className='container-scroll-column-produc'>
                                        <div className='gallery-four-column-produc'>
                                            {item.imagens && item.imagens.map((img, imgIndex) => (
                                                <img className='gallery-four-column-produc-img' key={imgIndex} src={img} alt={item.name} />
                                            ))}
                                        </div>
                                        <div className='contect-two-column-produc'>
                                            <h4>{item.name}</h4> 
                                            <p>{item.numb}</p>
                                        </div>
                                        <div className='description-two-column-produc'>
                                            <p>{item.content}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </section>}
                </div>
            </FadeInSection>
        </div>
    );
};