import "./Section_0.scss"
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase/config";

export const Section0 = ({setIsOpenR, setLoading, setError}) => {
    const [info, setInfo] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const specificDocRef = doc(db, 'sections_page', 'WZPJ8jQkvNbyhoLX223g');
        getDoc(specificDocRef)
            .then((document) => {
                const data = {
                    ...document.data(),
                    id: document.id,
                };
                setInfo(data); 
            })
            .catch((err) => {
                if (isMounted) {
                    setError(err);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });
        return () => { isMounted = false; };
    }, [setError, setLoading]);

    const navigate = useNavigate();
    const toggleRe = () => {
        setIsOpenR(prevIsOpenR => !prevIsOpenR);
        navigate( `${info.button.route}`);
    };
    return (
        <section className="section-contect">
            <div className="section-0-contect-container">
                <div className="section-0-title">
                    <h1 className="title-page" dangerouslySetInnerHTML={{ __html: info.title }}></h1>
                    <p className="subtitle-page" dangerouslySetInnerHTML={{ __html: info.subtitle }}></p>
                    <div className="container-button-nav">
                        {info.button&&<button className="button-tipe1"  onClick={toggleRe}>
                            {info.button.title}
                        </button>}
                    </div>
                </div>
                <div className="section-0-img">
                    <img alt={info.title} src={info.img} className="img-0"/>
                </div>
            </div>
        </section>
    );
}

