import "./NavBar.scss"
import { PcMenu } from "../../molecules/PcMenu/PcMenu";
import { Link } from "react-router-dom";
import { MovilMenu } from "../../molecules/MovileMenu/MovileMenu";
import { BurguerButton } from "../../atoms/BurguerButton/BurguerButton";
import React, { useState } from "react";

export const NavBar = ({setIsLogin}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const closeMenu = () => {
        setIsOpen(false);
    };
    return (
        <header>
            <div className="container-menu">
                <Link className="brand-menu" to={"/"}>
                    <img className="brand" alt="Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4"></img>
                </Link>
                <section className="menu">
                    <PcMenu
                    setIsLogin={setIsLogin} 
                    />
                    <BurguerButton 
                    toggleMenu = {toggleMenu} 
                    isOpen = {isOpen}
                    />
                </section>
            </div>
            <MovilMenu 
                setIsLogin={setIsLogin}
                closeMenu = {closeMenu}
                isOpen ={isOpen}
                setIsOpen={setIsOpen}
            />
        </header>
    );
}
