import { FormTrelli } from "../../UI/molecules/Form_Trelli/Form_Trelli";
// Paqueteria de efectos
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import { Section2 } from "../../UI/organisms/Section_2/Section_2";
import { Section3 } from "../../UI/organisms/Section_3/Section_3";
import { useState } from "react";
import { PageLoading } from "../../../Loanding";
import { useLocation } from "react-router-dom";
import { FormWebinar } from "../../UI/molecules/Form_Webinar/Form_Webinar";
// Component efectos de las secciones
const FadeInSection = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true, 
        threshold: 0.1,   
    });
    const props = useSpring({
        opacity: inView ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 1000 }, 
    });
    return <animated.div style={props} ref={ref}>{children}</animated.div>;
};
export const Trelli = ({setIsOpenR, setIdInfo, setIsWindow}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation(); 


    const settings2 = {
        dots: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
    };

    if (loading) return <PageLoading />;
    if (error) return <p>Error: {error.message}</p>;
    return ( 
        <div className="main-home">
            <FadeInSection>
                {location.pathname === "/aliados/treli"
                    ?<FormTrelli setLoading={setLoading} />
                    :<FormWebinar setLoading={setLoading} />
                }
            </FadeInSection>
            <FadeInSection>
                <Section2 settings={settings2} setIsOpenR={setIsOpenR} setLoading={setLoading} setError={setError}/>
            </FadeInSection>
             <FadeInSection>
                <Section3 setLoading={setLoading} setError={setError} setIdInfo={setIdInfo} setIsWindow ={setIsWindow}/>
            </FadeInSection>
        </div>
    );
}
