import React, { useState } from 'react';

export const Section_Gallery = ({ images }) => {
    // Estado para rastrear las imágenes descargadas
    const [downloadedImages, setDownloadedImages] = useState([]);

    const downloadImage = async (id, url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `image_${id}.jpg`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // Liberar el objeto URL después de su uso
            window.URL.revokeObjectURL(downloadUrl);

            // Agregar la imagen al estado de imágenes descargadas
            setDownloadedImages(prev => [...prev, id]);

        } catch (error) {
            console.error("Error descargando la imagen", id, error);
        }
    }

    return (
        <div>
            {images && 
                <div className='gallery-register'>
                    {images.map(image => (
                        <div 
                            className='gallery-register-item'
                            key={image.id} 
                            onClick={() => downloadImage(image.id, image.url)}
                            style={{ 
                                cursor: 'pointer',
                                // Si la imagen se ha descargado, cambia la opacidad a 0.5
                                opacity: downloadedImages.includes(image.id) ? 0.5 : 1 
                            }}
                        >
                            <img 
                                src={image.url} 
                                alt='Shofi'
                            />
                        </div>
                    ))}
                </div>
            }
        </div>
    );
}
