import { BiCheckDouble } from "react-icons/bi";
import { Link } from "react-router-dom";
import { PieChart } from '@mui/x-charts/PieChart';
import Stack from '@mui/material/Stack';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { InfoLoading } from "../../../../Loanding";
import { doc, getDoc, getFirestore} from "firebase/firestore";

export const ResultEncuesta = () => {
    const { id } = useParams ();
    const [answer, setAnswer] = useState ([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchEquipment() {
            try {
                const db = getFirestore();
                const userDocRef = doc(db, 'quizAnswer', id);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    setAnswer(userDoc.data()); // Establecer solo los datos del documento
                } else {
                    toast.error('Documento no encontrado');
                    setError('Documento no encontrado');
                }
            } catch (error) {
                toast.error('Error fetching user data:', error);
                setError(error);
            } finally {
                setLoading(false); // Asegurar que se establezca el estado de carga
            }
        }
        if (id) {
            fetchEquipment();
        }
        return () => { 
            // Aquí podrías limpiar algo si es necesario
        };
    }, [id]);
    // Preparar los datos para la gráfica circular
    const porcentajeRedondeado = Math.round(answer.resultado); // Redondea el porcentaje a un número entero
    const porcentajeRestanteRedondeado = 100 - porcentajeRedondeado; 
    const datosGrafica = [
        { label: 'Positivas', value: porcentajeRedondeado },
        { label: 'Negativas', value: porcentajeRestanteRedondeado }
    ];

    if (loading) return <InfoLoading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <>
            <section className="wimdons-quiz-contador-result">
                <div className="wimdons-quiz-questions">
                    <p className="title-result">Resultados de la encuesta:<br></br><span>{answer.title}</span></p>
                    <div className='dates-dashboard'>
                        <Stack direction="row" className="grad">
                            <PieChart
                                colors={['#1E67E2', '#85D0D5']} // Colores de tu elección
                                series={[{
                                    paddingAngle: 1,
                                    innerRadius: 100,
                                    outerRadius: 120,
                                    cornerRadius: 19,
                                    data: datosGrafica,
                                }]}
                                margin={{ right: 5 }}
                                width={250}
                                height={250}
                                legend={{ hidden: true }}
                            />
                        </Stack>
                        <h2 className="title  result">{answer.resultado.toFixed(0)}%</h2>
                    </div>
                    <Link className="button-result" to={"/encuesta/lista"} ><BiCheckDouble />Volver</Link>
                </div>
            </section>
        </>
    );
}
