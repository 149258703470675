import { InfoLoading } from "../../../../Loanding";
import { Link } from "react-router-dom";
import "./Equipos.scss";
import { BiChevronLeft } from "react-icons/bi";
import { useState } from "react";
import { BulkUpload } from "../creatorsusers/BulkUpload";
import UploadUsers from "../../../UI/molecules/SendUsers/SendUsers";

export const CreadorCvs = ({ idc , compañia , business_id, typecompany}) => {
    const [ loading, setLoading ] = useState(false);
    const csvURL = "https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fplantilla%2FPlantillaUserShofi.csv?alt=media&token=9a6ab531-44b0-4f8c-bcd5-fde5db0c95d4";

    if (!idc ) return <InfoLoading/>;
    if (loading) return <InfoLoading />;
    return (
        <div className="windons-user-cvs">
            <div className="encabezado-list">
                <h2 className="title-equip">Equipo: {compañia}</h2>
                <Link className='button-new' to="/usuarios">
                    <BiChevronLeft /> Volver
                </Link>
            </div>
            <div className="windons-quiz-init">
                <div className="section-quiz-init">
                    <div className="quiz-init-img">
                        <img alt="Shofi Encuestas" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/admin%2Fquiz%2FPA_Education_Study_001_Jane-PhotoRoom.png-PhotoRoom.png?alt=media&token=5dcc3fdc-e433-466c-a9e1-d9fa21789220&_gl=1*1q7d33j*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5OTAxNzM5OC4zNjQuMS4xNjk5MDI1OTcwLjE5LjAuMA.."/>
                        <h2 className="title-shofi-loanding">Este método es mas rápido<br></br>crea usuarios de manera rápida.</h2>
                    </div>
                    {typecompany==="C000001"
                        ?<div className="quiz-init-contect">
                            <h3 className="title-shofi-loanding">Carga de forma masiva usando esta Plantilla CSV:<br></br> Descarga modelo de carga
                                <a
                                    href={csvURL}
                                    download="cargamasiva.csv"
                                    className="button-download"
                                    rel="noopener noreferrer"
                                >
                                    Plantilla CSV
                                </a>
                            </h3>
                            <UploadUsers
                                idc = {idc}
                                business_id = {business_id}
                                compañia = {compañia}
                                setLoading = {setLoading}
                                typecompany = {typecompany}
                            />
                        </div>
                        :<div className="quiz-init-contect">
                            <BulkUpload idc={idc}  company={compañia} typecompany = {typecompany} business_id={business_id} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}