import "./StarcoIversion.scss"
import React, { useState} from 'react';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Link , useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BiChevronLeft} from 'react-icons/bi';
import { LuSave } from "react-icons/lu";
import { HiOutlinePhotograph } from "react-icons/hi";
import { InfoLoading } from '../../../../Loanding';


import { db, storage } from "../../../../firebase/config";
import { collection, doc, setDoc } from "firebase/firestore";

export const StarcoIversion = () => {
    const navigate = useNavigate();
    const [ img, setImg ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ imgPreviewUrl, setImgPreviewUrl ] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);

    const [formData, setFormData] = useState({
        lead: '',
        mentoring: '',
        dayOptions: [],
        hourOptions: {},
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImg(e.target.files[0]);
            const previewUrlImg = URL.createObjectURL(e.target.files[0]);
            setImgPreviewUrl(previewUrlImg);
        }
    };

    const uploadFile = async (file, path) => {
        const fileRef = ref(storage, path);
        const snapshot = await uploadBytes(fileRef, file);
        return getDownloadURL(snapshot.ref);
    };

    // Función modificada para manejar la selección de fechas
    const toggleDaySelection = (day) => {
        const isSelected = formData.dayOptions.find(option => option.value === day.value);
        const isDateSelected = selectedDates.includes(day.value); // Verifica si la fecha ya está seleccionada
        setSelectedDates(isDateSelected
            ? selectedDates.filter(date => date !== day.value) // Si está seleccionada, la remueve
            : [...selectedDates, day.value] // Si no está seleccionada, la agrega
        );
        setFormData({
            ...formData,
            dayOptions: isSelected
                ? formData.dayOptions.filter(option => option.value !== day.value)
                : [...formData.dayOptions, day],
        });
    };


    // Función para manejar el cambio de horas, actualizando hourOptions
    const handleHourChange = (dayValue, startTime = null, endTime = null) => {
        const dayHours = formData.hourOptions[dayValue] || {};
        // Establece la hora de inicio si se proporciona, de lo contrario mantiene la existente
        const start = startTime !== null ? startTime : dayHours.start;
        // Establece la hora de fin si se proporciona, de lo contrario mantiene la existente
        const end = endTime !== null ? endTime : dayHours.end;
        setFormData(prevFormData => ({
            ...prevFormData,
            hourOptions: {
                ...prevFormData.hourOptions,
                [dayValue]: { start, end },
            },
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            let imageUrl = '';
            if (img) imageUrl = await uploadFile(img, `starco/img/${img.name}`);

            const newFileRef = doc(collection(db, "startco_inversion"));
            await setDoc(newFileRef, {
                ...formData,
                timestamp: new Date(),
                imageUrl,
            });
            toast.success("Ficha creada con éxito");
            navigate("/startco");
        } catch (error) {
            toast.error("Error al crear la ficha: " + error.message);
        }
        setLoading(false)
    };

    if (loading) return <InfoLoading />;
    return (
        <div className="starco-admin">
            <div className="encabezado-list">
                <Link className='button-new' to="/startco/inversionista"><BiChevronLeft />Volver</Link>
                <h2 className="title">New inversionista</h2>
            </div>
            <div className='scroll-equip-admin'>
                <form onSubmit={handleSubmit} className="form-contact-e">
                    <div className='column-img'>
                        <div className='section-img'>
                            <div className='input-img'>
                                <img
                                    className='user'
                                    alt='user'
                                    src={imgPreviewUrl ||'https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/users%2Fuser.jpg?alt=media&token=f37c3246-4c72-4270-a332-9bbac12290db&_gl=1*1rnd6m4*_ga*MTg4NDU5NDI3My4xNjkzMjM2Mzg4*_ga_CW55HF8NVT*MTY5NTkwODQ1NS4yNjAuMS4xNjk1OTA4NDk1LjIwLjAuMA..'}
                                />
                            </div>
                            <div className='input-upload'>
                                <label htmlFor="file-upload" className="custom-file-upload">
                                    <HiOutlinePhotograph />   {imgPreviewUrl ? 'Cambiar' : 'Subir Imagen'}
                                </label>
                                <input id="file-upload" type="file" onChange={handleImageChange} style={{display: 'none'}} />
                            </div>
                        </div>
                    </div>
                    <div className='column-form'>
                        <div className='column-form-date'>
                            <div className='input-date-selection'>
                                <button className={`date-selection-button ${selectedDates.includes("2024-03-14") ? "date-selection-button-select" : ""}`} type="button" onClick={() => toggleDaySelection({ label: "14 de Marzo, 2024", value: "2024-03-14" })}>
                                    14 de Marzo, 2024
                                </button>
                                <button className={`date-selection-button ${selectedDates.includes("2024-03-15") ? "date-selection-button-select" : ""}`}  type="button" onClick={() => toggleDaySelection({ label: "15 de Marzo, 2024", value: "2024-03-15" })}>
                                    15 de Marzo, 2024
                                </button>
                            </div>
                            <div className='input-date-hour'>
                                {formData.dayOptions.map((day, index) => (
                                    <div key={index} className='hour-selection'>
                                        <label>{day.label}</label>
                                        <input
                                            type="time"
                                            value={formData.hourOptions[day.value]?.start || ''}
                                            onChange={(e) => handleHourChange(day.value, e.target.value, formData.hourOptions[day.value]?.end)}
                                        />
                                        <input
                                            type="time"
                                            value={formData.hourOptions[day.value]?.end || ''}
                                            onChange={(e) => handleHourChange(day.value, formData.hourOptions[day.value]?.start, e.target.value)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='input-date'>
                            <label>Lidera:</label>
                            <input type="text" name="lead" value={formData.lead} onChange={handleChange} required />
                        </div>
                        <div className='input-date'>
                            <label>Mentoria:</label>
                            <textarea type="text" name="mentoring" value={formData.mentoring} onChange={handleChange} required />
                        </div>
                        <button className='button-new' type="submit" ><LuSave />
                            Crear ficha
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}