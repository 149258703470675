import { Link } from "react-router-dom";
import "./ItenProductBase.scss";

function reduceLength(inputString, maxLength) {
    if (!inputString || typeof inputString !== 'string') return ""; // Retorna cadena vacía si inputString no está definido o no es una cadena
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.slice(0, maxLength);
    }
}
export const ItenProductBase = ({name, description , category , SKU , img , id }) => {
    const shortenedName1 = reduceLength((name || "").toUpperCase(), 1);
    const shortenedName2 = reduceLength( name , 16);
    const shortenedDescription = reduceLength(description|| "", 80);

    return ( 
        <Link to={`/productos/${id}`} className="item-product-bass">
            <div className="avatar">
                {img ? (
                    <div className="avatar-img"> 
                    <img alt="avatar" src={img}/>
                    </div>
                ) : (
                    <h2 className="avatar-letter">{shortenedName1}</h2>
                )}
            </div>
            <p className="name-user">{shortenedName2}</p>
            <p className="date">{SKU}</p>
            <p className="description">{shortenedDescription}</p>
            <p className="category">{category}</p>
        </Link>
    );
}
