import { Link} from "react-router-dom";
import "./MenuLeft.scss"
import { NavMenuAdmin } from "../../atoms/NavMenuAdmin/NavMenuAdmin";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase/config";



export const MenuLeft = ({ rol, idc ,setLoading ,setError }) => {
    const [ company ,setCompany] = useState([]);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        const fetchInfo = async () => {
            try {
                const userDocRef = doc(db, 'company', idc);
                const userDoc = await getDoc(userDocRef);

                if(userDoc.exists() && isMounted) {
                    setCompany(userDoc.data());
                } else if (isMounted) {
                    setError(new Error("No data found"));
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        setLoading(false);
        fetchInfo();
        return () => { isMounted = false; };
    }, [idc, setError, setLoading]);

    
    return (
        <article className="menu-left">
            {!company.imageUrl
                ?<Link className="brand-menu-left" to={"/"}>
                    <img className="logo-shofi" alt="Admin Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4"/>
                    <img className="icon-shofi" alt="Admin Icon Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4"/>
                </Link>
                :<Link className="brand-left" to={"/"}>
                    <img className="logo-shofi" alt="Admin Shofi" src="https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2FLogo%20SHOFI.png?alt=media&token=1a9a4e13-db69-44e8-8896-57a72d5caaf4"/>
                    <img className="logo-shofi2" alt="Admin Shofi" src={company.imageUrl}/>
                    {company.imageUrl &&
                        <img className="icon-shofi" alt="Admin Icon Shofi" src={company.imageUrl}/>
                    }
                </Link>
            }
            <NavMenuAdmin rol={rol}/>
            <div className="logout-left">
                V 1.80
            </div>
        </article>
    );
};
